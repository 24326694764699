import { webConfig } from "../utils/webConfig";

export const END_POINTS = {
  SHOP: {
    GET_MANUFACTURE: `${webConfig.apiBaseUrl}/api/Shop/GetManufacturers`,
    ADD_MANUFACTURE: `${webConfig.apiBaseUrl}/api/Shop/AddManufacturer`,
    UPDATE_MANUFACTURE: `${webConfig.apiBaseUrl}/api/Shop/UpdateManufacturer`,
    GET_SHOP_LOOKUP: `${webConfig.apiBaseUrl}/api/Shop/GetShopLookup`,
    GET_MODEL: `${webConfig.apiBaseUrl}/api/Shop/GetModels`,
    ADD_MODEL: `${webConfig.apiBaseUrl}/api/Shop/AddModel`,
    UPDATE_MODEL: `${webConfig.apiBaseUrl}/api/Shop/UpdateModel`,
    GET_EQUIPMENT: `${webConfig.apiBaseUrl}/api/Shop/GetEquipments`,
    ADD_EQUIPMENT: `${webConfig.apiBaseUrl}/api/Shop/AddEquipment`,
    UPDATE_EQUIPMENT: `${webConfig.apiBaseUrl}/api/Shop/UpdateEquipment`,
    GET_EQUIPMENT_TYPES: `${webConfig.apiBaseUrl}/api/Shop/GetEquipmentTypes`,
    ADD_EQUIPMENT_TYPE: `${webConfig.apiBaseUrl}/api/Shop/AddEquipmentType`,
    UPDATE_EQUIPMENT_TYPE: `${webConfig.apiBaseUrl}/api/Shop/UpdateEquipmentType`,
    GET_SHOP_EQUIPMENT_COLUMNS: `${webConfig.apiBaseUrl}/api/Shop/GetShopEquipmentColumns`,
    ASSIGN_EQUIPMENT_TYPE_COLUMNS: `${webConfig.apiBaseUrl}/api/Shop/AssignEquipmentTypeColumns`,
    GET_EQUIPMENT_FILE: `${webConfig.apiBaseUrl}/api/Shop/GetEquipmentFile`,
    GET_EQUIPMENT_MASTER_FILE: `${webConfig.apiBaseUrl}/api/Shop/GetEquipmentsMasterFile`,
    GET_EQUIPMENT_HISTORY_COLUMN: `${webConfig.apiBaseUrl}/api/Shop/GetEquipmentListHistoryByColumn`,
    GET_EQUIPMENT_LIST_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/GetEquipmentListVersionHistory`,
    CERTIFICATE_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/GetEquipmentListCertificateHistory`,
    ADD_UPDATE_MANUAL: `${webConfig.apiBaseUrl}/api/AddUsedPartsManual`
  },
  GET_TOOL_RENTALS: {
    GET_TOKEN: `${webConfig.apiBaseUrl}/api/GetShopUserToken`,
    VALIDATE_SHOP_GROUP: `${webConfig.apiBaseUrl}/api/ValidateShopGroup`,
    PROJECTS: {
      GET: `${webConfig.apiBaseUrl}/api/GetToolRentalProjects`,
      ADD: `${webConfig.apiBaseUrl}/api/AddToolRentalProject`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdateToolRentalProject`,
      GET_LIST_FOR_DROPDOWN: `${webConfig.apiBaseUrl}/api/GetToolRentalProjectsForDropDown`
    },
    ORDERS: {
      GET: `${webConfig.apiBaseUrl}/api/GetToolRentalOrders`,
      ADD: `${webConfig.apiBaseUrl}/api/AddToolRentalOrder`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdateToolRentalOrder`,
    },
    RATES: {
      GET: `${webConfig.apiBaseUrl}/api/GetToolRentalRates`,
      ADD: `${webConfig.apiBaseUrl}/api/AddToolRentalRate`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdateToolRentalRate`,
    },
    UNITS: {
      GET: `${webConfig.apiBaseUrl}/api/GetToolRentalUnits`,
      ADD: `${webConfig.apiBaseUrl}/api/AddToolRentalUnit`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdateToolRentalUnit`,
    },
    REPORT: {
      GET: `${webConfig.apiBaseUrl}/api/GetToolRentalReport`,
    },
    INVOICES: {
      GET: `${webConfig.apiBaseUrl}/api/GetToolRentalInvoices`,
      DOWNLOAD_INVOICE: `${webConfig.apiBaseUrl}/api/GetToolRentalInvoice`,
    }
  }
};


export const PARTS_END_POINTS = {
  GET_PARTS: {
    GET_TOKEN: `${webConfig.apiBaseUrl}/api/GetShopUserToken`,
    VALIDATE_SHOP_GROUP: `${webConfig.apiBaseUrl}/api/ValidateShopGroup`,
    MANUFACTURERS: {
      GET: `${webConfig.apiBaseUrl}/api/GetPartsManufacturers`,
      ADD: `${webConfig.apiBaseUrl}/api/AddPartsManufacturer`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdatePartsManufacturer`,            
      GET_LIST_FOR_DROPDOWN: `${webConfig.apiBaseUrl}/api/GetPartsManufacturersForDropDown`
    },
    MODELS: {
      GET: `${webConfig.apiBaseUrl}/api/GetPartsModels`,
      ADD: `${webConfig.apiBaseUrl}/api/AddPartsModel`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdatePartsModel`,      
      GET_LIST_FOR_DROPDOWN: `${webConfig.apiBaseUrl}/api/GetAllParts`
    },
    PARTS: {
      GET: `${webConfig.apiBaseUrl}/api/GetParts`,
      ADD: `${webConfig.apiBaseUrl}/api/AddPart`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdatePart`,
      DELETE: `${webConfig.apiBaseUrl}/api/DeletePart`,
      GET_ALL_PARTS: `${webConfig.apiBaseUrl}/api/GetAllParts`
    },
    PARTSDISMANTLEDEQUIPMENTS: {
      GET: `${webConfig.apiBaseUrl}/api/GetPartsDismantledEquipments`,
      ADD: `${webConfig.apiBaseUrl}/api/AddPartsDismantledEquipment`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdatePartsDismantledEquipment`,      
      GET_DISMANTLED_LOOKUP: `${webConfig.apiBaseUrl}/api/GetDismantledEquipmentLookups`,
      DELETE_DISMANTLED: `${webConfig.apiBaseUrl}/api/DeletePartsDismantledEquipment`,
      GET_DISMANTLED_EQUIPMENTS_FACETS: `${webConfig.apiBaseUrl}/api/GetDismantledEquipmentsFacets`,
    },
    PARTS_DISMANTLED_PICKUP_TRUCKS: {
      GET: `${webConfig.apiBaseUrl}/api/GetPartsDismantledPickupTrucks`,
      ADD: `${webConfig.apiBaseUrl}/api/AddPartsDismantledPickupTruck`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdatePartsDismantledPickupTruck`,
      DELETE: `${webConfig.apiBaseUrl}/api/DeletePartsDismantledPickupTruck`,
      PHOTO: `https://shopadmin.sureway.ca/shared_images_used_parts/dismantled/pickuptrucks/`,
    },
    PARTS_DISMANTLED_HEAVY_TRUCKS: {
      GET: `${webConfig.apiBaseUrl}/api/GetPartsDismantledHeavyTrucks`,
      ADD: `${webConfig.apiBaseUrl}/api/AddPartsDismantledHeavyTruck`,
      UPDATE: `${webConfig.apiBaseUrl}/api/UpdatePartsDismantledHeavyTruck`,
      DELETE: `${webConfig.apiBaseUrl}/api/DeletePartsDismantledHeavyTruck`,
      PHOTO: `https://shopadmin.sureway.ca/shared_images_used_parts/dismantled/heavytrucks/`,
    }
  }
};