import { IShopEquipment } from './../getEquipments/getEquipmentConstants';

export enum IUPDATE_SHOP_EQUIPMENT_REQUEST {
    REQUEST = "updateShopEquipment/UPDATE_SHOP_EQUIPMENT_REQUEST",
    SUCCESS = "updateShopEquipment/UPDATE_SHOP_EQUIPMENT_SUCCESS",
    FAILED = "updateShopEquipment/UPDATE_SHOP_EQUIPMENT_FAILED"
};

export interface IUpdateShopEquipmentRequest {
    token: string;
    request: IShopEquipment;
};