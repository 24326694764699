import { PARTS_END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IDELETE_PICKUP_TRUCK_REQUEST } from "./deletePickupTruckConstants";
import { IDeletePickupTruckLoadAction, IDeletePickupTruckLoadFailedAction, IDeletePickupTruckSuccessAction, 
    deletePickupTruckLoadFailedAction, deletePickupTruckLoadSuccessAction } from "./deletePickupTruckActions";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const deletePickupTruckEpic: Epic = (
    action$: ActionsObservable<IDeletePickupTruckLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IDeletePickupTruckSuccessAction | IDeletePickupTruckLoadFailedAction> =>
    action$.ofType(IDELETE_PICKUP_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS_DISMANTLED_PICKUP_TRUCKS.DELETE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<string>): IDeletePickupTruckSuccessAction | IDeletePickupTruckLoadFailedAction => {
                            return deletePickupTruckLoadSuccessAction(response.message);
                        }),
                        catchError((response: PartsResponse<any>) => ActionsObservable.of(deletePickupTruckLoadFailedAction(response.message)))
                    )
            )
        );