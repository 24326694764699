import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_RATES_REQUEST, IRate, IRateRequest } from "./getRatesConstants";


export interface IGetRatesLoadAction {
    type: IGET_RATES_REQUEST.REQUEST;
    data: IRateRequest
}
export const getRatesLoadAction = (data: IRateRequest): IGetRatesLoadAction => ({
    type: IGET_RATES_REQUEST.REQUEST,
    data
});
export interface IGetRatesSuccessAction {
    type: IGET_RATES_REQUEST.SUCCESS;
    list: ToolRentalsResponse<ByIdNumber<IRate>>;
}
export const getRatesLoadSuccessAction = (list: ToolRentalsResponse<ByIdNumber<IRate>>): IGetRatesSuccessAction => ({
    type: IGET_RATES_REQUEST.SUCCESS,
    list
});
export interface IGetRatesLoadFailedAction {
    type: IGET_RATES_REQUEST.FAILED;
    message: string;
}
export const getRatesLoadFailedAction = (message: string): IGetRatesLoadFailedAction => ({
    type: IGET_RATES_REQUEST.FAILED,
    message
});
