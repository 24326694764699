import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_PARTS_REQUEST, IPart } from "./getPartConstants";
import { IGetPartsLoadAction, IGetPartsLoadFailedAction, IGetPartsSuccessAction, getPartsLoadFailedAction, getPartsLoadSuccessAction } from "./getPartActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { PARTS_END_POINTS } from "../../endpoints";
import { withJsonContentType } from "../../epicUtils";

export const getPartsEpic: Epic = (
    action$: ActionsObservable<IGetPartsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPartsSuccessAction | IGetPartsLoadFailedAction> =>
    action$.ofType(IGET_PARTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IPart[]>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS.GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IPart[]>): IGetPartsSuccessAction => {
                            return getPartsLoadSuccessAction(response);
                        }),
                        catchError((response: PartsResponse<IPart[]>) => ActionsObservable.of(getPartsLoadFailedAction(response.message)))
                    )
            )
        );