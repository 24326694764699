import { IModel } from "../../getModels/getModelConstants";
import { IUPDATE_MODEL_REQUEST, IUpdateModelRequest } from "./updateModelConstants";


export interface IUpdateModelLoadAction {
    type: IUPDATE_MODEL_REQUEST.REQUEST;
    data: IUpdateModelRequest
}
export const updateModelLoadAction = (data: IUpdateModelRequest): IUpdateModelLoadAction => ({
    type: IUPDATE_MODEL_REQUEST.REQUEST,
    data
});
export interface IUpdateModelSuccessAction {
    type: IUPDATE_MODEL_REQUEST.SUCCESS;
    data: IModel;
}
export const updateModelLoadSuccessAction = (data: IModel): IUpdateModelSuccessAction => ({
    type: IUPDATE_MODEL_REQUEST.SUCCESS,
    data
});
export interface IUpdateModelLoadFailedAction {
    type: IUPDATE_MODEL_REQUEST.FAILED;
    message: string;
}
export const updateModelLoadFailedAction = (message: string): IUpdateModelLoadFailedAction => ({
    type: IUPDATE_MODEL_REQUEST.FAILED,
    message
});
