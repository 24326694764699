import { ById } from '../../../react/shared/publicInterfaces';
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_EQUIPMENT_TYPES_REQUEST, IGetEquipmentType, IGetEquipmentTypeRequest } from "./getEquipmentTypesConstants";

export interface IGetEquipmentTypesLoadAction {
    type: IGET_EQUIPMENT_TYPES_REQUEST.REQUEST;
    data: IGetEquipmentTypeRequest
}
export const getEquipmentTypesLoadAction = (data: IGetEquipmentTypeRequest): IGetEquipmentTypesLoadAction => ({
    type: IGET_EQUIPMENT_TYPES_REQUEST.REQUEST,
    data
});
export interface IGetEquipmentTypesSuccessAction {
    type: IGET_EQUIPMENT_TYPES_REQUEST.SUCCESS;
    list: PartsResponse<ById<IGetEquipmentType>>;
}
export const getEquipmentTypesLoadSuccessAction = (list: PartsResponse<ById<IGetEquipmentType>>): IGetEquipmentTypesSuccessAction => ({
    type: IGET_EQUIPMENT_TYPES_REQUEST.SUCCESS,
    list
});
export interface IGetEquipmentTypesLoadFailedAction {
    type: IGET_EQUIPMENT_TYPES_REQUEST.FAILED;
    message: string;
}
export const getEquipmentTypesLoadFailedAction = (message: string): IGetEquipmentTypesLoadFailedAction => ({
    type: IGET_EQUIPMENT_TYPES_REQUEST.FAILED,
    message
});
