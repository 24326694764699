
async function getSOCTEquipmentMoves(unit_number:string){
    let master_temp_list:any = [];
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type':'application/json' }, 
          body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', 
          request:{
            Unit_Number:unit_number
          }
        })
      }
        await fetch('https://api.sureway.ca/api/SOCT/GetSOCTEquipmentMoves', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((history:any) => {
              // Remove date of moves that were added automatically as 0-0-0 in backend
              if(history.date_Of_Move < "2000-01-01"){
                history.date_Of_Move ="N/A";
              }
              master_temp_list.push(history);
            });
        });
        return master_temp_list;
    }

    async function updateEquipmentMove(requestObject:any, currentUser:string){
        var cancelled:String;
        if(requestObject.cancelled){
          cancelled = "Yes";
        }
        else{
          cancelled = "No";
        }
        let responseStatus = null;
        const requestOptions = {
          method: 'PATCH',
          headers: { 'Content-Type':'application/json' }, 
          body: JSON.stringify({
            token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
            request:{
                ID:requestObject.id,
                Unit_Number:requestObject.unit_Number,
                New_Location:requestObject.new_Location,
                New_Sub_Location:requestObject.new_Sub_Location,
                New_Sub_Location_ID:requestObject.new_Sub_Location_ID,
                Scheduled_Move:requestObject.scheduled_Move,
                Date_Of_Move:requestObject.date_Of_Move,
                Comments:requestObject.comments,
                Cancelled:cancelled,
                Modified_By:currentUser,
                }
        })
      }
        await fetch('https://api.sureway.ca/api/SOCT/UpdateSOCTEquipmentMove', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json')
              // error response
            if(!response.ok) {
                const error = (data) || response.status;
                return Promise.reject(error);
            }
            responseStatus = response;
        });
        return responseStatus;
    }
    
async function AddEquipmentMove(requestObject:any, currentUser:string){
  console.log('adding: ', requestObject);
    let responseStatus = null;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
        request:{
            ID:requestObject.id,
            Unit_Number:requestObject.unit_number,
            Current_Location:requestObject.current_location,
            Current_Sub_Location:requestObject.current_sub_location,
            Current_Sub_Location_ID:requestObject.current_sub_location_ID,
            New_Location:requestObject.new_location,
            New_Sub_Location:requestObject.new_sub_location,
            New_Sub_Location_ID:requestObject.new_sub_location_ID,
            Scheduled_Move:requestObject.scheduled_move,
            Date_Of_Move:requestObject.date_of_move,
            Comments:requestObject.comments,
            Created_By: currentUser,
            Modified_By:currentUser,
            }
    })
  }
    await fetch('https://api.sureway.ca/api/SOCT/AddSOCTEquipmentMove', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')
          // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
    });
    return responseStatus;
}

    export {getSOCTEquipmentMoves, updateEquipmentMove, AddEquipmentMove};