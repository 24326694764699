import React from 'react';
import {TextField, Select, Paper, Grid, Button, Typography, FormControl, InputLabel, MenuItem} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {RouteComponentProps} from 'react-router-dom';

interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
    showMain:boolean;showMeterHist:boolean;showMoveHist:boolean;showCustomHist:boolean;showServiceHist:boolean;
}
export default class CreateMeterHistory extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false};
        this.onTextChange = this.onTextChange.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
    }
    onTextChange(){
    }
    onFormSave(){
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    render(){
        return(
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Creating new Meter History</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Typography
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                >
                                    Sureway Oil Change Tracker
                                </Typography>
                              
                                  
                                    </Toolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                    <Grid container spacing={6}>
                        <Grid item xs={4}>
                            <TextField disabled type="date" helperText="Previous Date" placeholder="prev_date"></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Previous Reading" placeholder="prev Reading"></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField required type="date" helperText="Date"></TextField>
                        </Grid>
                        <Grid item xs={4}>
                        <FormControl sx={{width:'100%'}}>
                        <InputLabel id="demo-simple-select-label">M_Unit_#</InputLabel>
                            <Select 
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                            >
                                <MenuItem value='test1'>test1</MenuItem>
                                <MenuItem value='test2'>test2</MenuItem>
                                <MenuItem value='test3'>test3</MenuItem>
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField required label="Current Hours" placeholder="hours"></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Location" placeholder="location"></TextField>
                        </Grid>
                        <Grid item xs={4}>
                        <FormControl sx={{width:'100%'}}>
                        <InputLabel id="demo-simple-select-label">Do you want to Update Location</InputLabel>
                            <Select 
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Do you want to Update Location"
                            >
                                <MenuItem value='Yes'>Yes</MenuItem>
                                <MenuItem value='No'>No</MenuItem>
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}><br></br>
                            <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                            <Button style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} variant="contained">Save</Button>
                        </Grid>
            </Grid>
                    </Paper>
                    </Paper>
                </Paper>
            </div>
        )
    }
}