import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_REPORT_REQUEST, IReport, IReportRequest } from "./getReportConstants";


export interface IGetReportLoadAction {
    type: IGET_REPORT_REQUEST.REQUEST;
    data: IReportRequest
}
export const getReportLoadAction = (data: IReportRequest): IGetReportLoadAction => ({
    type: IGET_REPORT_REQUEST.REQUEST,
    data
});
export interface IGetReportSuccessAction {
    type: IGET_REPORT_REQUEST.SUCCESS;
    list: ToolRentalsResponse<ByIdNumber<IReport>>;
}
export const getReportLoadSuccessAction = (list: ToolRentalsResponse<ByIdNumber<IReport>>): IGetReportSuccessAction => ({
    type: IGET_REPORT_REQUEST.SUCCESS,
    list
});
export interface IGetReportLoadFailedAction {
    type: IGET_REPORT_REQUEST.FAILED;
    message: string;
}
export const getReportLoadFailedAction = (message: string): IGetReportLoadFailedAction => ({
    type: IGET_REPORT_REQUEST.FAILED,
    message
});
