
export enum IGET_EQUIPMENT_VERSION_HISTORY_REQUEST {
    REQUEST = "getEquipmentVersionHistory/GET_EQUIPMENT_VERSION_HISTORY_REQUEST",
    SUCCESS = "getEquipmentVersionHistory/GET_EQUIPMENT_VERSION_HISTORY_SUCCESS",
    FAILED = "getEquipmentVersionHistory/GET_EQUIPMENT_VERSION_HISTORY_FAILED"
};

export interface IGetEquipmentVersionHistoryRequest {
    id: number;
    token: string;
};

export interface IGetEquipmentVersionHistory {
    id: number;
    itemID: number;
    equipment: string;
    equipment_Type: string;
    owner: string;
    serial_Number: string;
    details: string;
    tire_Size: string;
    status: string;
    year: number;
    driver: string;
    gl: string;
    current_Hours: string;
    class: string;
    positive_Air: string;
    gpS_Level: string;
    gpS_Grade: string;
    plate: string;
    plated: string;
    cert_Req: string;
    truck_Trailer_Due: string;
    tank_Crane_Due: string;
    cert_Number: string;
    inspection_Date: string;
    gvw: string;
    insurance_Category: string;
    equipment_Details: string;
    swing_Grease_Depth: string;
    swing_Grease_Volume: string;
    divisions: string;
    model_ID: number;
    quick_Attach_Serial: string;
    unit_Number: string;
    purchase_Date: string;
    purchase_Meter: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    location: string;
    comments: string;
    attachments: string;
    purchased_From: string;
    positive_Air_Shutoff: string;
    driver_Start_Date: string;
    driver_End_Date: string;
    driver_Owned_By: string;
    driver_Comments: string;
    service_Hours: string;
    operation: number;
    make: string;
    model: string;
};