import React from 'react';
import {TextField, Select, Paper, Grid, Button} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {RouteComponentProps} from 'react-router-dom';
import EditSOCTMain from './editSOCTMain';
import EditServiceHistoryTable from './editServiceHistoryTable';
import EditMeterHistoryTable from './editMeterHistoryTable';
import EditMoveHistoryTable from './editMoveHistoryTable';
import EditReportTable from './editReportTable';
import {editData, renderPageType} from '../../../sotc';
import SOCTHome from '../../../socthome';

interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
    showMain:boolean;showMeterHist:boolean;showMoveHist:boolean;showCustomHist:boolean;showServiceHist:boolean;data:any[];
    serviceHistory:any[];meterHistory:any[];moveHistory:any[];customHistory:any[];
}
export default class EditSOTCForm extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false, data:[],
        serviceHistory:[], meterHistory:[],moveHistory:[],customHistory:[]};
        this.renderHistoryReports = this.renderHistoryReports.bind(this);
        this.renderMeterHistory = this.renderMeterHistory.bind(this);
        this.renderMoveHistory = this.renderMoveHistory.bind(this);
        this.renderServiceHist = this.renderServiceHist.bind(this);
        this.renderSOCTMain = this.renderSOCTMain.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
    }
    renderSOCTMain(){
        this.setState({showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false});
    }
    renderMeterHistory(){
        this.setState({showMeterHist:true,showMain:false,showMoveHist:false,showCustomHist:false,showServiceHist:false});
    }
    renderMoveHistory(){
        this.setState({showMoveHist:true,showCustomHist:false,showServiceHist:false,showMain:false,showMeterHist:false});
    }
    renderHistoryReports(){
        this.setState({showCustomHist:true,showMain:false,showMeterHist:false,showServiceHist:false,showMoveHist:false});
    }
    renderServiceHist(){
        this.setState({showServiceHist:true,showMoveHist:false,showMain:false,showMeterHist:false,showCustomHist:false});
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    componentWillMount(){
        console.log(editData, renderPageType)
        if(!editData){
            this.props.history.push('/soct/lists');
            window.location.reload();
        }
        if(renderPageType==='Services'){
            this.renderServiceHist();
        }
        if(renderPageType==='Meters'){
            this.renderMeterHistory();
        }
        if(renderPageType==='Moves'){
            this.renderMoveHistory();
        }
        this.setState({});
    }
    render(){
        return (
            <>
            <SOCTHome history={this.props.history}></SOCTHome>
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Editing Unit # {editData.unit_Number}</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Button
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                   onClick={this.renderSOCTMain}
                                >
                                    Sureway Oil Change Tracker
                                </Button>
                              
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    <Button
                                         sx={{ my: 2, color: 'white', display: 'block'}}
                                         onClick={this.renderServiceHist}
                                     >
                                         Service History
                                     </Button>
                                   
                                        <Button
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                            onClick={this.renderMeterHistory}
                                        >
                                            Meter History
                                        </Button>

                                        <Button
                                         sx={{ my: 2, color: 'white', display: 'block' }}
                                         onClick={this.renderMoveHistory}
                                     >
                                         Equipment Move History
                                     </Button>
                                     
                                     <Button
                                         sx={{ my: 2, color: 'white', display: 'block' }}
                                         onClick={this.renderHistoryReports}
                                     >
                                        Reports
                                     </Button>
                                    </Box>
                                    </Toolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                    {this.state.showMain ?
                        <EditSOCTMain editData={editData} history={this.props.history} data={this.state.data}></EditSOCTMain>
                    :null}
                    {this.state.showMeterHist ?  
                        <EditMeterHistoryTable editData={editData} history={this.props.history} data={this.state.meterHistory}></EditMeterHistoryTable>
                    :null}
                    {this.state.showServiceHist ?  
                        <EditServiceHistoryTable editData={editData} history={this.props.history} data={this.state.serviceHistory}></EditServiceHistoryTable>
                    :null}
                    {this.state.showMoveHist ?  
                        <EditMoveHistoryTable editData={editData} history={this.props.history} data={this.state.moveHistory}></EditMoveHistoryTable>
                   :null}
                     {this.state.showCustomHist ?  
                        <EditReportTable editData={editData} history={this.props.history} data={this.state.customHistory}></EditReportTable>
                    :null}
                    {/* <Grid item xs={12}>
                        <br></br>
                            <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                            <Button style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} variant="contained">Save</Button>
                        </Grid> */}
                    </Paper>
                    </Paper>
                </Paper>
            </div>
            </>
        );
    }
}