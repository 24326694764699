import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IDispatch, IStore } from "../../../redux/reducers";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAPaperWithPadding } from "../../shared/paper";
import { LAButton } from "../../shared/buttons";
import { ROUTE } from "../../routes";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import image1 from "./images/image1.jpg";
import image2 from "./images/image2.jpg";
import image3 from "./images/image3.jpg";
import image4 from "./images/image4.jpg";
import image5 from "./images/image5.jpg";
import image6 from "./images/image6.jpg";
import { userName } from "../../shared/constExports";
import { validateShopGroup } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { hasPayload, Server, STATUS_ENUM } from "../../../redux/server";
import { PartsResponse } from "../../shared/publicInterfaces";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";



interface IDesktopHomeStoreProps {
    validateShopGroup: Server<PartsResponse<IValidateShopGroup>>;
};

interface IDesktopHomeDispatchProps {

};

interface IDesktopHomeOwnProps {

};

interface IDesktopHomeState {

};

const DesktopHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;
`;

type IDesktopHomeProps = RouteComponentProps
    & IDesktopHomeStoreProps
    & IDesktopHomeDispatchProps
    & IDesktopHomeOwnProps;

class DesktopHome extends PureComponent<IDesktopHomeProps, IDesktopHomeState> {

    public constructor(props: IDesktopHomeProps) {
        super(props);
        this.state = {

        };

    }

    public componentDidMount(): void {

    };

    public async componentDidUpdate(prevProps: IDesktopHomeProps, prevState: IDesktopHomeState): Promise<void> {
    };

    public render(): ReactNode {

        const { validateShopGroup } = this.props;
        const access = hasPayload(validateShopGroup) ? validateShopGroup.payload.response : undefined;

        return (
            <DesktopHomeStyles>
                <LAGrid spacing={3} className="text-center">

                    <LAGridItem xs={12}>
                        <h2>Welcome {userName}</h2>
                    </LAGridItem>

                    {validateShopGroup.kind === STATUS_ENUM.LOADING && <>
                        <LAGridItem xs={12}>
                            <LALoading message="Please wait.... checking your roles" />
                        </LAGridItem>
                    </>}

                    {validateShopGroup.kind === STATUS_ENUM.FAILED && <>
                        <LAGridItem xs={12}>
                            <LAErrorBox text="Failed to load your roles...." />
                        </LAGridItem>
                    </>}

                    {validateShopGroup.kind === STATUS_ENUM.SUCCEEDED && access && <>

                        {access.toolrentalsaccess && <LAGridItem xs={access.partsaccess ? 6 : 12}>
                            <LAButton fullWidth={true} label="Tool Rentals" onClick={this.onToolRentalClick} />
                        </LAGridItem>}

                        {access.partsaccess && <LAGridItem xs={access.toolrentalsaccess ? 6 : 12}>
                            <LAButton fullWidth={true} label="Parts" onClick={this.onPartsClick} />
                        </LAGridItem>}

                    </>}

                    {/* <LAGridItem xs={6}>
                        <LAButton fullWidth={true} label="SOCT" onClick={this.onSOCTClick} />
                    </LAGridItem> */}

                    <LAGridItem xs={12} className="slider">
                        <ImageGallery
                            showNav={false}
                            showPlayButton={false}
                            showFullscreenButton={false}
                            showThumbnails={true}
                            autoPlay={true}
                            items={images}
                        />
                    </LAGridItem>

                </LAGrid>
            </DesktopHomeStyles>
        );
    }

    private onToolRentalClick = (): void => {
        this.props.history.push(ROUTE.TOOL_RENTALS.INDEX);
    };

    private onPartsClick = (): void => {
        this.props.history.push(ROUTE.PARTS.PART.INDEX);
    };

    private onSOCTClick = (): void => {
        this.props.history.push(ROUTE.SOCT.INDEX);
    };


}

const mapStateToProps = (state: IStore): IDesktopHomeStoreProps => ({
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IDesktopHomeDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopHome);

export interface IImageGallery {
    original: string;
    thumbnail: string;
    originalWidth: string;
    originalHeight: string;
};

export const images: ReactImageGalleryItem[] = [
    {
        original: image1,
        thumbnail: image1,
        originalHeight: 550,
        originalWidth: 550
    },
    {
        original: image2,
        thumbnail: image2,
        originalHeight: 550,
        originalWidth: 550
    },
    {
        original: image3,
        thumbnail: image3,
        originalHeight: 550,
        originalWidth: 550
    },
    {
        original: image4,
        thumbnail: image4,
        originalHeight: 550,
        originalWidth: 550
    },
    {
        original: image5,
        thumbnail: image5,
        originalHeight: 550,
        originalWidth: 550
    },
    {
        original: image6,
        thumbnail: image6,
        originalHeight: 550,
        originalWidth: 550
    }
];
