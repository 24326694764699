import { ReactNode, PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ById, ByIdNumber, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IProject, IProjectRequest } from "../../../../redux/toolRentals/getProjects/getProjectsConstants";
import { getProjects } from "../../../../redux/toolRentals/getProjects/getProjectsAccessor";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getProjectsLoadAction } from "../../../../redux/toolRentals/getProjects/getProjectsActions";
import { MEDIA_QUERY_PHONE } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithPadding } from "../../../shared/paper";
import { ProjectGrid } from "./projectGrid";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { IUpdateProjectRequest } from "../../../../redux/toolRentals/update/project/updateProjectConstants";
import { updateProject } from "../../../../redux/toolRentals/update/project/updateProjectAccessor";
import { updateProjectLoadAction } from "../../../../redux/toolRentals/update/project/updateProjectActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { IAddProjectRequest } from "../../../../redux/toolRentals/add/project/addProjectConstants";
import { addProjectLoadAction } from "../../../../redux/toolRentals/add/project/addProjectActions";
import { addProject } from "../../../../redux/toolRentals/add/project/addProjectAccessor";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import LARoleBasedAccess from "../../../shared/roleBaseAccess";
import { IRoles, IValidateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { userName } from "../../../shared/constExports";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { ROUTE } from "../../../routes";

interface IProjectStoreProps {
    token: Server<ToolRentalsResponse<IToken>>;
    ProjectList: Server<ToolRentalsResponse<ByIdNumber<IProject>>>;
    UpdateProject: Server<string>;
    AddProject: Server<string>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IProjectDispatchProps {
    RequestProjectList: (request: IProjectRequest) => unknown;
    RequestAddProject: (request: IAddProjectRequest) => unknown;
    RequestUpdateProject: (request: IUpdateProjectRequest) => unknown;
};

interface IProjectOwnProps {

};

interface IProjectState {
    projects: ByIdNumber<IProject>;
    addEdit: IProject | undefined;
    error: ById<IFieldErrorKeyValue>;
};

const ProjectStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .moveRight {
        position: absolute;
        top: 0px;
        right: 10px;
    };
`;

type IProjectProps = RouteComponentProps
    & IProjectStoreProps
    & IProjectDispatchProps
    & IProjectOwnProps;

class Project extends PureComponent<IProjectProps, IProjectState> {

    public constructor(props: IProjectProps) {
        super(props);
        this.state = {
            projects: {},
            error: {},
            addEdit: undefined
        };
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IProjectProps, prevState: IProjectState): Promise<void> {
        if (this.props.ProjectList !== prevProps.ProjectList) {
            this.callReduxForData();
        };
    };

    public render(): ReactNode {

        const { ProjectList } = this.props;
        const { projects, error, addEdit } = this.state;

        return (
            <LARoleBasedAccess error={true} roleFor={IRoles[1]}>
                <ProjectStyles>
                    <LAGrid spacing={2}>
                        {(ProjectList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                            <LACenteredLoading />
                        </LAGridItem>}

                        {(ProjectList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                            <LAErrorBox text="Failed to Load Projects" />
                        </LAGridItem>}

                        {(ProjectList.kind === STATUS_ENUM.SUCCEEDED) && <>
                            <LAGridItem xs={12}>
                                <h2 className="text-center">{!addEdit ? "PROJECTS" : "ADD/UPDATE PROJECT"}</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                {addEdit ? <ProjectGrid
                                    {...this.state}
                                    data={addEdit}
                                    error={error}
                                    onEdit={this.handleEdit}
                                    onChange={this.onChange}
                                    onSave={this.handleSave}
                                /> :
                                    <LADevExtremeGrid
                                        add={true}
                                        export={true}
                                        onAdd={this.handleAdd}
                                        actionWidth={160}
                                        removeStyleBtn={true}
                                        searchPanel={true}
                                        exportFileName="Projects"
                                        filterHeader={true}
                                        onClick={this.handleEdit}
                                        onEdit={this.handleEdit}
                                        data={Object.values(projects)}
                                        columns={[
                                            { name: "project_Number", caption: "Project Number", type: "string" },
                                            { name: "company", caption: "Company", type: "string" },
                                            { name: "project_Name", caption: "Project Name", type: "string" },
                                            { name: "coordinator", caption: "Coordinator", type: "string" },
                                            { name: "status", caption: "Status", type: "string" },
                                            { name: "rental_Status", caption: "Rental Status", type: "string" }
                                        ]}
                                    />}
                            </LAGridItem>

                            <Prompt
                                when={(addEdit !== undefined)}
                                message='You have unsaved changes, are you sure you want to leave?'
                            />
                        </>}
                    </LAGrid>

                    <RequestStatus requestStatus={this.props.AddProject.kind} failedMessage="Failed to Add Project, Duplicate Project #" successMessage="Project Successfully Added" />
                    <RequestStatus requestStatus={this.props.UpdateProject.kind} failedMessage="Failed to Update Project, Duplicate Project #" successMessage="Project Successfully Updated" />
                </ProjectStyles>
            </LARoleBasedAccess>
        );
    }

    private onChange = (name: string, value: string) => {

        if (this.state.addEdit) {
            const iSError = this.state.error;
            if (value !== undefined) {
                if (value.length > 0) {
                    if (iSError[name])
                        delete iSError[name];
                } else {
                    iSError[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                };
            };

            this.setState({
                ...this.state,
                addEdit: {
                    ...this.state.addEdit,
                    [name]: value
                },
                error: iSError
            });
        }
    };

    private handleAdd = (): void => {
        const error: ById<IFieldErrorKeyValue> = {};
        error["project_Number"] = { key: "project_Number", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["company"] = { key: "company", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["project_Name"] = { key: "project_Name", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["coordinator"] = { key: "coordinator", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["status"] = { key: "status", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

        const time = new Date().toISOString();

        const addEdit: IProject = {
            project_Name: "",
            company: "",
            project_Number: "",
            coordinator: "",
            status: "",
            id: 0,
            rental_Status: "",
            modified: time,
            modified_By: userName,
            created: time,
            created_By: userName
        };

        this.setState({ addEdit: addEdit, error });
    };

    private handleEdit = (event: any): void => {
        const { addEdit } = this.state;
        if (event === undefined && addEdit && addEdit.id === 0) {
            this.setState({ addEdit: undefined, error: {} });
        } else {
            if (event === undefined && addEdit && addEdit.id !== 0) {
                if (hasPayload(this.props.ProjectList)) {
                    this.setState({ addEdit: undefined, error: {} });
                }
            } else if (event !== undefined) {
                this.setState({ addEdit: this.state.projects[event.row.data.id], error: {} });
            }
        };
    };


    private callReduxForData = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.toolrentalsaccess === true) {
                if (isNotLoaded(this.props.ProjectList)) {
                    this.sendRequest();
                };

                if (hasPayload(this.props.ProjectList)) {
                    this.setState({ projects: this.props.ProjectList.payload.response });
                };
            } else {
                this.props.history.push(ROUTE.TOOL_RENTALS.UNAUTHORIZED);
            }
        };
    };

    private sendRequest = (): void => {
        if (hasPayload(this.props.token))
            this.props.RequestProjectList({
                token: this.props.token.payload.response.token
            });
    };

    private handleSave = (): void => {
        if (this.state.addEdit)
            if (this.state.addEdit.id !== 0) {
                this.updateRequest();
            } else {
                this.addRequest();
            }
    };

    private updateRequest = (): void => {
        if (hasPayload(this.props.token) && this.state.addEdit) {
            this.props.RequestUpdateProject({
                request: this.state.addEdit,
                token: this.props.token.payload.response.token
            });

            this.setState({ addEdit: undefined });
        }
    };

    private addRequest = (): void => {
        if (hasPayload(this.props.token) && this.state.addEdit) {
            this.props.RequestAddProject({
                request: this.state.addEdit,
                Keywords: "",
                PageSize: 20,
                PageNumber: 1,
                token: this.props.token.payload.response.token
            });

            this.setState({ addEdit: undefined });
        }
    };

}

const mapStateToProps = (state: IStore): IProjectStoreProps => ({
    token: getToken(state),
    ProjectList: getProjects(state),
    AddProject: addProject(state),
    UpdateProject: updateProject(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IProjectDispatchProps => ({
    RequestProjectList: (request: IProjectRequest): unknown => dispatch(getProjectsLoadAction(request)),
    RequestAddProject: (request: IAddProjectRequest): unknown => dispatch(addProjectLoadAction(request)),
    RequestUpdateProject: (request: IUpdateProjectRequest): unknown => dispatch(updateProjectLoadAction(request))
});


export default connect(mapStateToProps, mapDispatchToProps)(Project);