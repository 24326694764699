import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_ALL_PARTS_REQUEST } from "./getAllPartsConstants";
import { IGetAllPartsLoadAction, IGetAllPartsLoadFailedAction, IGetAllPartsSuccessAction, getAllPartsLoadFailedAction, getAllPartsLoadSuccessAction } from "./getAllPartsActions";
import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { PARTS_END_POINTS } from "../../endpoints";
import { withJsonContentType } from "../../epicUtils";
import { IPart } from "../getParts/getPartConstants";

export const getAllPartsEpic: Epic = (
    action$: ActionsObservable<IGetAllPartsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetAllPartsSuccessAction | IGetAllPartsLoadFailedAction> =>
    action$.ofType(IGET_ALL_PARTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<ById<IPart>>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS.GET_ALL_PARTS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<ById<IPart>>): IGetAllPartsSuccessAction => {
                            return getAllPartsLoadSuccessAction(response);
                        }),
                        catchError((response: PartsResponse<ById<IPart>>) => ActionsObservable.of(getAllPartsLoadFailedAction(response.message)))
                    )
            )
        );