import { IDELETE_DISMANTLED_REQUEST, IDeleteDismantledRequest } from "./deleteDismantledConstants";


export interface IDeleteDismantledLoadAction {
    type: IDELETE_DISMANTLED_REQUEST.REQUEST;
    data: IDeleteDismantledRequest
}
export const deleteDismantledLoadAction = (data: IDeleteDismantledRequest): IDeleteDismantledLoadAction => ({
    type: IDELETE_DISMANTLED_REQUEST.REQUEST,
    data
});
export interface IDeleteDismantledSuccessAction {
    type: IDELETE_DISMANTLED_REQUEST.SUCCESS;
    message: string;
}
export const deleteDismantledLoadSuccessAction = (message: string): IDeleteDismantledSuccessAction => ({
    type: IDELETE_DISMANTLED_REQUEST.SUCCESS,
    message
});
export interface IDeleteDismantledLoadFailedAction {
    type: IDELETE_DISMANTLED_REQUEST.FAILED;
    message: string;
}
export const deleteDismantledLoadFailedAction = (message: string): IDeleteDismantledLoadFailedAction => ({
    type: IDELETE_DISMANTLED_REQUEST.FAILED,
    message
});
