import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_ORDER_REQUEST } from "./updateOrderConstants";
import { IUpdateOrderLoadAction, IUpdateOrderLoadFailedAction, IUpdateOrderSuccessAction, updateOrderLoadFailedAction, updateOrderLoadSuccessAction } from "./updateOrderActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const updateOrderEpic: Epic = (
    action$: ActionsObservable<IUpdateOrderLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateOrderSuccessAction | IUpdateOrderLoadFailedAction> =>
    action$.ofType(IUPDATE_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<any>>(
                    END_POINTS.GET_TOOL_RENTALS.ORDERS.UPDATE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<any>): IUpdateOrderSuccessAction | IUpdateOrderLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateOrderLoadSuccessAction(action.data.request);
                            }
                            const res = response;
                            res.response.unshift(action.data.request.id.toString());
                            return updateOrderLoadFailedAction(res);
                        }),
                        catchError((response: ToolRentalsResponse<any>) => ActionsObservable.of(updateOrderLoadFailedAction(response)))
                    )
            )
        );