import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { IUPDATE_PART_HEAVY_TRUCK_REQUEST } from "./updateHeavyTruckConstants";
import { IUpdatePartHeavyTruckLoadAction, IUpdatePartHeavyTruckLoadFailedAction, IUpdatePartHeavyTruckSuccessAction, updatePartHeavyTruckLoadFailedAction, updatePartHeavyTruckLoadSuccessAction } from "./updateHeavyTruckActions";

export const updatePartHeavyTruckEpic: Epic = (
    action$: ActionsObservable<IUpdatePartHeavyTruckLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdatePartHeavyTruckSuccessAction | IUpdatePartHeavyTruckLoadFailedAction> =>
    action$.ofType(IUPDATE_PART_HEAVY_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<null>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS_DISMANTLED_HEAVY_TRUCKS.UPDATE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<null>): IUpdatePartHeavyTruckSuccessAction | IUpdatePartHeavyTruckLoadFailedAction => {
                            if(response.message === "Success"){
                            return updatePartHeavyTruckLoadSuccessAction(action.data.request);
                            }
                            return updatePartHeavyTruckLoadFailedAction(response.message);
                        }),
                        catchError((response: PartsResponse<any>) => ActionsObservable.of(updatePartHeavyTruckLoadFailedAction(response.message)))
                    )
            )
        );