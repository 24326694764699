import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_LOOKUPS_REQUEST, IShopLookups, IShopLookupsRequest } from "./getLookupsConstants";


export interface IGetLookupsLoadAction {
    type: IGET_LOOKUPS_REQUEST.REQUEST;
    data: IShopLookupsRequest
}
export const getLookupsLoadAction = (data: IShopLookupsRequest): IGetLookupsLoadAction => ({
    type: IGET_LOOKUPS_REQUEST.REQUEST,
    data
});
export interface IGetLookupsSuccessAction {
    type: IGET_LOOKUPS_REQUEST.SUCCESS;
    list: PartsResponse<IShopLookups>;
}
export const getLookupsLoadSuccessAction = (list: PartsResponse<IShopLookups>): IGetLookupsSuccessAction => ({
    type: IGET_LOOKUPS_REQUEST.SUCCESS,
    list
});
export interface IGetLookupsLoadFailedAction {
    type: IGET_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getLookupsLoadFailedAction = (message: string): IGetLookupsLoadFailedAction => ({
    type: IGET_LOOKUPS_REQUEST.FAILED,
    message
});
