import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IADD_SHOP_EQUIPMENT_REQUEST, IAddShopEquipmentRequest } from "./addShopEquipmentsConstants";


export interface IAddShopEquipmentLoadAction {
    type: IADD_SHOP_EQUIPMENT_REQUEST.REQUEST;
    data: IAddShopEquipmentRequest
}
export const addShopEquipmentLoadAction = (data: IAddShopEquipmentRequest): IAddShopEquipmentLoadAction => ({
    type: IADD_SHOP_EQUIPMENT_REQUEST.REQUEST,
    data
});
export interface IAddShopEquipmentSuccessAction {
    type: IADD_SHOP_EQUIPMENT_REQUEST.SUCCESS;
    message: PartsResponse<string>;
}
export const addShopEquipmentLoadSuccessAction = (message: PartsResponse<string>): IAddShopEquipmentSuccessAction => ({
    type: IADD_SHOP_EQUIPMENT_REQUEST.SUCCESS,
    message
});
export interface IAddShopEquipmentLoadFailedAction {
    type: IADD_SHOP_EQUIPMENT_REQUEST.FAILED;
    message: string;
}
export const addShopEquipmentLoadFailedAction = (message: string): IAddShopEquipmentLoadFailedAction => ({
    type: IADD_SHOP_EQUIPMENT_REQUEST.FAILED,
    message
});
