import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IModelRequest } from "../../getModels/getModelConstants";
import { IGET_MODEL_LIST_DD_REQUEST, IModelListDD } from "./getModelListDDConstants";


export interface IGetModelListDDLoadAction {
    type: IGET_MODEL_LIST_DD_REQUEST.REQUEST;
    data: IModelRequest
}
export const getModelListDDLoadAction = (data: IModelRequest): IGetModelListDDLoadAction => ({
    type: IGET_MODEL_LIST_DD_REQUEST.REQUEST,
    data
});
export interface IGetModelListDDSuccessAction {
    type: IGET_MODEL_LIST_DD_REQUEST.SUCCESS;
    list: PartsResponse<IModelListDD[]>;
}
export const getModelListDDLoadSuccessAction = (list: PartsResponse<IModelListDD[]>): IGetModelListDDSuccessAction => ({
    type: IGET_MODEL_LIST_DD_REQUEST.SUCCESS,
    list
});
export interface IGetModelListDDLoadFailedAction {
    type: IGET_MODEL_LIST_DD_REQUEST.FAILED;
    message: string;
}
export const getModelListDDLoadFailedAction = (message: string): IGetModelListDDLoadFailedAction => ({
    type: IGET_MODEL_LIST_DD_REQUEST.FAILED,
    message
});
