import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { UnitIcon } from "../shared/icons";

interface IManufacturerDropDownProps {
    onDrawer?(): void;
};

type IManufacturerDropDown =
    RouteComponentProps &
    IManufacturerDropDownProps;

class ManufacturerDropDown extends PureComponent<IManufacturerDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoManufacturerPage}>
                <UnitIcon />
                <span className="ml-2">
                    Makes
                </span>
            </LAMenuItem>
        );
    };

    private gotoManufacturerPage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.PARTS.MANUFACTURER.INDEX);
    };

}

export default ManufacturerDropDown;
