import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_PARTS_HEAVY_TRUCK_REQUEST, IPartHeavyTruck } from "./getPartsDismantledHeavyTruckConstants";
import { IGetPartsDismantledHeavyTrucksLoadAction, IGetPartsDismantledHeavyTrucksLoadFailedAction, IGetPartsDismantledHeavyTrucksSuccessAction, getPartsDismantledHeavyTrucksLoadSuccessAction,getPartsDismantledHeavyTrucksLoadFailedAction} from "./getPartsDismantledHeavyTruckActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { PARTS_END_POINTS } from "../../endpoints";
import { withJsonContentType } from "../../epicUtils";

export const getPartsDismantledHeavyTrucksEpic: Epic = (
    action$: ActionsObservable<IGetPartsDismantledHeavyTrucksLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPartsDismantledHeavyTrucksSuccessAction | IGetPartsDismantledHeavyTrucksLoadFailedAction> =>
    action$.ofType(IGET_PARTS_HEAVY_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IPartHeavyTruck[]>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS_DISMANTLED_HEAVY_TRUCKS.GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IPartHeavyTruck[]>): IGetPartsDismantledHeavyTrucksSuccessAction => {
                            return getPartsDismantledHeavyTrucksLoadSuccessAction(response);
                        }),
                        catchError((response: PartsResponse<IPartHeavyTruck[]>) => ActionsObservable.of(getPartsDismantledHeavyTrucksLoadFailedAction(response.message)))
                    )
            )
        );