import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, PartsResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { IShopEquipmentRequest } from "../../../../redux/shop/getEquipments/getEquipmentConstants";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../../shared/roleBaseAccessForShop";
import { ReadAndWrite, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getEquipmentTypes } from "../../../../redux/shop/getEquipmentTypes/getEquipmentTypesAccessor";
import { getEquipmentTypesLoadAction } from "../../../../redux/shop/getEquipmentTypes/getEquipmentTypesActions";
import { IGetEquipmentType, IGetEquipmentTypeRequest } from "../../../../redux/shop/getEquipmentTypes/getEquipmentTypesConstants";


interface IEquipmentTypesStoreProps {
    getToken: Server<PartsResponse<IToken>>;
    getEquipmentTypes: Server<PartsResponse<ById<IGetEquipmentType>>>;
};

interface IEquipmentTypesDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getEquipmentTypesRequest: (data: IShopEquipmentRequest) => unknown;
};


interface IEquipmentTypesOwnProps {

};

interface IEquipmentTypesState {

};

const EquipmentTypesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IEquipmentTypesProps = RouteComponentProps
    & IEquipmentTypesStoreProps
    & IEquipmentTypesDispatchProps
    & IEquipmentTypesOwnProps;

class EquipmentTypes extends PureComponent<IEquipmentTypesProps, IEquipmentTypesState> {

    public constructor(props: IEquipmentTypesProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        //this.callServer();
        window.location.href = "https://apps.sureway.ca/field/equipment-types";
    };

    public componentDidUpdate(prevProps: IEquipmentTypesProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getEquipmentTypes } = this.props;
        const data = hasPayload(getEquipmentTypes) ? getEquipmentTypes.payload.response : [];

        return (
            <PageSpacing title="Equipment Types" description="SHOP - Equipment Types" fixedSpaceOnSmallerScreens={true}>
                <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>
                    <EquipmentTypesStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">EQUIPMENT TYPE LIST</h2>
                        <hr />

                        <LADevExtremeGrid
                            data={Object.values(data)}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            actionWidth={120}
                            removeStyleBtn={true}
                            height = {940}
                            exportFileName="Equipment Types"
                            columns={[
                                { name: "name", caption: "Name", type: "string" },
                                { name: "active", caption: "Active", type: "string" },
                                { name: "modified", caption: "Modified", type: "date" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />
                    </EquipmentTypesStyles>
                </RoleBasedAccessForShop>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SHOP.EQUIPMENT_TYPE.TYPE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SHOP.EQUIPMENT_TYPE.TYPE
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getEquipmentTypes) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getEquipmentTypesRequest({
                token: this.props.getToken.payload.response.token
            });
    };

}

const mapStateToProps = (state: IStore): IEquipmentTypesStoreProps => ({
    getToken: getToken(state),
    getEquipmentTypes: getEquipmentTypes(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IEquipmentTypesDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getEquipmentTypesRequest: (data: IGetEquipmentTypeRequest) => dispatch(getEquipmentTypesLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentTypes);