import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Route, Switch } from "react-router";
import { IStore, IDispatch } from "../redux/reducers";
import { ROUTE } from "./routes";
import Error404Page from "./error404Page";
import Header from "./header/header";
import MobileHeader from "./header/mobileHeader";
import Home from "./component/home/home";
import { getToken } from "../redux/getToken/getTokenAccessor";
import { IToken, ITokenRequest } from "../redux/getToken/getTokenConstants";
import { getTokenLoadAction } from "../redux/getToken/getTokenActions";
import { ToolRentalsResponse } from "./shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../redux/server";
import { userName } from "./shared/constExports";
import { LACenteredLoading } from "./shared/loading";
import { UnAuthorizedPage } from "./unAuthorizedPage";
import ToolRentalSecuredRoutes from "./toolRentalSecuredRoutes";
import PartsSecuredRoutes from "./partsSecuredRoutes";
import ShopManufacturers from "./component/shop/manufacturer/manufacturers";
import ShopManufacturer from "./component/shop/manufacturer/manufacturer";
import ShopEquipments from "./component/shop/equipment/equipments";
import ShopEquipment from "./component/shop/equipment/equipment";
import EquipmentTypes from "./component/shop/equipmentType/equipmentTypes";
import EquipmentType from "./component/shop/equipmentType/equipmentType";
import ConfigEquipmentType from "./component/shop/config/adminConfig";
import { IValidateShopGroup } from "../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroup } from "../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { validateShopGroupLoadAction } from "../redux/toolRentals/validateShopGroup/validateShopGroupActions";
import styled from "styled-components";
import { MEDIA_QUERY_PHONE } from "./shared/theme";
import SOTC from './component/soct/sotc';
import CreateSOTCPage from './component/soct/forms/SOCTMain/create/createSOTCPage';
import EditSOTCForm from './component/soct/forms/SOCTMain/edit/editSOTCPage';
import Meters from './component/soct/meters';
import CreateMeterHistory from './component/soct/forms/meters/createMeterHistory';
import EditMeterHistory from './component/soct/forms/meters/editMeterHistory';
import Sites from './component/soct/workingsites';
import CreateWorkingSite from './component/soct/forms/workingsites/createWorkingSite';
import EditWorkingSite from './component/soct/forms/workingsites/editWorkingSite';
import Moves from './component/soct/moves';
import CreateEquipmentMoves from './component/soct/forms/equipmentmoves/createEquipmentMoves';
import EditEquipmentMoves from './component/soct/forms/equipmentmoves/editEquipmentMoves';
import ScheduledService from './component/soct/scheduledservice';
import EditScheduledService from './component/soct/forms/scheduledservice/editScheduledService';
import BulkSOTC from "./component/soct/bulksoctedit";
import MeterAppTable from "./component/soct/meterapptable";


interface ISecuredRoutesStoreProps {
    token: Server<ToolRentalsResponse<IToken>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface ISecuredRoutesDispatchProps {
    RequestToken: (request: ITokenRequest) => unknown;
    RequestValidateShopGroup: (request: ITokenRequest) => unknown;
};

interface ISecuredRoutesState {
    showHeader: boolean;
    loading: boolean;
};

const SecuredRoutesStyles = styled.div`
    
    .mobileView {
        display: none;
    };

    .desktopView {
        display: block;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .mobileView {
            display: block;
        };

        .desktopView {
            display: none;
        };
    };
`;

type ISecuredRoutesProps =
    RouteComponentProps &
    ISecuredRoutesDispatchProps &
    ISecuredRoutesStoreProps;

class SecuredRoutes extends Component<ISecuredRoutesProps, ISecuredRoutesState> {
    public constructor(props: ISecuredRoutesProps) {
        super(props);
        this.state = {
            showHeader: true,
            loading: true
        };
    }

    public componentDidMount(): void {
        this.authorizationCheck();
    }

    public componentDidUpdate(prevProps: ISecuredRoutesProps): void {
        if (this.props.token !== prevProps.token) {
            this.authorizationCheck();
            this.requestValidateShopGroup();
        };
    }

    public render(): ReactNode {
        return (
            this.state.loading ? <LACenteredLoading message="Loading..." /> :
                <SecuredRoutesStyles>
                    
                    <div className="desktopView">
                        <Header {...this.props} validateGroup={this.props.validateShopGroup} />
                    </div>

                    <div className="mobileView">
                        <MobileHeader {...this.props} />
                    </div>

                    <Switch>
                        <Route exact={true} path={ROUTE.INDEX} component={Home} />
                        <Route path={ROUTE.TOOL_RENTALS.INDEX} component={ToolRentalSecuredRoutes} />
                        <Route path={ROUTE.PARTS.INDEX} component={PartsSecuredRoutes} />
                        <Route path={ROUTE.UNAUTHORIZED} component={UnAuthorizedPage} />
                        {/* <Route path={ROUTE.SHOP.MODEL.INDEX} component={ShopModels} /> */}
                        {/* <Route path={ROUTE.SHOP.MODEL.MODEL} component={ShopModel} /> */}
                        <Route path={ROUTE.SHOP.MANUFACTURER.INDEX} component={ShopManufacturers} />
                        <Route path={ROUTE.SHOP.MANUFACTURER.MANUFACTURER} component={ShopManufacturer} />
                        <Route path={ROUTE.SHOP.EQUIPMENT.INDEX} component={ShopEquipments} />
                        <Route path={ROUTE.SHOP.EQUIPMENT.EQUIPMENT} component={ShopEquipment} />
                        <Route path={ROUTE.SHOP.EQUIPMENT_TYPE.INDEX} component={EquipmentTypes} />
                        <Route path={ROUTE.SHOP.EQUIPMENT_TYPE.TYPE} component={EquipmentType} />
                        <Route path={ROUTE.SHOP.CONFIG.INDEX} component={ConfigEquipmentType} />
                        {/* <Route exact path={ROUTE.SOCT.INDEX} component={SOCTHome}></Route> */}
                        <Route exact path={ROUTE.SOCT.INDEX} component={SOTC}></Route>
                        <Route path={ROUTE.SOCT.SOCT_MAIN.LISTCREATE} component={CreateSOTCPage}></Route>
                        <Route path={ROUTE.SOCT.SOCT_MAIN.LISTEDIT} component={EditSOTCForm}></Route>
                        <Route exact path={ROUTE.SOCT.METERS.INDEX} component={Meters}></Route>
                        <Route path={ROUTE.SOCT.METERS.METERSCREATE} component={CreateMeterHistory}></Route>
                        <Route path={ROUTE.SOCT.METERS.METERSEDIT} component={EditMeterHistory}></Route>
                        <Route exact path={ROUTE.SOCT.SERVICES.INDEX} component={ScheduledService}></Route>
                        <Route path={ROUTE.SOCT.SERVICES.SCHEDULEDSERVICEEDIT} component={EditScheduledService}></Route>
                        <Route exact path={ROUTE.SOCT.EQUIPMENT_MOVES.INDEX} component={Moves}></Route>
                        <Route path={ROUTE.SOCT.EQUIPMENT_MOVES.MOVESCREATE} component={CreateEquipmentMoves}></Route>
                        <Route path={ROUTE.SOCT.EQUIPMENT_MOVES.MOVESEDIT} component={EditEquipmentMoves}></Route>
                        <Route exact path={ROUTE.SOCT.WORKING_SITES.INDEX} component={Sites}></Route>
                        <Route path={ROUTE.SOCT.WORKING_SITES.SITESCREATE} component={CreateWorkingSite}></Route>
                        <Route path={ROUTE.SOCT.WORKING_SITES.SITESEDIT} component={EditWorkingSite}></Route>
                        <Route path={ROUTE.SOCT.BULKSOCT.INDEX} component={BulkSOTC}></Route>
                        <Route path={ROUTE.SOCT.METERAPP.INDEX} component={MeterAppTable}></Route>
                       
                        <Route render={(): ReactNode => <Error404Page handleShowHeader={this.handleShowHeader} />} />
                    </Switch>
                </SecuredRoutesStyles>
        );
    }

    private handleShowHeader = (showHeader: boolean): void => {
        this.setState({ showHeader });
    };

    private authorizationCheck = (): void => {
        switch (this.props.token.kind) {
            case STATUS_ENUM.SUCCEEDED:
                if (this.props.token.payload.message !== "Success" && this.props.token.payload.response === null) {
                    if (this.state.loading === true) {
                        this.setState({ loading: false });
                    } else {
                        this.props.history.push(ROUTE.UNAUTHORIZED);
                    }
                } else {
                    if (this.state.loading)
                        this.setState({ loading: false });
                }
                break;

            case STATUS_ENUM.NOT_LOADED:
                this.getToken();
                break;

            case STATUS_ENUM.FAILED:
                if (this.state.loading === true) {
                    this.setState({ loading: false });
                } else {
                    this.props.history.push(ROUTE.UNAUTHORIZED);
                }
                break;

            case STATUS_ENUM.LOADING:
                if (this.state.loading === false) {
                    this.setState({ loading: true });
                }
                break;

            default:
                break;
        }
    };

    private getToken = (): void => {
        if (isNotLoaded(this.props.token))
            this.props.RequestToken({ request: { username: userName } });
    };

    private requestValidateShopGroup = (): void => {
        if (isNotLoaded(this.props.validateShopGroup) && hasPayload(this.props.token))
            this.props.RequestValidateShopGroup({ request: { username: userName }, token: this.props.token.payload.response.token });
    };

}

const mapStateToProps = (state: IStore): ISecuredRoutesStoreProps => ({
    token: getToken(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISecuredRoutesDispatchProps => ({
    RequestToken: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    RequestValidateShopGroup: (request: ITokenRequest): unknown => dispatch(validateShopGroupLoadAction(request))
});


export default connect(mapStateToProps, mapDispatchToProps)(SecuredRoutes);
