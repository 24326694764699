import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IProps{
    cancelPopup:any;
    confirmPopup:any;
}
interface IState{
    open:boolean;
}
export default class DialogPrompt extends React.Component<IProps, IState>{
  constructor(props:any){
      super(props);
      this.state = {open:true};
      this.handleClickOpen = this.handleClickOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
  }

handleClickOpen() {
    this.setState({open:true});
  }

   handleClose(){
    this.setState({open:false});
  }

  render(){
    return (
        <div>
          <Dialog open={this.state.open} onClose={this.handleClose}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Current Hours is more than 100 of the previous reading. Please Confirm. 
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.cancelPopup}>Cancel</Button>
              <Button onClick={this.props.confirmPopup}>Confirm</Button>
            </DialogActions>
          </Dialog>
        </div>
      );
  }
}