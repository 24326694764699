import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { UnitIcon } from "../shared/icons";

interface IRateDropDownProps {
    onDrawer?(): void;
};

type IRateDropDown =
    RouteComponentProps &
    IRateDropDownProps;

class RateDropDown extends PureComponent<IRateDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoRatePage}>
                <UnitIcon />
                <span className="ml-2">
                    Rates
                </span>
            </LAMenuItem>
        );
    };

    private gotoRatePage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.TOOL_RENTALS.RATE.INDEX);
    };

}

export default RateDropDown;
