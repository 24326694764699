import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_MODELS_REQUEST, IModel, IModelRequest } from "./getModelConstants";


export interface IGetModelsLoadAction {
    type: IGET_MODELS_REQUEST.REQUEST;
    data: IModelRequest
}
export const getModelsLoadAction = (data: IModelRequest): IGetModelsLoadAction => ({
    type: IGET_MODELS_REQUEST.REQUEST,
    data
});
export interface IGetModelsSuccessAction {
    type: IGET_MODELS_REQUEST.SUCCESS;
    list: PartsResponse<IModel[]>;
}
export const getModelsLoadSuccessAction = (list: PartsResponse<IModel[]>): IGetModelsSuccessAction => ({
    type: IGET_MODELS_REQUEST.SUCCESS,
    list
});
export interface IGetModelsLoadFailedAction {
    type: IGET_MODELS_REQUEST.FAILED;
    message: string;
}
export const getModelsLoadFailedAction = (message: string): IGetModelsLoadFailedAction => ({
    type: IGET_MODELS_REQUEST.FAILED,
    message
});
