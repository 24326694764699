import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_RATES_REQUEST, IRate } from "./getRatesConstants";
import { IGetRatesLoadAction, IGetRatesLoadFailedAction, IGetRatesSuccessAction, getRatesLoadFailedAction, getRatesLoadSuccessAction } from "./getRatesActions";
import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { END_POINTS } from "../../endpoints";
import { withJsonContentType } from "../../epicUtils";

export const getRatesEpic: Epic = (
    action$: ActionsObservable<IGetRatesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRatesSuccessAction | IGetRatesLoadFailedAction> =>
    action$.ofType(IGET_RATES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<ByIdNumber<IRate>>>(
                    END_POINTS.GET_TOOL_RENTALS.RATES.GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<ByIdNumber<IRate>>): IGetRatesSuccessAction => {
                            return getRatesLoadSuccessAction(response);
                        }),
                        catchError((response: ToolRentalsResponse<string>) => ActionsObservable.of(getRatesLoadFailedAction(response.message)))
                    )
            )
        );