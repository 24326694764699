import { IPaginationRequest } from "../../../../react/shared/publicInterfaces";
import { IAddUpdatePartDismantledEquipment } from "../../update/dismantledEquipment/updateDismantledEquipmentConstants";

export enum IADD_PART_DISMANTLED_EQUIPMENT_REQUEST {
    REQUEST = "addPart/ADD_PART_DISMANTLED_EQUIPMENT_REQUEST",
    SUCCESS = "addPart/ADD_PART_DISMANTLED_EQUIPMENT_SUCCESS",
    FAILED = "addPart/ADD_PART_DISMANTLED_EQUIPMENT_FAILED"
};

export interface IAddPartDismantledEquipmentRequest extends IPaginationRequest {
    request: IAddUpdatePartDismantledEquipment;
    token: string;
};