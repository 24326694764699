import { IPart } from "../../getParts/getPartConstants";

export enum IUPDATE_PART_REQUEST {
    REQUEST = "updatePart/UPDATE_PART_REQUEST",
    SUCCESS = "updatePart/UPDATE_PART_SUCCESS",
    FAILED = "updatePart/UPDATE_PART_FAILED"
};

export interface IUpdatePartRequest {
    request: IPart;
    token: string;
};