import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { ReportIcon, UnitIcon } from "../shared/icons";
import { Menu } from "@material-ui/core";

interface IPartDropDownProps {
    onDrawer?(): void;
};

interface IPartDropDownState {
    report: any;
};

type IPartDropDown =
    RouteComponentProps &
    IPartDropDownProps;

class PartDropDown extends PureComponent<IPartDropDown, IPartDropDownState> {

    public constructor(props: IPartDropDown) {
        super(props);
        this.state = {
            report: null
        };
    }

    public render(): ReactNode {
        return (
            <>
                <LAMenuItem onClick={this.onPart}>
                    <UnitIcon />
                    <span className="ml-2">
                        Parts
                    </span>
                </LAMenuItem>

                <Menu
                    anchorEl={this.state.report}
                    open={this.state.report}
                    onClose={this.onPart}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <LAMenuItem onClick={this.gotoPartPage}>
                        <UnitIcon />
                        <span className="ml-2">
                            Parts
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.goToReport}>
                        <ReportIcon />
                        <span className="ml-2">
                            Report
                        </span>
                    </LAMenuItem>
                </Menu>
            </>
        );
    };

    private onPart = (event?: any): void => {
        if (!this.props.onDrawer) {
            if (this.state.report !== null) {
                this.setState({ report: null });
            } else {
                this.setState({ report: event.currentTarget });
            }
        } else {
            this.gotoPartPage();
        }
    };

    private gotoPartPage = (): void => {
        if (this.props.onDrawer)
            this.props.onDrawer();
        this.props.history.push(ROUTE.PARTS.PART.INDEX);        
        this.setState({ report: null });
    };

    private goToReport = (): void => {
        this.props.history.push(ROUTE.PARTS.PART.REPORT);
        this.onPart();
    };

}

export default PartDropDown;