export enum IGET_PROJECT_LIST_DD_REQUEST {
    REQUEST = "getProjectListDD/GET_PROJECT_LIST_DD_REQUEST",
    SUCCESS = "getProjectListDD/GET_PROJECT_LIST_DD_SUCCESS",
    FAILED = "getProjectListDD/GET_PROJECT_LIST_DD_FAILED"
};

export interface IProjectListDD {
    id: number;
    project_Number: string;
    project_Name: string;
};