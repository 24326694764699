
export enum IADD_EQUIPMENT_TYPE_REQUEST {
    REQUEST = "addEquipmentType/ADD_EQUIPMENT_TYPE_REQUEST",
    SUCCESS = "addEquipmentType/ADD_EQUIPMENT_TYPE_SUCCESS",
    FAILED = "addEquipmentType/ADD_EQUIPMENT_TYPE_FAILED"
};

export interface IAddEquipmentTypeRequest {
    token: string;
    request: {
        Name: string;
        Active: string;
        Created_By: string;
        Modified_By: string;
    };
};