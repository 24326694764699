import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_PART_DISMANTLED_EQUIPMENT_REQUEST } from "./addDismantledEquipmentConstants";
import { IAddPartDismantledEquipmentLoadAction, IAddPartDismantledEquipmentLoadFailedAction, IAddPartDismantledEquipmentSuccessAction} from "./addDismantledEquipmentActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IAddPartDismantledEquipmentLoadAction
    | IAddPartDismantledEquipmentSuccessAction
    | IAddPartDismantledEquipmentLoadFailedAction
    | IFlushDataSuccessAction;

export const AddPartDismantledEquipmentReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.REQUEST:
            return loading;

        case IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.SUCCESS:
            return succeeded("Success");

        case IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};