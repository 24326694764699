import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, PartsResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { getShopManufacturers } from "../../../../redux/shop/getManufactures/getShopManufacturesAccessor";
import { getShopManufacturesLoadAction } from "../../../../redux/shop/getManufactures/getShopManufacturesActions";
import { IShopManufacture, IShopManufactureRequest } from "../../../../redux/shop/getManufactures/getShopManufacturesConstants";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../../shared/roleBaseAccessForShop";
import { ReadAndWrite, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";


interface IShopManufacturersStoreProps {
    getToken: Server<PartsResponse<IToken>>;
    getManufacturers: Server<PartsResponse<ById<IShopManufacture>>>;
};

interface IShopManufacturersDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getShopManufacturersRequest: (data: IShopManufactureRequest) => unknown;
};


interface IShopManufacturersOwnProps {

};

interface IShopManufacturersState {

};

const ShopManufacturersStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        height: 3%;
        left: 3%;
        top: 2%;
    };
`;

type IShopManufacturersProps = RouteComponentProps
    & IShopManufacturersStoreProps
    & IShopManufacturersDispatchProps
    & IShopManufacturersOwnProps;

class ShopManufacturers extends PureComponent<IShopManufacturersProps, IShopManufacturersState> {

    public constructor(props: IShopManufacturersProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
       // this.callServer();
       window.location.href = "https://apps.sureway.ca/field/manufacturers";
    };

    public componentDidUpdate(prevProps: IShopManufacturersProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getManufacturers } = this.props;
        const data = hasPayload(getManufacturers) ? getManufacturers.payload.response : [];

        return (
            <PageSpacing title="Manufacturers" description="SHOP - Manufacturers" fixedSpaceOnSmallerScreens={true}>
                    <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>
                <ShopManufacturersStyles>

                    <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                    <h2 className="text-center">MAKES</h2>
                    <hr />

                    <LADevExtremeGrid
                        data={Object.values(data)}
                        onEdit={this.onEdit}
                        onClick={this.onClick}
                        searchPanel={true}
                        filterHeader={true}
                        removeStyleBtn={true}
                        export={true}
                        height = {900}
                        exportFileName="Manufacturer"
                        columns={[
                            { name: "manufacturer_Name", caption: "Make", type: "string" },
                            { name: "created", caption: "Created", type: "date" },
                            { name: "modified", caption: "Modified", type: "date" },
                            { name: "created_By", caption: "Created By", type: "string" },
                            { name: "modified_By", caption: "Modified By", type: "string" }
                        ]}
                    />
                </ShopManufacturersStyles>
                </RoleBasedAccessForShop>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SHOP.MANUFACTURER.MANUFACTURER
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SHOP.MANUFACTURER.MANUFACTURER
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getManufacturers) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getShopManufacturersRequest({
                token: this.props.getToken.payload.response.token
            });
    };

}

const mapStateToProps = (state: IStore): IShopManufacturersStoreProps => ({
    getToken: getToken(state),
    getManufacturers: getShopManufacturers(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IShopManufacturersDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getShopManufacturersRequest: (data: IShopManufactureRequest) => dispatch(getShopManufacturesLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopManufacturers);