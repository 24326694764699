import React from 'react';
import {Table,TableBody,TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Backdrop, Button, Box} from '@mui/material';
import { getSOCTWorkingSubSitesByParentID } from '../../../api/workingsites/workingSitesAPI';

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
};

const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
}

interface IProps {
    closePopup:any;
    parentID:number;
}
interface IState {
    open:boolean;
    subSites:any[];
}

export default class SubSiteTablePopup extends React.Component<IProps, IState> {
    constructor(props:any){
        super(props);
        this.state = {open:true, subSites:[]}
    }
    async componentDidMount(){
        let sub_sites_list = await getSOCTWorkingSubSitesByParentID(this.props.parentID, false);
        console.log(sub_sites_list);
        if(sub_sites_list !=null){
            this.setState({subSites:sub_sites_list});
        }
    }
    render(){
        return (
            <>
            <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={BoxStyle}>  
                        <Button sx={ExitButtonStyle} onClick={this.props.closePopup}>X</Button>
                        <TableContainer component={Paper}>
                    <Table size="small" aria-label="Product info">
                            <TableHead>
                            <TableRow>
                                <TableCell>Sub Site Name</TableCell>
                                <TableCell>Sub Site Address</TableCell>
                                <TableCell>Sub Site Coordinates</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell align="right">Created</TableCell>
                                <TableCell align="right">Created By</TableCell>
                                <TableCell align="right">Modified</TableCell>
                                <TableCell align="right">Modified By</TableCell>
                            </TableRow>
                        </TableHead>
                        {this.state.subSites.map((subSite:any) => { 
                        return <TableBody>
                            <TableRow>
                                <TableCell>{subSite.sub_Site_Name}</TableCell>
                                <TableCell>{subSite.sub_Site_Address}</TableCell>
                                <TableCell>{subSite.sub_Site_Coordinates}</TableCell>
                                <TableCell>{subSite.active}</TableCell>
                                <TableCell align="right">{subSite.created.slice(0,10)}</TableCell>
                                <TableCell align="right">{subSite.created_By}</TableCell>
                                <TableCell align="right">{subSite.modified.slice(0,10)}</TableCell>
                                <TableCell align="right">{subSite.modified_By}</TableCell>
                            </TableRow>
                        </TableBody>
                        })     }
                        </Table> 
                        
                </TableContainer>  
                    </Box>
            </Modal>
            </>
        );
    }
}