export enum IDELETE_HEAVY_TRUCK_REQUEST {
    REQUEST = "deleteHeavyTruck/DELETE_HEAVY_TRUCK_REQUEST",
    SUCCESS = "deleteHeavyTruck/DELETE_HEAVY_TRUCK_SUCCESS",
    FAILED = "deleteHeavyTruck/DELETE_HEAVY_TRUCK_FAILED"
};

export interface IDeleteHeavyTruckRequest {
    request: {
        ItemID: number;
    };
    token: string;
};