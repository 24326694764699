import { hasPayload } from './../../server';
import { ByIdNumber } from './../../../react/shared/publicInterfaces';
import { ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../clearRedux/clearReduxConstants";
import { IGET_UNITS_REQUEST, IUnit } from "./getUnitsConstants";
import { IGetUnitsLoadAction, IGetUnitsLoadFailedAction, IGetUnitsSuccessAction } from "./getUnitsActions";
import { IADD_UNIT_REQUEST } from "../add/unit/addUnitConstants";
import { IAddUnitSuccessAction } from "../add/unit/addUnitActions";
import { IUpdateUnitSuccessAction } from '../update/unit/updateUnitActions';
import { IUPDATE_UNIT_REQUEST } from '../update/unit/updateUnitConstants';

type Actions =
    | IGetUnitsLoadAction
    | IGetUnitsSuccessAction
    | IGetUnitsLoadFailedAction
    | IAddUnitSuccessAction
    | IUpdateUnitSuccessAction
    | IFlushDataSuccessAction;

export const GetUnitsReducer = (state: Server<ToolRentalsResponse<ByIdNumber<IUnit>>> = notLoaded, action: Actions): Server<ToolRentalsResponse<ByIdNumber<IUnit>>> => {
    switch (action.type) {
        case IGET_UNITS_REQUEST.REQUEST:
            return loading;

        case IGET_UNITS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_UNITS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_UNIT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_UNIT_REQUEST.SUCCESS:
            if(hasPayload(state)){
                state.payload.response[action.data.id] = action.data;
                return state;
            }
            return notLoaded;

        default:
            return state;
    }
};