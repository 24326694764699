import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IDELETE_PART_REQUEST } from "./deletePartConstants";
import { IDeletePartLoadAction, IDeletePartLoadFailedAction, IDeletePartSuccessAction } from "./deletePartActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IDeletePartLoadAction
    | IDeletePartSuccessAction
    | IDeletePartLoadFailedAction
    | IFlushDataSuccessAction;

export const DeletePartReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IDELETE_PART_REQUEST.REQUEST:
            return loading;

        case IDELETE_PART_REQUEST.SUCCESS:
            return succeeded("Success");

        case IDELETE_PART_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};