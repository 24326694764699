import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { DozerIcon} from "../shared/icons";

interface IPartDismantledEquipmentsDropDownProps {
    onDrawer?(): void;
};

type IPartDismantledEquipmentDropDown =
    RouteComponentProps &
    IPartDismantledEquipmentsDropDownProps;

class DismantledEquipmentDropDown extends PureComponent<IPartDismantledEquipmentDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoPartDismantledPage}>
                <DozerIcon />
                <span className="ml-2">
                    Dismantled Equipment
                </span>
            </LAMenuItem>
        );
    };

    private gotoPartDismantledPage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();
            
        this.props.history.push(ROUTE.PARTS.DISMANTLED.INDEX);
    };

}

export default DismantledEquipmentDropDown;
