import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IGetShopManufacturesLoadAction, IGetShopManufacturesLoadFailedAction, IGetShopManufacturesSuccessAction } from "./getShopManufacturesActions";
import { IGET_SHOP_MANUFACTURERS_REQUEST, IShopManufacture } from "./getShopManufacturesConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";
import { IADD_SHOP_MANUFACTURERS_REQUEST } from "../addManufactures/addShopManufacturesConstants";
import { IUPDATE_SHOP_MANUFACTURERS_REQUEST } from "../updateManufactures/updateShopManufacturesConstants";
import { IUpdateShopManufacturesSuccessAction } from "../updateManufactures/updateShopManufacturesActions";
import { IAddShopManufacturesSuccessAction } from "../addManufactures/addShopManufacturesActions";

type Actions =
    | IGetShopManufacturesLoadAction
    | IGetShopManufacturesSuccessAction
    | IAddShopManufacturesSuccessAction
    | IUpdateShopManufacturesSuccessAction
    | IGetShopManufacturesLoadFailedAction
    | IFlushDataSuccessAction;

export const GetShopManufacturersReducer = (state: Server<PartsResponse<ById<IShopManufacture>>> = notLoaded, action: Actions): Server<PartsResponse<ById<IShopManufacture>>> => {
    switch (action.type) {
        case IGET_SHOP_MANUFACTURERS_REQUEST.REQUEST:
            return loading;

        case IGET_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SHOP_MANUFACTURERS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};