import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_PARTS_PICKUP_TRUCK_REQUEST, IPartPickupTruck, IPartPickupTruckRequest } from "./getPartsDismantledPickupTruckConstants";


export interface IGetPartsDismantledPickupTrucksLoadAction {
    type: IGET_PARTS_PICKUP_TRUCK_REQUEST.REQUEST;
    data: IPartPickupTruckRequest
}
export const getPartsDismantledPickupTrucksLoadAction = (data: IPartPickupTruckRequest): IGetPartsDismantledPickupTrucksLoadAction => ({
    type: IGET_PARTS_PICKUP_TRUCK_REQUEST.REQUEST,
    data
});
export interface IGetPartsDismantledPickupTrucksSuccessAction {
    type: IGET_PARTS_PICKUP_TRUCK_REQUEST.SUCCESS;
    list: PartsResponse<IPartPickupTruck[]>;
}
export const getPartsDismantledPickupTrucksLoadSuccessAction = (list: PartsResponse<IPartPickupTruck[]>): IGetPartsDismantledPickupTrucksSuccessAction => ({
    type: IGET_PARTS_PICKUP_TRUCK_REQUEST.SUCCESS,
    list
});
export interface IGetPartsDismantledPickupTrucksLoadFailedAction {
    type: IGET_PARTS_PICKUP_TRUCK_REQUEST.FAILED;
    message: string;
}
export const getPartsDismantledPickupTrucksLoadFailedAction = (message: string): IGetPartsDismantledPickupTrucksLoadFailedAction => ({
    type: IGET_PARTS_PICKUP_TRUCK_REQUEST.FAILED,
    message
});
