import { IDELETE_HEAVY_TRUCK_REQUEST, IDeleteHeavyTruckRequest } from "./deleteHeavyTruckConstants";


export interface IDeleteHeavyTruckLoadAction {
    type: IDELETE_HEAVY_TRUCK_REQUEST.REQUEST;
    data: IDeleteHeavyTruckRequest
}
export const deleteHeavyTruckLoadAction = (data: IDeleteHeavyTruckRequest): IDeleteHeavyTruckLoadAction => ({
    type: IDELETE_HEAVY_TRUCK_REQUEST.REQUEST,
    data
});
export interface IDeleteHeavyTruckSuccessAction {
    type: IDELETE_HEAVY_TRUCK_REQUEST.SUCCESS;
    message: string;
}
export const deleteHeavyTruckLoadSuccessAction = (message: string): IDeleteHeavyTruckSuccessAction => ({
    type: IDELETE_HEAVY_TRUCK_REQUEST.SUCCESS,
    message
});
export interface IDeleteHeavyTruckLoadFailedAction {
    type: IDELETE_HEAVY_TRUCK_REQUEST.FAILED;
    message: string;
}
export const deleteHeavyTruckLoadFailedAction = (message: string): IDeleteHeavyTruckLoadFailedAction => ({
    type: IDELETE_HEAVY_TRUCK_REQUEST.FAILED,
    message
});
