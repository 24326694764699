import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { ReportIcon } from "../shared/icons";

interface IInvoicesDropDownProps {
    onDrawer?(): void;
};

type IInvoicesDropDown =
    RouteComponentProps &
    IInvoicesDropDownProps;

class InvoiceDropDown extends PureComponent<IInvoicesDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoInvoicesPage}>
                <ReportIcon />
                
                <span className="ml-2">
                    Invoice
                </span>
            </LAMenuItem>
        );
    };

    private gotoInvoicesPage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.TOOL_RENTALS.INVOICE.INDEX);
    };

}

export default InvoiceDropDown;
