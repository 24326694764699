
export default function registerServiceWorker(): void {
    
    if ("serviceWorker" in navigator) { 
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`)
            .then(() => 
                // navigator.serviceWorker.addEventListener("message", (event) => {
                //     console.log(event.data.msg, event.data.url);
                // })
                console.log("service worked dev")
            );
    };
}
