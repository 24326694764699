export enum IUPDATE_PART_DISMANTLED_EQUIPMENT_REQUEST {
    REQUEST = "updatePart/UPDATE_PART_DISMANTLED_EQUIPMENT_REQUEST",
    SUCCESS = "updatePart/UPDATE_PART_DISMANTLED_EQUIPMENT_SUCCESS",
    FAILED = "updatePart/UPDATE_PART_DISMANTLED_EQUIPMENT_FAILED"
};

export interface IUpdatePartDismantledEquipmentRequest {
    request: IAddUpdatePartDismantledEquipment;
    token: string;
};

export interface IAddUpdatePartDismantledEquipment {
    id: number;
    make: string; 
    model: string;
    hours: string;
    location: string;
    notes: string;
    photos_1: string;
    photos_2: string;
    photos_3: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
    serial_No: string;
    unit_No: string;
    available_Components: IAddUpdatePartDismantledComponent[];
};

export interface IAddUpdatePartDismantledComponent {
    ID: number;
    Name: string;
    Notes: string;
    Part_No: string;
    Condition: string;
};