import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_MANUFACTURER_REQUEST } from "./addManufacturerConstants";
import { IAddManufacturerLoadAction, IAddManufacturerLoadFailedAction, IAddManufacturerSuccessAction } from "./addManufacturerActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IAddManufacturerLoadAction
    | IAddManufacturerSuccessAction
    | IAddManufacturerLoadFailedAction
    | IFlushDataSuccessAction;

export const AddManufacturerReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_MANUFACTURER_REQUEST.REQUEST:
            return loading;

        case IADD_MANUFACTURER_REQUEST.SUCCESS:
            return succeeded("Success");

        case IADD_MANUFACTURER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};