export enum IUPDATE_PART_PICKUP_TRUCK_REQUEST {
    REQUEST = "updatePart/UPDATE_PART_PICKUP_TRUCK_REQUEST",
    SUCCESS = "updatePart/UPDATE_PART_PICKUP_TRUCK_SUCCESS",
    FAILED = "updatePart/UPDATE_PART_PICKUP_TRUCK_FAILED"
};

export interface IUpdatePartPickupTruckRequest {
    request: IAddUpdatePartPickupTruck;
    token: string;
};

export interface IAddUpdatePartPickupTruck {
    id: number;
    make: string; 
    model: string;
    hours: string;
    location: string;
    notes: string;
    photos_1: string;
    photos_2: string;
    photos_3: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
    serial_No: string;
    unit_No: string;
};