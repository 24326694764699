import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

export const adalConfig = {
    instance: "https://adfs.sureway.ca/",
    tenant: "adfs",
    clientId: "7abb57fb-62a0-494b-bb2b-e4265d933a4c",
    endpoints: {
        api: "",
    },
    cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

