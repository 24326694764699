import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IRate, IRateRequest } from "../../getRates/getRatesConstants";
import { IGET_CLASS_AND_TYPES_REQUEST } from "./getClassAndTypeConstants";


export interface IGetClassAndTypesLoadAction {
    type: IGET_CLASS_AND_TYPES_REQUEST.REQUEST;
    data: IRateRequest
}
export const getClassAndTypesLoadAction = (data: IRateRequest): IGetClassAndTypesLoadAction => ({
    type: IGET_CLASS_AND_TYPES_REQUEST.REQUEST,
    data
});
export interface IGetClassAndTypesSuccessAction {
    type: IGET_CLASS_AND_TYPES_REQUEST.SUCCESS;
    list: ToolRentalsResponse<IRate[]>;
}
export const getClassAndTypesLoadSuccessAction = (list: ToolRentalsResponse<IRate[]>): IGetClassAndTypesSuccessAction => ({
    type: IGET_CLASS_AND_TYPES_REQUEST.SUCCESS,
    list
});
export interface IGetClassAndTypesLoadFailedAction {
    type: IGET_CLASS_AND_TYPES_REQUEST.FAILED;
    message: string;
}
export const getClassAndTypesLoadFailedAction = (message: string): IGetClassAndTypesLoadFailedAction => ({
    type: IGET_CLASS_AND_TYPES_REQUEST.FAILED,
    message
});
