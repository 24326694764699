import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IPart } from "../../getParts/getPartConstants";
import { IADD_PART_REQUEST, IAddPartRequest } from "./addPartConstants";


export interface IAddPartLoadAction {
    type: IADD_PART_REQUEST.REQUEST;
    data: IAddPartRequest
}
export const addPartLoadAction = (data: IAddPartRequest): IAddPartLoadAction => ({
    type: IADD_PART_REQUEST.REQUEST,
    data
});
export interface IAddPartSuccessAction {
    type: IADD_PART_REQUEST.SUCCESS;
    data: PartsResponse<IPart[]>;
}
export const addPartLoadSuccessAction = (data: PartsResponse<IPart[]>): IAddPartSuccessAction => ({
    type: IADD_PART_REQUEST.SUCCESS,
    data
});
export interface IAddPartLoadFailedAction {
    type: IADD_PART_REQUEST.FAILED;
    message: string;
}
export const addPartLoadFailedAction = (message: string): IAddPartLoadFailedAction => ({
    type: IADD_PART_REQUEST.FAILED,
    message
});
