
export enum IADD_SHOP_MANUFACTURERS_REQUEST {
    REQUEST = "addShopManufactures/ADD_SHOP_MANUFACTURERS_REQUEST",
    SUCCESS = "addShopManufactures/ADD_SHOP_MANUFACTURERS_SUCCESS",
    FAILED = "addShopManufactures/ADD_SHOP_MANUFACTURERS_FAILED"
};

export interface IAddShopManufactureRequest {
    token: string;
    request: {
        Manufacturer_Name: string;
        Created_By: string;
        Modified_By: string;
    };
};