import { IUnit } from "../../getUnits/getUnitsConstants";

export enum IUPDATE_UNIT_REQUEST {
    REQUEST = "updateUnit/UPDATE_UNIT_REQUEST",
    SUCCESS = "updateUnit/UPDATE_UNIT_SUCCESS",
    FAILED = "updateUnit/UPDATE_UNIT_FAILED"
};

export interface IUpdateUnitRequest {
    request: IUnit;
    token: string;
};