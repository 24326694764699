import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IPartDismantledEquipment } from "../../getPartsDismantledEquipments/getPartsDismantledEquipmentConstants";
import { IADD_PART_DISMANTLED_EQUIPMENT_REQUEST, IAddPartDismantledEquipmentRequest } from "./addDismantledEquipmentConstants";


export interface IAddPartDismantledEquipmentLoadAction {
    type: IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.REQUEST;
    data: IAddPartDismantledEquipmentRequest
}
export const addPartDismantledEquipmentLoadAction = (data: IAddPartDismantledEquipmentRequest): IAddPartDismantledEquipmentLoadAction => ({
    type: IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.REQUEST,
    data
});
export interface IAddPartDismantledEquipmentSuccessAction {
    type: IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.SUCCESS;
    data: PartsResponse<IPartDismantledEquipment[]>;
}
export const addPartDismantledEquipmentLoadSuccessAction = (data: PartsResponse<IPartDismantledEquipment[]>): IAddPartDismantledEquipmentSuccessAction => ({
    type: IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.SUCCESS,
    data
});
export interface IAddPartDismantledEquipmentLoadFailedAction {
    type: IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.FAILED;
    message: string;
}
export const addPartDismantledEquipmentLoadFailedAction = (message: string): IAddPartDismantledEquipmentLoadFailedAction => ({
    type: IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.FAILED,
    message
});
