
export enum IGET_EQUIPMENT_TYPES_REQUEST {
    REQUEST = "getEquipmentHistoryColumns/GET_EQUIPMENT_TYPES_REQUEST",
    SUCCESS = "getEquipmentHistoryColumns/GET_EQUIPMENT_TYPES_SUCCESS",
    FAILED = "getEquipmentHistoryColumns/GET_EQUIPMENT_TYPES_FAILED"
};

export interface IGetEquipmentHistoryColumnRequest {
    token: string;
    request:{
        Equipment_ID: number;
        Column_Name: string;         
    };
};

export interface IGetEquipmentHistoryColumn {
    id: number;
    history: string;
    created: string;
    created_By: string;
    owned_By?: string;
    start_Date?: string;
    end_Date?: string;
    comments?: string;
};