
export enum IGET_SHOP_MODELS_REQUEST {
    REQUEST = "getShopModels/GET_SHOP_MODELS_REQUEST",
    SUCCESS = "getShopModels/GET_SHOP_MODELS_SUCCESS",
    FAILED = "getShopModels/GET_SHOP_MODELS_FAILED"
};

export interface IShopModelRequest {
    token: string;
};

export interface IShopModel {
    id: number;
    make: string;
    model: string;
    manufacturer_ID: number;
    unit_Number: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    manufacturer_Name: string;
};