import { IPaginationRequest } from "../../../../react/shared/publicInterfaces";
import { IPart } from "../../getParts/getPartConstants";

export enum IADD_PART_REQUEST {
    REQUEST = "addPart/ADD_PART_REQUEST",
    SUCCESS = "addPart/ADD_PART_SUCCESS",
    FAILED = "addPart/ADD_PART_FAILED"
};

export interface IAddPartRequest extends IPaginationRequest {
    request: IPart;
    token: string;
};