import { IPaginationRequest } from "../../../react/shared/publicInterfaces";

export enum IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST {
    REQUEST = "getPartsDismantledEquipment/GET_PARTS_DISMANTLED_EQUIPMENT_REQUEST",
    SUCCESS = "getPartsDismantledEquipment/GET_PARTS_DISMANTLED_EQUIPMENT_SUCCESS",
    FAILED = "getPartsDismantledEquipment/GET_PARTS_DISMANTLED_EQUIPMENT_FAILED"
};

export interface IPartDismantledEquipmentRequest extends IPaginationRequest {
    token: string;
    equipments: string[];
};

export interface IPartDismantledEquipment {
    id: number;
    make: string; 
    model: string;
    hours: string;
    location: string;
    notes: string;
    photos_1: string;
    photos_2: string;
    photos_3: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
    serial_No: string;
    unit_No: string;
    equipment_Type: string;
    available_Components: IPartDismantledComponent[];
    status:string;
};

export interface IPartDismantledComponent {
    id: number;
    equipment_ID?: number;
    part_No: string;
    condition: string;
    created?: string;
    created_By?: string;
    modified?: string;
    modified_By?: string;
    name: string;
    notes: string;
};

export const StatusValues: { id: number, name: string }[] = [{ id: 1, name: "Active" }, { id: 2, name: "Sold" }];