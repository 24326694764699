
export enum IUPDATE_EQUIPMENT_TYPE_REQUEST {
    REQUEST = "updateEquipmentType/UPDATE_EQUIPMENT_TYPE_REQUEST",
    SUCCESS = "updateEquipmentType/UPDATE_EQUIPMENT_TYPE_SUCCESS",
    FAILED = "updateEquipmentType/UPDATE_EQUIPMENT_TYPE_FAILED"
};

export interface IUpdateEquipmentTypeRequest {
    token: string;
    request: {
        ID: number;
        Name: string;
        Active: string;
        Created_By: string;
        Modified_By: string;
    };
};