import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, PartsResponse, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { getShopEquipmentsLoadAction } from "../../../../redux/shop/getEquipments/getEquipmentActions";
import { getShopEquipments } from "../../../../redux/shop/getEquipments/getEquipmentAccessor";
import { IShopEquipment, IShopEquipmentRequest } from "../../../../redux/shop/getEquipments/getEquipmentConstants";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../../shared/roleBaseAccessForShop";
import { IValidateShopGroup, ReadAndWrite, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IShopLookups, IShopLookupsRequest } from "../../../../redux/shop/getLookups/getLookupsConstants";
import { getLookups } from "../../../../redux/shop/getLookups/getLookupsAccessor";
import { getLookupsLoadAction } from "../../../../redux/shop/getLookups/getLookupsActions";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { getEquipmentVersionHistory } from "../../../../redux/shop/getEquipmentVersionHistory/getEquipmentVersionHistoryAccessor";
import { getEquipmentVersionHistoryLoadAction } from "../../../../redux/shop/getEquipmentVersionHistory/getEquipmentVersionHistoryActions";
import { IGetEquipmentVersionHistory, IGetEquipmentVersionHistoryRequest } from "../../../../redux/shop/getEquipmentVersionHistory/getEquipmentVersionHistoryConstants";
import { VersionHistoryPopup } from "./historyPopup";


interface IShopEquipmentsStoreProps {
    getToken: Server<PartsResponse<IToken>>;
    getDropDownData: Server<PartsResponse<IShopLookups>>;
    getEquipments: Server<PartsResponse<ById<IShopEquipment>>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
    getEquipmentVersionHistory: Server<PartsResponse<IGetEquipmentVersionHistory[]>>;
};

interface IShopEquipmentsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getDropDownDataRequest: (data: IShopLookupsRequest) => unknown;
    getShopEquipmentsRequest: (data: IShopEquipmentRequest) => unknown;
    getEquipmentVersionHistoryRequest: (data: IGetEquipmentVersionHistoryRequest) => unknown;
};


interface IShopEquipmentsOwnProps {

};

interface IShopEquipmentsState {
    versionHistoryPopup: boolean;
};

const ShopEquipmentsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-right {
        position: absolute;
        right: 3%;
        height: 3%;
        top: 2%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };

    .downloadStyle{
        font-family: Arial, Helvetica, sans-serif;
        text-decoration: underline;
        font-size: 16px;
        
    }
`;

type IShopEquipmentsProps = RouteComponentProps
    & IShopEquipmentsStoreProps
    & IShopEquipmentsDispatchProps
    & IShopEquipmentsOwnProps;

class ShopEquipments extends PureComponent<IShopEquipmentsProps, IShopEquipmentsState> {

    public constructor(props: IShopEquipmentsProps) {
        super(props);
        this.state = {
            versionHistoryPopup: false
        };
    }

    public componentDidMount(): void {
       // this.callServer();
       window.location.href = "https://apps.sureway.ca/field/equipments";
    };

    public componentDidUpdate(prevProps: IShopEquipmentsProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { versionHistoryPopup } = this.state;
        const { getEquipments, validateShopGroup, getEquipmentVersionHistory } = this.props;
        const data = hasPayload(getEquipments) ? getEquipments.payload.response : [];
        const versionHistory = hasPayload(getEquipmentVersionHistory) ? getEquipmentVersionHistory.payload.response : [];
        const getRole = hasPayload(validateShopGroup) ? validateShopGroup.payload.response.adminAccess : "";
        const readOnly = getRole === ReadOnly ? true : undefined;

        return (
            <PageSpacing title="Equipments" description="SHOP - Equipment" fixedSpaceOnSmallerScreens={true}>
                <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>
                    <ShopEquipmentsStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" disabled={readOnly} onClick={this.handleAdd} />
                        <h2 className="text-center">EQUIPMENT LIST</h2>

                        {/* <div className="pull-right">
                            <LALinkButton className="downloadStyle" label="Download a copy of old Equipments Master File" onClick={this.handleMasterFile} />
                        </div> */}
                        <hr />

                        <LADevExtremeGrid
                            data={Object.values(data)}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            actionWidth={120}
                            removeStyleBtn={true}
                            height={1200}
                            id="equipmentList"
                            customRowColor={true}
                            exportFileName="Equipments"
                            storeLocally={true}
                            onCustomClick={this.handleCustom}
                            columns={[
                                { name: "unit_Number", caption: "Unit #", type: "string", sortAsc: true },
                                { name: "equipment", caption: "Equipment", type: "string" },
                                { name: "equipment_Type", caption: "Equipment Type", type: "string" },
                                { name: "owner", caption: "Owner", type: "string" },
                                { name: "serial_Number", caption: "Serial Number", type: "string" },
                                { name: "make", caption: "Make", type: "string" },
                                { name: "model", caption: "Model", type: "string" },
                                { name: "details", caption: "Details", type: "string" },
                                { name: "tire_Size", caption: "Tire Size", type: "string" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "year", caption: "Year", type: "number" },
                                { name: "driver", caption: "Driver", type: "string" },
                                { name: "plate", caption: "Plate", type: "string" },
                                { name: "History", caption: "Version History", type: "button" },                              
                                { name: "current_Hours", caption: "Current Hours", type: "string" },
                                { name: "gpS_Level", caption: "GPS Level", type: "string" },
                                { name: "cert_Number", caption: "Cert Number", type: "string" },
                                { name: "equipment_Details", caption: "Equipment Details", type: "string" },
                                { name: "modified", caption: "Modified", type: "date" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />
                    </ShopEquipmentsStyles>
                </RoleBasedAccessForShop>

                <VersionHistoryPopup
                            data={versionHistory}
                            open={versionHistoryPopup}
                            status={getEquipmentVersionHistory.kind}
                            onCancel={this.handleVersionHistoryClose}
                        />
            </PageSpacing>
        );
    }

    private handleVersionHistoryClose = (): void => {
        this.setState({ versionHistoryPopup: false });
    };

    private handleCustom = (name: string, column: any): void => {
        if (hasPayload(this.props.getToken)) {
            const data: IGetEquipmentVersionHistory = column.row.data;

            this.props.getEquipmentVersionHistoryRequest({
                id: data.id,
                token: this.props.getToken.payload.response.token
            });

            this.setState({ versionHistoryPopup: true });
        }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SHOP.EQUIPMENT.EQUIPMENT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SHOP.EQUIPMENT.EQUIPMENT
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getEquipments) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getShopEquipmentsRequest({
                token: this.props.getToken.payload.response.token
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token
            });
    };

}

const mapStateToProps = (state: IStore): IShopEquipmentsStoreProps => ({
    getToken: getToken(state),
    getDropDownData: getLookups(state),
    getEquipments: getShopEquipments(state),
    validateShopGroup: validateShopGroup(state),
    getEquipmentVersionHistory: getEquipmentVersionHistory(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IShopEquipmentsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getDropDownDataRequest: (data: IShopLookupsRequest) => dispatch(getLookupsLoadAction(data)),
    getShopEquipmentsRequest: (data: IShopEquipmentRequest) => dispatch(getShopEquipmentsLoadAction(data)),
    getEquipmentVersionHistoryRequest: (data: IGetEquipmentVersionHistoryRequest) => dispatch(getEquipmentVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopEquipments);