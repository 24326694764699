import React from 'react';
import {TextField, FormControl, FormGroup, FormLabel, Checkbox, FormControlLabel, Paper, Grid, Button, Typography, Modal, Backdrop, FormHelperText} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {RouteComponentProps} from 'react-router-dom';
import {editScheduledServiceData, newScheduledService} from '../SOCTMain/edit/editServiceHistoryTable';
import UpdateScheduledService from '../../api/scheduledservice/updateScheduledService';
import {globalEditID} from '../../sotc';
import splitDate from '../../../../shared/splitDate';
import { getServiceTypesByUnitNumber, getSOCTServiceFile } from '../../api/scheduledservice/addScheduledService';
import { currentUser } from '../../socthome';

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
    overflow: 'auto',
    maxHeight:'100%',
};

interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
    showMain:boolean;showMeterHist:boolean;showMoveHist:boolean;showCustomHist:boolean;showServiceHist:boolean;data:any[];
    serviceHistory:any[];meterHistory:any[];moveHistory:any[];customHistory:any[];
    currentHoursCheck:boolean;modalOpen:boolean;
}

var serviceTypeCheck:any = {service_0_check:false,service_100_check:false,service_250_check:false,service_500_check:false,service_1500_check:false,service_3000_check:false,
    service_A_check:false, service_B_check:false, service_D_check:false, service_annual_check:false};
var editServiceTypes:any = {serviceType:'',service_0:false,service_100:false,service_250:false,service_500:false,service_1500:false,service_3000:false,
service_A:false, service_B:false, service_D:false, service_Annual:false};
var cancel_service_check:boolean =false;
var disableForm:boolean = false;
export default class EditScheduledService extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false, data:[],
        serviceHistory:[], meterHistory:[],moveHistory:[],customHistory:[], currentHoursCheck:false, modalOpen:true};
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileRemove = this.handleFileRemove.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onGetFileButtonClick = this.onGetFileButtonClick.bind(this);
    }
    onGetFileButtonClick(equipment_id:any, filename:string, service_id:any){
        getSOCTServiceFile(equipment_id, filename, service_id);
      }
    handleFileUpload(event:any){
        if(editScheduledServiceData.attachments === null){
            editScheduledServiceData.attachments = [];
        }
        editScheduledServiceData.attachments.push(event.target.files[0].name);
        this.setState({});
    }
    handleFileRemove(index:number,event:any){
        editScheduledServiceData.attachments.splice(index,1);
        this.setState({});
    }
    // Add string seperated service types to service type string
    updateServiceType(){
        editServiceTypes.serviceType = '';
        if(editServiceTypes.service_0){
            editServiceTypes.serviceType ='0';
        }
        if(editServiceTypes.service_100){
            editServiceTypes.serviceType =editServiceTypes.serviceType+',100';
        }
        if(editServiceTypes.service_250){
            editServiceTypes.serviceType =editServiceTypes.serviceType+',250';
        }
        if(editServiceTypes.service_500){
            editServiceTypes.serviceType =editServiceTypes.serviceType+',500';
        }
        if(editServiceTypes.service_1500){
            editServiceTypes.serviceType =editServiceTypes.serviceType+',1500';
        }
        if(editServiceTypes.service_3000){
            editServiceTypes.serviceType =editServiceTypes.serviceType+',3000';
        }
        if(editServiceTypes.service_A){
            editServiceTypes.serviceType =editServiceTypes.serviceType+',Schedule A';
        }
        if(editServiceTypes.service_B){
            editServiceTypes.serviceType =editServiceTypes.serviceType+',Schedule B';
        }
        if(editServiceTypes.service_D){
            editServiceTypes.serviceType =editServiceTypes.serviceType+',Schedule D';
        }
        if(editServiceTypes.service_Annual){
            editServiceTypes.serviceType =editServiceTypes.serviceType+',Annual Service';
        }
        // Remove first "," if exists
        if(editServiceTypes.serviceType[0] === ','){
            editServiceTypes.serviceType = editServiceTypes.serviceType.substring(1); 
        }
        editScheduledServiceData.service_Type = editServiceTypes.serviceType;
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'service_date':
                editScheduledServiceData.service_Date = event.target.value;
                break;
            case 'current_hours_check':
                this.setState({currentHoursCheck:!this.state.currentHoursCheck});
                break;
            case 'comments':
                editScheduledServiceData.comments = event.target.value;
                break;
            case 'ecm':
                editScheduledServiceData.ecm = event.target.value;
                break;
            case 'meter_reading':
                editScheduledServiceData.meter_Reading = event.target.value;
                break;
            case 'completed_date':
                editScheduledServiceData.completed_Date = event.target.value;
                break;
            case 'service_type_0':
                editServiceTypes.service_0 = !editServiceTypes.service_0;
                editServiceTypes.service_100 = false;
                editServiceTypes.service_250 = false;
                editServiceTypes.service_500 = false;
                editServiceTypes.service_1500 = false;
                editServiceTypes.service_3000 = false;
                editServiceTypes.service_A = false;
                editServiceTypes.service_B = false;
                editServiceTypes.service_D = false;
                editServiceTypes.service_Annual = false;
                break;
            case 'service_type_100':
                editServiceTypes.service_100 = !editServiceTypes.service_100;
                break;
            case 'service_type_250':
                editServiceTypes.service_250 = !editServiceTypes.service_250;
                break;
            case 'service_type_500':
                editServiceTypes.service_500 = !editServiceTypes.service_500;
                break;
            case 'service_type_1500':
                editServiceTypes.service_1500 = !editServiceTypes.service_1500;
                break;
            case 'service_type_3000':
                editServiceTypes.service_3000 =!editServiceTypes.service_3000;
                break;
            case 'service_type_A':
                editServiceTypes.service_A = !editServiceTypes.service_A;
                break;
            case 'service_type_B':
                editServiceTypes.service_B = !editServiceTypes.service_B;
                break;
            case 'service_type_D':
                editServiceTypes.service_D = !editServiceTypes.service_D;
                break;
            case 'service_type_annual':
                editServiceTypes.service_Annual = !editServiceTypes.service_Annual;
                break;
            case 'cancel_service_check':
                cancel_service_check = !cancel_service_check;
                break;
        } 
        this.setState({});
    }
    async onSave(){
        this.updateServiceType();
        if(editServiceTypes.serviceType!='' && ((editScheduledServiceData.ecm!='') || (editScheduledServiceData.meter_Reading!=''))){
            const res:any = await UpdateScheduledService(editScheduledServiceData, this.state.currentHoursCheck, currentUser, cancel_service_check);
            if(res.status === 200){
                alert("Successfully updated scheduled service");
                this.props.history.push('/soct/lists/edit/id='+globalEditID);
            }
            else{
                alert("error editing scheduled service");
            }
        }
        else{
            alert("Service type must be entered, and ecm or meter reading are mandatory");
        }
    }
    onFormCancel(){
        editServiceTypes = {serviceType:'',service_0:false,service_100:false,service_250:false,service_500:false,service_1500:false,service_3000:false,
        service_A:false, service_B:false, service_D:false, service_Annual:false};
        serviceTypeCheck = {service_0_check:false,service_100_check:false,service_250_check:false,service_500_check:false,service_1500_check:false,service_3000_check:false,
            service_A_check:false, service_B_check:false, service_D_check:false, service_annual_check:false};
        this.props.history.goBack();
    }
    async componentDidMount(){
        console.log(editScheduledServiceData)
        cancel_service_check = editScheduledServiceData.cancelled === "Yes";
        disableForm = editScheduledServiceData.cancelled === "Yes";
        let service_types_array = editScheduledServiceData.service_Type.split(",");
        let available_service_options:any = await getServiceTypesByUnitNumber(editScheduledServiceData.unit_Number);
        available_service_options = available_service_options[0].split(",");
        available_service_options.map((each:any) => {
                if(each === '0'){
                    serviceTypeCheck.service_0_check = true;
                }
                if(each === '100'){
                    serviceTypeCheck.service_100_check = true;
                }
                if(each === '250'){
                    serviceTypeCheck.service_250_check = true;
                }
                if(each === '500'){
                    serviceTypeCheck.service_500_check = true;
                }
                if(each === '1500'){
                    serviceTypeCheck.service_1500_check = true;
                }
                if(each === '3000'){
                    serviceTypeCheck.service_3000_check = true;
                }
                if(each === 'Schedule A'){
                    serviceTypeCheck.service_A_check = true;
                }
                if(each === 'Schedule B'){
                    serviceTypeCheck.service_B_check = true;
                }
                if(each === 'Schedule D'){
                    serviceTypeCheck.service_D_check = true;
                }
                if(each === 'Annual Service'){
                    serviceTypeCheck.service_annual_check = true;
                }
            });
        service_types_array.map((service:string) => {
            if(service === '0'){
                editServiceTypes.service_0 = true;
            }
            if(service === '100'){
                editServiceTypes.service_100 = true;
            }
            if(service === '250'){
                editServiceTypes.service_250 = true;
            }
            if(service === '500'){
                editServiceTypes.service_500 = true;
            }
            if(service === '1000'){
                editServiceTypes.service_1000 = true;
            }
            if(service === '1500'){
                editServiceTypes.service_1500 = true;
            }
            if(service === '3000'){
                editServiceTypes.service_3000 = true;
            }
            if(service === 'Schedule A'){
                editServiceTypes.service_A = true;
            }
            if(service === 'Schedule B'){
                editServiceTypes.service_B = true;
            }
            if(service === 'Schedule D'){
                editServiceTypes.service_D = true;
            }
            if(service === 'Annual Service'){
                editServiceTypes.service_Annual = true;
            }
        });
        this.setState({});
    }
    render(){
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.modalOpen}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                >  
                        <Box sx={BoxStyle}>
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Modifying Scheduled Service History</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Typography
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                >
                                    Unit # - {editScheduledServiceData.unit_Number}
                                </Typography>
                              
                                    </Toolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                    <Grid container spacing={6}>
                <Grid item xs={4}>
                    <TextField disabled type="date" label="Previous Date" defaultValue={splitDate(newScheduledService.previous_Date)}></TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField disabled label="Previous Reading" placeholder="prev_Reading" defaultValue={newScheduledService.previous_Reading}></TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{backgroundColor:'white'}} type="date" label="Service Date" defaultValue={splitDate(editScheduledServiceData.service_Date)} onChange={this.handleChange} name="service_date"></TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled label="Current Location" defaultValue={editScheduledServiceData.current_Location}></TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled label="Current Sub Location" defaultValue={editScheduledServiceData.current_Sub_Location}></TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled label="Service Location" defaultValue={editScheduledServiceData.service_Location}></TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled label="Service Sub Location" defaultValue={editScheduledServiceData.service_Sub_Location}></TextField>
                </Grid>
                <Grid item xs={6}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Service Type</FormLabel>
                                {/* Map each service type and only display the services that are available */}
                                    <FormGroup row>
                            {serviceTypeCheck.service_0_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={disableForm} checked={editServiceTypes.service_0 || editScheduledServiceData.custom_Service==="Yes"} onChange={this.handleChange} name="service_type_0" />
                                }
                                label="0 (Custom Service Set up / non pm service)"
                            />:null}
                            {serviceTypeCheck.service_100_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={editScheduledServiceData.custom_Service==="Yes" || disableForm} checked={editServiceTypes.service_100} onChange={this.handleChange} name="service_type_100" />
                                }
                                label="100"
                            />:null}
                            {serviceTypeCheck.service_250_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={editScheduledServiceData.custom_Service==="Yes" || disableForm} checked={editServiceTypes.service_250} onChange={this.handleChange} name="service_type_250" />
                                }
                                label="250"
                            />:null}
                            {serviceTypeCheck.service_500_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={editScheduledServiceData.custom_Service==="Yes" || disableForm} checked={editServiceTypes.service_500} onChange={this.handleChange} name="service_type_500" />
                                }
                                label="500"
                            />:null}
                            {serviceTypeCheck.service_1500_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={editScheduledServiceData.custom_Service==="Yes" || disableForm} checked={editServiceTypes.service_1500} onChange={this.handleChange} name="service_type_1500" />
                                }
                                label="1500"
                            />:null}
                            {serviceTypeCheck.service_3000_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={editScheduledServiceData.custom_Service==="Yes" || disableForm} checked={editServiceTypes.service_3000} onChange={this.handleChange} name="service_type_3000" />
                                }
                                label="3000"
                            />:null}
                            {serviceTypeCheck.service_A_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={editScheduledServiceData.custom_Service==="Yes" || disableForm} checked={editServiceTypes.service_A} onChange={this.handleChange} name="service_type_A" />
                                }
                                label="Schedule A"
                            />:null}
                            {serviceTypeCheck.service_B_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={editScheduledServiceData.custom_Service==="Yes" || disableForm} checked={editServiceTypes.service_B} onChange={this.handleChange} name="service_type_B" />
                                }
                                label="Schedule B"
                            />:null}
                            {serviceTypeCheck.service_D_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={editScheduledServiceData.custom_Service==="Yes" || disableForm} checked={editServiceTypes.service_D} onChange={this.handleChange} name="service_type_D" />
                                }
                                label="Schedule D"
                            />:null}
                            {serviceTypeCheck.service_annual_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={editScheduledServiceData.custom_Service==="Yes" || disableForm} checked={editServiceTypes.service_Annual} onChange={this.handleChange} name="service_type_annual" />
                                }
                                label="Annual Service"
                            />:null}
                            </FormGroup>
                            <FormHelperText>Select 1 or all</FormHelperText>
                        </FormControl>
                        </Grid>
                {editScheduledServiceData.equipment_Type === 'Excavators' ? 
                <Grid item xs={4}>
                    <TextField disabled={disableForm} label="ECM" defaultValue={editScheduledServiceData.ecm} onChange={this.handleChange} name="ecm"></TextField>
                </Grid>
                :null}
                {/* <Grid item xs={4}>
                    <TextField disabled label="Unit Number" defaultValue={editScheduledServiceData.unit_Number}></TextField>
                </Grid> */}
                <Grid item xs={4}>
                    <TextField disabled={editScheduledServiceData.custom_Service==="No" || disableForm} label="Meter Reading" defaultValue={editScheduledServiceData.meter_Reading} onChange={this.handleChange} name="meter_reading"></TextField>
                </Grid>
                {editScheduledServiceData.custom_Service === "Yes" ? 
                <>
                    <Grid item xs={3}>
                         <TextField disabled={disableForm} type="date"  helperText="Completed Date" defaultValue={editScheduledServiceData.completed_Date ? splitDate(editScheduledServiceData.completed_Date):null} onChange={this.handleChange} name="completed_date"></TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField disabled={disableForm} type="string" label="Hour Interval" defaultValue={editScheduledServiceData.service_Type}></TextField>
                    </Grid>
                    </>
                :null}
                <Grid item xs={3}>
                        <FormLabel component="legend">Cancel Service?</FormLabel>
                        <FormControlLabel label="Yes" control={<Checkbox disabled={disableForm} checked={cancel_service_check} onChange={this.handleChange} name='cancel_service_check'></Checkbox>}></FormControlLabel>
                    </Grid>
                <Grid item xs={6}>
                                <FormLabel component="legend">Do you want to update Current Hours?</FormLabel>
                                    <FormControlLabel
                                        control={
                                        <Checkbox disabled checked={this.state.currentHoursCheck} onChange={this.handleChange} name="current_hours_check" />
                                        }
                                        label="Yes"
                                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField style={{backgroundColor:'white'}} disabled={disableForm} fullWidth rows={4} multiline label="Comments" defaultValue={editScheduledServiceData.comments} onChange={this.handleChange} name="comments"></TextField>
                </Grid>
                <Grid item xs={12}>
                    <Paper variant="outlined" style={{padding:'15px', backgroundColor:'#e0bfbc'}}>
                        <Grid item xs={12}>
                            <Typography style={{borderBottomColor:'black', borderBottomWidth:'thick', borderBottom:'solid', textAlign:'center', fontWeight:'bold'}}>Attachments</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={disableForm} fullWidth variant="outlined" component="label">Drag and drop, or click to upload files<input hidden accept="image/*" multiple type="file" onChange={this.handleFileUpload}></input></Button>
                            {editScheduledServiceData ? editScheduledServiceData.attachments ?
                            editScheduledServiceData.attachments.map((file:any, index:any) => {
                                return <Grid item xs={3}><Button onClick={(e:any)=>{this.onGetFileButtonClick(editScheduledServiceData.unit_Number, e.target.innerHTML.substring(0,e.target.innerHTML.indexOf("<")),editScheduledServiceData.id)}} className="dataGrid-edit-btn">{file}</Button><Button onClick={this.handleFileRemove.bind(this,index)}>X</Button></Grid>
                            })
                            :null:null}
                        </Grid>
                    </Paper>
                </Grid>
                        <Grid item xs={12}>
                            <br></br>
                                <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                                <Button disabled={disableForm} style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onSave} variant="contained">Save</Button>
                            </Grid>
                            </Grid>
                    </Paper>
                    </Paper>
                </Paper>
            </div></Box>
            </Modal>
        );
    }
}