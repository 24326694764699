import React from "react";
import { ById, HasClass } from "../../../shared/publicInterfaces";
import { LAIconButton } from "../../../shared/buttons";
import { AddIcon, CancelIcon, DeleteIcon, SaveIcon } from "../../../shared/icons";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import LATextField from "../../../shared/textField";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { DamagesValues, IOrder } from "../../../../redux/toolRentals/getOrders/getOrdersConstants";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { GREEN_COLOR, LIGHT_GREY_COLOR, RED_COLOR, SECONDARY_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LADatePicker from "../../../shared/datePicker";
import LAAutoComplete, { LADropDownForOrder } from "../../../shared/autoComplete";
import { IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import { undefinedFunction } from "../../../shared/constExports";
import styled from "styled-components";
import { IRate } from "../../../../redux/toolRentals/getRates/getRatesConstants";
import { IProjectListDD } from "../../../../redux/toolRentals/powerDropdown/getProjectListDD/getProjectListDDConstants";
import { IUnit } from "../../../../redux/toolRentals/getUnits/getUnitsConstants";

interface IOrderGridProps extends HasClass {
    data: IOrder;
    ddData: IUnit[];
    onSave: () => void;
    serverError: string[];
    onUnitAdd: () => void;
    projectList: IProjectListDD[];
    error: ById<IFieldErrorKeyValue>;
    onUnitDelete: (index: number) => void;
    errorUnits: ById<IFieldErrorKeyValue>[];
    onEdit: (id: number | undefined) => void;
    onChange: (name: string, value: string) => void;
    onUnitChange: (index: number, name: string, value: string) => void;
};

const OrderGridStyles = styled.div`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };
    .header-row {
        background-color: ${SECONDARY_COLOR};
    };
    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
    .error {
        color: ${WARNING_COLOR};
    };
    .del-btn {
        position: absolute;
        bottom: 0px;
        right: 0px;
    };
`;

export const OrderGrid: React.FC<IOrderGridProps> = React.memo((props: IOrderGridProps) =>
    {
        return <OrderGridStyles>
            <TableContainer component={LAPaperWithLessPadding}>
                <Table size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow className="header-row">
                            <TableCell className="title" align="center">PROJECT NUMBER</TableCell>
                            <TableCell className="title" align="center">PROJECT NAME</TableCell>
                            <TableCell className="title" align="center">PO</TableCell>
                            <TableCell className="title" align="center">UNITS</TableCell>
                            <TableCell className="title" align="center">RENTAL STATUS</TableCell>
                            <TableCell className="title" align="center">EDIT</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <EditRow {...props} error={props.error} value={props.data} onCancel={props.onEdit} 
                            onSave={props.onSave} onChange={props.onChange} errorUnits={props.errorUnits}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
            <br />

                    <LAPaperWithPadding>
                        <LAGrid justify="center" alignItems="center">

                            <LAGridItem className="text-center" xs={12}>
                                <strong>UNITS</strong>
                            </LAGridItem>

                            {props.data.units.map((a, index) => {
                                const disableCheck = ((a.status_Set_Date !== undefined) && a.status_Set_Date !== null) ? true : false;
                                const onUnitChange = (name: string, value: string) => props.onUnitChange(index, name, value);
                                const onClassChange = (e: unknown, value: IRate) => props.onUnitChange(index, "class", (value !== null) ? value.class : "");
                                const onTypeChange = (e: unknown, value: IRate) => props.onUnitChange(index, "type", (value !== null) ? value.type : "");
                                const onDateOut = (v: string) => props.onUnitChange(index, "date_Out", v);
                                const onDateReturn = (v: string) => props.onUnitChange(index, "date_Returned", v);
                                const classVal = props.ddData.find(x => x.class === a.class);
                                const typeVal = props.ddData.find(x => x.type === a.type);
                                const onUnitDelete = () => props.data.units.length > 1 ? props.onUnitDelete(index) : alert("Atleast one unit is required per order!");
                                const onDamagesChange = (e: unknown, value: { id: number; name: string; }) => props.onUnitChange(index, "damages", (value !== null) ? value.name : "");
                                return (
                                    <LAGridItem xs={12}>
                                        <LAPaperWithPadding>
                                            <LAGrid>
                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        clearOnEscape={true}
                                                        dropDownPlaceHolder="Class"
                                                        multiple={false}
                                                        getOptionLabel="class"
                                                        filterSelectedOptions={true}
                                                        autoHighlight={true}
                                                        disabled={disableCheck}
                                                        onChange={onClassChange}
                                                        selectionRemove={undefinedFunction}
                                                        option={filterDropDown(props.ddData, a.class, a.type, a.unit_No, true)}
                                                        errorText={props.errorUnits[index] && props.errorUnits[index]["class"] ? props.errorUnits[index]["class"].message : undefined}
                                                        value={classVal ? classVal : null}
                                                        defaultValue={props.ddData.find(x => x.class === a.class)} />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        clearOnEscape={true}
                                                        dropDownPlaceHolder="Type"
                                                        multiple={false}
                                                        getOptionLabel="type"
                                                        filterSelectedOptions={true}
                                                        autoHighlight={true}
                                                        disabled={disableCheck}
                                                        onChange={onTypeChange}
                                                        selectionRemove={undefinedFunction}
                                                        option={filterDropDown(props.ddData, a.class, a.type, a.unit_No, false)}
                                                        errorText={props.errorUnits[index] && props.errorUnits[index]["type"] ? props.errorUnits[index]["type"].message : undefined}
                                                        value={typeVal ? typeVal : null}
                                                        defaultValue={props.ddData.find(x => x.type === a.type)} />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField errorText={props.errorUnits[index] && props.errorUnits[index]["unit_No"] ? props.errorUnits[index]["unit_No"].message : undefined}
                                                        name="unit_No" label="Unit Number" fullWidth={true} value={a.unit_No} onChange={onUnitChange} disabled={disableCheck} />
                                                </LAGridItem>
                                                <LAGridItem xs={4}>
                                                    <LATextField errorText={props.errorUnits[index] && props.errorUnits[index]["quantity"] ? props.errorUnits[index]["quantity"].message : undefined}
                                                        name="quantity" label="Quantity" fullWidth={true} value={a.quantity} onChange={onUnitChange} type="number"
                                                        disabled={(classVal ? (classVal.class === "Misc Quantity Items" ? disableCheck : true) : true)} />
                                                </LAGridItem>
                                                <LAGridItem xs={4}>
                                                    <strong className={props.errorUnits[index] && props.errorUnits[index]["date_Out"] ? "error" : ""}>Date Out</strong><br />
                                                    <LADatePicker value={a.date_Out} onChange={onDateOut} disable={disableCheck ? true : undefined} />
                                                    <br />
                                                    <span className="error">{props.errorUnits[index] && props.errorUnits[index]["date_Out"] ? props.errorUnits[index]["date_Out"].message : undefined}</span>
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <strong>Date Returned</strong>
                                                    <br />
                                                    <LADatePicker minDate={a.date_Out} maxDate={new Date().toISOString()} value={a.date_Returned} disable={disableCheck ? true : undefined} onChange={onDateReturn} />
                                                </LAGridItem>
                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        multiple={false}
                                                        getOptionLabel="name"
                                                        autoHighlight={true}
                                                        option={DamagesValues}
                                                        onChange={onDamagesChange}
                                                        filterSelectedOptions={true}
                                                        dropDownPlaceHolder="Damages"
                                                        selectionRemove={undefinedFunction}
                                                        value={DamagesValues.find(x => x.name === a.damages)}
                                                        defaultValue={DamagesValues.find(x => x.name === a.damages)}
                                                        disabled={(disableCheck ? true : (a.date_Returned ? false : true))}
                                                        errorText={props.errorUnits[index] && props.errorUnits[index]["damages"] ? props.errorUnits[index]["damages"].message : undefined}
                                                    />
                                                </LAGridItem>
                                                <LAGridItem xs={4}>
                                                    <LATextField errorText={props.errorUnits[index] && props.errorUnits[index]["picked_Up_By"] ? props.errorUnits[index]["picked_Up_By"].message : undefined}
                                                        name="picked_Up_By" label="Picked Up By" fullWidth={true} value={a.picked_Up_By} onChange={onUnitChange} disabled={disableCheck}
                                                        />
                                                </LAGridItem>
                                                <LAGridItem xs={4}>
                                                    <LATextField errorText={props.errorUnits[index] && props.errorUnits[index]["invoices"] ? props.errorUnits[index]["invoices"].message : undefined}
                                                        name="invoices" disabled={true} label="Invoices" fullWidth={true} value={a.invoices} onChange={undefinedFunction}
                                                        />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LAIconButton className="del-btn" label="Delete Unit" icon={<DeleteIcon />} onClick={onUnitDelete}
                                                        disabled={disableCheck ? true : undefined} />
                                                </LAGridItem>
                                            </LAGrid>
                                        </LAPaperWithPadding>
                                    </LAGridItem>
                                );
                            })}

                            <LAGridItem xs={12}>
                                <LAIconButton label="Add Unit" icon={<AddIcon />} onClick={props.onUnitAdd} />
                            </LAGridItem>

                            {props.serverError.slice(1).map((x, index) => (
                                <LAGridItem className="error pt-2" key={index} xs={12}>
                                    {x}
                                </LAGridItem>
                            ))}

                        </LAGrid>
                    </LAPaperWithPadding>
        </OrderGridStyles>
    }
);


interface IEditRowProps {
    value: IOrder;
    onSave: () => void;
    projectList: IProjectListDD[];
    error: ById<IFieldErrorKeyValue>;
    onCancel: (id: undefined) => void;
    errorUnits: ById<IFieldErrorKeyValue>[];
    onChange: (name: string, value: string) => void;
};

const EditRow: React.FC<IEditRowProps> = React.memo((props: IEditRowProps) => {
    const onCancel = () => props.onCancel(undefined);

    const onProjectNumber = (e: unknown, value: IProjectListDD) => props.onChange("project_Number", (value !== null) ? value.project_Number : "");
    return <>
        <TableRow key={props.value.id}>
            <TableCell component="th" scope="row">
                <LADropDownForOrder
                    dropDownPlaceHolder="Project Number"
                    multiple={false}
                    getOptionLabel="project_Number"
                    filterSelectedOptions={true}
                    autoHighlight={true}
                    onChange={onProjectNumber}
                    selectionRemove={undefinedFunction}
                    option={props.projectList}
                    errorText={props.error["project_Number"] ? props.error["project_Number"].message : undefined}
                    value={props.value.project_Number ? props.projectList.find(x => x.project_Number === props.value.project_Number) : ""}
                    defaultValue={props.value.project_Number ? props.projectList.find(x => x.project_Number === props.value.project_Number) : ""}
                />
            </TableCell>
          
            <TableCell align="center">
                <LATextField disabled={true} errorText={props.error["project_Name"] ? props.error["project_Name"].message : undefined}
                    name="project_Name" label="" fullWidth={true} value={props.value.project_Name} onChange={props.onChange} />
            </TableCell>

            <TableCell align="center">
                <LATextField errorText={props.error["po"] ? props.error["po"].message : undefined}
                    name="po" label="PO" fullWidth={true} value={props.value.po} onChange={props.onChange} />
            </TableCell>

            <TableCell align="center">
            </TableCell>

            <TableCell align="center">
                {props.value.rental_Status}
            </TableCell>

            <TableCell align="right">
                <LAGrid spacing={1}>
                    <LAGridItem xs={6}>
                        <LAIconButton 
                        label="Save" onClick={props.onSave}
                        disabled={
                            ((Object.values(props.error).length > 0) || props.errorUnits.some(q => Object.values(q).length > 0)) 
                            ? true : undefined} 
                        icon={<SaveIcon color={((Object.values(props.error).length > 0) || props.errorUnits.some(q => Object.values(q).length > 0)) ? LIGHT_GREY_COLOR : GREEN_COLOR} />} 
                        />
                    </LAGridItem>

                    <LAGridItem xs={6}>
                        <LAIconButton icon={<CancelIcon color={RED_COLOR} />} label="Cancel" onClick={onCancel} />
                    </LAGridItem>
                </LAGrid>
            </TableCell>
        </TableRow>
    </>
});

const filterDropDown = (data: IUnit[], className: string, type: string, unitNo: string, forClass: boolean): IUnit[] => {
    let response: IUnit[] = [];

    if (unitNo.length > 0) {
        data.forEach((x) => {
            if (x.unit_No === unitNo)
                response.push(x);
        });

        if (forClass) {
            response = response.filter((v, i, a) => a.findIndex(t => (t.class === v.class)) === i);
        } else {
            response = response.filter((v, i, a) => a.findIndex(t => (t.type === v.type)) === i);
        }
    };

    if (className.length > 0 && unitNo.length === 0) {
        data.forEach((x) => {
            if (x.class === className)
                response.push(x);
        });
        response = response.filter((v, i, a) => a.findIndex(t => (t.type === v.type && t.class && v.class)) === i);
    };

    if (type.length > 0 && unitNo.length === 0) {
        data.forEach((x) => {
            if (x.type === type)
                response.push(x);
        });
        response = response.filter((v, i, a) => a.findIndex(t => (t.type === v.type && t.class && v.class)) === i);
    };

    if (unitNo.length === 0 && className.length === 0 && type.length === 0) {
        if (forClass) {
            response = data.filter((v, i, a) => a.findIndex(t => (t.class === v.class && t.class && v.class)) === i);
        } else {
            response = data.filter((v, i, a) => a.findIndex(t => (t.type === v.type && t.class && v.class)) === i);
        }
    };
    
    if (response.length === 0) {
        response = data;
    };

    return response;
};