import { IToken } from "./../../getToken/getTokenConstants";

export enum IGET_EQUIPMENT_FACETS_REQUEST {
    REQUEST = "getDismantledEquipmentFacets/GET_EQUIPMENT_FACETS_REQUEST",
    SUCCESS = "getDismantledEquipmentFacets/GET_EQUIPMENT_FACETS_SUCCESS",
    FAILED = "getDismantledEquipmentFacets/GET_EQUIPMENT_FACETS_FAILED"
};

export interface IDismantledEquipmentFacetsRequest extends IToken {    
    
};

export interface IDismantledEquipmentFacets {
    count: number;
    equipment: string;
};

