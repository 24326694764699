import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_RATE_REQUEST } from "./updateRateConstants";
import { IUpdateRateLoadAction, IUpdateRateLoadFailedAction, IUpdateRateSuccessAction, updateRateLoadFailedAction, updateRateLoadSuccessAction } from "./updateRateActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const updateRateEpic: Epic = (
    action$: ActionsObservable<IUpdateRateLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateRateSuccessAction | IUpdateRateLoadFailedAction> =>
    action$.ofType(IUPDATE_RATE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<null>>(
                    END_POINTS.GET_TOOL_RENTALS.RATES.UPDATE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<null>): IUpdateRateSuccessAction | IUpdateRateLoadFailedAction => {
                            if(response.message === "Success"){
                            return updateRateLoadSuccessAction(action.data.request);
                            }

                            return updateRateLoadFailedAction(response.message);
                        }),
                        catchError((response: ToolRentalsResponse<any>) => ActionsObservable.of(updateRateLoadFailedAction(response.message)))
                    )
            )
        );