import { ById } from './../../../react/shared/publicInterfaces';
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_SHOP_MANUFACTURERS_REQUEST, IShopManufacture, IShopManufactureRequest } from "./getShopManufacturesConstants";


export interface IGetShopManufacturesLoadAction {
    type: IGET_SHOP_MANUFACTURERS_REQUEST.REQUEST;
    data: IShopManufactureRequest
}
export const getShopManufacturesLoadAction = (data: IShopManufactureRequest): IGetShopManufacturesLoadAction => ({
    type: IGET_SHOP_MANUFACTURERS_REQUEST.REQUEST,
    data
});
export interface IGetShopManufacturesSuccessAction {
    type: IGET_SHOP_MANUFACTURERS_REQUEST.SUCCESS;
    list: PartsResponse<ById<IShopManufacture>>;
}
export const getShopManufacturesLoadSuccessAction = (list: PartsResponse<ById<IShopManufacture>>): IGetShopManufacturesSuccessAction => ({
    type: IGET_SHOP_MANUFACTURERS_REQUEST.SUCCESS,
    list
});
export interface IGetShopManufacturesLoadFailedAction {
    type: IGET_SHOP_MANUFACTURERS_REQUEST.FAILED;
    message: string;
}
export const getShopManufacturesLoadFailedAction = (message: string): IGetShopManufacturesLoadFailedAction => ({
    type: IGET_SHOP_MANUFACTURERS_REQUEST.FAILED,
    message
});
