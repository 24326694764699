import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Pager, SearchPanel, 
    FilterPanel,
  FilterBuilderPopup,
  Export, Selection,
  Editing,
  Lookup,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography, FormControl, RadioGroup, Radio, FormControlLabel} from '@mui/material';
  import {RouteComponentProps} from 'react-router-dom';
import SOCTHome, {currentUser} from './socthome';
import {ApproveMeterApp} from './api/meterreading/meterReadingAPI';
import {getSOCTWorkingSites, getSOCTWorkingSubSitesByParentID} from './api/workingsites/workingSitesAPI';

interface IState {
  soctMaster:any;
  open:boolean;
  view:string;
}
interface IProps extends RouteComponentProps{
  history:any
}

var editData:any;
var unitNumbers:any = [];
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
var globalEditID:Number = 0;
var renderPageType:string = '';
var approvedData:any[] = [];
var tempApprovedData:any[] = [];
var copy_list:any[] = [];
var working_site_options:any[] = [];
var sub_site_options:any[] = [];
var sub_site_mapping:any[] = [];
export default class MeterAppTable extends React.Component<IProps,IState>{
  constructor(props:any){
    super(props);
    this.state ={soctMaster:[], open:false, view:'draft'};
    this.selectedRowKeys = this.selectedRowKeys.bind(this);
    this.handleApproved = this.handleApproved.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.filterView = this.filterView.bind(this);
    this.setWorkingSiteLocation = this.setWorkingSiteLocation.bind(this);
    this.setSubWorkingSiteLocation = this.setSubWorkingSiteLocation.bind(this);
    this.onEditChange = this.onEditChange.bind(this);
  }
  setSubWorkingSiteLocation(rowData:any, value:any){
    console.log(rowData, value);
    rowData.sub_Location = value.sub_Site_Name;
    this.setState({});
  }
  async setWorkingSiteLocation(rowData:any, value:any){
    rowData.location = value.site_Name;
    sub_site_options = await getSOCTWorkingSubSitesByParentID(value.id, false);
    this.setState({});  
  }
  filterView(eventKey:any){
    switch(eventKey.target.value) {
        case 'draft':
            this.setState({view:'draft'});
            this.refreshState();
            var filtered_list = copy_list.filter((elem:any) => { return (elem.status != "Approved")});
            this.setState({soctMaster:filtered_list});
            break;
        case 'approved':
            this.setState({view:'approved'});
            this.refreshState();
            var filtered_list = copy_list.filter((elem:any) => { return (elem.status != "Draft")});
            this.setState({soctMaster:filtered_list});
            break;
        case 'all':
            this.setState({view:'all'});
            this.refreshState();
            var filtered_list = copy_list.filter((elem:any) => { return (elem.status === "Draft" || elem.status === "Approved")});
            this.setState({soctMaster:filtered_list});
            break;
       }
  }
  async handleApproved(){
    var temp_approved:any[] = [];
    var final_approved:any[] = [];
      approvedData.reverse().map((second:any) => {
        if(!temp_approved.some(elem => (elem.id === second.id))){
          temp_approved.push(second);
        }
      });
      console.log(temp_approved, tempApprovedData);
      temp_approved.map((elem:any) => {
        if(tempApprovedData.some(second => second.id === elem.id)){
          final_approved.push(elem);
        }
      });
      console.log('final selected is ', final_approved)
    let error = false;
    if(final_approved!=null && final_approved.length>0){
        for(var i=0; i< final_approved.length; i++){
            if((final_approved[i].location === "" || final_approved[i].location===null) || (final_approved[i].sub_Location==="" || final_approved[i].sub_Location===null)){
                alert("Cannot approve record with empty location and or sub location");
                error = true;
            }
        }
        if(!error){
          console.log(final_approved)
            var res:any = await ApproveMeterApp(final_approved, currentUser);
            if(res.status === 200){
                alert("Successfully approved meter readings for app");
                this.refreshState();
            } 
            else{
                alert("Error approving meter readings for app");
            }
        }
    }
    else{
        alert("At least one record must be selected to approve!")
    }
  }
  onEditChange(event:any){
    let temp_row = event.row;
    if(temp_row){
      approvedData.push(temp_row.data);
    }
    
  }
  selectedRowKeys(event:any){
    tempApprovedData = event.selectedRowsData;
    //this.saveEdits(event);
  }
  refreshState(){
    window.location.href = "https://apps.sureway.ca/field/soct/meterapp";
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
  // api.sureway.ca
    fetch('https://api.sureway.ca/api/SOCT/GetSOCTMeterApp', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        data.map((history:any) => {
            history.temp_location_id = 0;
            if(this.state.view === 'draft'){
                if(history.status === 'Draft'){
                    master_temp_list.push(history);
                }
            }
            else if(this.state.view === 'approved'){
                if(history.status === 'Approved'){
                    master_temp_list.push(history);
                }
            }
            else{
                master_temp_list.push(history);
            }
        });
        copy_list = master_temp_list;
        this.setState({soctMaster:master_temp_list});
    })
  }
  async componentDidMount(){
    var result:any = await getSOCTWorkingSites(false);
    result.map((location:any) => {
        working_site_options.push(location);
    })
    this.refreshState();
  }
    render(){
        return (
          <> <SOCTHome history={this.props.history}></SOCTHome>
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
            <MUIButton disabled={this.state.view!='draft'} onClick={this.handleApproved} style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" >Approve</MUIButton>
            <Typography className='dataGrid-title'>Meter App Update</Typography>
            <Grid item xs={3}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={this.filterView}
                        defaultValue={"draft"}
                    >
                        <FormControlLabel value="draft" control={<Radio size="small"/>} label="Draft" />
                        <FormControlLabel value="approved" control={<Radio size="small"/>} label="Approved" />
                        <FormControlLabel value="all" control={<Radio size="small"/>} label="All" />
                        </RadioGroup>
                </FormControl>
            </Grid>
            <DataGrid focusedRowEnabled={true}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
                dataSource={this.state.soctMaster}
                keyExpr="id"
                showBorders={true}
                wordWrapEnabled={true}
                onSelectionChanged={this.selectedRowKeys}
                onEditorPrepared={this.onEditChange}
                >
                    <Editing mode="batch" allowUpdating={true}></Editing> 
            <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." />    
              <Paging enabled={true} defaultPageSize={20}/>
              <Pager
                visible={true}
                allowedPageSizes={[20,40,60]}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
                <FilterRow visible={true}></FilterRow>
                <FilterPanel visible={true} />
   <FilterBuilderPopup position={filterBuilderPopupPosition} />
     <HeaderFilter visible={true} allowSearch={true} />
     <FilterRow visible={true}></FilterRow>
      <FilterPanel visible={true} />
      <Export enabled={true} allowExportSelectedData={true} />
       <Column allowEditing={false} dataField="unit_Number" caption="Unit #" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="make" caption="Make" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="model" caption="Model" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="date" dataType="date" caption="Meter Date" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="previous_Reading" caption="Previous Reading" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="current_Hours" caption="Current Hours" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="ecm" caption="ECM" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="previous_ECM_Reading" caption="Previous ECM" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={this.state.view==='draft'} dataField="location" caption="Location" alignment="left" width={200} headerCellRender={renderTitleHeader} setCellValue={this.setWorkingSiteLocation}>
        <Lookup dataSource={working_site_options} displayExpr="site_Name" ></Lookup>
       </Column>
       <Column allowEditing={this.state.view==='draft'} dataField="sub_Location" caption="Sub Location" alignment="left" width={200} headerCellRender={renderTitleHeader} setCellValue={this.setSubWorkingSiteLocation}>
        <Lookup dataSource={sub_site_options} displayExpr="sub_Site_Name"></Lookup>
       </Column>
       <Column allowEditing={false} dataField="status" caption="Status" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column allowEditing={this.state.view==='draft'} dataField="comments" caption="Comments" alignment="left" headerCellRender={renderTitleHeader}/>
       {this.state.view==='draft' ?
       <Selection
            mode="multiple"
            selectAllMode={'page'}
            showCheckBoxesMode={'onClick'}
          />:null}
            </DataGrid>
            </Card>
            </Grid>
            </>
        );
    }
}
export {editData, globalEditID, unitNumbers, renderPageType};