import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_PROJECT_LIST_DD_REQUEST, IProjectListDD } from "./getProjectListDDConstants";
import { IGetProjectListDDLoadAction, IGetProjectListDDLoadFailedAction, IGetProjectListDDSuccessAction, getProjectListDDLoadFailedAction, getProjectListDDLoadSuccessAction } from "./getProjectListDDActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const getProjectListDDEpic: Epic = (
    action$: ActionsObservable<IGetProjectListDDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetProjectListDDSuccessAction | IGetProjectListDDLoadFailedAction> =>
    action$.ofType(IGET_PROJECT_LIST_DD_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<IProjectListDD[]>>(
                    END_POINTS.GET_TOOL_RENTALS.PROJECTS.GET_LIST_FOR_DROPDOWN,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<IProjectListDD[]>): IGetProjectListDDSuccessAction => {
                            return getProjectListDDLoadSuccessAction(response);
                        }),
                        catchError((response: ToolRentalsResponse<IProjectListDD[]>) => ActionsObservable.of(getProjectListDDLoadFailedAction(response.message)))
                    )
            )
        );