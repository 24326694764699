import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IVALIDATE_SHOP_GROUP_REQUEST, IValidateShopGroup } from "./validateShopGroupConstants";
import { IValidateShopGroupLoadAction, IValidateShopGroupLoadFailedAction, IValidateShopGroupSuccessAction, 
    validateShopGroupLoadFailedAction, validateShopGroupLoadSuccessAction 
} from "./validateShopGroupActions";
import { ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { END_POINTS } from "../../endpoints";
import { withJsonContentType } from "../../epicUtils";

export const validateShopGroupEpic: Epic = (
    action$: ActionsObservable<IValidateShopGroupLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IValidateShopGroupSuccessAction | IValidateShopGroupLoadFailedAction> =>
    action$.ofType(IVALIDATE_SHOP_GROUP_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<IValidateShopGroup>>(
                    END_POINTS.GET_TOOL_RENTALS.VALIDATE_SHOP_GROUP,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<IValidateShopGroup>): IValidateShopGroupSuccessAction | IValidateShopGroupLoadFailedAction => {
                            if(response.message === "Success"){
                                return validateShopGroupLoadSuccessAction(response);
                            }
                            return validateShopGroupLoadFailedAction(response.message);
                        }),
                        catchError((response: ToolRentalsResponse<IValidateShopGroup>) => ActionsObservable.of(validateShopGroupLoadFailedAction(response.message)))
                    )
            )
        );