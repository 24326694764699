
export enum IGET_LOOKUPS_REQUEST {
    REQUEST = "getLookups/GET_LOOKUPS_REQUEST",
    SUCCESS = "getLookups/GET_LOOKUPS_SUCCESS",
    FAILED = "getLookups/GET_LOOKUPS_FAILED"
};

export interface IShopLookupsRequest {
    token: string;
};


export interface IShopLookups {
    equipment_Types: string[];
    models: { id: number, model: string }[];
    manufacturers: { id: number, manufacturer_Name: string }[];
    owners: IShopLookupOwners[];
};


export interface IShopLookupOwners { 
    id: number;
    name: string;
    company: string;
};