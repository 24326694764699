import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_PART_REQUEST } from "./addPartConstants";
import { IAddPartLoadAction, IAddPartLoadFailedAction, IAddPartSuccessAction, addPartLoadFailedAction, addPartLoadSuccessAction } from "./addPartActions";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { IPart } from "../../getParts/getPartConstants";

export const addPartEpic: Epic = (
    action$: ActionsObservable<IAddPartLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddPartSuccessAction | IAddPartLoadFailedAction> =>
    action$.ofType(IADD_PART_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IPart[]>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS.ADD,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IPart[]>): IAddPartSuccessAction | IAddPartLoadFailedAction => {
                            if(response.message === "Success"){
                                return addPartLoadSuccessAction(response);
                            }
                            return addPartLoadFailedAction(response.message);
                        }),
                        catchError((response: PartsResponse<IPart[]>) => ActionsObservable.of(addPartLoadFailedAction(response.message)))
                    )
            )
        );