import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { IUPDATE_PART_PICKUP_TRUCK_REQUEST } from "./updatePickupTruckConstants";
import { IUpdatePartPickupTruckLoadAction, IUpdatePartPickupTruckLoadFailedAction, IUpdatePartPickupTruckSuccessAction, updatePartPickupTruckLoadFailedAction, updatePartPickupTruckLoadSuccessAction } from "./updatePickupTruckActions";

export const updatePartPickupTruckEpic: Epic = (
    action$: ActionsObservable<IUpdatePartPickupTruckLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdatePartPickupTruckSuccessAction | IUpdatePartPickupTruckLoadFailedAction> =>
    action$.ofType(IUPDATE_PART_PICKUP_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<null>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS_DISMANTLED_PICKUP_TRUCKS.UPDATE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<null>): IUpdatePartPickupTruckSuccessAction | IUpdatePartPickupTruckLoadFailedAction => {
                            if(response.message === "Success"){
                            return updatePartPickupTruckLoadSuccessAction(action.data.request);
                            }
                            return updatePartPickupTruckLoadFailedAction(response.message);
                        }),
                        catchError((response: PartsResponse<any>) => ActionsObservable.of(updatePartPickupTruckLoadFailedAction(response.message)))
                    )
            )
        );