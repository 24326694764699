import { IDELETE_PART_REQUEST, IDeletePartRequest } from "./deletePartConstants";


export interface IDeletePartLoadAction {
    type: IDELETE_PART_REQUEST.REQUEST;
    data: IDeletePartRequest
}
export const deletePartLoadAction = (data: IDeletePartRequest): IDeletePartLoadAction => ({
    type: IDELETE_PART_REQUEST.REQUEST,
    data
});
export interface IDeletePartSuccessAction {
    type: IDELETE_PART_REQUEST.SUCCESS;
    message: string;
}
export const deletePartLoadSuccessAction = (message: string): IDeletePartSuccessAction => ({
    type: IDELETE_PART_REQUEST.SUCCESS,
    message
});
export interface IDeletePartLoadFailedAction {
    type: IDELETE_PART_REQUEST.FAILED;
    message: string;
}
export const deletePartLoadFailedAction = (message: string): IDeletePartLoadFailedAction => ({
    type: IDELETE_PART_REQUEST.FAILED,
    message
});
