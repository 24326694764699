import React, { PureComponent, ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BLACK_COLOR, LIGHT_GREY, DARK_WHITE } from "../../react/shared/theme";
import { LAButton } from "../../react/shared/buttons";
import HomeDropDown from "./homeDropDown";
import { ListIcon, LogOutIcon } from "../../react/shared/icons";
import { ROUTE } from "../../react/routes";
import ManufacturerDropDown from "./manufacturerDropDown";
import PartDropDown from "./partDropDown";
import DismantledEquipmentDropDown from "./dismantledEquipmentDropDown";
import { logOutClick } from "./header";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { LACrossButtonWithInWindow } from "../shared/crossButton";
import styled from "styled-components";
import logo from "../../logo.png";


interface IMobileHeaderOwnProps {

};

interface IMobileHeaderState {
    open: boolean;
    drawer: boolean;
    termsAndCondition: boolean;
};

const MobileHeaderStyles = styled.div`
    .webserver {
        color: ${DARK_WHITE};
    };

    .logo-img {
        padding-top: 15px;
        width: 170px;
        height: 35px;
    };

`;

type IMobileHeaderProps =
    RouteComponentProps
    & IMobileHeaderOwnProps;

class MobileHeader extends PureComponent<IMobileHeaderProps, IMobileHeaderState> {

    public constructor(props: IMobileHeaderProps) {
        super(props);
        this.state = {
            open: false,
            drawer: false,
            termsAndCondition: false
        };
    }

    public render(): ReactNode {

        return (
            <MobileHeaderStyles>
                <MobileHeaderLayout {...this.props} onDrawer={this.onDrawer} onHome={this.onHome} />

                <Drawer
                    onClose={this.onDrawer}
                    open={this.state.drawer}
                    PaperProps={{ style: { width: "80%", backgroundColor: LIGHT_GREY, color: BLACK_COLOR } }}
                >
                        <div className="sub-header">
                            <HomeDropDown {...this.props} onDrawer={this.onDrawer} />
                            <ManufacturerDropDown {...this.props} onDrawer={this.onDrawer} />
                            <PartDropDown {...this.props} onDrawer={this.onDrawer} />
                            <DismantledEquipmentDropDown {...this.props} onDrawer={this.onDrawer} />
                        </div>
                    <LACrossButtonWithInWindow onClick={this.onDrawer} />

                </Drawer>
            </MobileHeaderStyles>
        );
    }


    private onDrawer = (): void => {
        this.setState({ drawer: !this.state.drawer });
    };

    private onHome = (): void => {
        this.props.history.push(ROUTE.INDEX);
        
        if(this.state.drawer)
            this.onDrawer();
    };

}

export default MobileHeader;



const theme = createTheme({
    palette: {
        primary: {
            main: LIGHT_GREY,
        }
    },
});

interface IMobileHeaderLayout extends RouteComponentProps {
    onHome: () => void;
    onDrawer: () => void;
};

const MobileHeaderLayout: React.FC<IMobileHeaderLayout> = React.memo((props: IMobileHeaderLayout) =>
    <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="primary"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={props.onDrawer}
                    >
                        <ListIcon color={BLACK_COLOR} />
                    </IconButton>

                    <Typography onClick={props.onHome} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <div className="webserver">
                        <img src={logo} className="logo-img" alt="logo" title="logo" />
                        </div>
                    </Typography>

                    <LAButton label="Log Out" startIcon={<LogOutIcon color={BLACK_COLOR} />} onClick={logOutClick} />
                </Toolbar>
            </AppBar>
        </Box>
    </ThemeProvider>
);