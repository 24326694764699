import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Grid, Button, Paper, TextField, Stack, Alert, Box, Backdrop, IconButton, 
FormControl, FormLabel, FormGroup, Checkbox, FormControlLabel} from '@mui/material';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
};

interface IState{
    showAddSubSiteForm:boolean;
    open:boolean;
    showError:boolean;
    errorText:boolean;
}
interface IProps{
    data:any[];
    reRender:any;
    disabled:boolean;
}
var newSubSite = {sub_Site_Name:'', sub_Site_Address:'', sub_Site_Coordinates:'', active:true, notes:'', id:0};
var isUnique = false;
export default class SubWorkingSiteTable extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state ={showAddSubSiteForm:false, open:true, showError:false, errorText:false};
        this.handleChange = this.handleChange.bind(this);
        this.saveSubSite = this.saveSubSite.bind(this);
        this.cancelSubSite = this.cancelSubSite.bind(this);
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'sub_site_name':
                newSubSite.sub_Site_Name = event.target.value;
                break;
            case 'sub_site_address':
                newSubSite.sub_Site_Address = event.target.value;
                break;
            case 'sub_site_coordinates':
                newSubSite.sub_Site_Coordinates = event.target.value;
                break;
            case 'active':
                newSubSite.active = !newSubSite.active;
                break;
            case 'notes':
                newSubSite.notes = event.target.value;
                break;
        }
        this.setState({});
    }
    isUnique(sub_site_name:string){
        for (var i=0; i < this.props.data.length; i++){
            if(this.props.data[i].sub_Site_Name.trim().toLowerCase() === sub_site_name.trim().toLocaleLowerCase()){
                isUnique = true;
                this.props.reRender();
                return false;
            }
        };
        isUnique = false;
        this.props.reRender();
        return true;
    }
    saveSubSite(){
        if(this.isUnique(newSubSite.sub_Site_Name)){
            this.props.data.push(newSubSite);
            newSubSite = {sub_Site_Name:'', sub_Site_Address:'', sub_Site_Coordinates:'', active:true, notes:'', id:0};
            this.setState({showAddSubSiteForm:false});
            this.props.reRender();
        }
        else{
            alert("Sub site name already exists for this site");
        }
    }
    cancelSubSite(){
        newSubSite = {sub_Site_Name:'', sub_Site_Address:'', sub_Site_Coordinates:'', active:true, notes:'', id:0};
        this.setState({showAddSubSiteForm:false});
    }

    handleSubSiteEdit(index:number,event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'sub_site_name':
                if(this.isUnique(event.target.value)){
                    this.props.data[index].sub_Site_Name = event.target.value;
                }
                else{
                    alert("Sub site name already exists!");
                }
                break;
            case 'sub_site_address':
                this.props.data[index].sub_Site_Address = event.target.value;
                break;
            case 'sub_site_coordinates':
                this.props.data[index].sub_Site_Coordinates = event.target.value;
                break;
            case 'sub_site_active':
                this.props.data[index].active = !this.props.data[index].active;
                break;
            case 'sub_site_notes':
                this.props.data[index].notes = event.target.value;
                break;
        }
        this.setState({});
    }
    render(){
    return(
    <>
    <TableContainer component={Paper}>
    <Table size="small" aria-label="Product info">
        <TableHead>
            <TableRow>
                <TableCell align="center" colSpan={3} sx={{ color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px',backgroundColor:'#bf0000', padding:'10px'}}>Sub Working Sites</TableCell>
                <TableCell align="right" colSpan={6}> <Button disabled={this.props.disabled} style={{backgroundColor:'#bf0000'}} onClick={() => {this.setState({showAddSubSiteForm:true})}} variant="contained">Add new</Button></TableCell>
            </TableRow>
        
        <TableRow>
            <TableCell>Sub Site Name</TableCell>
            <TableCell >Sub Site Address</TableCell>
            <TableCell >Sub Site Coordinates</TableCell>
            <TableCell >Active</TableCell>
            <TableCell >Notes</TableCell>
            {/* <TableCell align="right">Delete</TableCell> */}
        </TableRow>
    </TableHead>
     {this.props.data.map((subSite:any, index:any) => {   
        //  Prevent showing deleted rows or null rows
     if(subSite.sub_Site_Name !='' && subSite.id!=-1){
    return <TableBody>
        <TableRow>
            <TableCell ><TextField onChange={this.handleSubSiteEdit.bind(this,index)} defaultValue={subSite.sub_Site_Name} name="sub_site_name" autoComplete='nope'></TextField></TableCell>
            <TableCell ><TextField onChange={this.handleSubSiteEdit.bind(this,index)} defaultValue={subSite.sub_Site_Address} name="sub_site_address" autoComplete='nope'></TextField></TableCell>
            <TableCell ><TextField onChange={this.handleSubSiteEdit.bind(this,index)} defaultValue={subSite.sub_Site_Coordinates} name="sub_site_coordinates" autoComplete='nope'></TextField></TableCell>
            <TableCell ><Checkbox onChange={this.handleSubSiteEdit.bind(this,index)} checked={subSite.active} name="sub_site_active"></Checkbox></TableCell>
            <TableCell ><TextField onChange={this.handleSubSiteEdit.bind(this,index)} defaultValue={subSite.notes} name="sub_site_notes" autoComplete='nope'></TextField ></TableCell>
            {/* <TableCell align="right"><Button className={"del-customer-btn"} onClick={this.deleteSubSite.bind(this, index, subSite.id)}>X</Button></TableCell> */}
        </TableRow>
    </TableBody>
     }
    })}
    </Table> </TableContainer>
    {this.state.showAddSubSiteForm ?  <Modal id='warehouse-emp-modal'
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.state.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.open}>
          <Box sx={BoxStyle}>
          {this.state.showError ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                  Delivery Address cannot be empty
                </Alert>
              </Stack>
            :null}
            <br></br>
            <Grid item xs={12}>
                        <Typography gutterBottom variant="h4" >Adding new sub site</Typography>
                        </Grid>
            <Grid container spacing={3} direction='row'>
                <Grid item xs={6}>
                    <TextField label="Sub Site Name" helperText={this.state.errorText === true ? 'Delivery Address already exists':null} 
                    autoComplete='nope'
                    error={this.state.errorText===true}
                     id={'outlined-error-helper-text'} 
                     name="sub_site_name"
                     onChange={this.handleChange}>
                     </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField label="Sub Site Address" helperText={this.state.errorText === true ? 'Delivery Address already exists':null} 
                    autoComplete='nope'
                    error={this.state.errorText===true} 
                    id={'outlined-error-helper-text'} 
                    name="sub_site_address"
                    onChange={this.handleChange}>

                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField label="Sub Site Coordinates" helperText={this.state.errorText === true ? 'Delivery Address already exists':null} 
                    autoComplete='nope'
                    placeholder="ex: 12.24, -89.33"
                    error={this.state.errorText===true} 
                    id={'outlined-error-helper-text'} 
                    name="sub_site_coordinates"
                    onChange={this.handleChange}>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Active?</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={newSubSite.active} name="active" onChange={this.handleChange}/>
                                }
                                label="Yes"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Notes" rows={3} multiline name="notes" onChange={this.handleChange} autoComplete='nope'></TextField>
                </Grid>
                <Grid item xs={6}>
            <Button variant="outlined" className="cancel-employee-save" onClick={this.cancelSubSite}>Cancel Changes</Button>
            </Grid>
            <Grid item xs={6}>
            <Button variant="outlined" className="save-employee" onClick={this.saveSubSite}>Save Info</Button>
            </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>:null}
    </>
    );
    }
}
export {isUnique};