import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IADD_UNIT_REQUEST } from "./addUnitConstants";
import { IAddUnitLoadAction, IAddUnitLoadFailedAction, IAddUnitSuccessAction } from "./addUnitActions";

type Actions =
    | IAddUnitLoadAction
    | IAddUnitSuccessAction
    | IAddUnitLoadFailedAction
    | IFlushDataSuccessAction;

export const AddUnitReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_UNIT_REQUEST.REQUEST:
            return loading;

        case IADD_UNIT_REQUEST.SUCCESS:
            return succeeded("Success");

        case IADD_UNIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};