import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST, IGetShopEquipmentColumn, IGetShopEquipmentColumnRequest } from "./getShopEquipmentColumnsConstants";

export interface IGetShopEquipmentColumnsLoadAction {
    type: IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.REQUEST;
    data: IGetShopEquipmentColumnRequest
}
export const getShopEquipmentColumnsLoadAction = (data: IGetShopEquipmentColumnRequest): IGetShopEquipmentColumnsLoadAction => ({
    type: IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.REQUEST,
    data
});
export interface IGetShopEquipmentColumnsSuccessAction {
    type: IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.SUCCESS;
    list: PartsResponse<IGetShopEquipmentColumn>;
}
export const getShopEquipmentColumnsLoadSuccessAction = (list: PartsResponse<IGetShopEquipmentColumn>): IGetShopEquipmentColumnsSuccessAction => ({
    type: IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.SUCCESS,
    list
});
export interface IGetShopEquipmentColumnsLoadFailedAction {
    type: IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.FAILED;
    message: string;
}
export const getShopEquipmentColumnsLoadFailedAction = (message: string): IGetShopEquipmentColumnsLoadFailedAction => ({
    type: IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.FAILED,
    message
});
