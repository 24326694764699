import { IDELETE_PICKUP_TRUCK_REQUEST, IDeletePickupTruckRequest } from "./deletePickupTruckConstants";


export interface IDeletePickupTruckLoadAction {
    type: IDELETE_PICKUP_TRUCK_REQUEST.REQUEST;
    data: IDeletePickupTruckRequest
}
export const deletePickupTruckLoadAction = (data: IDeletePickupTruckRequest): IDeletePickupTruckLoadAction => ({
    type: IDELETE_PICKUP_TRUCK_REQUEST.REQUEST,
    data
});
export interface IDeletePickupTruckSuccessAction {
    type: IDELETE_PICKUP_TRUCK_REQUEST.SUCCESS;
    message: string;
}
export const deletePickupTruckLoadSuccessAction = (message: string): IDeletePickupTruckSuccessAction => ({
    type: IDELETE_PICKUP_TRUCK_REQUEST.SUCCESS,
    message
});
export interface IDeletePickupTruckLoadFailedAction {
    type: IDELETE_PICKUP_TRUCK_REQUEST.FAILED;
    message: string;
}
export const deletePickupTruckLoadFailedAction = (message: string): IDeletePickupTruckLoadFailedAction => ({
    type: IDELETE_PICKUP_TRUCK_REQUEST.FAILED,
    message
});
