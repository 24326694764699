import { IPaginationRequest } from "../../../react/shared/publicInterfaces";

export enum IGET_PARTS_REQUEST {
    REQUEST = "getParts/GET_PARTS_REQUEST",
    SUCCESS = "getParts/GET_PARTS_SUCCESS",
    FAILED = "getParts/GET_PARTS_FAILED"
};

export interface IPartRequest extends IPaginationRequest {
    request: {};
    token: string;
};

export interface IPart {
    id: number;
    name: string; 
    make: string;
    model: string;
    part_No: string;
    type: string;
    condition: string;
    location: string;
    description: string;
    notes: string;
    year: string;
    weight: string;
    available_Quantity: string;
    price: string;
    price_Date?: string;
    photos_1: string;
    photos_2: string;
    photos_3: string;
    alternate_Part_No: string;
    core_Charge: string;
    core_Charge_Date?: string;
    unit_No: string;
    discontinued: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
};

export const AvailableValues: { id: number, name: string }[] = [{ id: 1, name: "Yes" }, { id: 2, name: "No" }];

export const DiscontinuedValues: { id: number, name: string }[] = [{ id: 1, name: "N" }, { id: 2, name: "Y" }];

export const ContinuedValues: { id: number, name: string }[] = [{ id: 1, name: "Used" }, { id: 2, name: "New" }];