import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_EQUIPMENT_TYPE_REQUEST } from "./updateEquipmentTypeConstants";
import { IUpdateEquipmentTypeLoadAction, IUpdateEquipmentTypeLoadFailedAction, IUpdateEquipmentTypeSuccessAction, updateEquipmentTypeLoadFailedAction, updateEquipmentTypeLoadSuccessAction } from "./updateEquipmentTypeActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const updateEquipmentTypeEpic: Epic = (
    action$: ActionsObservable<IUpdateEquipmentTypeLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateEquipmentTypeSuccessAction | IUpdateEquipmentTypeLoadFailedAction> =>
    action$.ofType(IUPDATE_EQUIPMENT_TYPE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.SHOP.UPDATE_EQUIPMENT_TYPE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<string>): IUpdateEquipmentTypeSuccessAction | IUpdateEquipmentTypeLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateEquipmentTypeLoadSuccessAction(response);
                            } else {
                                return updateEquipmentTypeLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: PartsResponse<string>) => ActionsObservable.of(updateEquipmentTypeLoadFailedAction(response.message)))
                    )
            )
        );