import { IOrder } from "../../getOrders/getOrdersConstants";

export enum IUPDATE_ORDER_REQUEST {
    REQUEST = "updateOrder/UPDATE_ORDER_REQUEST",
    SUCCESS = "updateOrder/UPDATE_ORDER_SUCCESS",
    FAILED = "updateOrder/UPDATE_ORDER_FAILED"
};

export interface IUpdateOrderRequest {
    request: IOrder;
    token: string;
};