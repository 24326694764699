import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IDispatch, IStore } from "../../../redux/reducers";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import { LAPaperWithPadding } from "../../shared/paper";
import DesktopHome from "./desktopHome";
import MobileHome from "./mobileHome";



interface IHomeStoreProps {

};

interface IHomeDispatchProps {

};

interface IHomeOwnProps {

};

interface IHomeState {

};

const HomeStyles = styled(LAPaperWithPadding)`
    .desktopHome {
        display: block;
    };

    .mobileHome {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
      .mobileHome {
        display: block;
      };

        .desktopHome {
            display: none;
        };
    };
`;

type IHomeProps = RouteComponentProps
    & IHomeStoreProps
    & IHomeDispatchProps
    & IHomeOwnProps;

class Home extends PureComponent<IHomeProps, IHomeState> {

    public constructor(props: IHomeProps) {
        super(props);
        this.state = {

        };

    }

    public render(): ReactNode {

        return (
            <HomeStyles>
                <div className="desktopHome">
                    <DesktopHome {...this.props} />
                </div>

                <div className="mobileHome">
                    <MobileHome {...this.props} />
                </div>
            </HomeStyles>
        );
    }

}

const mapStateToProps = (state: IStore): IHomeStoreProps => ({

});

const mapDispatchToProps = (dispatch: IDispatch): IHomeDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Home);