import React from 'react';
import {TextField, Paper, Grid, Button, Typography, FormControlLabel, Checkbox, FormHelperText} from '@mui/material';
  import 'devextreme/dist/css/dx.light.css';
import AppBar from '@mui/material/AppBar';
import {Toolbar as MUIToolbar} from '@mui/material';
import Container from '@mui/material/Container';
import {RouteComponentProps} from 'react-router-dom';
import SubWorkingSiteTable from './subworkingsites/subWorkingSiteTable';
import {AddWorkingSite} from '../../api/workingsites/workingSitesAPI';
import { export_list } from '../../workingsites';
import SOCTHome from '../../socthome';
import { getSOCTUserRole } from '../../api/soct/soctAPI';
import jwt_decode from "jwt-decode";

const getToken = localStorage.getItem("adal.idtoken");
const token: any = (getToken !== null && getToken.length > 0) ? jwt_decode(getToken) : { unique_name: "SUREWAYGROUP\\dylan.ollikka" };
const currentUser = token.unique_name.substr(token.unique_name.lastIndexOf("\\") + 1);

const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
    showMain:boolean;showMeterHist:boolean;showMoveHist:boolean;showCustomHist:boolean;showServiceHist:boolean;data:any[];
}
var newWorkingSite = {site_Name:'', site_Address:'', latitude:'', longitude:'', notes:'', active:true, no_sub_site_check:false, rental_status:false};
var subWorkingSites = [{sub_Site_Name:'', sub_Site_Address:'', sub_Site_Coordinates:'', active:true,notes:''}];
export default class CreateWorkingSite extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false, data:[]};
        this.handleChange = this.handleChange.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.renderSubSiteTable = this.renderSubSiteTable.bind(this);
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'site_name':
                newWorkingSite.site_Name = event.target.value;
                break;
            case 'site_address':
                newWorkingSite.site_Address = event.target.value;
                break;
            case 'latitude':
                newWorkingSite.latitude = event.target.value;
                break;
            case 'longitude':
                newWorkingSite.longitude = event.target.value;
                break;
            case 'notes':
                newWorkingSite.notes = event.target.value;
                break;
            case 'active':
                newWorkingSite.active = !newWorkingSite.active;
                break;
            case 'no_sub_site_check':
                newWorkingSite.no_sub_site_check = !newWorkingSite.no_sub_site_check;
                break;
            case 'rental_status':
                newWorkingSite.rental_status = !newWorkingSite.rental_status;
                break;
        }
        this.setState({});
    }
    isUnique(site_name:string){
        for(var i=0; i<export_list.length; i++){
            if(export_list[i].site_Name.trim().toLowerCase() === site_name.trim().toLowerCase()){
                return false;
            }
        }
        return true;
    }

    async onFormSave(){
        var removedNullWorkingSite =[];
        for(var index=0;index<subWorkingSites.length; index++){
            if(subWorkingSites[index].sub_Site_Name != ""){
                removedNullWorkingSite.push(subWorkingSites[index]);
            }
        }
        // Site name is mandatory, and if no_sub_site_check is false than at least one subsite must be entered
        if(newWorkingSite.site_Name!='' && ( (newWorkingSite.no_sub_site_check === false && removedNullWorkingSite.length >0) || newWorkingSite.no_sub_site_check===true)){
            // Check if site name is unique
            if(this.isUnique(newWorkingSite.site_Name)){
                var res:any = await AddWorkingSite(newWorkingSite, removedNullWorkingSite, currentUser);
                newWorkingSite.active = false;
                newWorkingSite.notes = "";
                newWorkingSite.site_Name = "";
                newWorkingSite.latitude = "";
                newWorkingSite.longitude = "";
                newWorkingSite.no_sub_site_check = false;
                newWorkingSite.rental_status = false;
                subWorkingSites = [{sub_Site_Name:'', sub_Site_Address:'', sub_Site_Coordinates:'', active:true,notes:''}]
                if(res.status === 200){
                    alert("Successfully added new working site and sub sites");
                    this.props.history.goBack();
                }
                else{
                    alert("Failed to add new working site and sub sites");
                }
            }
            else{
                alert("Site name already exists");
            }
        }
        else{
            alert("Site name is mandatory, and at least one sub site must be entered if no sub sites is unchecked");
        }
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    renderSubSiteTable(){
        this.setState({});
    }
    async componentDidMount(){
        var res:any = await getSOCTUserRole(currentUser);
        if(res.isAuthenticated){
        if(res.role !='Admin'){
            this.props.history.push('/');
        }
        }
    }
    render(){
        return(
            <>
            <SOCTHome history={this.props.history}></SOCTHome>
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Create New Working Site</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <MUIToolbar disableGutters>
                                <Typography
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                >
                                    Sureway Oil Change Tracker
                                </Typography>
                                    </MUIToolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Site Name" onChange={this.handleChange} name="site_name" autoComplete='nope'></TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth helperText="optional" label="Site Address" onChange={this.handleChange} name="site_address" autoComplete='nope'></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth helperText="optional" placeholder="ex: 12.24" label="Latitude" onChange={this.handleChange} name="latitude" autoComplete='nope'></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth helperText="optional" placeholder="ex: -89.33" label="Longitude" onChange={this.handleChange} name="longitude" autoComplete='nope'></TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Notes" placeholder="notes" onChange={this.handleChange} name="notes" autoComplete='nope'></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel  control={<Checkbox checked={newWorkingSite.active} onChange={this.handleChange} name="active"/>} label="Active"/>
                            <FormHelperText>For when a site is renamed or completely removed</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel  control={<Checkbox checked={newWorkingSite.no_sub_site_check} onChange={this.handleChange} name="no_sub_site_check"/>} label="No Sub Sites"/>
                            <FormHelperText>When a working site has no sub sites</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel  control={<Checkbox checked={newWorkingSite.rental_status} onChange={this.handleChange} name="rental_status"/>} label="Rental"/>
                            <FormHelperText>For when a site is a rental</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <SubWorkingSiteTable disabled={newWorkingSite.no_sub_site_check} data={subWorkingSites} reRender={this.renderSubSiteTable}></SubWorkingSiteTable>
                        </Grid>
                        <Grid item xs={12}><br></br>
                            <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                            <Button style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onFormSave} variant="contained">Save</Button>
                        </Grid>
            </Grid>
                    </Paper>
                    </Paper>
                </Paper>
            </div>
            </>
        )
    }
}