import { ReactNode, PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ById, PartsResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IPart, IPartRequest } from "../../../../redux/parts/getParts/getPartConstants";
import { getParts } from "../../../../redux/parts/getParts/getPartsAccessor";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getPartsLoadAction } from "../../../../redux/parts/getParts/getPartActions";
import { MEDIA_QUERY_PHONE, RED_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithPadding } from "../../../shared/paper";
import { PartGrid } from "./partGrid";
import SearchBox from "../../../shared/searchBox";
import { LAButton, LAIconButton, LAIconUploadButton } from "../../../shared/buttons";
import { AddIcon, DownloadIcon, FielIcon, UploadFileIcon } from "../../../shared/icons";
import LAPagination from "../../../shared/pagination";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { addPartLoadAction } from "../../../../redux/parts/add/part/addPartActions";
import { updatePartLoadAction } from "../../../../redux/parts/update/part/updatePartActions";
import { IUpdatePartRequest } from "../../../../redux/parts/update/part/updatePartConstants";
import { IAddPartRequest } from "../../../../redux/parts/add/part/addPartConstants";
import { updatePart } from "../../../../redux/parts/update/part/updatePartAccessor";
import { addPart } from "../../../../redux/parts/add/part/addPartAccessor";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import { ONE, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import downloadCsv from "download-csv";
import { END_POINTS, PARTS_END_POINTS } from "../../../../redux/endpoints";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IManufacturerListDD } from "../../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDConstants";
import { IManufacturerRequest } from "../../../../redux/parts/getManufacturers/getManufacturerConstants";
import { getManufacturerListDDLoadAction } from "../../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDActions";
import { getManufacturerListDD } from "../../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDAccessor";
import LADeleteConfirm from "../../../shared/deleteConfirmationBox";
import { IDeletePartRequest } from "../../../../redux/parts/delete/part/deletePartConstants";
import { deletePartLoadAction } from "../../../../redux/parts/delete/part/deletePartActions";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ROUTE } from "../../../routes";

interface IPartStoreProps {
    token: Server<PartsResponse<IToken>>;
    PartList: Server<PartsResponse<IPart[]>>;
    UpdatePart: Server<string>;
    AddPart: Server<string>;
    validateShopGroup: Server<PartsResponse<IValidateShopGroup>>;
    ManufacturerListDD: Server<PartsResponse<IManufacturerListDD[]>>;
};

interface IPartDispatchProps {
    RequestPartList: (request: IPartRequest) => unknown;
    RequestDeletePart: (request: IDeletePartRequest) => unknown;
    RequestAddPart: (request: IAddPartRequest) => unknown;
    RequestUpdatePart: (request: IUpdatePartRequest) => unknown;
    RequestManufacturerListDD: (request: IManufacturerRequest) => unknown;
};

interface IPartOwnProps {

};

interface IPartState {
    newAdd: boolean;
    open: boolean;
    editId: number | undefined;
    projects: IPart[];
    initialProjects: IPart[];
    searchText: string;
    currentPage: number;
    total: number;
    deleteId: number;
    deleteConfirm: boolean;
    error: ById<IFieldErrorKeyValue>;
};

const PartStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;

        .pagination-btn {
            margin-top: 30px;
        };
    };

    .total-record {
        font-weight: bold;
        color: ${RED_COLOR};
    };

    .action-btn {
        position: absolute;
        right: 0;
        top: 0;
    };
`;

type IPartProps = RouteComponentProps
    & IPartStoreProps
    & IPartDispatchProps
    & IPartOwnProps;

class Part extends PureComponent<IPartProps, IPartState> {

    public constructor(props: IPartProps) {
        super(props);
        this.state = {
            newAdd: false,
            open: false,
            editId: undefined,
            projects: [],
            initialProjects: [],
            searchText: "",
            currentPage: 1,
            error: {},
            total: 0,
            deleteConfirm: false,
            deleteId: 0
        };
        this.keyWordSearch = this.debounce(this.keyWordSearch, 500);
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IPartProps, prevState: IPartState): Promise<void> {
        if (this.props.PartList !== prevProps.PartList) {
            this.callReduxForData();
        }
    };

    public render(): ReactNode {

        const { ManufacturerListDD, PartList, validateShopGroup } = this.props;
        const { projects, editId, searchText, currentPage, error, total, open, newAdd, deleteConfirm } = this.state;
        const disabled = (hasPayload(this.props.validateShopGroup) && this.props.validateShopGroup.payload.response.partsAdminAccess === "R") ? true : undefined;
        
        return (
            <PartStyles>
                <LAGrid spacing={2}>
                    {(PartList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                        <LACenteredLoading />
                    </LAGridItem>}

                    {(PartList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                        <LAErrorBox text="Failed to Load Parts" />
                    </LAGridItem>}

                    {(PartList.kind === STATUS_ENUM.SUCCEEDED) && <>
                        <LAGridItem xs={12} sm={12} md={12} lg={2} className="text-center">
                            <LAPaperWithPadding>
                                <h2>PARTS</h2>
                                <span className="total-record">Total Count: {PartList.payload.totalRecords ?? 0}</span>
                                <SearchBox
                                    fullWidth={true}
                                    text={searchText}
                                    placeHolder="Search"
                                    onSubmit={() => this.sendRequest()}
                                    onChange={this.handleSearchChange}
                                    searchStatus={this.props.PartList.kind}
                                />
                                <br></br>
                                <LAGrid>
                                    <LAGridItem xs={12} md={6} lg={6} className="text-center">
                                        <LAButton 
                                            label="Search"
                                            onClick={this.keyWordSearch}
                                        />
                                    </LAGridItem>
                                    <LAGridItem xs={12} md={6} lg={6} className="text-center">
                                        <LAButton 
                                            label="Reset"
                                            onClick={this.handleResetClick}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAPaperWithPadding>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={12} lg={10}>
                            <LAPaperWithPadding>
                                <LAGrid>

                                    <div className="action-btn">

                                        <LAIconUploadButton
                                            id="uploadManual"
                                            docxTypes=".pdf"
                                            label="Upload Manual"
                                            icon={<UploadFileIcon />}
                                            onClick={this.handleManualUpload}
                                        />

                                        <LAIconButton
                                            icon={<FielIcon />}
                                            label="View Available Manuals"
                                            onClick={this.handleManualClick}
                                        />

                                        <LAIconButton
                                            label="Add New"
                                            icon={<AddIcon />}
                                            disabled={disabled}
                                            onClick={this.handleAdd}
                                        />

                                        <LAIconButton
                                            label="Download CSV"
                                            icon={<DownloadIcon />}
                                            onClick={this.getDataForExport}
                                        />
                                    </div>

                                    <LAGridItem xs={12} className="pagination-btn">
                                        <LAPagination
                                            rowsPerPage={20}
                                            className="mt-5"
                                            currentPage={currentPage}
                                            numberOfItems={total}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        {projects && projects.length > 0 ?
                                            <PartGrid
                                                {...this.state}
                                                data={projects}
                                                editId={editId}
                                                disabled={disabled}
                                                error={error}
                                                open={open}
                                                newAdd={newAdd}
                                                onRotate={this.onRotate}
                                                onEdit={this.handleEdit}
                                                onChange={this.onChange}
                                                onSave={this.handleSave}
                                                removeImage={this.removeImage}
                                                onImageUpload={this.onImageUpload}
                                                onPermanentDelete={this.onPermanentDelete}
                                                manufacturersList={hasPayload(ManufacturerListDD) ? ManufacturerListDD.payload.response : []}
                                            /> : <LAErrorBox text="No Record Found" />}
                                    </LAGridItem>
                                    <LAGridItem xs={12}>
                                        <LAPagination
                                            rowsPerPage={20}
                                            className="mt-5"
                                            currentPage={currentPage}
                                            numberOfItems={total}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAPaperWithPadding>
                        </LAGridItem>

                        <Prompt
                            when={newAdd || (editId !== undefined)}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                    </>}
                </LAGrid>

                <LADeleteConfirm
                    cancelClick={this.onDeleteCancel}
                    confirmClick={this.onDeleteConfirm}
                    open={deleteConfirm}
                    text="Are you sure you want to delete this ?"
                />

                <RequestStatus requestStatus={this.props.AddPart.kind} failedMessage="Failed to Add Part" successMessage="Part Successfully Added" />
                <RequestStatus requestStatus={this.props.UpdatePart.kind} failedMessage="Failed to Update Part" successMessage="Part Successfully Updated" />
            </PartStyles>
        );
    }

    private handleManualUpload = (event: any): void => {

        new Promise((resolve, reject): any => {
            const files = event.target.files;

            if (files !== null) {
                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            resolve(reader.result);
                        };
                    };
                    reader.readAsDataURL(x);
                });
            }
        }).then((x) => {
            if (hasPayload(this.props.token))
                fetch(END_POINTS.SHOP.ADD_UPDATE_MANUAL, {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        filebase64: x,
                        token: this.props.token.payload.response.token
                    })
                })
                    .then(res => {
                        console.log(res)
                    })
                    .catch((res) => {
                        return console.log(res);
                    });
        });
    };

    private handleManualClick = (): void => {
        window.open('https://portal.propsense.com/parts/manuals/manual.pdf', '_blank', 'fullscreen=yes');
    };

    private onPermanentDelete = (id: number): void => {
        this.setState({ deleteId: id, deleteConfirm: true });
    };

    private onDeleteCancel = (): void => {
        this.setState({ deleteId: 0, deleteConfirm: false });
    };

    private onDeleteConfirm = (): void => {
        if (hasPayload(this.props.token)) {
            this.props.RequestDeletePart({
                token: this.props.token.payload.response.token,
                request: {
                    ItemID: this.state.deleteId
                }
            });

            this.onDeleteCancel();
        };
    };

    private onRotate = async (imgIndex: number, reverse: boolean): Promise<void> => {
        if (reverse)
            this.rotateImage(270, imgIndex);

        if (!reverse)
            this.rotateImage(90, imgIndex);
    };

    private rotateImage = (degree: number, imgIndex: number): void => {
        let img = new Image();

        const iS = [...this.state.projects];
        const ind = this.state.projects.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };

        img.onload = async (): Promise<void> => {
            let canvas: any = document.createElement("canvas");
            let cContext = canvas.getContext("2d");
            let cw = img.width, ch = img.height, cx = 0, cy = 0;

            switch (degree) {
                case 90:
                    cw = img.height;
                    ch = img.width;
                    cy = img.height * (-1);
                    break;
                case 180:
                    cx = img.width * (-1);
                    cy = img.height * (-1);
                    break;
                case 270:
                    cw = img.height;
                    ch = img.width;
                    cx = img.width * (-1);
                    break;
            };

            canvas.setAttribute("width", cw);
            canvas.setAttribute("height", ch);

            cContext.rotate(degree * Math.PI / 180);
            cContext.drawImage(img, cx, cy);

            if (imgIndex === 1) {
                val.photos_1 = canvas.toDataURL();
            } else if (imgIndex === 2) {
                val.photos_2 = canvas.toDataURL();
            } else if (imgIndex === 3) {
                val.photos_3 = canvas.toDataURL();
            };

            iS[ind] = val;
            this.setState({ projects: iS });
        };

        if (imgIndex === 1) {
            img.src = val.photos_1;
        } else if (imgIndex === 2) {
            img.src = val.photos_2;
        } else if (imgIndex === 3) {
            img.src = val.photos_3;
        };
    };

    private removeImage = (index: number): void => {
        const iS = [...this.state.projects];
        const ind = this.state.projects.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };

        if (index === 1)
            val.photos_1 = "";

        if (index === 2)
            val.photos_2 = "";

        if (index === 3)
            val.photos_3 = "";

        iS[ind] = val;
        this.setState({ projects: iS });
    };

    private onImageUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {

        const iS = [...this.state.projects];
        const ind = this.state.projects.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };
        new Promise<void>((resolve, reject): void => {
            if (event.target.files !== null) {
                Object.values(event.target.files).forEach(async (x, index, array) => {

                    // const converted = await webPImageConverter(x, false);
                    // eslint-disable-next-line no-undef
                    const reader = new FileReader();

                    reader.onloadend = (e): void => {

                        if (reader.result) {
                            if ((val.photos_1 === null) || (val.photos_1.length === ZEROTH)) {
                                val.photos_1 = reader.result.toString();
                            } else if ((val.photos_2 === null) || (val.photos_2.length === ZEROTH)) {
                                val.photos_2 = reader.result.toString();
                            } else if ((val.photos_3 === null) || (val.photos_3.length === ZEROTH)) {
                                val.photos_3 = reader.result.toString();
                            }

                            iS[ind] = val;
                            this.setState({ projects: iS });
                        }

                        if (index === array.length - ONE)
                            resolve();
                    };
                    reader.readAsDataURL(x);
                });
            }
        });
    };

    private getDataForExport = (): void => {
        if (hasPayload(this.props.token))
            fetch(PARTS_END_POINTS.GET_PARTS.PARTS.GET, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    request: {},
                    Keywords: "",
                    PageSize: this.state.total,
                    PageNumber: 1,
                    token: this.props.token.payload.response.token
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(async res => {
                    const val: IPart[] = res.response;
                    const dataToExport: {
                        id: number, name: string, model: string, make: string, part_No: string, type: string, condition: string, unit_No: string,
                        location: string, description: string, notes: string, year: string, weight: string, price: string, price_Date: string, available_Quantity: string, alternare_Part_No: string, core_Charge: string, core_Charge_Date: string, discontinued: string
                    }[] = [];

                    await val.forEach((x: IPart) => {
                        dataToExport.push({
                            id: x.id,
                            name: x.name,
                            part_No: x.part_No,
                            alternare_Part_No: x.alternate_Part_No,
                            make: x.make,
                            model: x.model,
                            core_Charge: x.core_Charge,
                            core_Charge_Date: x.core_Charge_Date ?? "",
                            discontinued: x.discontinued,
                            type: x.type,
                            unit_No: x.unit_No,
                            description: x.description,
                            condition: x.condition,
                            location: x.location,
                            notes: x.notes,
                            year: x.year,
                            weight: x.weight,
                            price: x.price,
                            price_Date: x.price_Date ?? "",
                            available_Quantity: x.available_Quantity
                        });
                    });

                    downloadCsv(dataToExport,
                        {
                            id: "Item #", name: "Name", part_No: "Part No", alternate_Part_No: "Alternate Part No", make: "Make", model: "Model", core_Charge: "Core Charge", discontinued: "Discontinued", type: "Type", unit_No: "Unit #",
                            description: "Description", condition: "Condition", location: "Location", notes: "Notes", year: "Year", weight: "Weight", price: "Price", available_Quantity: "Available Quantity"
                        },
                        "parts.csv");
                })
                .catch((res) => console.log(res));
    };

    private debounce = (fn: any, delay: any): any => {
        let timer: any = null;
        return (...args: any): any => {
            const context = this;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    };

    private keyWordSearch = async (): Promise<void> => {
        this.sendRequest();
    };

    private onChange = (name: string, value: string) => {

        const iS = [...this.state.projects];
        const iSError = this.state.error;
        const ind = this.state.projects.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };
        const iSInitial = [...this.state.initialProjects];
        const initialInd = this.state.initialProjects.findIndex(x => x.id === this.state.editId);
        const initialVal = { ...iSInitial[initialInd] };
        // console.log(val);
        switch (name) {
            case "name":
                val.name = value;
                break;
            case "part_No":
                val.part_No = value;
                break;
            case "make":
                val.make = value;
                break;
            case "alternate_Part_No":
                val.alternate_Part_No = value;
                break;
            case "discontinued":
                val.discontinued = value;
                break;
            case "core_Charge":
                if(val.core_Charge === null || val.core_Charge === undefined || val.core_Charge === "") {
                    val.core_Charge_Date = new Date().toLocaleDateString('en-CA');
                }
                val.core_Charge = value;
                if((val.core_Charge !== null || val.core_Charge !== undefined || val.core_Charge !== "") && (!val.core_Charge_Date)) {
                    val.core_Charge_Date = new Date().toLocaleDateString('en-CA');
                }
                if(val.core_Charge === null || val.core_Charge === undefined || val.core_Charge === "" || val.core_Charge === initialVal?.core_Charge?.toString() ) {
                    val.core_Charge_Date = initialVal.core_Charge_Date
                }
                break;
            case "core_Charge_Date":
                val.core_Charge_Date = value;
                break;
            case "unit_No":
                val.unit_No = value;
                break;
            case "type":
                val.type = value;
                break;
            case "model":
                val.model = value;
                break;
            case "condition":
                val.condition = value;
                break;
            case "location":
                val.location = value;
                break;
            // case "description":
            //     val.description = value;
            //     break;
            case "notes":
                val.notes = value;
                break;
            // case "year":
            //     val.year = value;
            //     break;
            case "weight":
                val.weight = value;
                break;
            case "available_Quantity":
                if (Number(value) > 0)
                    val.available_Quantity = value;
                else 
                    val.available_Quantity = "0";
                break;
            case "price":
                if(val.price === null || val.price === undefined || val.price === "") {
                    val.price_Date = new Date().toLocaleDateString('en-CA');
                }
                val.price = value;
                if((val.price !== null || val.price !== undefined || val.price !== "") && (!val.price_Date)) {
                    val.price_Date = new Date().toLocaleDateString('en-CA');
                }
                if(val.price === null || val.price === undefined || val.price === "" || val.price === initialVal?.price?.toString() ) {
                    val.price_Date = initialVal.price_Date
                }
                break;
            case "price_Date":
                val.price_Date = value;
                break;
        };

        if (value !== undefined) {
            if (value.length > 0) {
                if (iSError[name])
                    delete iSError[name];
            } else {
                if (name === "part_No" || name === "name" || name === "make" || name === "condition")
                    iSError[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };
        };

        iS[ind] = val;
        // console.log(iS[ind]);
        this.setState({ projects: iS, error: iSError });
    };

    private handlePageChange = (currentPage?: number, rowsPerPage?: number): void => {
        this.setState({ currentPage: currentPage ?? 1 });
        this.sendRequest(currentPage);
    };

    private handleAdd = (): void => {
        const iS = [...this.state.projects];
        const time = new Date().toISOString();

        let max = 1;

        if (iS.length > 0) {
            max = iS[0].id;
            for (let i = 1; i < iS.length; i++) {
                let value = iS[i].id;
                max = (value > max) ? value : max;
            };
        }

        iS.unshift({
            id: max + 1, name: "", part_No: "", make: "", model: "", condition: "", type: "", description: "", location: "", notes: "", year: "", weight: "", unit_No: "",
            photos_2: "", photos_3: "", available_Quantity: "1", price: "",price_Date: "", photos_1: "", alternate_Part_No: "", core_Charge: "",core_Charge_Date: "", discontinued: "", modified: time, modified_By: userName, created: time, created_By: userName
        });

        let error: ById<IFieldErrorKeyValue> = {};
        error["name"] = { key: "name", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["part_No"] = { key: "part_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["make"] = { key: "make", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["condition"] = { key: "condition", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        // error["description"] = { key: "description", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

        this.setState({ projects: iS, editId: max + 1, newAdd: true, error, open: true });
    };

    private handleSearchChange = (searchText: string): void => {
        this.setState({ searchText, currentPage: 1, editId: undefined, newAdd: false });
        // this.keyWordSearch();
    };

    private handleResetClick = (): void => {
        this.setState({ searchText: "", currentPage: 1, editId: undefined, newAdd: false });
        this.keyWordSearch();
    };

    private handleEdit = (editId: number | undefined): void => {
        if (editId === undefined && this.state.newAdd === true) {
            const iS = [...this.state.projects];
            const ind = iS.findIndex(x => x.id === this.state.editId);
            iS.splice(ind, 1);

            this.setState({ editId, projects: iS, error: {}, open: false });
        } else {
            if (editId === undefined) {
                if (hasPayload(this.props.PartList)) {
                    this.setState({ editId, error: {}, projects: this.props.PartList.payload.response, open: false });
                }
            } else if (editId !== undefined) {
                const iS = this.state.projects;
                const ind = iS.findIndex(x => x.id === editId);
                let error: ById<IFieldErrorKeyValue> = {};
                if (iS[ind].name === undefined || iS[ind].name.length === ZEROTH)
                    error["name"] = { key: "name", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                if (iS[ind].part_No === undefined || iS[ind].part_No.length === ZEROTH)
                    error["part_No"] = { key: "part_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                if (iS[ind].make === undefined || iS[ind].make.length === ZEROTH)
                    error["make"] = { key: "make", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                if (iS[ind].condition === undefined || iS[ind].condition.length === ZEROTH)
                    error["condition"] = { key: "condition", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                // if((iS[ind].description === undefined) || iS[ind].description.length === ZEROTH)
                // error["description"] = { key: "description", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                // if (iS[ind].location === undefined || iS[ind].location.length === ZEROTH)
                //     error["location"] = { key: "location", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                iS[ind].modified_By = userName;
                this.setState({ editId, projects: iS, error, open: true });
            }
        };
    };

    private callReduxForData = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.partsaccess === true) {
                if (isNotLoaded(this.props.PartList)) {
                    this.sendRequest();
                };
                if (isNotLoaded(this.props.ManufacturerListDD)) {
                    if (hasPayload(this.props.token))
                        this.props.RequestManufacturerListDD({
                            request: {},
                            Keywords: "",
                            PageSize: 0,
                            PageNumber: 0,
                            token: this.props.token.payload.response.token
                        });
                };
                if (hasPayload(this.props.PartList)) {
                    let data = this.props.PartList.payload;
                    this.setState({ projects: data.response, initialProjects: data.response, total: data.totalRecords ?? 0 });
                };
            } else {
                this.props.history.push(ROUTE.PARTS.UNAUTHORIZED);
            }
        };
    };

    private sendRequest = (currentPage?: number): void => {
        if (hasPayload(this.props.token))
            this.props.RequestPartList({
                request: {},
                Keywords: this.state.searchText,
                PageSize: 20,
                PageNumber: currentPage ?? this.state.currentPage,
                token: this.props.token.payload.response.token
            });
    };

    private handleSave = (): void => {
        if (this.state.newAdd === false) {
            this.updateRequest();
        } else {
            this.addRequest();
        };
    };

    private updateRequest = (): void => {
        if (hasPayload(this.props.token)) {
            const iS = [...this.state.projects];
            const ind = iS.findIndex(x => x.id === this.state.editId);

            this.props.RequestUpdatePart({
                request: iS[ind],
                token: this.props.token.payload.response.token
            });

            this.setState({ editId: undefined });
        }
    };

    private addRequest = (): void => {
        if (hasPayload(this.props.token)) {
            const iS = [...this.state.projects];
            const ind = iS.findIndex(x => x.id === this.state.editId);
            iS[ind].id = 0;
            this.props.RequestAddPart({
                request: iS[ind],
                Keywords: "",
                PageSize: 20,
                PageNumber: 1,
                token: this.props.token.payload.response.token
            });
            iS.splice(ind, 1);
            this.setState({ editId: undefined, newAdd: false, currentPage: 1, searchText: "", projects: iS });
        }
    };
}

const mapStateToProps = (state: IStore): IPartStoreProps => ({
    token: getToken(state),
    PartList: getParts(state),
    AddPart: addPart(state),
    UpdatePart: updatePart(state),
    validateShopGroup: validateShopGroup(state),
    ManufacturerListDD: getManufacturerListDD(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IPartDispatchProps => ({
    RequestPartList: (request: IPartRequest): unknown => dispatch(getPartsLoadAction(request)),
    RequestAddPart: (request: IAddPartRequest): unknown => dispatch(addPartLoadAction(request)),
    RequestDeletePart: (request: IDeletePartRequest): unknown => dispatch(deletePartLoadAction(request)),
    RequestUpdatePart: (request: IUpdatePartRequest): unknown => dispatch(updatePartLoadAction(request)),
    RequestManufacturerListDD: (request: IManufacturerRequest): unknown => dispatch(getManufacturerListDDLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Part);