import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isFailed, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, PartsResponse, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { userName } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import PageSpacing from "../../../shared/pageSpacing";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LATextField from "../../../shared/textField";
import { getShopManufacturers } from "../../../../redux/shop/getManufactures/getShopManufacturesAccessor";
import { getShopManufacturesLoadAction } from "../../../../redux/shop/getManufactures/getShopManufacturesActions";
import { IShopManufacture, IShopManufactureRequest } from "../../../../redux/shop/getManufactures/getShopManufacturesConstants";
import { IAddShopManufactureRequest } from "../../../../redux/shop/addManufactures/addShopManufacturesConstants";
import { IUpdateShopManufactureRequest } from "../../../../redux/shop/updateManufactures/updateShopManufacturesConstants";
import { addShopManufacturers } from "../../../../redux/shop/addManufactures/addShopManufacturesAccessor";
import { updateShopManufacturers } from "../../../../redux/shop/updateManufactures/updateShopManufacturesAccessor";
import { addShopManufacturesLoadAction } from "../../../../redux/shop/addManufactures/addShopManufacturesActions";
import { updateShopManufacturesLoadAction } from "../../../../redux/shop/updateManufactures/updateShopManufacturesActions";
import { IValidateShopGroup, ReadAndWrite, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../../shared/roleBaseAccessForShop";
import LAErrorBox from "../../../shared/errorBox";

interface IShopManufacturerStoreProps {
    getToken: Server<PartsResponse<IToken>>;
    addShopManufacturer: Server<PartsResponse<string>>;
    updateShopManufacturer: Server<PartsResponse<string>>;
    getManufacturers: Server<PartsResponse<ById<IShopManufacture>>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IShopManufacturerDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    addManufacturerRequest: (data: IAddShopManufactureRequest) => unknown;
    getShopManufacturersRequest: (data: IShopManufactureRequest) => unknown;
    updateManufacturerRequest: (data: IUpdateShopManufactureRequest) => unknown;
};

interface IShopManufacturerOwnProps {

};

interface IShopManufacturerState {
    sError: string;
    manufacturer: IShopManufacture;
    errors: ById<IFieldErrorKeyValue>;
};

const ShopManufacturerStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IShopManufacturerProps = RouteComponentProps
    & IShopManufacturerStoreProps
    & IShopManufacturerDispatchProps
    & IShopManufacturerOwnProps;

class ShopManufacturer extends PureComponent<IShopManufacturerProps, IShopManufacturerState> {

    public constructor(props: IShopManufacturerProps) {
        super(props);
        this.state = {
            errors: {
                "manufacturer_Name": { key: "manufacturer_Name", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            manufacturer: {
                id: 0,
                created: "",
                modified: "",
                created_By: userName,
                modified_By: userName,
                manufacturer_Name: ""
            },
            sError: ""
        };
    }

    public componentDidMount(): void {
       // this.callServer();
       window.location.href = "https://apps.sureway.ca/field/manufacturers";
    };

    public componentDidUpdate(prevProps: IShopManufacturerProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addShopManufacturer !== prevProps.addShopManufacturer) {
                if (isSucceeded(this.props.addShopManufacturer)) {
                    this.handleCancel();
                };
                if (isFailed(this.props.addShopManufacturer)) {
                    this.setState({ sError: this.props.addShopManufacturer.message as string });
                };
            };

            if (this.props.updateShopManufacturer !== prevProps.updateShopManufacturer) {
                if (isSucceeded(this.props.updateShopManufacturer)) {
                    this.handleCancel();
                };
                if (isFailed(this.props.updateShopManufacturer)) {
                    this.setState({ sError: this.props.updateShopManufacturer.message as string });
                };
            };
        }
    };


    public render(): ReactNode {

        const { manufacturer, errors, sError } = this.state;
        const { updateShopManufacturer, addShopManufacturer, validateShopGroup } = this.props;
        const getRole = hasPayload(validateShopGroup) ? validateShopGroup.payload.response.adminAccess : "";
        const readOnly = getRole === ReadOnly ? true : undefined;

        return (
            <PageSpacing title="Equipment List - Make" description="Equipment List - Make" fixedSpaceOnSmallerScreens={true}>
                         <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>
                <ShopManufacturerStyles>

                    <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                    <h2 className="text-center">{manufacturer.id > 0 ? "VIEW/UPDATE " : "ADD "} MANUFACTURER</h2>
                    <hr />

                    <LAPaperWithPadding>
                        <LAGrid spacing={2}>

                            <LAGridItem xs={12}>
                                <LATextField
                                    variant="outlined"
                                    label="Make"
                                    fullWidth={true}
                                    name="manufacturer_Name"
                                    onChange={this.onChange}
                                    disabled={readOnly}
                                    value={manufacturer.manufacturer_Name}
                                    errorText={errors["manufacturer_Name"] ? errors["manufacturer_Name"].message : undefined}
                                />
                            </LAGridItem>

                            {sError.length > 0 && <LAGridItem xs={12}>
                                <LAErrorBox text={sError} />
                            </LAGridItem>}

                            <LAGridItem xs={12}>
                                <LASaveAndCancelButton
                                    onSave={this.onSave}
                                    onCancel={this.handleCancel}
                                    disableSave={Object.values(errors).length > 0 ? true : readOnly}
                                />
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>

                    <RequestStatus requestStatus={addShopManufacturer.kind} successMessage="Make successfully saved" />
                    <RequestStatus requestStatus={updateShopManufacturer.kind} successMessage="Make successfully updated" />
                </ShopManufacturerStyles>
                </RoleBasedAccessForShop>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SHOP.MANUFACTURER.INDEX);
    };

    private onSave = (): void => {
        const data = this.state.manufacturer;
        if (hasPayload(this.props.getToken))
            if (this.state.manufacturer.id === 0) {
                this.props.addManufacturerRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        Created_By: data.created_By,
                        Modified_By: data.modified_By,
                        Manufacturer_Name: data.manufacturer_Name
                    }
                });
            } else {
                this.props.updateManufacturerRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: data.id,
                        Created_By: data.created_By,
                        Modified_By: userName,
                        Manufacturer_Name: data.manufacturer_Name
                    }
                });
            };

            this.setState({ sError: "" });
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            manufacturer: {
                ...this.state.manufacturer,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getManufacturers) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getShopManufacturersRequest({
                token: this.props.getToken.payload.response.token
            });

        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getManufacturers)) && (this.state.manufacturer.id === 0)) {
            const manufacturer = this.props.getManufacturers.payload.response[Number(id)];

            if (manufacturer)
                this.setState({ manufacturer, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IShopManufacturerStoreProps => ({
    getToken: getToken(state),
    getManufacturers: getShopManufacturers(state),
    addShopManufacturer: addShopManufacturers(state),
    updateShopManufacturer: updateShopManufacturers(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IShopManufacturerDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getShopManufacturersRequest: (data: IShopManufactureRequest) => dispatch(getShopManufacturesLoadAction(data)),
    addManufacturerRequest: (data: IAddShopManufactureRequest) => dispatch(addShopManufacturesLoadAction(data)),
    updateManufacturerRequest: (data: IUpdateShopManufactureRequest) => dispatch(updateShopManufacturesLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopManufacturer);