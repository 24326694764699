import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_CLASS_TYPE_AND_UNIT_REQUEST } from "./getClassTypeAndUnitConstants";
import { IGetClassTypeAndUnitLoadAction, IGetClassTypeAndUnitLoadFailedAction, IGetClassTypeAndUnitSuccessAction, getClassTypeAndUnitLoadFailedAction, getClassTypeAndUnitLoadSuccessAction } from "./getClassTypeAndUnitActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { IUnit } from "../../getUnits/getUnitsConstants";

export const getClassTypeAndUnitEpic: Epic = (
    action$: ActionsObservable<IGetClassTypeAndUnitLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetClassTypeAndUnitSuccessAction | IGetClassTypeAndUnitLoadFailedAction> =>
    action$.ofType(IGET_CLASS_TYPE_AND_UNIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<IUnit[]>>(
                    END_POINTS.GET_TOOL_RENTALS.UNITS.GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<IUnit[]>): IGetClassTypeAndUnitSuccessAction => {
                            return getClassTypeAndUnitLoadSuccessAction(response);
                        }),
                        catchError((response: ToolRentalsResponse<IUnit[]>) => ActionsObservable.of(getClassTypeAndUnitLoadFailedAction(response.message)))
                    )
            )
        );