import { IPaginationRequest } from "../../../../react/shared/publicInterfaces";
import { IUnit } from "../../getUnits/getUnitsConstants";

export enum IADD_UNIT_REQUEST {
    REQUEST = "addUnit/ADD_UNIT_REQUEST",
    SUCCESS = "addUnit/ADD_UNIT_SUCCESS",
    FAILED = "addUnit/ADD_UNIT_FAILED"
};

export interface IAddUnitRequest extends IPaginationRequest {
    request: IUnit;
    token: string;
};