import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ById, ByIdNumber, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isFailed, isNotLoaded, isSucceeded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IOrder, IOrderRequest } from "../../../../redux/toolRentals/getOrders/getOrdersConstants";
import { getOrders } from "../../../../redux/toolRentals/getOrders/getOrdersAccessor";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getOrdersLoadAction } from "../../../../redux/toolRentals/getOrders/getOrdersActions";
import { MEDIA_QUERY_PHONE } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithPadding } from "../../../shared/paper";
import { OrderGrid } from "./orderGrid";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { addOrder } from "../../../../redux/toolRentals/add/order/addOrderAccessor";
import { updateOrder } from "../../../../redux/toolRentals/update/order/updateOrderAccessor";
import { IAddOrderRequest } from "../../../../redux/toolRentals/add/order/addOrderConstants";
import { IUpdateOrderRequest } from "../../../../redux/toolRentals/update/order/updateOrderConstants";
import { updateOrderLoadAction } from "../../../../redux/toolRentals/update/order/updateOrderActions";
import { addOrderLoadAction } from "../../../../redux/toolRentals/add/order/addOrderActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import { IProjectRequest } from "../../../../redux/toolRentals/getProjects/getProjectsConstants";
import { IProjectListDD } from "../../../../redux/toolRentals/powerDropdown/getProjectListDD/getProjectListDDConstants";
import { getProjectListDD } from "../../../../redux/toolRentals/powerDropdown/getProjectListDD/getProjectListDDAccessor";
import { getProjectListDDLoadAction } from "../../../../redux/toolRentals/powerDropdown/getProjectListDD/getProjectListDDActions";
import { undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import { IUnit, IUnitRequest } from "../../../../redux/toolRentals/getUnits/getUnitsConstants";
import { getClassTypeAndUnit } from "../../../../redux/toolRentals/powerDropdown/getClassTypeAndUnit/getClassTypeAndUnitAccessor";
import { getClassTypeAndUnitLoadAction } from "../../../../redux/toolRentals/powerDropdown/getClassTypeAndUnit/getClassTypeAndUnitActions";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { IValidateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { ROUTE } from "../../../routes";

interface IOrderStoreProps {
    AddOrder: Server<string>;
    UpdateOrder: Server<string>;
    token: Server<ToolRentalsResponse<IToken>>;
    OrderList: Server<ToolRentalsResponse<ByIdNumber<IOrder>>>;
    ClassTypeAndUnit: Server<ToolRentalsResponse<ByIdNumber<IUnit>>>;
    ProjectListDD: Server<ToolRentalsResponse<ByIdNumber<IProjectListDD>>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IOrderDispatchProps {
    RequestOrderList: (request: IOrderRequest) => unknown;
    RequestAddOrder: (request: IAddOrderRequest) => unknown;
    RequestUpdateOrder: (request: IUpdateOrderRequest) => unknown;
    RequestClassTypeAndUnit: (request: IUnitRequest) => unknown;
    RequestProjectListDD: (request: IProjectRequest) => unknown;
};

interface IOrderOwnProps {

};

interface IOrderState {
    addEdit: IOrder | undefined;
    orders: ByIdNumber<IOrder>;
    error: ById<IFieldErrorKeyValue>;
    errorUnits: ById<IFieldErrorKeyValue>[];
};

const OrderStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IOrderProps = RouteComponentProps
    & IOrderStoreProps
    & IOrderDispatchProps
    & IOrderOwnProps;

class Order extends PureComponent<IOrderProps, IOrderState> {

    public constructor(props: IOrderProps) {
        super(props);
        this.state = {
            addEdit: undefined,
            orders: {},
            error: {},
            errorUnits: []
        };
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IOrderProps, prevState: IOrderState): Promise<void> {
        if (this.props.OrderList !== prevProps.OrderList) {
            this.callReduxForData();
        };

        if (this.props.AddOrder !== prevProps.AddOrder) {
            if (isSucceeded(this.props.AddOrder) && this.state.addEdit) {
                this.setState({ addEdit: undefined, error: {}, errorUnits: [] });
            };
        };

        if (this.props.UpdateOrder !== prevProps.UpdateOrder) {
            if (isSucceeded(this.props.UpdateOrder) && this.state.addEdit) {
                this.setState({ addEdit: undefined, error: {}, errorUnits: [] });
            };
        }
    };

    public render(): ReactNode {
        const { ClassTypeAndUnit, ProjectListDD, UpdateOrder, AddOrder, OrderList } = this.props;
        const { orders, error, addEdit, errorUnits } = this.state;

        let sError: string[] = [];

        if (isFailed(AddOrder) && this.state.addEdit) {
            if (Array.isArray(AddOrder.message)) {
                if (AddOrder.message && AddOrder.message[0]) {
                    if (+AddOrder.message[0])
                        sError = AddOrder.message;
                }
            }
        } else if (isFailed(UpdateOrder) && this.state.addEdit) {
            if (Array.isArray(UpdateOrder.message)) {
                if (UpdateOrder.message && UpdateOrder.message[0]) {
                    if (+UpdateOrder.message[0])
                        sError = UpdateOrder.message;
                }
            }
        };

        return (
            <OrderStyles>
                <LAGrid className="text-center" spacing={2}>

                    {(OrderList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                        <LACenteredLoading />
                    </LAGridItem>}

                    {(OrderList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                        <LAErrorBox text="Failed to Load Orders" />
                    </LAGridItem>}

                    {(OrderList.kind === STATUS_ENUM.SUCCEEDED) && <>
                        <LAGridItem xs={12} className="text-center">
                            <h2>ORDERS</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            {orders && Object.values(orders).length > 0 ?

                                addEdit ? <OrderGrid
                                    {...this.state}
                                    data={addEdit}
                                    error={error}
                                    serverError={sError}
                                    errorUnits={errorUnits}
                                    onEdit={this.handleEdit}
                                    onChange={this.onChange}
                                    onSave={this.handleSave}
                                    onUnitAdd={this.onUnitAdd}
                                    onUnitChange={this.onUnitChange}
                                    onUnitDelete={this.handleUnitDelete}
                                    projectList={hasPayload(ProjectListDD) ? Object.values(ProjectListDD.payload.response) : []}
                                    ddData={hasPayload(ClassTypeAndUnit) ? Object.values(ClassTypeAndUnit.payload.response) : []}
                                /> :
                                    <LADevExtremeGrid
                                        add={true}
                                        export={true}
                                        onAdd={this.handleAdd}
                                        actionWidth={160}
                                        removeStyleBtn={true}
                                        exportFileName="Orders"
                                        searchPanel={true}
                                        filterHeader={true}
                                        onEdit={this.handleEdit}
                                        onClick={undefinedFunction}
                                        data={Object.values(orders)}
                                        columns={[
                                            { name: "project_Number", caption: "Project Number", type: "string" },
                                            { name: "project_Name", caption: "Project Name", type: "string" },
                                            { name: "po", caption: "PO", type: "string" },
                                            { name: "rental_Status", caption: "Rental Status", type: "string" },
                                            { name: "modified", caption: "Modified", type: "date", sortDesc: true },
                                            { name: "modified_By", caption: "Modified By", type: "string" },
                                        ]}
                                    />
                                : <LAErrorBox text="No Record Found" />}
                        </LAGridItem>
                    </>}

                </LAGrid>

                <RequestStatus requestStatus={this.props.AddOrder.kind} failedMessage="Failed to Add Order" successMessage="Order Successfully Added" />
                <RequestStatus requestStatus={this.props.UpdateOrder.kind} failedMessage="Failed to Update Order" successMessage="Order Successfully Updated" />
            </OrderStyles>
        );
    }

    private handleUnitDelete = (index: number): void => {
        const iS = this.state.addEdit;
        if (iS && iS.units) {
            iS.units.splice(index, 1);

            const errorUnits = [...this.state.errorUnits];
            errorUnits.splice(index, 1);

            this.setState({ addEdit: { ...this.state.addEdit, ...iS }, errorUnits });
        }
    };

    private onUnitChange = (unitInd: number, name: string, value: string): void => {
        const iS = this.state.addEdit;

        if (iS && iS.units) {

            const iSError = this.state.errorUnits;
            if (iSError[unitInd] === undefined) {
                iSError[unitInd] = {};
            }

            if (name === "quantity") {
                if (!isNaN(Number(value)))
                    iS.units[unitInd].quantity = value;
            };
            if (name === "class") {
                iS.units[unitInd].class = value;
                iS.units[unitInd].quantity = "1";
                if (value === "") {
                    iS.units[unitInd].type = "";
                    iS.units[unitInd].unit_No = "";

                    if (iSError[unitInd]["type"] === undefined)
                        iSError[unitInd]["type"] = { key: "type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

                    if (iSError[unitInd]["unit_No"] === undefined)
                        iSError[unitInd]["unit_No"] = { key: "unit_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                }
            };
            if (name === "type") {
                iS.units[unitInd].type = value;
                if (value === "") {
                    iS.units[unitInd].class = "";
                    iS.units[unitInd].unit_No = "";
                    if (iSError[unitInd]["class"] === undefined)
                        iSError[unitInd]["class"] = { key: "class", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

                    if (iSError[unitInd]["unit_No"] === undefined)
                        iSError[unitInd]["unit_No"] = { key: "unit_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                }
            };

            if (name === "picked_Up_By") {
                iS.units[unitInd].picked_Up_By = value;
            };
            if (name === "damages") {
                iS.units[unitInd].damages = value;
            };

            if (name === "date_Out") {
                iS.units[unitInd].date_Out = new Date(value).toISOString();
            };

            if (name === "date_Returned") {
                iS.units[unitInd].date_Returned = new Date(value).toISOString();
                if (iSError[unitInd]["damages"] && value.length > ZEROTH)
                    delete iSError[unitInd]["damages"];

                if (value !== undefined || value !== null || value !== "")
                    if (((iS.units[unitInd].damages === null) || iS.units[unitInd].damages.length === ZEROTH))
                        iSError[unitInd]["damages"] = { key: "damages", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };

            if (name === "invoices") {
                iS.units[unitInd].invoices = value;
            };

            if (name === "unit_No") {
                iS.units[unitInd].unit_No = value;
                if (iSError[unitInd]["unit_No"])
                    delete iSError[unitInd]["unit_No"];

                iS.units[unitInd].quantity = "1";
                if (hasPayload(this.props.ClassTypeAndUnit) && iS.units[unitInd] && iS.units[unitInd].unit_No) {
                    let uId = iS.units[unitInd].unit_No;
                    let unit = Object.values(this.props.ClassTypeAndUnit.payload.response).find(x => x.unit_No === uId);
                    if (unit) {
                        iS.units[unitInd].class = unit.class;
                        iS.units[unitInd].type = unit.type;

                        if (iSError[unitInd] && iSError[unitInd]["class"])
                            delete iSError[unitInd]["class"];
                        if (iSError[unitInd] && iSError[unitInd]["type"])
                            delete iSError[unitInd]["type"];
                    } else {
                        iS.units[unitInd].class = "";
                        iS.units[unitInd].type = "";
                        if (iSError[unitInd]["unit_No"]) {
                            iSError[unitInd]["unit_No"].message = "Invalid Unit";
                        } else {
                            iSError[unitInd]["unit_No"] = { key: "unit_No", message: "Invalid Unit" };
                        }
                    }
                };

                if (value === "") {
                    iS.units[unitInd].class = "";
                    iS.units[unitInd].type = "";
                }
            };

            if ((iS.units[unitInd].class.length > 0 && iS.units[unitInd].type.length > 0) && (iS.units[unitInd].unit_No.length === 0)) {
                if (hasPayload(this.props.ClassTypeAndUnit) && iS.units[unitInd]) {
                    let uId = iS.units[unitInd];
                    let uNo = Object.values(this.props.ClassTypeAndUnit.payload.response).find(x => ((x.type === uId.type) && (x.class === uId.class)));
                    if (uNo) {
                        iS.units[unitInd].unit_No = uNo.unit_No;
                        if (iSError[unitInd] && iSError[unitInd]["unit_No"])
                            delete iSError[unitInd]["unit_No"];
                    }
                };
            };

            if (value.length > 0) {
                if (iSError[unitInd] && iSError[unitInd][name] && name !== "unit_No") {
                    delete iSError[unitInd][name];
                }
            } else {
                if (iSError[unitInd])
                    iSError[unitInd][name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };

            if (iSError[unitInd]) {
                if (Object.values(iSError[unitInd]).length === ZEROTH)
                    iSError.splice(unitInd, 1);
            }

            this.setState({ addEdit: { ...this.state.addEdit, ...iS }, errorUnits: iSError });
        }
    };

    private onUnitAdd = (): void => {
        const iS: IOrder | undefined = this.state.addEdit;
        if (iS && iS.units) {

            iS.units.push({
                id: 0, unit_No: "", class: "", damages: "", invoices: "", type: "", date_Out: "", picked_Up_By: iS.units.slice(-1)[0].picked_Up_By, quantity: ""
            });

            let errorUnits = this.state.errorUnits;
            if (errorUnits.length !== iS.units.length) {
                for (let i = errorUnits.length; iS.units.length >= i; i++) {
                    errorUnits.push({});
                };
            } else {
                errorUnits.push({});
            };
            errorUnits[iS.units.length - 1]["class"] = { key: "class", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            errorUnits[iS.units.length - 1]["type"] = { key: "type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            errorUnits[iS.units.length - 1]["unit_No"] = { key: "unit_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            errorUnits[iS.units.length - 1]["date_Out"] = { key: "date_Out", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            if (iS.units[iS.units.length - 1].picked_Up_By.length === 0)
                errorUnits[iS.units.length - 1]["picked_Up_By"] = { key: "picked_Up_By", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

            this.setState({ addEdit: { ...this.state.addEdit, ...iS }, errorUnits });
        }
    };

    private onChange = (name: string, value: string) => {
        const val = this.state.addEdit;
        const iSError = { ...this.state.error };
        if (val) {
            switch (name) {
                case "project_Number":
                    val.project_Number = value;
                    break;

                case "po":
                    val.po = value;
                    break;
            };

            if (value.length > 0) {
                if (iSError[name])
                    delete iSError[name];
            } else {
                iSError[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };

            this.setState({ addEdit: { ...this.state.addEdit, ...val }, error: iSError });
        }
    };


    private handleAdd = (): void => {
        const time = new Date().toISOString();

        let iS: IOrder = {
            id: 0, project_Number: "", project_Name: "", po: "",
            units: [{
                id: 0, unit_No: "", class: "", damages: "", invoices: "", type: "", date_Out: "", picked_Up_By: "", quantity: "1"
            }], rental_Status: "", modified: time, modified_By: userName, created: time, created_By: userName
        };

        let error: ById<IFieldErrorKeyValue> = {};
        let errorUnits: ById<IFieldErrorKeyValue>[] = [{}];
        error["project_Number"] = { key: "project_Number", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["po"] = { key: "po", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

        errorUnits[0]["class"] = { key: "class", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        errorUnits[0]["type"] = { key: "type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        errorUnits[0]["unit_No"] = { key: "unit_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        errorUnits[0]["date_Out"] = { key: "date_Out", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        errorUnits[0]["picked_Up_By"] = { key: "picked_Up_By", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        this.setState({ addEdit: iS, error, errorUnits });
    };


    private handleEdit = (event: any): void => {
        if (event === undefined) {
            this.setState({ addEdit: undefined, error: {}, errorUnits: [] });
        } else {
            const obj = this.state.orders[event.row.data.id];
            this.setState({ addEdit: obj, error: {}, errorUnits: [] });
        };
    };

    private callReduxForData = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.toolrentalsaccess === true) {
                if (isNotLoaded(this.props.OrderList)) {
                    this.sendRequest();
                };

                if (isNotLoaded(this.props.ProjectListDD)) {
                    if (hasPayload(this.props.token))
                        this.props.RequestProjectListDD({
                            token: this.props.token.payload.response.token
                        });
                };

                if (isNotLoaded(this.props.ClassTypeAndUnit)) {
                    if (hasPayload(this.props.token))
                        this.props.RequestClassTypeAndUnit({
                            token: this.props.token.payload.response.token
                        });
                };

                if (hasPayload(this.props.OrderList)) {
                    let data = this.props.OrderList.payload;
                    this.setState({ orders: data.response });
                };
            } else {
                this.props.history.push(ROUTE.TOOL_RENTALS.UNAUTHORIZED);
            }
        };
    };

    private sendRequest = (currentPage?: number): void => {
        if (hasPayload(this.props.token))
            this.props.RequestOrderList({
                token: this.props.token.payload.response.token
            });
    };

    private handleSave = (): void => {
        if (this.state.addEdit)
            if (this.state.addEdit.id > 0) {
                this.updateRequest();
            } else {
                this.addRequest();
            };
    };

    private updateRequest = (): void => {
        if (hasPayload(this.props.token) && this.state.addEdit) {
            this.props.RequestUpdateOrder({
                request: this.state.addEdit,
                token: this.props.token.payload.response.token
            });

        }
    };

    private addRequest = (): void => {
        if (hasPayload(this.props.token) && this.state.addEdit) {
            this.props.RequestAddOrder({
                request: this.state.addEdit,
                token: this.props.token.payload.response.token
            });
        }
    };

}

const mapStateToProps = (state: IStore): IOrderStoreProps => ({
    token: getToken(state),
    OrderList: getOrders(state),
    AddOrder: addOrder(state),
    UpdateOrder: updateOrder(state),
    ClassTypeAndUnit: getClassTypeAndUnit(state),
    ProjectListDD: getProjectListDD(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IOrderDispatchProps => ({
    RequestOrderList: (request: IOrderRequest): unknown => dispatch(getOrdersLoadAction(request)),
    RequestAddOrder: (request: IAddOrderRequest): unknown => dispatch(addOrderLoadAction(request)),
    RequestUpdateOrder: (request: IUpdateOrderRequest): unknown => dispatch(updateOrderLoadAction(request)),
    RequestClassTypeAndUnit: (request: IUnitRequest): unknown => dispatch(getClassTypeAndUnitLoadAction(request)),
    RequestProjectListDD: (request: IProjectRequest): unknown => dispatch(getProjectListDDLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Order);