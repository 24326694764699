import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Grid, Button, Paper, TextField, Stack, Alert, Box, Backdrop, IconButton,  Checkbox, FormControlLabel, FormHelperText} from '@mui/material';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
};

interface IState{
    showAddSubSiteForm:boolean;
    open:boolean;
    showError:boolean;
    errorText:boolean;
}
interface IProps{
    data:any[];
    reRender:any;
    disabled:boolean;
}
var newCustomService = {hour_Interval:'', completed_Date:'', notes:'', email_Check:false, service_Date:''};
export default class CustomServiceTable extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state ={showAddSubSiteForm:false, open:true, showError:false, errorText:false};
        this.handleChange = this.handleChange.bind(this);
        this.saveSubSite = this.saveSubSite.bind(this);
        this.cancelSubSite = this.cancelSubSite.bind(this);
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'hour_interval':
                newCustomService.hour_Interval = event.target.value;
                break;
            case 'notes':
                newCustomService.notes = event.target.value;
                break;
            case 'completed_date':
                newCustomService.completed_Date = event.target.value;
                break;
            case 'email_check':
                newCustomService.email_Check = !newCustomService.email_Check;
                break;
            case 'service_date':
                newCustomService.service_Date = event.target.value;
                break;
        }
        this.setState({});
    }
    saveSubSite(){
        console.log(newCustomService)
        if((newCustomService.hour_Interval === '' && newCustomService.service_Date==='') || newCustomService.notes===''){
            alert("Notes, hour interval and service date are mandatory");
        }
        else{
            if((newCustomService.completed_Date < newCustomService.service_Date) && newCustomService.completed_Date!=''){
                alert("Completed Date must occur after service date");
            }
            else{
                this.props.data.push(newCustomService);
                newCustomService = {hour_Interval:'', completed_Date:'', notes:'', email_Check:false, service_Date:''};
                this.setState({showAddSubSiteForm:false});
                this.props.reRender();
            }
        }
    }
    cancelSubSite(){
        newCustomService = {hour_Interval:'', completed_Date:'', notes:'', email_Check:false, service_Date:''};
        this.setState({showAddSubSiteForm:false});
    }
    deleteSubSite(index:number, id:number, event:any){
        this.props.data.splice(index, 1);
        this.setState({});
        this.props.reRender();
    }
    handleSubSiteEdit(index:number,event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'hour_interval':
                this.props.data[index].hour_Interval = event.target.value;
                break;
            case 'completed_date':
                this.props.data[index].completed_Date = event.target.value;
                break;
            case 'service_date':
                this.props.data[index].service_Date = event.target.value;
                break;
            case 'notes':
                this.props.data[index].notes = event.target.value;
                break;
            case 'email_check':
                this.props.data[index].email_Check = !this.props.data[index].email_Check;
                break;
        }
        this.setState({});
    }
    render(){
    return(
    <>
    <TableContainer component={Paper}>
    <Table size="small" aria-label="Product info">
        <TableHead>
            <TableRow>
                <TableCell align="center" colSpan={3} sx={{ color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px',backgroundColor:'#bf0000', padding:'10px'}}>Custom Services</TableCell>
                <TableCell align="right" colSpan={6}> <Button disabled={this.props.disabled} style={{backgroundColor:'#bf0000'}} onClick={() => {this.setState({showAddSubSiteForm:true})}} variant="contained">Add new</Button></TableCell>
            </TableRow>
        
        <TableRow>
            <TableCell>Hour Interval</TableCell>
            <TableCell >Notes</TableCell>
            <TableCell >Service Date</TableCell>
            <TableCell >Completed Date</TableCell>
            <TableCell >Send Email</TableCell>
        </TableRow>
    </TableHead>
     {this.props.data.map((service:any, index:any) => {   
        //  Prevent showing deleted rows or null rows
     if(service.notes!='' && service.id!=-1){
       // if(service.hour_Interval !='' && service.id!=-1){
    return <TableBody>
        <TableRow>
            <TableCell ><TextField onChange={this.handleSubSiteEdit.bind(this,index)} placeholder={service.hour_Interval} name="hour_interval"></TextField></TableCell>
            <TableCell ><TextField onChange={this.handleSubSiteEdit.bind(this,index)} placeholder={service.notes} name="notes"></TextField></TableCell>
            <TableCell ><TextField onChange={this.handleSubSiteEdit.bind(this,index)}  placeholder={service.service_Date} name="service_date"></TextField ></TableCell>
            <TableCell ><TextField onChange={this.handleSubSiteEdit.bind(this,index)} placeholder={service.completed_Date} name="completed_date"></TextField ></TableCell>
            <TableCell><Checkbox onChange={this.handleSubSiteEdit.bind(this,index)} checked={service.email_Check} name="email_check"></Checkbox></TableCell>
            <TableCell align="right"><Button className={"del-customer-btn"} onClick={this.deleteSubSite.bind(this, index, service.id)}>X</Button></TableCell>
        </TableRow>
    </TableBody>
     }
    })}
    </Table> </TableContainer>
    {this.state.showAddSubSiteForm ?  <Modal id='warehouse-emp-modal'
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.state.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.open}>
          <Box sx={BoxStyle}>
          {this.state.showError ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                  Delivery Address cannot be empty
                </Alert>
              </Stack>
            :null}
            <br></br>
            <Grid item xs={12}>
                        <Typography gutterBottom variant="h4" >Add new custom service</Typography>
                        </Grid>
            <Grid container spacing={3} direction='row'>
                <Grid item xs={6}>
                    <TextField label="Hour Interval"
                     id={'outlined-error-helper-text'} 
                     name="hour_interval"
                     onChange={this.handleChange}>
                     </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField type="date" helperText="Service Date" name="service_date" onChange={this.handleChange}></TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField type="date" helperText="Completed Date" name="completed_date" onChange={this.handleChange}></TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField label="Notes"
                    multiline
                    rows={4}
                    id={'outlined-error-helper-text'} 
                    name="notes"
                    onChange={this.handleChange}>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                            <FormControlLabel  control={<Checkbox checked={newCustomService.email_Check} onChange={this.handleChange} name="email_check"/>} label="Notify Shop Admin"/>
                            <FormHelperText>This sends email to shop admin when meter reading is less than or equal to 50 hours within requested hour interval reading, or a day of the requested service date</FormHelperText>
                </Grid>
                <Grid item xs={6}>
            <Button variant="outlined" className="cancel-employee-save" onClick={this.cancelSubSite}>Cancel Changes</Button>
            </Grid>
            <Grid item xs={6}>
            <Button variant="outlined" className="save-employee" onClick={this.saveSubSite}>Save Info</Button>
            </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>:null}
    </>
    );
    }
}