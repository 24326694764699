import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../clearRedux/clearReduxConstants";
import { IGET_PROJECTS_REQUEST, IProject } from "./getProjectsConstants";
import { IGetProjectsLoadAction, IGetProjectsLoadFailedAction, IGetProjectsSuccessAction } from "./getProjectsActions";
import { IAddProjectSuccessAction } from "../add/project/addProjectActions";
import { IADD_PROJECT_REQUEST } from "../add/project/addProjectConstants";
import { IUpdateProjectSuccessAction } from "../update/project/updateProjectActions";
import { IUPDATE_PROJECT_REQUEST } from "../update/project/updateProjectConstants";

type Actions =
    | IGetProjectsLoadAction
    | IGetProjectsSuccessAction
    | IGetProjectsLoadFailedAction
    | IAddProjectSuccessAction
    | IUpdateProjectSuccessAction
    | IFlushDataSuccessAction;

export const GetProjectsReducer = (state: Server<ToolRentalsResponse<ByIdNumber<IProject>>> = notLoaded, action: Actions): Server<ToolRentalsResponse<ByIdNumber<IProject>>> => {
    switch (action.type) {
        case IGET_PROJECTS_REQUEST.REQUEST:
            return loading;

        case IGET_PROJECTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PROJECTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_PROJECT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_PROJECT_REQUEST.SUCCESS:
            if (hasPayload(state)) {
                state.payload.response[action.data.id] = action.data;
                return state;
            }
            return notLoaded;

        default:
            return state;
    }
};