import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_CLASS_TYPE_AND_UNIT_REQUEST } from "./getClassTypeAndUnitConstants";
import { IGetClassTypeAndUnitLoadAction, IGetClassTypeAndUnitLoadFailedAction, IGetClassTypeAndUnitSuccessAction } from "./getClassTypeAndUnitActions";
import { IADD_UNIT_REQUEST } from "../../add/unit/addUnitConstants";
import { IAddUnitSuccessAction } from "../../add/unit/addUnitActions";
import { IUnit } from "../../getUnits/getUnitsConstants";

type Actions =
    | IGetClassTypeAndUnitLoadAction
    | IGetClassTypeAndUnitSuccessAction
    | IGetClassTypeAndUnitLoadFailedAction
    | IAddUnitSuccessAction
    | IFlushDataSuccessAction;

export const GetClassTypeAndUnitReducer = (state: Server<ToolRentalsResponse<IUnit[]>> = notLoaded, action: Actions): Server<ToolRentalsResponse<IUnit[]>> => {
    switch (action.type) {
        case IGET_CLASS_TYPE_AND_UNIT_REQUEST.REQUEST:
            return loading;

        case IGET_CLASS_TYPE_AND_UNIT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_CLASS_TYPE_AND_UNIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_UNIT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};