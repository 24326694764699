import React from 'react';
import {TextField, Select, Button, Grid, InputLabel, MenuItem, FormControl, Paper, Typography, Radio, RadioGroup, FormControlLabel, FormLabel, FormGroup, Checkbox} from '@mui/material';
import DataGrid, {
    Column,Editing,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import splitDate from '../../../../../shared/splitDate';
import {updateSOCT, getSOCTFile} from '../../../api/soct/soctAPI';
import {getSOCTWorkingSites, getSOCTWorkingSubSitesByParentID} from '../../../api/workingsites/workingSitesAPI';
import { editData } from '../../../workingsites';
import { currentUser } from '../../../socthome';

const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
interface IProps {
    history:any;
    data:any[];
    editData:any;
}
interface IState{
    unit_Number:string;
    make:string;
    model:string;
    location:string;
    service_Date:any;
    current_Hours:number;
    last_EO:number;
    filter_Cuttings:string;
    assigned:any[];
    location_list:any[];
}
var editSOCTMain:any = null;
var workingSites:any = [];
var exportWorkingSites:any;
var sub_site_locations:any[] = [];
var parent_id:number = 0;
var geotab_sync:boolean;
export default class EditSOCTMain extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state={unit_Number:'',make:'',model:'',location:'',service_Date:'',current_Hours:0,last_EO:0,filter_Cuttings:'',assigned:[], location_list:[]};
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onGetFileButtonClick = this.onGetFileButtonClick.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
    }
    onGetFileButtonClick(equipment_id:any, filename:string){
        getSOCTFile(equipment_id, filename);
    }
    handleFileRemove(index:number,event:any){
        editSOCTMain.attachments.splice(index,1);
        this.setState({});
    }
    handleFileUpload(event:any){
        if(editSOCTMain.attachments === null){
            editSOCTMain.attachments = [];
        }
        editSOCTMain.attachments.push(event.target.files[0].name);
        this.setState({});
    }
    handleStatusChange(event:any){
        switch(event.target.value){
            case 'Active':
                editSOCTMain.status = 'Active';
                break;
            case 'Inactive':
                editSOCTMain.status = 'Inactive';
                break;
            case 'Out Of Service':
                editSOCTMain.status = 'Out Of Service';
                break;
        }
        this.setState({});
    }
    async handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'make':
                editSOCTMain.make = event.target.value;
                break;
            case 'model':
                editSOCTMain.model = event.target.value;
                break;
            case 'location':
                editSOCTMain.location = event.target.value;
                var parent_id_changed=0;   
                exportWorkingSites.map((site:any) => {
                    if(site.site_Name === event.target.value){
                        parent_id_changed = site.id;
                    }
                });
                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id_changed, true);
                break;
            case 'sub_location':
                editSOCTMain.sub_Location = event.target.value;
                sub_site_locations.map((site:any) => {
                    if(site.sub_Site_Name === event.target.value){
                        editSOCTMain.sub_Location_ID = site.id;
                    }
                });
            break;
            case 'servicedate':
                editSOCTMain.service_Date = event.target.value;
                break;
            case 'currenthours':
                editSOCTMain.current_Hours = event.target.value;
                break;
            case 'lasteo':
                editSOCTMain.last_EO = event.target.value;
                break;
            case 'filtercuttings':
                editSOCTMain.filter_Cuttings = event.target.value;
                break;
            case 'ecm':
                editSOCTMain.ecm = event.target.value;
                break;
            case 'comments':
                editSOCTMain.comments = event.target.value;
                break;
            case 'geotab_sync':
                geotab_sync = !geotab_sync;
                break;
        }
        this.setState({});
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    async onFormSave(){
        console.log('saving data ', editSOCTMain);
        var result:any = await updateSOCT(editSOCTMain, currentUser, geotab_sync);
        if(result.status === 200){
            alert("Successfully updated SOCT item");
            this.props.history.push('/soct/lists');
        }
        else{
            alert("Error updating SOCT item");
        }
    }
    async componentDidMount(){
        editSOCTMain = this.props.editData;
        geotab_sync = editSOCTMain.geotab_Sync === "Yes";
        var temp_location_list:any[] = [];
        workingSites = await getSOCTWorkingSites(true);
       
        if(workingSites != null){
            workingSites.map((location:any) => {
                temp_location_list.push(location);
                if(location.site_Name === this.props.editData.location){
                    parent_id = location.id;
                }
            });
            exportWorkingSites = temp_location_list.sort((a:any,b:any) => (a.site_Name > b.site_Name) ? 1:-1);
            sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
            this.setState({location_list:temp_location_list.map((location:any) => <MenuItem value={location.site_Name}>{location.site_Name}</MenuItem>)});
        }
        this.setState({});
    }
    render(){
        return (
            <Grid container spacing={6}>
            <Grid item xs={3}>
                <TextField disabled label="Unit" defaultValue={this.props.editData.unit_Number}></TextField>
            </Grid>
            <Grid item xs={3}>
                <TextField disabled label="Make" defaultValue={this.props.editData.make} onChange={this.handleChange} name="make"></TextField>
            </Grid>
            <Grid item xs={3}>
                <TextField disabled label="Model" defaultValue={this.props.editData.model} onChange={this.handleChange} name="model"></TextField>
            </Grid>
            <Grid item xs={3}>
                <TextField disabled label="Equipment Type" defaultValue={this.props.editData.equipment_Type} onChange={this.handleChange} name="model"></TextField>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={4}>
            <FormControl sx={{width:'100%'}}>
            <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select disabled
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Location"
                    value={editSOCTMain ? editSOCTMain.location : this.props.editData.location}
                    onChange={this.handleChange}
                    name="location"

                >
                    {/* <MenuItem value={this.props.editData.location}>{this.props.editData.location}</MenuItem> */}
                   {/* {exportWorkingSites.map((location:any) => <MenuItem value={location.site_Name}>{location.site_Name}</MenuItem>})} */}
                   {this.state.location_list}
                </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
            <FormControl sx={{width:'100%'}}>
            <InputLabel id="demo-simple-select-label">Sub Location</InputLabel>
                <Select disabled
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Location"
                    value={editSOCTMain ? editSOCTMain.sub_Location: this.props.editData.sub_Location}
                    onChange={this.handleChange}
                    name="sub_location"

                >
                   {sub_site_locations.map((sub_site) => {
                        return <MenuItem value={sub_site.sub_Site_Name}>{sub_site.sub_Site_Name}</MenuItem>
                    })}
                </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{width:'100%'}}>
                <TextField disabled helperText="Service Date" type='date' defaultValue={this.props.editData.service_Date!=null ? splitDate(this.props.editData.service_Date):''} onChange={this.handleChange} name="servicedate"></TextField>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField disabled label="Current Hours" defaultValue={this.props.editData.current_Hours} onChange={this.handleChange} name="currenthours"></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField disabled label="Last_E:O" defaultValue={this.props.editData.last_EO} onChange={this.handleChange} name="lasteo"></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField disabled label="Filter Cuttings" defaultValue={this.props.editData.filter_Cuttings} onChange={this.handleChange} name="filtercuttings"></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField disabled label="ECM" defaultValue={this.props.editData.ecm} onChange={this.handleChange} name="ecm"></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField fullWidth label="Comments" multiline rows={4} defaultValue={this.props.editData.comments} onChange={this.handleChange} name="comments"></TextField>
            </Grid>
            <Grid item xs={4} >
                <FormControl>
                    <FormLabel>Status</FormLabel>
                    <RadioGroup
                        
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={this.handleStatusChange}
                        defaultValue={this.props.editData.status ? this.props.editData.status: "Active"}
                    >
                        <FormControlLabel value="Active" control={<Radio size="small"/>} label="Active" />
                        <FormControlLabel value="Inactive" control={<Radio size="small"/>} label="Inactive" />
                        <FormControlLabel value="Out Of Service" control={<Radio size="small"/>} label="Out Of Service" />
                        </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Sync to Geotab</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={geotab_sync} onChange={this.handleChange} name="geotab_sync"/>
                                }
                                label="Yes"
                            />
                        </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
            <Paper variant="outlined" style={{padding:'15px', backgroundColor:'#e0bfbc'}}>
                <Grid item xs={12}>
                    <Typography style={{borderBottomColor:'black', borderBottomWidth:'thick', borderBottom:'solid', textAlign:'center', fontWeight:'bold'}}>Attachments</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="outlined" component="label">Drag and drop, or click to upload files<input hidden accept="image/*" multiple type="file" onChange={this.handleFileUpload}></input></Button>
                    {editSOCTMain ? editSOCTMain.attachments ? editSOCTMain.attachments.map((file:any, index:any) => {
                        return <Grid item xs={3}><Button onClick={(e:any)=>{this.onGetFileButtonClick(this.props.editData.id, e.target.innerHTML.substring(0,e.target.innerHTML.indexOf("<")))}} className="dataGrid-edit-btn">{file}</Button><Button onClick={this.handleFileRemove.bind(this,index)}>X</Button></Grid>
                    }):null:null}
                </Grid>
            </Paper>
            </Grid>
            <Grid item xs={12}><br></br>
                <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                <Button style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onFormSave} variant="contained">Save</Button>
            </Grid>
        </Grid>
          
        );
    }
}
export {exportWorkingSites};