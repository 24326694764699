import { IPaginationRequest } from "../../../react/shared/publicInterfaces";

export enum IGET_PARTS_HEAVY_TRUCK_REQUEST {
    REQUEST = "getPartsHeavyTruck/GET_PARTS_HEAVY_TRUCK_REQUEST",
    SUCCESS = "getPartsHeavyTruck/GET_PARTS_HEAVY_TRUCK_SUCCESS",
    FAILED = "getPartsHeavyTruck/GET_PARTS_HEAVY_TRUCK_FAILED"
};

export interface IPartHeavyTruckRequest extends IPaginationRequest {
    token: string;
};

export interface IPartHeavyTruck {
    id: number;
    make: string; 
    model: string;
    hours: string;
    location: string;
    notes: string;
    photos_1: string;
    photos_2: string;
    photos_3: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
    serial_No: string;
    unit_No: string;
    status: string;
};