import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_MANUFACTURER_REQUEST } from "./addManufacturerConstants";
import { IAddManufacturerLoadAction, IAddManufacturerLoadFailedAction, IAddManufacturerSuccessAction, addManufacturerLoadFailedAction, addManufacturerLoadSuccessAction } from "./addManufacturerActions";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { IManufacturer } from "../../getManufacturers/getManufacturerConstants";

export const addManufacturerEpic: Epic = (
    action$: ActionsObservable<IAddManufacturerLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddManufacturerSuccessAction | IAddManufacturerLoadFailedAction> =>
    action$.ofType(IADD_MANUFACTURER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IManufacturer[]>>(
                    PARTS_END_POINTS.GET_PARTS.MANUFACTURERS.ADD,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IManufacturer[]>): IAddManufacturerSuccessAction | IAddManufacturerLoadFailedAction => {
                            if(response.message === "Success"){
                                return addManufacturerLoadSuccessAction(response);
                            }
                            return addManufacturerLoadFailedAction(response.message);
                        }),
                        catchError((response: PartsResponse<IManufacturer[]>) => ActionsObservable.of(addManufacturerLoadFailedAction(response.message)))
                    )
            )
        );