import { IModel } from "../../getModels/getModelConstants";

export enum IUPDATE_MODEL_REQUEST {
    REQUEST = "updateModel/UPDATE_MODEL_REQUEST",
    SUCCESS = "updateModel/UPDATE_MODEL_SUCCESS",
    FAILED = "updateModel/UPDATE_MODEL_FAILED"
};

export interface IUpdateModelRequest {
    request: IModel;
    token: string;
};