import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import {RouteComponentProps} from 'react-router-dom';
  import SubSiteTablePopup from './forms/workingsites/subworkingsites/subWorkingSiteTablePopup';
import SOCTHome from './socthome';

  interface IProps extends RouteComponentProps{
    history:any
  }
  interface IState {
    data:any[];
    showSubSitePopup:boolean;
    view:string;
  }

const renderTitleHeader = (data:any) => {
  return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
var editData:any;
var globaleditID:number;
var subSiteParentID:number;
var copy_list:any[];
var export_list:any[];
export default class Sites extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {data:[], showSubSitePopup:false, view:'all'};
        this.displaySubSiteTablePopup = this.displaySubSiteTablePopup.bind(this);
        this.closeSubSiteTablePopup = this.closeSubSiteTablePopup.bind(this);
        this.editItem = this.editItem.bind(this);
        this.filterView = this.filterView.bind(this);
    }
    editItem(id:number, data:any){
      editData = data;
      globaleditID = id;
      this.props.history.push('/soct/sites/edit/id='+globaleditID);
    }
    displaySubSiteTablePopup(workingSiteID:number){
      subSiteParentID = workingSiteID;
      this.setState({showSubSitePopup:true});
    }
    closeSubSiteTablePopup(){
      this.setState({showSubSitePopup:false});
    }
    filterView(eventKey:any){
      switch(eventKey.target.value) {
        case 'active':
            this.setState({view:'active'});
            var filtered_list = copy_list.filter((elem:any) => { return (elem.active != "False")});
            this.setState({data:filtered_list});
            break;
        case 'nonactive':
            this.setState({view:'nonactive'});
            var filtered_list = copy_list.filter((elem:any) => { return (elem.active != "True")});
            this.setState({data:filtered_list});
            break;
        case 'all':
            this.setState({view:'all'});
            var filtered_list = copy_list.filter((elem:any) => { return (elem.active === "True" || elem.active === "False")});
            this.setState({data:filtered_list});
            break;
       }
    }
    async componentDidMount(){
      window.location.href = "https://apps.sureway.ca/field/soct/sites";
    }
    render(){
        return (
          <>
          <SOCTHome history={this.props.history}></SOCTHome>
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
              <MUIButton onClick={()=> {this.props.history.push('/soct/sites/create')}} style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" startIcon={<AddCircleIcon />}>Add New</MUIButton>
              <Typography className='dataGrid-title'>Working Sites</Typography>
              <Grid item xs={3}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={this.filterView}
                                        defaultValue={"all"}
                                    >
                                        <FormControlLabel value="active" control={<Radio size="small"/>} label="Active" />
                                        <FormControlLabel value="nonactive" control={<Radio size="small"/>} label="Non Active" />
                                        <FormControlLabel value="all" control={<Radio size="small"/>} label="All" />
                                        </RadioGroup>
                                </FormControl>
                             </Grid>
            <DataGrid focusedRowEnabled={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={true}
               dataSource={this.state.data}
               keyExpr="id"
               showBorders={true}
               wordWrapEnabled={true}
              > 
           <SearchPanel visible={true}
       width={340}
       placeholder="Search for..." />    
     <Paging enabled={true} defaultPageSize={20}/>
     <Pager
       visible={true}
       allowedPageSizes={[20,40,60]}
       displayMode={'full'}
       showPageSizeSelector={true}
       showInfo={true}
       showNavigationButtons={true} />
       <FilterRow visible={true}></FilterRow>
       <FilterPanel visible={true} />
   {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
     <HeaderFilter visible={true} allowSearch={true} />
     
     <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
     <Button text="View/Update" cssClass="dataGrid-edit-btn" onClick={(e:any) => {this.editItem(e.row.data.id, e.row.data)}}></Button>
       </Column>
       <Column dataField="id" caption="id" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="site_Name" caption="Site Name" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="site_Address" caption="Site Address" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="latitude" caption="Latitude" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="longitude" caption="Longitude" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="notes" caption="Notes" alignment="left" width={400} headerCellRender={renderTitleHeader}/>
       <Column dataField="active" caption="Active" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="rental_Status" caption="Rental" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column type="buttons" caption="Sub Sites" headerCellRender={renderTitleHeader}>
        <Button text="View" cssClass="dataGrid-view-btn" onClick={(e:any) => {this.displaySubSiteTablePopup(e.row.data.id)}}></Button>
       </Column>
       <Column dataField="created" dataType="date" caption="Created" alignment="left" width={200} headerCellRender={renderTitleHeader}/>
       <Column dataField="modified" dataType="date"caption="Modified" alignment="left" width={200} headerCellRender={renderTitleHeader}/>
       <Column dataField="created_By" caption="Created By" alignment="left" width={200} headerCellRender={renderTitleHeader}/>
       <Column dataField="modified_By" caption="Modified_By" alignment="left" width={200} headerCellRender={renderTitleHeader}/>
            </DataGrid>
            {this.state.showSubSitePopup ? <SubSiteTablePopup closePopup={this.closeSubSiteTablePopup} parentID={subSiteParentID}></SubSiteTablePopup>:null}
            </Card>
            </Grid>
            </>
        );
    }
}
export {globaleditID, editData, export_list};