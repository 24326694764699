import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_SHOP_EQUIPMENT_REQUEST } from "./updateShopEquipmentsConstants";
import { IUpdateShopEquipmentLoadAction, IUpdateShopEquipmentLoadFailedAction, IUpdateShopEquipmentSuccessAction, updateShopEquipmentLoadFailedAction, updateShopEquipmentLoadSuccessAction } from "./updateShopEquipmentsActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const updateShopEquipmentEpic: Epic = (
    action$: ActionsObservable<IUpdateShopEquipmentLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateShopEquipmentSuccessAction | IUpdateShopEquipmentLoadFailedAction> =>
    action$.ofType(IUPDATE_SHOP_EQUIPMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.SHOP.UPDATE_EQUIPMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<string>): IUpdateShopEquipmentSuccessAction | IUpdateShopEquipmentLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateShopEquipmentLoadSuccessAction(response);
                            } else {
                                return updateShopEquipmentLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: PartsResponse<string>) => ActionsObservable.of(updateShopEquipmentLoadFailedAction(response.message)))
                    )
            )
        );