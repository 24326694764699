import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_PART_HEAVY_TRUCK_REQUEST } from "./addHeavyTruckConstants";
import { IAddPartHeavyTruckLoadAction, IAddPartHeavyTruckLoadFailedAction, IAddPartHeavyTruckSuccessAction} from "./addHeavyTruckActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IAddPartHeavyTruckLoadAction
    | IAddPartHeavyTruckSuccessAction
    | IAddPartHeavyTruckLoadFailedAction
    | IFlushDataSuccessAction;

export const AddPartHeavyTruckReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_PART_HEAVY_TRUCK_REQUEST.REQUEST:
            return loading;

        case IADD_PART_HEAVY_TRUCK_REQUEST.SUCCESS:
            return succeeded("Success");

        case IADD_PART_HEAVY_TRUCK_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};