import { PARTS_END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_LOOKUPS_REQUEST, IDismantledLookups } from "./getDismantledLookupsConstants";
import { IGetDismantledLookupsLoadAction, IGetDismantledLookupsLoadFailedAction, IGetDismantledLookupsSuccessAction, getDismantledLookupsLoadFailedAction, getDismantledLookupsLoadSuccessAction } from "./getDismantledLookupsActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getDismantledLookupsEpic: Epic = (
    action$: ActionsObservable<IGetDismantledLookupsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetDismantledLookupsSuccessAction | IGetDismantledLookupsLoadFailedAction> =>
    action$.ofType(IGET_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IDismantledLookups>>(
                    PARTS_END_POINTS.GET_PARTS.PARTSDISMANTLEDEQUIPMENTS.GET_DISMANTLED_LOOKUP,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IDismantledLookups>): IGetDismantledLookupsSuccessAction => {
                            return getDismantledLookupsLoadSuccessAction(response);
                        }),
                        catchError((response: PartsResponse<string>) => ActionsObservable.of(getDismantledLookupsLoadFailedAction(response.message)))
                    )
            )
        );