import { ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { ITokenRequest } from "../../getToken/getTokenConstants";
import { IVALIDATE_SHOP_GROUP_REQUEST, IValidateShopGroup } from "./validateShopGroupConstants";


export interface IValidateShopGroupLoadAction {
    type: IVALIDATE_SHOP_GROUP_REQUEST.REQUEST;
    data: ITokenRequest
}
export const validateShopGroupLoadAction = (data: ITokenRequest): IValidateShopGroupLoadAction => ({
    type: IVALIDATE_SHOP_GROUP_REQUEST.REQUEST,
    data
});
export interface IValidateShopGroupSuccessAction {
    type: IVALIDATE_SHOP_GROUP_REQUEST.SUCCESS;
    data: ToolRentalsResponse<IValidateShopGroup>;
}
export const validateShopGroupLoadSuccessAction = (data: ToolRentalsResponse<IValidateShopGroup>): IValidateShopGroupSuccessAction => ({
    type: IVALIDATE_SHOP_GROUP_REQUEST.SUCCESS,
    data
});
export interface IValidateShopGroupLoadFailedAction {
    type: IVALIDATE_SHOP_GROUP_REQUEST.FAILED;
    message: string;
}
export const validateShopGroupLoadFailedAction = (message: string): IValidateShopGroupLoadFailedAction => ({
    type: IVALIDATE_SHOP_GROUP_REQUEST.FAILED,
    message
});
