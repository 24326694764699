import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IGetLookupsLoadAction, IGetLookupsLoadFailedAction, IGetLookupsSuccessAction } from "./getLookupsActions";
import { IGET_LOOKUPS_REQUEST, IShopLookups } from "./getLookupsConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IGetLookupsLoadAction
    | IGetLookupsSuccessAction
    | IGetLookupsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetShopLookupsReducer = (state: Server<PartsResponse<IShopLookups>> = notLoaded, action: Actions): Server<PartsResponse<IShopLookups>> => {
    switch (action.type) {
        case IGET_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};