import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography} from '@mui/material';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import {RouteComponentProps} from 'react-router-dom';

interface IProps extends RouteComponentProps{
  history:any
}

  var testdata = [{id:1,date:'2021/12/08',prev_Date:'2021/12/07', prev_Reading:20600,m_unit_:'ACG33', current_Hours:1200, completed_Date:'2021/12/08', location:'Kelowna',new_Location:'1492',unit_Location:'Kelowna', created:'2021/12/07', modified:'2021/12/07', created_By:'dylan.ollikka', modified_By:'dylan.ollikka'},
  {id:2,prev_Date:'2021/06/12',date:'2021/06/12', prev_Reading:1600,m_unit_:'AC1G323',current_Hours:12000, completed_Date:'',location:'Kelowna',new_Location:'',unit_Location:'Kelowna',created:'2021/06/12',modified:'2021/06/12',created_By:'dylan.ollikka', modified_By:'dylan.ollikka'},
{id:3,prev_Date:'2021/03/17',date:'2021/03/18', prev_Reading:2000,m_unit_:'DJW922',current_Hours:11200, completed_Date:'',location:'Kelowna',new_Location:'1492',unit_Location:'Kelowna',created:'2021/03/17',modified:'2021/03/17',created_By:'test.admin', modified_By:'test.admin'},
{id:4,prev_Date:'2021/01/27',date:'2021/01/28', prev_Reading:600,m_unit_:'C1G3213',current_Hours:5200, completed_Date:'2021/01/30',location:'Kelowna',new_Location:'',unit_Location:'Kelowna',created:'2021/01/27',modified:'2021/01/27',created_By:'test.admin', modified_By:'test.admin'}];

const renderTitleHeader = (data:any) => {
    return <p style={{ color:'#5383d3',font: 'Cookie'}}>{data.column.caption}</p>
}
export default class Meters extends React.Component<IProps,{}>{
    constructor(props:any){
        super(props);
        this.state = {};
    }
    render(){
        return (
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
              <MUIButton onClick={()=> {this.props.history.push('/soct/meters/create')}} style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" startIcon={<AddCircleIcon />}>Add New</MUIButton>
              <Typography className='dataGrid-title'>Meter Reading</Typography>
            <DataGrid focusedRowEnabled={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={true}
               dataSource={testdata}
               keyExpr="id"
               showBorders={true}
              > 
           <SearchPanel visible={true}
       width={340}
       placeholder="Search for..." />    
     <Paging enabled={true} defaultPageSize={5}/>
     <Pager
       visible={true}
       allowedPageSizes={[5,10,20]}
       displayMode={'full'}
       showPageSizeSelector={true}
       showInfo={true}
       showNavigationButtons={true} />
       <FilterRow visible={true}></FilterRow>
       <FilterPanel visible={true} />
   {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
     <HeaderFilter visible={true} allowSearch={true} />
     
     <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
        <Button text="View/Update" cssClass="dataGrid-edit-btn" onClick={(e:any) => this.props.history.push('/soct/meters/edit/id='+e.row.data.id)}></Button>
       </Column>
       <Column dataField="id" caption="id" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="date" dataType="date" caption="Date" alignment="left" width={150} headerCellRender={renderTitleHeader}/>
       <Column dataField="prev_Date" dataType="date" caption="Prev_Date" alignment="left" width={150} headerCellRender={renderTitleHeader}/>
       <Column dataField="m_unit_" caption="M_Unit_#" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="prev_Reading" caption="Previous Read" alignment="left" width={150} headerCellRender={renderTitleHeader}/>
       <Column dataField="current_Hours" caption="current_Hours" alignment="left" width={200} headerCellRender={renderTitleHeader}/>
       <Column dataField="completed_Date" caption="Completed Date" alignment="left" width={200} headerCellRender={renderTitleHeader}/>
       <Column dataField="location" caption="Location" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="new_Location" caption="New Location" alignment="left" width={200} headerCellRender={renderTitleHeader}/>
       <Column dataField="unit_Location" caption="Unit Location" alignment="left" width={200} headerCellRender={renderTitleHeader}/>
       <Column dataField="created" dataType="date" caption="Created" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="modified" dataType="date"caption="Modified" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="created_By" caption="Created By" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="modified_By" caption="Modified_By" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
            </DataGrid>
            </Card>
            </Grid>
        );
    }
}