import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_TOKENS_REQUEST, IToken } from "./getTokenConstants";
import { IGetTokenLoadAction, IGetTokenLoadFailedAction, IGetTokenSuccessAction, getTokenLoadFailedAction, getTokenLoadSuccessAction } from "./getTokenActions";
import { ToolRentalsResponse } from "../../react/shared/publicInterfaces";
import { IStore } from "../reducers";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { withJsonContentType } from "../epicUtils";

export const getTokenEpic: Epic = (
    action$: ActionsObservable<IGetTokenLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetTokenSuccessAction | IGetTokenLoadFailedAction> =>
    action$.ofType(IGET_TOKENS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<IToken>>(
                    END_POINTS.GET_TOOL_RENTALS.GET_TOKEN,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<IToken>): IGetTokenSuccessAction | IGetTokenLoadFailedAction => {
                            if(response.message === "Success"){
                                return getTokenLoadSuccessAction(response);
                            }
                            return getTokenLoadFailedAction(response.message);
                        }),
                        catchError((response: ToolRentalsResponse<IToken>) => ActionsObservable.of(getTokenLoadFailedAction(response.message)))
                    )
            )
        );