import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_ORDER_REQUEST } from "./addOrderConstants";
import { IAddOrderLoadAction, IAddOrderLoadFailedAction, IAddOrderSuccessAction, addOrderLoadFailedAction, addOrderLoadSuccessAction } from "./addOrderActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const addOrderEpic: Epic = (
    action$: ActionsObservable<IAddOrderLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddOrderSuccessAction | IAddOrderLoadFailedAction> =>
    action$.ofType(IADD_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<any>>(
                    END_POINTS.GET_TOOL_RENTALS.ORDERS.ADD,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<any>): IAddOrderSuccessAction | IAddOrderLoadFailedAction => {
                            if(response.message === "Success"){
                                return addOrderLoadSuccessAction(response);
                            }
                            const res = response;
                            res.response.unshift(action.data.request.id.toString());
                            return addOrderLoadFailedAction(res);
                        }),
                        catchError((response: ToolRentalsResponse<any>) => ActionsObservable.of(addOrderLoadFailedAction(response)))
                    )
            )
        );