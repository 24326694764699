import { IProject } from "../../getProjects/getProjectsConstants";
import { IUPDATE_PROJECT_REQUEST, IUpdateProjectRequest } from "./updateProjectConstants";


export interface IUpdateProjectLoadAction {
    type: IUPDATE_PROJECT_REQUEST.REQUEST;
    data: IUpdateProjectRequest
}
export const updateProjectLoadAction = (data: IUpdateProjectRequest): IUpdateProjectLoadAction => ({
    type: IUPDATE_PROJECT_REQUEST.REQUEST,
    data
});
export interface IUpdateProjectSuccessAction {
    type: IUPDATE_PROJECT_REQUEST.SUCCESS;
    data: IProject;
}
export const updateProjectLoadSuccessAction = (data: IProject): IUpdateProjectSuccessAction => ({
    type: IUPDATE_PROJECT_REQUEST.SUCCESS,
    data
});
export interface IUpdateProjectLoadFailedAction {
    type: IUPDATE_PROJECT_REQUEST.FAILED;
    message: string;
}
export const updateProjectLoadFailedAction = (message: string): IUpdateProjectLoadFailedAction => ({
    type: IUPDATE_PROJECT_REQUEST.FAILED,
    message
});
