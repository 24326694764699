import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IUnit } from "../../getUnits/getUnitsConstants";
import { IADD_UNIT_REQUEST, IAddUnitRequest } from "./addUnitConstants";


export interface IAddUnitLoadAction {
    type: IADD_UNIT_REQUEST.REQUEST;
    data: IAddUnitRequest
}
export const addUnitLoadAction = (data: IAddUnitRequest): IAddUnitLoadAction => ({
    type: IADD_UNIT_REQUEST.REQUEST,
    data
});
export interface IAddUnitSuccessAction {
    type: IADD_UNIT_REQUEST.SUCCESS;
    data: ToolRentalsResponse<IUnit[]>;
}
export const addUnitLoadSuccessAction = (data: ToolRentalsResponse<IUnit[]>): IAddUnitSuccessAction => ({
    type: IADD_UNIT_REQUEST.SUCCESS,
    data
});
export interface IAddUnitLoadFailedAction {
    type: IADD_UNIT_REQUEST.FAILED;
    message: string;
}
export const addUnitLoadFailedAction = (message: string): IAddUnitLoadFailedAction => ({
    type: IADD_UNIT_REQUEST.FAILED,
    message
});
