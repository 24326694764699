import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IAddManufacturerSuccessAction } from "../add/manufacturer/addManufacturerActions";
import { IADD_MANUFACTURER_REQUEST } from "../add/manufacturer/addManufacturerConstants";
import { IGetManufacturersLoadAction, IGetManufacturersLoadFailedAction, IGetManufacturersSuccessAction } from "./getManufacturerActions";
import { IGET_MANUFACTURERS_REQUEST, IManufacturer } from "././getManufacturerConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IGetManufacturersLoadAction
    | IGetManufacturersSuccessAction
    | IGetManufacturersLoadFailedAction
    | IAddManufacturerSuccessAction
    | IFlushDataSuccessAction;

export const GetManufacturersReducer = (state: Server<PartsResponse<IManufacturer[]>> = notLoaded, action: Actions): Server<PartsResponse<IManufacturer[]>> => {
    switch (action.type) {
        case IGET_MANUFACTURERS_REQUEST.REQUEST:
            return loading;

        case IGET_MANUFACTURERS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MANUFACTURERS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_MANUFACTURER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};