import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IUnit, IUnitRequest } from "../../getUnits/getUnitsConstants";
import { IGET_CLASS_TYPE_AND_UNIT_REQUEST } from "./getClassTypeAndUnitConstants";


export interface IGetClassTypeAndUnitLoadAction {
    type: IGET_CLASS_TYPE_AND_UNIT_REQUEST.REQUEST;
    data: IUnitRequest
}
export const getClassTypeAndUnitLoadAction = (data: IUnitRequest): IGetClassTypeAndUnitLoadAction => ({
    type: IGET_CLASS_TYPE_AND_UNIT_REQUEST.REQUEST,
    data
});
export interface IGetClassTypeAndUnitSuccessAction {
    type: IGET_CLASS_TYPE_AND_UNIT_REQUEST.SUCCESS;
    list: ToolRentalsResponse<IUnit[]>;
}
export const getClassTypeAndUnitLoadSuccessAction = (list: ToolRentalsResponse<IUnit[]>): IGetClassTypeAndUnitSuccessAction => ({
    type: IGET_CLASS_TYPE_AND_UNIT_REQUEST.SUCCESS,
    list
});
export interface IGetClassTypeAndUnitLoadFailedAction {
    type: IGET_CLASS_TYPE_AND_UNIT_REQUEST.FAILED;
    message: string;
}
export const getClassTypeAndUnitLoadFailedAction = (message: string): IGetClassTypeAndUnitLoadFailedAction => ({
    type: IGET_CLASS_TYPE_AND_UNIT_REQUEST.FAILED,
    message
});
