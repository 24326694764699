export enum IGET_UNITS_REQUEST {
    REQUEST = "getUnits/GET_UNITS_REQUEST",
    SUCCESS = "getUnits/GET_UNITS_SUCCESS",
    FAILED = "getUnits/GET_UNITS_FAILED"
};

export interface IUnitRequest {
    token: string;
};

export interface IUnit {
    id: number;
    unit_No: string;
    description: string;
    class: string;
    type: string;
    daily: string;
    weekly: string;
    monthly: string;
    size: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
    quantity: string;
};