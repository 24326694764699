export enum IGET_RATES_REQUEST {
    REQUEST = "getRates/GET_RATES_REQUEST",
    SUCCESS = "getRates/GET_RATES_SUCCESS",
    FAILED = "getRates/GET_RATES_FAILED"
};

export interface IRateRequest {
    token: string;
};

export interface IRate {
    id: number;
    class: string;
    type: string;
    daily: string;
    weekly: string;
    monthly: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
};