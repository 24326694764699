import { IManufacturer } from "../../getManufacturers/getManufacturerConstants";

export enum IUPDATE_MANUFACTURER_REQUEST {
    REQUEST = "updateManufacturer/UPDATE_MANUFACTURER_REQUEST",
    SUCCESS = "updateManufacturer/UPDATE_MANUFACTURER_SUCCESS",
    FAILED = "updateManufacturer/UPDATE_MANUFACTURER_FAILED"
};

export interface IUpdateManufacturerRequest {
    request: IManufacturer;
    token: string;
};