export enum IDELETE_DISMANTLED_REQUEST {
    REQUEST = "deleteDismantled/DELETE_DISMANTLED_REQUEST",
    SUCCESS = "deleteDismantled/DELETE_DISMANTLED_SUCCESS",
    FAILED = "deleteDismantled/DELETE_DISMANTLED_FAILED"
};

export interface IDeleteDismantledRequest {
    request: {
        ItemID: number;
    };
    token: string;
};