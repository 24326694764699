import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IProjectRequest } from "../../getProjects/getProjectsConstants";
import { IGET_PROJECT_LIST_DD_REQUEST, IProjectListDD } from "./getProjectListDDConstants";


export interface IGetProjectListDDLoadAction {
    type: IGET_PROJECT_LIST_DD_REQUEST.REQUEST;
    data: IProjectRequest
}
export const getProjectListDDLoadAction = (data: IProjectRequest): IGetProjectListDDLoadAction => ({
    type: IGET_PROJECT_LIST_DD_REQUEST.REQUEST,
    data
});
export interface IGetProjectListDDSuccessAction {
    type: IGET_PROJECT_LIST_DD_REQUEST.SUCCESS;
    list: ToolRentalsResponse<IProjectListDD[]>;
}
export const getProjectListDDLoadSuccessAction = (list: ToolRentalsResponse<IProjectListDD[]>): IGetProjectListDDSuccessAction => ({
    type: IGET_PROJECT_LIST_DD_REQUEST.SUCCESS,
    list
});
export interface IGetProjectListDDLoadFailedAction {
    type: IGET_PROJECT_LIST_DD_REQUEST.FAILED;
    message: string;
}
export const getProjectListDDLoadFailedAction = (message: string): IGetProjectListDDLoadFailedAction => ({
    type: IGET_PROJECT_LIST_DD_REQUEST.FAILED,
    message
});
