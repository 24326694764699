import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_PARTS_HEAVY_TRUCK_REQUEST, IPartHeavyTruck, IPartHeavyTruckRequest } from "./getPartsDismantledHeavyTruckConstants";


export interface IGetPartsDismantledHeavyTrucksLoadAction {
    type: IGET_PARTS_HEAVY_TRUCK_REQUEST.REQUEST;
    data: IPartHeavyTruckRequest
}
export const getPartsDismantledHeavyTrucksLoadAction = (data: IPartHeavyTruckRequest): IGetPartsDismantledHeavyTrucksLoadAction => ({
    type: IGET_PARTS_HEAVY_TRUCK_REQUEST.REQUEST,
    data
});
export interface IGetPartsDismantledHeavyTrucksSuccessAction {
    type: IGET_PARTS_HEAVY_TRUCK_REQUEST.SUCCESS;
    list: PartsResponse<IPartHeavyTruck[]>;
}
export const getPartsDismantledHeavyTrucksLoadSuccessAction = (list: PartsResponse<IPartHeavyTruck[]>): IGetPartsDismantledHeavyTrucksSuccessAction => ({
    type: IGET_PARTS_HEAVY_TRUCK_REQUEST.SUCCESS,
    list
});
export interface IGetPartsDismantledHeavyTrucksLoadFailedAction {
    type: IGET_PARTS_HEAVY_TRUCK_REQUEST.FAILED;
    message: string;
}
export const getPartsDismantledHeavyTrucksLoadFailedAction = (message: string): IGetPartsDismantledHeavyTrucksLoadFailedAction => ({
    type: IGET_PARTS_HEAVY_TRUCK_REQUEST.FAILED,
    message
});
