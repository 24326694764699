import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IManufacturer } from "../../getManufacturers/getManufacturerConstants";
import { IADD_MANUFACTURER_REQUEST, IAddManufacturerRequest } from "./addManufacturerConstants";


export interface IAddManufacturerLoadAction {
    type: IADD_MANUFACTURER_REQUEST.REQUEST;
    data: IAddManufacturerRequest
}
export const addManufacturerLoadAction = (data: IAddManufacturerRequest): IAddManufacturerLoadAction => ({
    type: IADD_MANUFACTURER_REQUEST.REQUEST,
    data
});
export interface IAddManufacturerSuccessAction {
    type: IADD_MANUFACTURER_REQUEST.SUCCESS;
    data: PartsResponse<IManufacturer[]>;
}
export const addManufacturerLoadSuccessAction = (data: PartsResponse<IManufacturer[]>): IAddManufacturerSuccessAction => ({
    type: IADD_MANUFACTURER_REQUEST.SUCCESS,
    data
});
export interface IAddManufacturerLoadFailedAction {
    type: IADD_MANUFACTURER_REQUEST.FAILED;
    message: string;
}
export const addManufacturerLoadFailedAction = (message: string): IAddManufacturerLoadFailedAction => ({
    type: IADD_MANUFACTURER_REQUEST.FAILED,
    message
});
