import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_PARTS_PICKUP_TRUCK_REQUEST, IPartPickupTruck } from "./getPartsDismantledPickupTruckConstants";
import { IGetPartsDismantledPickupTrucksLoadAction, IGetPartsDismantledPickupTrucksLoadFailedAction, IGetPartsDismantledPickupTrucksSuccessAction, getPartsDismantledPickupTrucksLoadSuccessAction,getPartsDismantledPickupTrucksLoadFailedAction} from "./getPartsDismantledPickupTruckActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { PARTS_END_POINTS } from "../../endpoints";
import { withJsonContentType } from "../../epicUtils";

export const getPartsDismantledPickupTrucksEpic: Epic = (
    action$: ActionsObservable<IGetPartsDismantledPickupTrucksLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPartsDismantledPickupTrucksSuccessAction | IGetPartsDismantledPickupTrucksLoadFailedAction> =>
    action$.ofType(IGET_PARTS_PICKUP_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IPartPickupTruck[]>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS_DISMANTLED_PICKUP_TRUCKS.GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IPartPickupTruck[]>): IGetPartsDismantledPickupTrucksSuccessAction => {
                            return getPartsDismantledPickupTrucksLoadSuccessAction(response);
                        }),
                        catchError((response: PartsResponse<IPartPickupTruck[]>) => ActionsObservable.of(getPartsDismantledPickupTrucksLoadFailedAction(response.message)))
                    )
            )
        );