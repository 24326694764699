import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RED_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";

interface IPartsUnauthorizedPageProps {

};

const PartsUnauthorizedPageStyles = styled.div`
    text-align: center;

    h1 {
        color: ${RED_COLOR};
        font-size: 50px;
    };

    p {
        font-size: 30px;
    };
`;

export const PartsUnauthorizedPage: React.FC<IPartsUnauthorizedPageProps> = React.memo((props: IPartsUnauthorizedPageProps) => {
    return <PartsUnauthorizedPageStyles>
            <h1>401</h1>
            <p id="heading">Unauthorized</p>
            <p id="text">Sorry you do not have permission to access Parts</p>
            <p id="text">Please contact IT.Developers@sureway.ca to get access</p>            
            <Link id="link" to={ROUTE.INDEX}>Go back to the homepage</Link>
    </PartsUnauthorizedPageStyles>;
});