import React from "react";
// import { Color } from "csstype";
import { HasClass } from "./publicInterfaces";

// to render any SVG in browser with data url
// data:image/svg+xml;utf8,

const DEFAULT_SIZE = 24;


export interface IIconProps extends HasClass {
    width?: number;
    height?: number;
    color?: string;
};

export const WarningIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
    </svg>
);

export const RotateLeft = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M7.11 8.53L5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z" />
    </svg>
);

export const RotateRight = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M15.55 5.55L11 1v3.07C7.06 4.56 4 7.92 4 12s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91V10l4.55-4.45zM19.93 11c-.17-1.39-.72-2.73-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zM13 17.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42l1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z" />
    </svg>
);

export const EditIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
    </svg>
);

export const SearchIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const DeleteIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const SaveIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
    </svg>
);

export const CancelIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M3,3v18h18V3H3z M17,15.59L15.59,17L12,13.41L8.41,17L7,15.59L10.59,12L7,8.41L8.41,7L12,10.59L15.59,7L17,8.41L13.41,12 L17,15.59z" />
    </svg>
);

export const AddIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
    </svg>
);

export const ArrowFastForwardIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M4 18l8.5-6L4 6v12zm9-12v12l8.5-6L13 6z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const ArrowFastRewindIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M11 18V6l-8.5 6 8.5 6zm.5-6l8.5 6V6l-8.5 6z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const ArrowLeftIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
        <path fill="none" d="M0 0h24v24H0z" />
    </svg>
);

export const ArrowRightIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
        <path fill="none" d="M0 0h24v24H0z" />
    </svg>
);

export const ExpandIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
);

export const CollapseIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>
);

export const DollarIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
);

export const OrderIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M17.21 9l-4.38-6.56c-.19-.28-.51-.42-.83-.42-.32 0-.64.14-.83.43L6.79 9H2c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27L23 10c0-.55-.45-1-1-1h-4.79zM9 9l3-4.4L15 9H9zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </svg>
);

export const ProjectIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <polygon points="18,16 16,16 16,15 8,15 8,16 6,16 6,15 2,15 2,20 22,20 22,15 18,15" />
        <path d="M20,8h-3V6c0-1.1-0.9-2-2-2H9C7.9,4,7,4.9,7,6v2H4c-1.1,0-2,0.9-2,2v4h4v-2h2v2h8v-2h2v2h4v-4C22,8.9,21.1,8,20,8z M15,8 H9V6h6V8z" />
    </svg>
);

export const UnitIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5C13 2.12 11.88 1 10.5 1S8 2.12 8 3.5V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5c1.38 0 2.5-1.12 2.5-2.5S21.88 11 20.5 11z" />
    </svg>
);

export const EquipmentIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <rect height="8.48" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.8717 17.6255)" width="3" x="16.34" y="12.87" /><path d="M17.5,10c1.93,0,3.5-1.57,3.5-3.5c0-0.58-0.16-1.12-0.41-1.6l-2.7,2.7L16.4,6.11l2.7-2.7C18.62,3.16,18.08,3,17.5,3 C15.57,3,14,4.57,14,6.5c0,0.41,0.08,0.8,0.21,1.16l-1.85,1.85l-1.78-1.78l0.71-0.71L9.88,5.61L12,3.49 c-1.17-1.17-3.07-1.17-4.24,0L4.22,7.03l1.41,1.41H2.81L2.1,9.15l3.54,3.54l0.71-0.71V9.15l1.41,1.41l0.71-0.71l1.78,1.78 l-7.41,7.41l2.12,2.12L16.34,9.79C16.7,9.92,17.09,10,17.5,10z" />
    </svg>
    
);

export const LogOutIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" />
    </svg>
);

export const DownloadIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
    </svg>
);

export const HomeIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </svg>
);

export const AddPhotoIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M19 7v2.99s-1.99.01-2 0V7h-3s.01-1.99 0-2h3V2h2v3h3v2h-3zm-3 4V8h-3V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8h-3zM5 19l3-4 2 3 3-4 4 5H5z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const ReportIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0,0h24v24H0V0z" fill="none" />
        <path d="M15,3H5C3.9,3,3.01,3.9,3.01,5L3,19c0,1.1,0.89,2,1.99,2H19c1.1,0,2-0.9,2-2V9L15,3z M8,17c-0.55,0-1-0.45-1-1s0.45-1,1-1 s1,0.45,1,1S8.55,17,8,17z M8,13c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S8.55,13,8,13z M8,9C7.45,9,7,8.55,7,8s0.45-1,1-1 s1,0.45,1,1S8.55,9,8,9z M14,10V4.5l5.5,5.5H14z" />
    </svg>
);

export const ManufacturerIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M22,22H2V10l7-3v2l5-2l0,3h3l1-8h3l1,8V22z M12,9.95l-5,2V10l-3,1.32V20h16v-8h-8L12,9.95z M11,18h2v-4h-2V18z M7,18h2v-4 H7V18z M17,14h-2v4h2V14z" />
    </svg>
);


export const ListIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M6 29.75V26.75H42V29.75ZM6 38.05V35.05H42V38.05ZM6 21.4V18.4H42V21.4ZM6 13.05V10.05H42V13.05Z" />
    </svg>
);

export const PickupTruckIcon = React.memo((props: IIconProps): JSX.Element =>
    // <svg xmlns="http://www.w3.org/2000/svg"
    //     width={props.width || DEFAULT_SIZE}
    //     height={props.height || DEFAULT_SIZE}
    //     viewBox="0 0 24 24"
    //     fill={props.color}
    // >
    //     <rect height="8.48" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.8717 17.6255)" width="3" x="16.34" y="12.87" /><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9 1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    // </svg>
    <img src="/img/pickup_truck.svg" width={props.width || DEFAULT_SIZE}
    height={props.height || DEFAULT_SIZE} ></img>
);

export const DozerIcon = React.memo((props: IIconProps): JSX.Element =>
<img src="/img/equipment_icon.png" width={props.width || DEFAULT_SIZE}
height={props.height || DEFAULT_SIZE} ></img>
);

export const ModelIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" /><path d="M1 5h2v14H1zm4 0h2v14H5zm17 0H10c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-1 12H11V7h10v10zm-3.57-4.38l-2 2.57L14 13.47l-2 2.52h8z" />
    </svg>
);

export const ToolIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M22.61 18.99l-9.08-9.08c.93-2.34.45-5.1-1.44-7C9.79.61 6.21.4 3.66 2.26L7.5 6.11 6.08 7.52 2.25 3.69C.39 6.23.6 9.82 2.9 12.11c1.86 1.86 4.57 2.35 6.89 1.48l9.11 9.11c.39.39 1.02.39 1.41 0l2.3-2.3c.4-.38.4-1.01 0-1.41zm-3 1.6l-9.46-9.46c-.61.45-1.29.72-2 .82-1.36.2-2.79-.21-3.83-1.25C3.37 9.76 2.93 8.5 3 7.26l3.09 3.09 4.24-4.24-3.09-3.09c1.24-.07 2.49.37 3.44 1.31 1.08 1.08 1.49 2.57 1.24 3.96-.12.71-.42 1.37-.88 1.96l9.45 9.45-.88.89z" />
    </svg>
);

export const EquipmentTypeIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z" />
    </svg>
);

export const CrossIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);


export const UploadFileIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z" />
    </svg>
);

export const FielIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M14.17,5L19,9.83V19H5V5L14.17,5L14.17,5 M14.17,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V9.83 c0-0.53-0.21-1.04-0.59-1.41l-4.83-4.83C15.21,3.21,14.7,3,14.17,3L14.17,3z M7,15h10v2H7V15z M7,11h10v2H7V11z M7,7h7v2H7V7z" />
    </svg>
);