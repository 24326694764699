import { IPaginationRequest } from "../../../../react/shared/publicInterfaces";
import { IModel } from "../../getModels/getModelConstants";

export enum IADD_MODEL_REQUEST {
    REQUEST = "addModel/ADD_MODEL_REQUEST",
    SUCCESS = "addModel/ADD_MODEL_SUCCESS",
    FAILED = "addModel/ADD_MODEL_FAILED"
};

export interface IAddModelRequest extends IPaginationRequest {
    request: IModel;
    token: string;
};