import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ByIdNumber, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { MEDIA_QUERY_PHONE } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithLessPadding } from "../../../shared/paper";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { IInvoices, IInvoicesRequest } from "../../../../redux/toolRentals/getInvoices/getInvoicesConstants";
import { getInvoices } from "../../../../redux/toolRentals/getInvoices/getInvoicesAccessor";
import { getInvoicesLoadAction } from "../../../../redux/toolRentals/getInvoices/getInvoicesActions";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { undefinedFunction } from "../../../shared/constExports";
import { END_POINTS } from "../../../../redux/endpoints";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ROUTE } from "../../../routes";


interface IInvoicesStoreProps {
    token: Server<ToolRentalsResponse<IToken>>;
    InvoicesList: Server<ToolRentalsResponse<ByIdNumber<IInvoices>>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IInvoicesDispatchProps {
    RequestInvoicesList: (request: IInvoicesRequest) => unknown;
};

interface IInvoicesOwnProps {

};

interface IInvoicesState {
    invoices: ByIdNumber<IInvoices>;
};

const InvoicesStyles = styled(LAPaperWithLessPadding)`
    margin: 40px 15px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .moveRight {
        position: absolute;
        top: 0px;
        right: 10px;
    };
`;

type IInvoicesProps = RouteComponentProps
    & IInvoicesStoreProps
    & IInvoicesDispatchProps
    & IInvoicesOwnProps;

class Invoices extends PureComponent<IInvoicesProps, IInvoicesState> {

    public constructor(props: IInvoicesProps) {
        super(props);
        this.state = {
            invoices: {},
        };
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IInvoicesProps, prevState: IInvoicesState): Promise<void> {
        if (this.props.InvoicesList !== prevProps.InvoicesList) {
            this.callReduxForData();
        }
    };

    public render(): ReactNode {

        const { invoices } = this.state;
        const { InvoicesList } = this.props;

        return (
            <InvoicesStyles>
                <LAGrid spacing={2}>
                    {(InvoicesList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                        <LACenteredLoading />
                    </LAGridItem>}

                    {(InvoicesList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                        <LAErrorBox text="Failed to Load Invoices" />
                    </LAGridItem>}

                    {(InvoicesList.kind === STATUS_ENUM.SUCCEEDED) && <>
                        <LAGridItem xs={12}>
                            <h2 className="text-center">INVOICES</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LADevExtremeGrid
                                export={true}
                                actionWidth={100}
                                searchPanel={true}
                                filterHeader={true}                               
                                removeStyleBtn={true}
                                onClick={undefinedFunction}
                                data={Object.values(invoices)}
                                customBtnLabel="PDF"
                                exportFileName="Invoices"
                                customBtn={this.handlePDFClick}
                                columns={[
                                    { name: "project_Number", caption: "Project Number", type: "string" },
                                    { name: "coordinator", caption: "Coordinator", type: "string", width: 120 },
                                    { name: "po", caption: "PO", type: "string" },
                                    { name: "unit_No", caption: "Unit No", type: "string" },
                                    { name: "class", caption: "Class", type: "string" },
                                    { name: "type", caption: "Type", type: "string" },
                                    { name: "quantity", caption: "Quantity", type: "number", width: 90 },
                                    { name: "picked_Up_By", caption: "Picked Up By", type: "string" },
                                    { name: "invoice", caption: "Invoice", type: "string" },
                                    { name: "rate_Type", caption: "Rate Type", type: "string", width: 75 },
                                    { name: "rate_Period", caption: "Rate Period", type: "decimal" },
                                    { name: "rate_CostPerPeriod", caption: "Rate Cost Per Period", format: "currency", type: "number" },
                                    { name: "rate_Cost", caption: "Rate Cost", type: "number", format: "currency", width: 65 },
                                    { name: "status", caption: "Status", type: "string" },
                                    { name: "invoice_Start", caption: "Invoice Start", type: "date" },
                                    { name: "invoice_End", caption: "Invoice End", type: "date" },
                                    { name: "days", caption: "Days", type: "string", width: 65 },
                                ]}
                            />
                        </LAGridItem>
                    </>}
                </LAGrid>
            </InvoicesStyles>
        );
    }

    private handlePDFClick = (event: any): void => {
        if (hasPayload(this.props.token))
            fetch(END_POINTS.GET_TOOL_RENTALS.INVOICES.DOWNLOAD_INVOICE, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: this.props.token.payload.response.token,
                    request: {
                        invoice: event.row.data.invoice
                    }
                })
            })
                .then(res => {
                    return res.blob();
                })
                .then(async blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = `OrderInvoice-${event.row.data.invoice}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch((res) => console.log(res));
    };

    private callReduxForData = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.toolrentalsaccess === true) {
                if (isNotLoaded(this.props.InvoicesList)) {
                    this.sendRequest();
                };
        
                if (hasPayload(this.props.InvoicesList)) {
                    this.setState({ invoices: this.props.InvoicesList.payload.response });
                }
            } else {
                this.props.history.push(ROUTE.TOOL_RENTALS.UNAUTHORIZED);
            }
        };
    };

    private sendRequest = (): void => {
        if (hasPayload(this.props.token))
            this.props.RequestInvoicesList({
                token: this.props.token.payload.response.token
            });
    };

}

const mapStateToProps = (state: IStore): IInvoicesStoreProps => ({
    token: getToken(state),
    InvoicesList: getInvoices(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IInvoicesDispatchProps => ({
    RequestInvoicesList: (request: IInvoicesRequest): unknown => dispatch(getInvoicesLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);