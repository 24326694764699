import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_UNITS_REQUEST, IUnit, IUnitRequest } from "./getUnitsConstants";


export interface IGetUnitsLoadAction {
    type: IGET_UNITS_REQUEST.REQUEST;
    data: IUnitRequest
}
export const getUnitsLoadAction = (data: IUnitRequest): IGetUnitsLoadAction => ({
    type: IGET_UNITS_REQUEST.REQUEST,
    data
});
export interface IGetUnitsSuccessAction {
    type: IGET_UNITS_REQUEST.SUCCESS;
    list: ToolRentalsResponse<ByIdNumber<IUnit>>;
}
export const getUnitsLoadSuccessAction = (list: ToolRentalsResponse<ByIdNumber<IUnit>>): IGetUnitsSuccessAction => ({
    type: IGET_UNITS_REQUEST.SUCCESS,
    list
});
export interface IGetUnitsLoadFailedAction {
    type: IGET_UNITS_REQUEST.FAILED;
    message: string;
}
export const getUnitsLoadFailedAction = (message: string): IGetUnitsLoadFailedAction => ({
    type: IGET_UNITS_REQUEST.FAILED,
    message
});
