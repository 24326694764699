import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { UnitIcon } from "../shared/icons";

interface IUnitDropDownProps {
    onDrawer?(): void;
};

type IUnitDropDown =
    RouteComponentProps &
    IUnitDropDownProps;

class UnitDropDown extends PureComponent<IUnitDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoUnitPage}>
                <UnitIcon />
                
                <span className="ml-2">
                    Units
                </span>
            </LAMenuItem>
        );
    };

    private gotoUnitPage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.TOOL_RENTALS.UNIT.INDEX);
    };

}

export default UnitDropDown;
