import React from "react";
import { ById, HasClass } from "../../../shared/publicInterfaces";
import { LAIconButton } from "../../../shared/buttons";
import { CancelIcon, EditIcon, SaveIcon } from "../../../shared/icons";
import { LAPaperWithLessPadding } from "../../../shared/paper";
import LATextField from "../../../shared/textField";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IManufacturer } from "../../../../redux/parts/getManufacturers/getManufacturerConstants";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { SECONDARY_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, RED_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import styled from "styled-components";
import LAAutoComplete from "../../../shared/autoComplete";
import { ActiveValues, undefinedFunction } from "../../../shared/constExports";

interface IManufacturerGridProps extends HasClass {
    editId: number | undefined;
    data: IManufacturer[];
    error: ById<IFieldErrorKeyValue>;
    onSave: () => void;
    onEdit: (id: number | undefined) => void;
    onChange: (name: string, value: string) => void;
};

const ManufacturerGridStyles = styled.div`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };
    
    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
`;


export const ManufacturerGrid: React.FC<IManufacturerGridProps> = React.memo((props: IManufacturerGridProps) =>
<ManufacturerGridStyles>
    <TableContainer component={LAPaperWithLessPadding}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow className="header-row">
                    <TableCell className="title" align="center">Make</TableCell>
                    <TableCell className="title" align="center">Active</TableCell>
                    <TableCell className="title" align="center">Edit</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.map((row: IManufacturer, index: number) => (
                    (props.editId && (row.id === props.editId)) ?
                        <EditRow {...props} index={index} error={props.error} value={row} onCancel={props.onEdit} onSave={props.onSave} onChange={props.onChange} />
                        :
                        <Row {...props} index={index} value={row} onEdit={props.onEdit} />
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    </ManufacturerGridStyles>
);


interface IRowProps {
    index: number;
    value: IManufacturer;
    onEdit: (id: number | undefined) => void;
};

const Row: React.FC<IRowProps> = React.memo((props: IRowProps) => {
    const onEdit = () => props.onEdit(props.value.id);

    return <TableRow key={props.value.id} className={props.index % 2 ? "odd-row" : ""}>
        <TableCell component="th" scope="row" align="center">
            {props.value.name}
        </TableCell>
        <TableCell align="center">{props.value.active}</TableCell>
        <TableCell align="center">
            <LAIconButton icon={<EditIcon />} label="Edit" onClick={onEdit} />
        </TableCell>
    </TableRow>
}
);


interface IEditRowProps {
    index: number;
    value: IManufacturer;
    onSave: () => void;
    error: ById<IFieldErrorKeyValue>;
    onCancel: (id: undefined) => void;
    onChange: (name: string, value: string) => void;
};

const EditRow: React.FC<IEditRowProps> = React.memo((props: IEditRowProps) => {

    const onCancel = () => props.onCancel(undefined);
    const err = Object.values(props.error).length > 0 ? true : undefined;
    const onActiveChange = (e: unknown, value: { id: number, name: string }) => props.onChange("active", (value !== null) ? value.name : "");

    return <TableRow key={props.value.id} className={props.index % 2 ? "odd-row" : ""}>
        <TableCell component="th" scope="row" align="center">
            <LATextField errorText={props.error["name"] ? props.error["name"].message : undefined}
                name="name" label="Make" value={props.value.name} onChange={props.onChange}
            />
        </TableCell>
       
        <TableCell align="center">
            <LAAutoComplete
                dropDownPlaceHolder="Active"
                multiple={false}
                getOptionLabel="name"
                filterSelectedOptions={true}
                autoHighlight={true}
                onChange={onActiveChange}
                selectionRemove={undefinedFunction}
                option={ActiveValues}
                errorText={props.error["active"] ? props.error["active"].message : undefined}
                value={ActiveValues.find(x => x.name === props.value.active)}
                defaultValue={ActiveValues.find(x => x.name === props.value.active)}
            />
        </TableCell>
        
        <TableCell align="right">{<LAGrid spacing={1}>
            <LAGridItem xs={6}>
                <LAIconButton disabled={err} icon={<SaveIcon color={err ? LIGHT_GREY_COLOR : GREEN_COLOR} />} label="Save" onClick={props.onSave} />
            </LAGridItem>

            <LAGridItem xs={6}>
                <LAIconButton icon={<CancelIcon color={RED_COLOR} />} label="Cancel" onClick={onCancel} />
            </LAGridItem>
        </LAGrid>}</TableCell>
    </TableRow>
});