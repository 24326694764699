import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_MANUFACTURERS_REQUEST, IManufacturer } from "./getManufacturerConstants";
import { IGetManufacturersLoadAction, IGetManufacturersLoadFailedAction, IGetManufacturersSuccessAction, getManufacturersLoadFailedAction, getManufacturersLoadSuccessAction } from "./getManufacturerActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { PARTS_END_POINTS } from "../../endpoints";
import { withJsonContentType } from "../../epicUtils";

export const getManufacturersEpic: Epic = (
    action$: ActionsObservable<IGetManufacturersLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetManufacturersSuccessAction | IGetManufacturersLoadFailedAction> =>
    action$.ofType(IGET_MANUFACTURERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IManufacturer[]>>(
                    PARTS_END_POINTS.GET_PARTS.MANUFACTURERS.GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IManufacturer[]>): IGetManufacturersSuccessAction => getManufacturersLoadSuccessAction(response)),
                        catchError((response: PartsResponse<IManufacturer[]>) => ActionsObservable.of(getManufacturersLoadFailedAction(response.message)))
                    )
            )
        );