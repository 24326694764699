import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_EQUIPMENT_TYPES_REQUEST, IGetEquipmentType } from "./getEquipmentTypesConstants";
import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetEquipmentTypesLoadAction, IGetEquipmentTypesLoadFailedAction, IGetEquipmentTypesSuccessAction, getEquipmentTypesLoadFailedAction, getEquipmentTypesLoadSuccessAction } from "./getEquipmentTypesActions";

export const getEquipmentTypesEpic: Epic = (
    action$: ActionsObservable<IGetEquipmentTypesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetEquipmentTypesSuccessAction | IGetEquipmentTypesLoadFailedAction> =>
    action$.ofType(IGET_EQUIPMENT_TYPES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<ById<IGetEquipmentType>>>(
                    END_POINTS.SHOP.GET_EQUIPMENT_TYPES,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<ById<IGetEquipmentType>>): IGetEquipmentTypesSuccessAction => {
                            return getEquipmentTypesLoadSuccessAction(response);
                        }),
                        catchError((response: PartsResponse<string>) => ActionsObservable.of(getEquipmentTypesLoadFailedAction(response.message)))
                    )
            )
        );