import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_MANUFACTURER_LIST_DD_REQUEST, IManufacturerListDD } from "./getManufacturerListDDConstants";
import { IAddManufacturerSuccessAction } from "../../add/manufacturer/addManufacturerActions";
import { IADD_MANUFACTURER_REQUEST } from "../../add/manufacturer/addManufacturerConstants";
import { IGetManufacturerListDDLoadAction, IGetManufacturerListDDLoadFailedAction, IGetManufacturerListDDSuccessAction } from "./getManufacturerListDDActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IGetManufacturerListDDLoadAction
    | IGetManufacturerListDDSuccessAction
    | IGetManufacturerListDDLoadFailedAction
    | IAddManufacturerSuccessAction
    | IFlushDataSuccessAction;

export const GetManufacturerListDDReducer = (state: Server<ToolRentalsResponse<IManufacturerListDD[]>> = notLoaded, action: Actions): Server<ToolRentalsResponse<IManufacturerListDD[]>> => {
    switch (action.type) {
        case IGET_MANUFACTURER_LIST_DD_REQUEST.REQUEST:
            return loading;

        case IGET_MANUFACTURER_LIST_DD_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MANUFACTURER_LIST_DD_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_MANUFACTURER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};