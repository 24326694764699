import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_PROJECTS_REQUEST, IProject, IProjectRequest } from "./getProjectsConstants";


export interface IGetProjectsLoadAction {
    type: IGET_PROJECTS_REQUEST.REQUEST;
    data: IProjectRequest
}
export const getProjectsLoadAction = (data: IProjectRequest): IGetProjectsLoadAction => ({
    type: IGET_PROJECTS_REQUEST.REQUEST,
    data
});
export interface IGetProjectsSuccessAction {
    type: IGET_PROJECTS_REQUEST.SUCCESS;
    list: ToolRentalsResponse<ByIdNumber<IProject>>;
}
export const getProjectsLoadSuccessAction = (list: ToolRentalsResponse<ByIdNumber<IProject>>): IGetProjectsSuccessAction => ({
    type: IGET_PROJECTS_REQUEST.SUCCESS,
    list
});
export interface IGetProjectsLoadFailedAction {
    type: IGET_PROJECTS_REQUEST.FAILED;
    message: string;
}
export const getProjectsLoadFailedAction = (message: string): IGetProjectsLoadFailedAction => ({
    type: IGET_PROJECTS_REQUEST.FAILED,
    message
});
