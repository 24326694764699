import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Route, Switch } from "react-router";
import { IStore, IDispatch } from "../redux/reducers";
import { ROUTE } from "./routes";
import Error404Page from "./error404Page";
import Project from "./component/toolRentals/project/project";
import Order from "./component/toolRentals/order/order";
import Unit from "./component/toolRentals/unit/unit";
import Rate from "./component/toolRentals/rate/rate";
import { getToken } from "../redux/getToken/getTokenAccessor";
import { IToken, ITokenRequest } from "../redux/getToken/getTokenConstants";
import { getTokenLoadAction } from "../redux/getToken/getTokenActions";
import { ToolRentalsResponse } from "./shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../redux/server";
import { userName } from "./shared/constExports";
import { LACenteredLoading } from "./shared/loading";
import Report from "./component/toolRentals/report/report";
import ToolRentalHome from "./component/home/toolRentalHome";
import { IValidateShopGroup } from "../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroup } from "../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { validateShopGroupLoadAction } from "../redux/toolRentals/validateShopGroup/validateShopGroupActions";
import { ToolRentalsUnauthorizedPage } from "./component/toolRentals/toolRentalsUnauthroizedPage";
import Invoice from "./component/toolRentals/invoice/invoice";

interface IToolRentalSecuredRoutesStoreProps {
    token: Server<ToolRentalsResponse<IToken>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IToolRentalSecuredRoutesDispatchProps {
    RequestToken: (request: ITokenRequest) => unknown;
    RequestValidateShopGroup: (request: ITokenRequest) => unknown;
};

interface IToolRentalSecuredRoutesState {
    showHeader: boolean;
    loading: boolean;
};

type IToolRentalSecuredRoutesProps =
    RouteComponentProps &
    IToolRentalSecuredRoutesDispatchProps &
    IToolRentalSecuredRoutesStoreProps;

class ToolRentalSecuredRoutes extends Component<IToolRentalSecuredRoutesProps, IToolRentalSecuredRoutesState> {
    public constructor(props: IToolRentalSecuredRoutesProps) {
        super(props);
        this.state = {
            showHeader: true,
            loading: true
        };
    }

    public componentDidMount(): void {
        this.authorizationCheck();
    }

    public componentDidUpdate(prevProps: IToolRentalSecuredRoutesProps): void {
        if (this.props.token !== prevProps.token || this.props.validateShopGroup !== prevProps.validateShopGroup) {
            this.authorizationCheck();
        }
    }

    public render(): ReactNode {
        return (
            this.state.loading ? <LACenteredLoading message="Loading..." /> :
                <>
                    <Switch>
                        <Route exact={true} path={ROUTE.TOOL_RENTALS.INDEX} component={ToolRentalHome} />
                        <Route exact={true} path={ROUTE.TOOL_RENTALS.PROJECT.INDEX} component={Project} />
                        <Route exact={true} path={ROUTE.TOOL_RENTALS.ORDER.INDEX} component={Order} />
                        <Route exact={true} path={ROUTE.TOOL_RENTALS.UNIT.INDEX} component={Unit} />
                        <Route exact={true} path={ROUTE.TOOL_RENTALS.RATE.INDEX} component={Rate} />
                        <Route exact={true} path={ROUTE.TOOL_RENTALS.REPORT.INDEX} component={Report} />
                        <Route exact={true} path={ROUTE.TOOL_RENTALS.INVOICE.INDEX} component={Invoice} />
                        <Route path={ROUTE.TOOL_RENTALS.UNAUTHORIZED} component={ToolRentalsUnauthorizedPage} />
                        <Route render={(): ReactNode => <Error404Page handleShowHeader={this.handleShowHeader} />} />
                    </Switch>
                </>
        );
    }

    private handleShowHeader = (showHeader: boolean): void => {
        this.setState({ showHeader });
    };

    private authorizationCheck = (): void => {
        if (hasPayload(this.props.token)) {
            switch (this.props.validateShopGroup.kind) {
                case STATUS_ENUM.SUCCEEDED:
                    if (this.props.validateShopGroup.payload.message !== "Success" && this.props.validateShopGroup.payload.response === null) {
                        if (this.state.loading === true) {
                            this.setState({ loading: false });
                        }
                        this.props.history.push(ROUTE.TOOL_RENTALS.UNAUTHORIZED);
                    } else {
                        if (this.state.loading) {
                            this.setState({ loading: false });
                            if (this.props.validateShopGroup.payload.response.toolrentalsaccess === false) {
                                this.props.history.push(ROUTE.TOOL_RENTALS.UNAUTHORIZED);
                            }
                        };
                    }
                    break;

                case STATUS_ENUM.NOT_LOADED:
                    this.requestValidateShopGroup();
                    break;

                case STATUS_ENUM.FAILED:
                    if (this.state.loading === true) {
                        this.setState({ loading: false });
                    }
                    this.props.history.push(ROUTE.TOOL_RENTALS.UNAUTHORIZED);
                    break;

                case STATUS_ENUM.LOADING:
                    if (this.state.loading === false) {
                        this.setState({ loading: true });
                    }
                    break;

                default:
                    break;
            };
        };

        if (isNotLoaded(this.props.token)) {
            this.props.RequestToken({ request: { username: userName } });
        };
    };

    private requestValidateShopGroup = (): void => {
        if (isNotLoaded(this.props.validateShopGroup) && hasPayload(this.props.token)){
            this.props.RequestValidateShopGroup({ request: { username: userName }, token: this.props.token.payload.response.token });
        }
    };

}

const mapStateToProps = (state: IStore): IToolRentalSecuredRoutesStoreProps => ({
    token: getToken(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IToolRentalSecuredRoutesDispatchProps => ({
    RequestToken: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    RequestValidateShopGroup: (request: ITokenRequest): unknown => dispatch(validateShopGroupLoadAction(request))
});


export default connect(mapStateToProps, mapDispatchToProps)(ToolRentalSecuredRoutes);
