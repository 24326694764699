import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_EQUIPMENT_TYPE_REQUEST } from "./addEquipmentTypeConstants";
import { IAddEquipmentTypeLoadAction, IAddEquipmentTypeLoadFailedAction, IAddEquipmentTypeSuccessAction, addEquipmentTypeLoadFailedAction, addEquipmentTypeLoadSuccessAction } from "./addEquipmentTypeActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const addEquipmentTypeEpic: Epic = (
    action$: ActionsObservable<IAddEquipmentTypeLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddEquipmentTypeSuccessAction | IAddEquipmentTypeLoadFailedAction> =>
    action$.ofType(IADD_EQUIPMENT_TYPE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.SHOP.ADD_EQUIPMENT_TYPE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<string>): IAddEquipmentTypeSuccessAction | IAddEquipmentTypeLoadFailedAction => {
                            if(response.message === "Success"){
                                return addEquipmentTypeLoadSuccessAction(response);
                            } else {
                                return addEquipmentTypeLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: PartsResponse<string>) => ActionsObservable.of(addEquipmentTypeLoadFailedAction(response.message)))
                    )
            )
        );