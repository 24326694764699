import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IUPDATE_ORDER_REQUEST } from "./updateOrderConstants";
import { IUpdateOrderLoadAction, IUpdateOrderLoadFailedAction, IUpdateOrderSuccessAction } from "./updateOrderActions";
import { IGET_ORDERS_REQUEST } from "../../getOrders/getOrdersConstants";
import { IGetOrdersSuccessAction } from "../../getOrders/getOrdersActions";

type Actions =
    | IUpdateOrderLoadAction
    | IUpdateOrderSuccessAction
    | IUpdateOrderLoadFailedAction
    | IGetOrdersSuccessAction
    | IFlushDataSuccessAction;

export const UpdateOrderReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_ORDER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_ORDER_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_ORDER_REQUEST.FAILED:
            return failed(action.message.response);

        case IGET_ORDERS_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};