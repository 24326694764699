import { ById } from "../../../react/shared/publicInterfaces";

export enum IVALIDATE_SHOP_GROUP_REQUEST {
    REQUEST = "validateShopGroup/VALIDATE_SHOP_GROUP_REQUEST",
    SUCCESS = "validateShopGroup/VALIDATE_SHOP_GROUP_SUCCESS",
    FAILED = "validateShopGroup/VALIDATE_SHOP_GROUP_FAILED"
};

export interface IValidateShopGroup {
  cvipAccess: string;
  adminAccess: string;
  partsaccess: boolean;
  toolrentalsaccess: boolean;
  toolrentalsRoles: string[];
  partsAdminAccess: string;
};

export const ReadOnly = "R";
export const ReadAndWrite = "RW";

export const IRoles: ById<string> = {
  1: "projects",
  2: "units",
  3: "rates",
  4: "invoices"
};