import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IADD_ORDER_REQUEST } from "./addOrderConstants";
import { IAddOrderLoadAction, IAddOrderLoadFailedAction, IAddOrderSuccessAction } from "./addOrderActions";

type Actions =
    | IAddOrderLoadAction
    | IAddOrderSuccessAction
    | IAddOrderLoadFailedAction
    | IFlushDataSuccessAction;

export const AddOrderReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_ORDER_REQUEST.REQUEST:
            return loading;

        case IADD_ORDER_REQUEST.SUCCESS:
            return succeeded("Success");

        case IADD_ORDER_REQUEST.FAILED:
            return failed(action.message.response);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};