import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_MANUFACTURERS_REQUEST, IManufacturer, IManufacturerRequest } from "./getManufacturerConstants";


export interface IGetManufacturersLoadAction {
    type: IGET_MANUFACTURERS_REQUEST.REQUEST;
    data: IManufacturerRequest
}
export const getManufacturersLoadAction = (data: IManufacturerRequest): IGetManufacturersLoadAction => ({
    type: IGET_MANUFACTURERS_REQUEST.REQUEST,
    data
});
export interface IGetManufacturersSuccessAction {
    type: IGET_MANUFACTURERS_REQUEST.SUCCESS;
    list: PartsResponse<IManufacturer[]>;
}
export const getManufacturersLoadSuccessAction = (list: PartsResponse<IManufacturer[]>): IGetManufacturersSuccessAction => ({
    type: IGET_MANUFACTURERS_REQUEST.SUCCESS,
    list
});
export interface IGetManufacturersLoadFailedAction {
    type: IGET_MANUFACTURERS_REQUEST.FAILED;
    message: string;
}
export const getManufacturersLoadFailedAction = (message: string): IGetManufacturersLoadFailedAction => ({
    type: IGET_MANUFACTURERS_REQUEST.FAILED,
    message
});
