import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_CLASS_AND_TYPES_REQUEST } from "./getClassAndTypeConstants";
import { IGetClassAndTypesLoadAction, IGetClassAndTypesLoadFailedAction, IGetClassAndTypesSuccessAction, getClassAndTypesLoadFailedAction, getClassAndTypesLoadSuccessAction } from "./getClassAndTypeActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { IRate } from "../../getRates/getRatesConstants";

export const getClassAndTypesEpic: Epic = (
    action$: ActionsObservable<IGetClassAndTypesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetClassAndTypesSuccessAction | IGetClassAndTypesLoadFailedAction> =>
    action$.ofType(IGET_CLASS_AND_TYPES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<IRate[]>>(
                    END_POINTS.GET_TOOL_RENTALS.RATES.GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<IRate[]>): IGetClassAndTypesSuccessAction => {
                            return getClassAndTypesLoadSuccessAction(response);
                        }),
                        catchError((response: ToolRentalsResponse<IRate[]>) => ActionsObservable.of(getClassAndTypesLoadFailedAction(response.message)))
                    )
            )
        );