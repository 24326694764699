import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IDELETE_DISMANTLED_REQUEST } from "./deleteDismantledConstants";
import { IDeleteDismantledLoadAction, IDeleteDismantledLoadFailedAction, IDeleteDismantledSuccessAction } from "./deleteDismantledActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IDeleteDismantledLoadAction
    | IDeleteDismantledSuccessAction
    | IDeleteDismantledLoadFailedAction
    | IFlushDataSuccessAction;

export const DeleteDismantledReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IDELETE_DISMANTLED_REQUEST.REQUEST:
            return loading;

        case IDELETE_DISMANTLED_REQUEST.SUCCESS:
            return succeeded("Success");

        case IDELETE_DISMANTLED_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};