import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IPartHeavyTruck } from "../../getPartsDismantledHeavyTrucks/getPartsDismantledHeavyTruckConstants";
import { IADD_PART_HEAVY_TRUCK_REQUEST, IAddPartHeavyTruckRequest } from "./addHeavyTruckConstants";


export interface IAddPartHeavyTruckLoadAction {
    type: IADD_PART_HEAVY_TRUCK_REQUEST.REQUEST;
    data: IAddPartHeavyTruckRequest
}
export const addPartHeavyTruckLoadAction = (data: IAddPartHeavyTruckRequest): IAddPartHeavyTruckLoadAction => ({
    type: IADD_PART_HEAVY_TRUCK_REQUEST.REQUEST,
    data
});
export interface IAddPartHeavyTruckSuccessAction {
    type: IADD_PART_HEAVY_TRUCK_REQUEST.SUCCESS;
    data: PartsResponse<IPartHeavyTruck[]>;
}
export const addPartHeavyTruckLoadSuccessAction = (data: PartsResponse<IPartHeavyTruck[]>): IAddPartHeavyTruckSuccessAction => ({
    type: IADD_PART_HEAVY_TRUCK_REQUEST.SUCCESS,
    data
});
export interface IAddPartHeavyTruckLoadFailedAction {
    type: IADD_PART_HEAVY_TRUCK_REQUEST.FAILED;
    message: string;
}
export const addPartHeavyTruckLoadFailedAction = (message: string): IAddPartHeavyTruckLoadFailedAction => ({
    type: IADD_PART_HEAVY_TRUCK_REQUEST.FAILED,
    message
});
