import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { PickupTruckIcon } from "../shared/icons";

interface IPartDismantledHeavyTrucksDropDownProps {
    onDrawer?(): void;
};

type IPartDismantledHeavyTruckDropDown =
    RouteComponentProps &
    IPartDismantledHeavyTrucksDropDownProps;

class DismantledHeavyTruckDropDown extends PureComponent<IPartDismantledHeavyTruckDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoPartDismantledPage}>
                <PickupTruckIcon />
                <span className="ml-2">
                   Dismantled Heavy Trucks
                </span>
            </LAMenuItem>
        );
    };

    private gotoPartDismantledPage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();
            
        this.props.history.push(ROUTE.PARTS.HEAVY_TRUCK.INDEX);
    };

}

export default DismantledHeavyTruckDropDown;
