import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IUPDATE_UNIT_REQUEST } from "./updateUnitConstants";
import { IUpdateUnitLoadAction, IUpdateUnitLoadFailedAction, IUpdateUnitSuccessAction } from "./updateUnitActions";

type Actions =
    | IUpdateUnitLoadAction
    | IUpdateUnitSuccessAction
    | IUpdateUnitLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateUnitReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_UNIT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_UNIT_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_UNIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};