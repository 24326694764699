export enum IGET_REPORT_REQUEST {
    REQUEST = "getReport/GET_REPORT_REQUEST",
    SUCCESS = "getReport/GET_REPORT_SUCCESS",
    FAILED = "getReport/GET_REPORT_FAILED"
};

export interface IReportRequest {
    token: string;
};

export interface IReport {
    id: number;
    unit_No: string;    
    class: string;
    type: string;    
    damages: string;  
    date_Out: string;
    date_Returned: string;
    picked_Up_By: string;
    invoices: string;
    status_Set_Date: string;
    status: string;
    project_Number: string;
    project_Name: string;
    po: string;
    rental_Status: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
    quantity: number;
};