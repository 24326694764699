import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IADD_SHOP_MANUFACTURERS_REQUEST } from "./addShopManufacturesConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";
import { IAddShopManufacturesLoadAction, IAddShopManufacturesLoadFailedAction, IAddShopManufacturesSuccessAction } from "./addShopManufacturesActions";

type Actions =
    | IAddShopManufacturesLoadAction
    | IAddShopManufacturesSuccessAction
    | IAddShopManufacturesLoadFailedAction
    | IFlushDataSuccessAction;

export const AddShopManufacturersReducer = (state: Server<PartsResponse<string>> = notLoaded, action: Actions): Server<PartsResponse<string>> => {
    switch (action.type) {
        case IADD_SHOP_MANUFACTURERS_REQUEST.REQUEST:
            return loading;

        case IADD_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_SHOP_MANUFACTURERS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};