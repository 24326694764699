import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IProject } from "../../getProjects/getProjectsConstants";
import { IADD_PROJECT_REQUEST, IAddProjectRequest } from "./addProjectConstants";


export interface IAddProjectLoadAction {
    type: IADD_PROJECT_REQUEST.REQUEST;
    data: IAddProjectRequest
}
export const addProjectLoadAction = (data: IAddProjectRequest): IAddProjectLoadAction => ({
    type: IADD_PROJECT_REQUEST.REQUEST,
    data
});
export interface IAddProjectSuccessAction {
    type: IADD_PROJECT_REQUEST.SUCCESS;
    data: ToolRentalsResponse<IProject[]>;
}
export const addProjectLoadSuccessAction = (data: ToolRentalsResponse<IProject[]>): IAddProjectSuccessAction => ({
    type: IADD_PROJECT_REQUEST.SUCCESS,
    data
});
export interface IAddProjectLoadFailedAction {
    type: IADD_PROJECT_REQUEST.FAILED;
    message: string;
}
export const addProjectLoadFailedAction = (message: string): IAddProjectLoadFailedAction => ({
    type: IADD_PROJECT_REQUEST.FAILED,
    message
});
