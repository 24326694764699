import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_PART_DISMANTLED_EQUIPMENT_REQUEST } from "./addDismantledEquipmentConstants";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { IPartDismantledEquipment } from "../../getPartsDismantledEquipments/getPartsDismantledEquipmentConstants";
import { addPartDismantledEquipmentLoadFailedAction, addPartDismantledEquipmentLoadSuccessAction, IAddPartDismantledEquipmentLoadAction, IAddPartDismantledEquipmentLoadFailedAction, IAddPartDismantledEquipmentSuccessAction } from "./addDismantledEquipmentActions";

export const addPartDismantledEquipmentEpic: Epic = (
    action$: ActionsObservable<IAddPartDismantledEquipmentLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddPartDismantledEquipmentSuccessAction | IAddPartDismantledEquipmentLoadFailedAction> =>
    action$.ofType(IADD_PART_DISMANTLED_EQUIPMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IPartDismantledEquipment[]>>(
                    PARTS_END_POINTS.GET_PARTS.PARTSDISMANTLEDEQUIPMENTS.ADD,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IPartDismantledEquipment[]>): IAddPartDismantledEquipmentSuccessAction | IAddPartDismantledEquipmentLoadFailedAction => {
                            if(response.message === "Success"){
                                return addPartDismantledEquipmentLoadSuccessAction(response);
                            }
                            return addPartDismantledEquipmentLoadFailedAction(response.message);
                        }),
                        catchError((response: PartsResponse<IPartDismantledEquipment[]>) => ActionsObservable.of(addPartDismantledEquipmentLoadFailedAction(response.message)))
                    )
            )
        );