import { IPaginationRequest } from "../../../../react/shared/publicInterfaces";
import { IAddUpdatePartPickupTruck } from "../../update/pickupTruck/updatePickupTruckConstants";

export enum IADD_PART_PICKUP_TRUCK_REQUEST {
    REQUEST = "addPart/ADD_PART_PICKUP_TRUCK_REQUEST",
    SUCCESS = "addPart/ADD_PART_PICKUP_TRUCK_SUCCESS",
    FAILED = "addPart/ADD_PART_PICKUP_TRUCK_FAILED"
};

export interface IAddPartPickupTruckRequest extends IPaginationRequest {
    request: IAddUpdatePartPickupTruck;
    token: string;
};