import { IUPDATE_PART_PICKUP_TRUCK_REQUEST, IUpdatePartPickupTruckRequest, IAddUpdatePartPickupTruck } from "./updatePickupTruckConstants";


export interface IUpdatePartPickupTruckLoadAction {
    type: IUPDATE_PART_PICKUP_TRUCK_REQUEST.REQUEST;
    data: IUpdatePartPickupTruckRequest
}
export const updatePartPickupTruckLoadAction = (data: IUpdatePartPickupTruckRequest): IUpdatePartPickupTruckLoadAction => ({
    type: IUPDATE_PART_PICKUP_TRUCK_REQUEST.REQUEST,
    data
});
export interface IUpdatePartPickupTruckSuccessAction {
    type: IUPDATE_PART_PICKUP_TRUCK_REQUEST.SUCCESS;
    data: IAddUpdatePartPickupTruck;
}
export const updatePartPickupTruckLoadSuccessAction = (data: IAddUpdatePartPickupTruck): IUpdatePartPickupTruckSuccessAction => ({
    type: IUPDATE_PART_PICKUP_TRUCK_REQUEST.SUCCESS,
    data
});
export interface IUpdatePartPickupTruckLoadFailedAction {
    type: IUPDATE_PART_PICKUP_TRUCK_REQUEST.FAILED;
    message: string;
}
export const updatePartPickupTruckLoadFailedAction = (message: string): IUpdatePartPickupTruckLoadFailedAction => ({
    type: IUPDATE_PART_PICKUP_TRUCK_REQUEST.FAILED,
    message
});
