import { IPaginationRequest } from "../../../react/shared/publicInterfaces";

export enum IGET_MANUFACTURERS_REQUEST {
    REQUEST = "getManufacturers/GET_MANUFACTURERS_REQUEST",
    SUCCESS = "getManufacturers/GET_MANUFACTURERS_SUCCESS",
    FAILED = "getManufacturers/GET_MANUFACTURERS_FAILED"
};

export interface IManufacturerRequest extends IPaginationRequest {
    request: {};
    token: string;    
};

export interface IManufacturer {
    id: number;
    name: string;
    active: string;    
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
};