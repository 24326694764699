import { IPart } from "../../getParts/getPartConstants";
import { IUPDATE_PART_REQUEST, IUpdatePartRequest } from "./updatePartConstants";


export interface IUpdatePartLoadAction {
    type: IUPDATE_PART_REQUEST.REQUEST;
    data: IUpdatePartRequest
}
export const updatePartLoadAction = (data: IUpdatePartRequest): IUpdatePartLoadAction => ({
    type: IUPDATE_PART_REQUEST.REQUEST,
    data
});
export interface IUpdatePartSuccessAction {
    type: IUPDATE_PART_REQUEST.SUCCESS;
    data: IPart;
}
export const updatePartLoadSuccessAction = (data: IPart): IUpdatePartSuccessAction => ({
    type: IUPDATE_PART_REQUEST.SUCCESS,
    data
});
export interface IUpdatePartLoadFailedAction {
    type: IUPDATE_PART_REQUEST.FAILED;
    message: string;
}
export const updatePartLoadFailedAction = (message: string): IUpdatePartLoadFailedAction => ({
    type: IUPDATE_PART_REQUEST.FAILED,
    message
});
