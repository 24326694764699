import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_MODEL_REQUEST } from "./updateModelConstants";
import { IUpdateModelLoadAction, IUpdateModelLoadFailedAction, IUpdateModelSuccessAction } from "./updateModelActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IUpdateModelLoadAction
    | IUpdateModelSuccessAction
    | IUpdateModelLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateModelReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_MODEL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_MODEL_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_MODEL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};