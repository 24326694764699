import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LABluePaper, LAGreenPaper, LAPaperWithLessPadding, LAPaperWithPadding, LARedPaper, LAYellowPaper } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server, STATUS, STATUS_ENUM } from "../../../../redux/server";
import { ById, PartsResponse, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { undefinedFunction, userName } from "../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import { WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import PageSpacing from "../../../shared/pageSpacing";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { CertificateTypes, DynamicColumns, equipmentStatusDropdown, IShopEquipment, IShopEquipmentFile, IShopEquipmentRequest } from "../../../../redux/shop/getEquipments/getEquipmentConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { getShopEquipmentsLoadAction } from "../../../../redux/shop/getEquipments/getEquipmentActions";
import { IAddShopEquipmentRequest } from "../../../../redux/shop/addEquipments/addShopEquipmentsConstants";
import { IUpdateShopEquipmentRequest } from "../../../../redux/shop/updateEquipments/updateShopEquipmentsConstants";
import { addShopEquipmentLoadAction } from "../../../../redux/shop/addEquipments/addShopEquipmentsActions";
import { updateShopEquipmentLoadAction } from "../../../../redux/shop/updateEquipments/updateShopEquipmentsActions";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getShopEquipments } from "../../../../redux/shop/getEquipments/getEquipmentAccessor";
import { addShopEquipment } from "../../../../redux/shop/addEquipments/addShopEquipmentsAccessor";
import { updateShopEquipment } from "../../../../redux/shop/updateEquipments/updateShopEquipmentsAccessor";
import { getLookups } from "../../../../redux/shop/getLookups/getLookupsAccessor";
import { IShopLookupOwners, IShopLookups, IShopLookupsRequest } from "../../../../redux/shop/getLookups/getLookupsConstants";
import { getLookupsLoadAction } from "../../../../redux/shop/getLookups/getLookupsActions";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import LADatePicker from "../../../shared/datePicker";
import LADeleteConfirm from "../../../shared/deleteConfirmationBox";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup, ReadAndWrite, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../../shared/roleBaseAccessForShop";
import LAErrorBox from "../../../shared/errorBox";
import { LACenteredLoading } from "../../../shared/loading";
import { getShopEquipmentColumnsLoadAction } from "../../../../redux/shop/getShopEquipmentColumns/getShopEquipmentColumnsActions";
import { IGetShopEquipmentColumn, IGetShopEquipmentColumnRequest } from "../../../../redux/shop/getShopEquipmentColumns/getShopEquipmentColumnsConstants";
import { getShopEquipmentColumns } from "../../../../redux/shop/getShopEquipmentColumns/getShopEquipmentColumnsAccessor";
import { IGetEquipmentHistoryColumn, IGetEquipmentHistoryColumnRequest } from "../../../../redux/shop/getEquipmentHistoryColumn/getEquipmentHistoryColumnConstants";
import { LADropZone } from "../../../shared/dropZone";
import LALinkButton from "../../../shared/linkButton";
import { END_POINTS } from "../../../../redux/endpoints";
import { getEquipmentHistoryColumnsLoadAction } from "../../../../redux/shop/getEquipmentHistoryColumn/getEquipmentHistoryColumnActions";
import { getEquipmentHistoryColumns } from "../../../../redux/shop/getEquipmentHistoryColumn/getEquipmentHistoryColumnAccessor";
import { HistoryPopup } from "./historyPopup";
import LADropDownForOwners from "./equipmentOwnerDropdown";
import { LACheckBox } from "../../../shared/checkBox";
import { CertificateHistoryPopup } from "./certificateHistoryPopup";

const requiredFields = ["equipment", "equipment_Type", "unit_Number", "owner", "model", "make", "serial_Number"];
const serviceHoursList = ["100", "250", "500", "1500", "3000", "Schedule A", "Schedule B", "Schedule D", "Annual Service"];

interface IShopEquipmentStoreProps {
    getToken: Server<PartsResponse<IToken>>;
    addShopEquipment: Server<PartsResponse<string>>;
    updateShopEquipment: Server<PartsResponse<string>>;
    getDropDownData: Server<PartsResponse<IShopLookups>>;
    getEquipments: Server<PartsResponse<ById<IShopEquipment>>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
    getShopEquipmentColumnsStatus: Server<PartsResponse<IGetShopEquipmentColumn>>;
    getEquipmentHistoryColumns: Server<PartsResponse<IGetEquipmentHistoryColumn[]>>;
};

interface IShopEquipmentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getShopEquipmentsRequest: (data: IShopEquipmentRequest) => unknown;
    addEquipmentRequest: (data: IAddShopEquipmentRequest) => unknown;
    updateEquipmentRequest: (data: IUpdateShopEquipmentRequest) => unknown;
    getDropDownDataRequest: (data: IShopLookupsRequest) => unknown;
    getShopEquipmentColumnsRequest: (data: IGetShopEquipmentColumnRequest) => unknown;
    getEquipmentHistoryColumnsRequest: (data: IGetEquipmentHistoryColumnRequest) => unknown;
};


interface IShopEquipmentOwnProps {

};

interface IShopEquipmentState {
    column: string;
    historyOpen: boolean;
    back: true | undefined;
    driverChanged: boolean;
    equipment: IShopEquipment;
    certificateHistory: boolean;
    backStatus: true | undefined;
    attachments: IShopEquipmentFile[];
    errors: ById<IFieldErrorKeyValue>;
    certError: ById<ById<IFieldErrorKeyValue>>;
};

const ShopEquipmentStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .required-text {
        color: ${WARNING_COLOR};
    };
`;

type IShopEquipmentProps = RouteComponentProps
    & IShopEquipmentStoreProps
    & IShopEquipmentDispatchProps
    & IShopEquipmentOwnProps;

class ShopEquipment extends PureComponent<IShopEquipmentProps, IShopEquipmentState> {

    public constructor(props: IShopEquipmentProps) {
        super(props);
        this.state = {
            errors: {
                "equipment": { key: "equipment", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "equipment_Type": { key: "equipment_Type", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "unit_Number": { key: "unit_Number", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "owner": { key: "owner", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "model": { key: "model", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "make": { key: "make", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            certError: {},
            back: undefined,
            attachments: [],
            driverChanged: false,
            backStatus: undefined,
            column: "",
            historyOpen: false,
            certificateHistory: false,
            equipment: {
                id: 0,
                equipment: "",
                model_ID: 0,
                make: "",
                model: "",
                owner: "",
                files: [],
                created_By: userName,
                modified_By: userName,
                service_Hours: "",
                created: "",
                modified: "",
                certificates: []
            }
        };
    }

    public componentDidMount(): void {
        //this.callServer();
        window.location.href = "https://apps.sureway.ca/field/equipments";
    };

    public componentDidUpdate(prevProps: IShopEquipmentProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addShopEquipment !== prevProps.addShopEquipment) {
                if (isSucceeded(this.props.addShopEquipment)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateShopEquipment !== prevProps.updateShopEquipment) {
                if (isSucceeded(this.props.updateShopEquipment)) {
                    this.handleCancel();
                };
            };
        }
    };


    public render(): ReactNode {

        const { equipment, errors, back, attachments, driverChanged, historyOpen, column, certError, certificateHistory } = this.state;
        const { updateShopEquipment, addShopEquipment, getDropDownData, validateShopGroup, getEquipments, getShopEquipmentColumnsStatus, getEquipmentHistoryColumns, getToken } = this.props;
        const dropDown = hasPayload(getDropDownData) ? getDropDownData.payload.response : { manufacturers: [], models: [], equipment_Types: [], owners: [] };
        const equipTypes = hasPayload(getDropDownData) ? convertList(dropDown.equipment_Types ?? []) : [];
        const equipColumnsCheck = hasPayload(getShopEquipmentColumnsStatus) ? getShopEquipmentColumnsStatus.payload.response.mapped_Columns.find(x => x.equipment_Type === equipment.equipment_Type) : undefined;
        const equipColumns = equipColumnsCheck ? equipColumnsCheck.column_IDs.split(",") : [];
        const serviceHours = equipment.service_Hours ? equipment.service_Hours.split(",") : [];
        const onDate = (name: string, v: string) => this.onChange(name, new Date(v).toISOString());
        const onOwner = (e: unknown, v: IShopLookupOwners): void => this.handleDropDownChange(e, v !== null ? v.company : "", "owner");
        const getRole = hasPayload(validateShopGroup) ? validateShopGroup.payload.response.adminAccess : "";
        const getCVIPRole = hasPayload(validateShopGroup) ? validateShopGroup.payload.response.cvipAccess : "";
        const readOnly = getRole === ReadOnly ? true : undefined;
        const CVIPReadOnly = getCVIPRole === ReadOnly ? true : undefined;
        const onSOCTChange = (check: boolean): void => this.handleSOCTCheck(check);
        const historyData = hasPayload(getEquipmentHistoryColumns) ? getEquipmentHistoryColumns.payload.response : [];
        const driverHistory = (): void => this.callColumnHistory("driver");
        const ownerHistory = (): void => this.callColumnHistory("owner");
        const plateHistory = (): void => this.callColumnHistory("plate");
        const detailsHistory = (): void => this.callColumnHistory("details");
        const serialHistory = (): void => this.callColumnHistory("serial_Number");
        const unitHistory = (): void => this.callColumnHistory("unit_Number");
        const certificateHistoryClick = (): void => this.callColumnHistory("certificate");

        return (
            <PageSpacing title="Sureway - Equipment" description="Sureway - Equipment" fixedSpaceOnSmallerScreens={true}>
                <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>
                    {getEquipments.kind === STATUS.LOADING && <LACenteredLoading message="Loading ... Please wait" />}
                    {getEquipments.kind === STATUS.FAILED && <LAErrorBox text="Failed to load data... Please try again" />}
                    {getEquipments.kind === STATUS_ENUM.SUCCEEDED && <>
                        <ShopEquipmentStyles>

                            <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                            <h2 className="text-center">{equipment.id > 0 ? "VIEW/UPDATE " : "ADD "} EQUIPMENT</h2>
                            <hr />

                            <LAPaperWithPadding>
                                <LAGrid spacing={2}>

                                    <LAGridItem xs={12}>
                                        <LAGreenPaper>

                                            <LAGrid spacing={1}>

                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>DETAILS</h3>
                                                    <hr />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        multiple={false}
                                                        autoHighlight={true}
                                                        option={equipTypes}
                                                        name="equipment_Type"
                                                        filterSelectedOptions={true}
                                                        getOptionLabel="name"
                                                        disabled={readOnly}
                                                        selectionRemove={undefinedFunction}
                                                        onChange={this.handleDropDownChange}
                                                        dropDownPlaceHolder="Equipment Type"
                                                        errorText={errors["equipment_Type"] ? errors["equipment_Type"].message : undefined}
                                                        value={(equipTypes && equipment.equipment_Type) ? equipTypes.find(x => x.name === equipment.equipment_Type) : ""}
                                                        defaultValue={(equipTypes && equipment.equipment_Type) ? equipTypes.find(x => x.name === equipment.equipment_Type) : ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Equipment"
                                                        fullWidth={true}
                                                        name="equipment"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.equipment}
                                                        errorText={errors["equipment"] ? errors["equipment"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Unit Number"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="unit_Number"
                                                        onChange={this.onChange}
                                                        value={equipment.unit_Number ?? ""}
                                                        errorText={errors["unit_Number"] ? errors["unit_Number"].message : undefined}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Unit History"
                                                        onClick={unitHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LADropDownForOwners
                                                        dropDownPlaceHolder="Owner"
                                                        option={dropDown.owners ?? []}
                                                        defaultValue={dropDown.owners.find(q => q.company === equipment.owner) ?? ""}
                                                        value={dropDown.owners.find(q => q.company === equipment.owner) ?? ""}
                                                        getOptionLabel="company"
                                                        size="small"
                                                        filterSelectedOptions={true}
                                                        autoHighlight={true}
                                                        disabled={readOnly}
                                                        onChange={onOwner}
                                                        errorText={errors["owner"] ? errors["owner"].message : undefined}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Owner History"
                                                        onClick={ownerHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        multiple={false}
                                                        autoHighlight={true}
                                                        name="make"
                                                        filterSelectedOptions={true}
                                                        option={dropDown.manufacturers}
                                                        getOptionLabel="manufacturer_Name"
                                                        disabled={readOnly}
                                                        selectionRemove={undefinedFunction}
                                                        onChange={this.handleDropDownChange}
                                                        dropDownPlaceHolder="Make"
                                                        errorText={errors["make"] ? errors["make"].message : undefined}
                                                        value={(hasPayload(getDropDownData) && equipment.make) ? dropDown.manufacturers.find(x => x.manufacturer_Name === equipment.make) : ""}
                                                        defaultValue={(hasPayload(getDropDownData) && equipment.make) ? dropDown.manufacturers.find(x => x.manufacturer_Name === equipment.make) : ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Model"
                                                        fullWidth={true}
                                                        name="model"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.model ?? ""}
                                                        errorText={errors["model"] ? errors["model"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Year"
                                                        fullWidth={true}
                                                        name="year"
                                                        disabled={readOnly}
                                                        onChange={this.onYearChange}
                                                        value={equipment.year ?? ""}
                                                        errorText={errors["year"] ? errors["year"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Serial Number"
                                                        fullWidth={true}
                                                        name="serial_Number"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.serial_Number ?? ""}
                                                        errorText={errors["serial_Number"] ? errors["serial_Number"].message : undefined}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Serial History"
                                                        onClick={serialHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        freeText={true}
                                                        multiple={false}
                                                        autoHighlight={true}
                                                        option={equipmentStatusDropdown}
                                                        name="status"
                                                        filterSelectedOptions={true}
                                                        getOptionLabel="name"
                                                        disabled={readOnly}
                                                        selectionRemove={undefinedFunction}
                                                        onChange={this.handleDropDownValue}
                                                        dropDownPlaceHolder="Status"
                                                        value={equipment.status ? equipmentStatusDropdown.find(x => x.name === equipment.status) : ""}
                                                        defaultValue={equipment.status ? equipmentStatusDropdown.find(x => x.name === equipment.status) : ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Current Hours"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="current_Hours"
                                                        onChange={this.onChange}
                                                        value={equipment.current_Hours ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4} className="text-center">
                                                    <strong>Purchase Date</strong>
                                                    <br />
                                                    <LADatePicker disable={readOnly} value={equipment.purchase_Date ?? ""} onChange={(date) => onDate("purchase_Date", date)} />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Purchase From"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="purchase_From"
                                                        onChange={this.onChange}
                                                        value={equipment.purchase_From ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Purchase Meter"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="purchase_Meter"
                                                        onChange={this.onChange}
                                                        value={equipment.purchase_Meter ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Plate"
                                                        fullWidth={true}
                                                        name="plate"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.plate ?? ""}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Plate History"
                                                        onClick={plateHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Details / Attachments"
                                                        fullWidth={true}
                                                        name="details"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.details ?? ""}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Details History"
                                                        onClick={detailsHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Comments"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="comments"
                                                        onChange={this.onChange}
                                                        value={equipment.comments ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Equipment Details"
                                                        fullWidth={true}
                                                        name="equipment_Details"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.equipment_Details ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Location"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="location"
                                                        onChange={this.onChange}
                                                        value={equipment.location ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LACheckBox
                                                        label="Add Unit to SOCT"
                                                        onChange={onSOCTChange}
                                                        disabled={CVIPReadOnly}
                                                        value={equipment.add_To_SOCT === "Yes" ? true : false}
                                                    />
                                                </LAGridItem>

                                            </LAGrid>

                                        </LAGreenPaper>
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LAYellowPaper>
                                            <LAGrid spacing={1}>

                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>DRIVER INFORMATION</h3>
                                                    <hr />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Driver"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="driver"
                                                        onChange={this.onDriverChange}
                                                        value={equipment.driver ?? ""}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Driver History"
                                                        onClick={driverHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4} className="text-center">
                                                    <strong>Driver Start Date</strong>
                                                    {<span className="required-text">{errors["driver_Start_Date"] ? errors["driver_Start_Date"].message : ""}</span>}
                                                    <br />
                                                    <LADatePicker disable={readOnly} value={equipment.driver_Start_Date ?? ""} onChange={(date) => onDate("driver_Start_Date", date)} />
                                                </LAGridItem>

                                                <LAGridItem xs={4} className="text-center">
                                                    <strong>Driver End Date</strong>
                                                    <br />
                                                    <LADatePicker disable={readOnly} value={equipment.driver_End_Date ?? ""} onChange={(date) => onDate("driver_End_Date", date)} />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Driver Owned By"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="driver_Owned_By"
                                                        onChange={this.onChange}
                                                        value={equipment.driver_Owned_By ?? ""}
                                                        errorText={errors["driver_Owned_By"] ? errors["driver_Owned_By"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Driver Comments"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="driver_Comments"
                                                        onChange={this.onChange}
                                                        value={equipment.driver_Comments ?? ""}
                                                        errorText={errors["driver_Comments"] ? errors["driver_Comments"].message : undefined}
                                                    />
                                                </LAGridItem>

                                            </LAGrid>
                                        </LAYellowPaper>
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LAGreenPaper>
                                            <LAGrid spacing={1}>

                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>SERVICE</h3>
                                                    <hr />
                                                </LAGridItem>

                                                {serviceHoursList.map((q, index) => {
                                                    const onCheckBox = (): void => this.handleServiceHours(q);
                                                    return (
                                                        <LAGridItem xs={4} key={index}>
                                                            <LACheckBox
                                                                label={q}
                                                                onChange={onCheckBox}
                                                                disabled={CVIPReadOnly}
                                                                value={serviceHours.includes(q)}
                                                            />
                                                        </LAGridItem>
                                                    );
                                                })}

                                            </LAGrid>
                                        </LAGreenPaper>
                                    </LAGridItem>

                                    {equipColumns.length > 0 && <LAGridItem xs={12}>
                                        <LARedPaper>

                                            <LAGrid spacing={1}>

                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>OTHER INFORMATION</h3>
                                                    <hr />
                                                </LAGridItem>

                                                {DynamicColumns.sort(this.sortColumns).map((x, index) => {
                                                    const val: any = this.state.equipment;

                                                    if (equipColumns && equipColumns.includes(x.id.toString())) {
                                                        if (x.type === "date") {
                                                            return (
                                                                <LAGridItem xs={4} className="text-center">
                                                                    <strong>{x.label}</strong>
                                                                    <br />
                                                                    <LADatePicker disable={readOnly} value={val[x.name] ?? ""} onChange={(date) => onDate(x.name, date)} />
                                                                </LAGridItem>
                                                            );
                                                        } else if (x.type === "string") {
                                                            return (
                                                                <LAGridItem xs={4} key={index}>
                                                                    <LATextField
                                                                        variant="outlined"
                                                                        label={x.label}
                                                                        fullWidth={true}
                                                                        disabled={readOnly}
                                                                        name={x.name}
                                                                        onChange={this.onChange}
                                                                        value={val[x.name] ?? ""}
                                                                    />
                                                                </LAGridItem>
                                                            );
                                                        } else if (x.type === "dropdown" && x.dropdownData) {
                                                            return (
                                                                <LAGridItem xs={4} key={index}>
                                                                    <LAAutoComplete
                                                                        freeText={true}
                                                                        multiple={false}
                                                                        autoHighlight={true}
                                                                        option={x.dropdownData}
                                                                        name={x.name}
                                                                        filterSelectedOptions={true}
                                                                        getOptionLabel="name"
                                                                        disabled={readOnly}
                                                                        selectionRemove={undefinedFunction}
                                                                        onChange={this.handleDropDownValue}
                                                                        dropDownPlaceHolder={x.label}
                                                                        value={val[x.name] ? x.dropdownData.find(q => q.name === val[x.name]) : ""}
                                                                        defaultValue={val[x.name] ? x.dropdownData.find(x => x.name === val[x.name]) : ""}
                                                                    />
                                                                </LAGridItem>
                                                            )
                                                        } else if (x.type === "array") {
                                                            return (
                                                                <LAGridItem xs={12} key={index}>
                                                                    <LAPaperWithLessPadding>
                                                                        <LAGrid>
                                                                            {CertificateTypes.map((q) => {
                                                                                const findIndex = equipment.certificates.findIndex(a => a.type === q.name);
                                                                                const onCheckBox = (check: boolean): void => this.handleCertCheck("type", check, q.name);
                                                                                const dueDate = (date: string): void => this.handleCertificate("due_Date", date, q.name);
                                                                                const onCertificate = (name: string, value: string): void => this.handleCertificate("certificate_No", value, q.name);
                                                                                return (
                                                                                    <LAGridItem xs={12} key={q.id}>
                                                                                        <LAGrid>

                                                                                            <LAGridItem xs={2}>
                                                                                                <LACheckBox
                                                                                                    label={q.name}
                                                                                                    disabled={CVIPReadOnly}
                                                                                                    onChange={onCheckBox}
                                                                                                    value={findIndex >= 0 ? true : false}
                                                                                                />
                                                                                            </LAGridItem>

                                                                                            <LAGridItem xs={2}>
                                                                                                <strong>Due Date</strong>

                                                                                                {certError[q.name] && certError[q.name]["due_Date"] ?
                                                                                                    <span className="required-text ml-2">Required</span>
                                                                                                    : <></>}

                                                                                                <br />
                                                                                                <LADatePicker
                                                                                                    disable={CVIPReadOnly}
                                                                                                    onChange={dueDate}
                                                                                                    value={findIndex >= 0 ? equipment.certificates[findIndex].due_Date : ""}
                                                                                                />
                                                                                            </LAGridItem>

                                                                                            <LAGridItem xs={2}>
                                                                                                <LATextField
                                                                                                    name={q.name}
                                                                                                    fullWidth={true}
                                                                                                    variant="outlined"
                                                                                                    disabled={CVIPReadOnly}
                                                                                                    label="Certificate #"
                                                                                                    onChange={onCertificate}
                                                                                                    value={findIndex >= 0 ? equipment.certificates[findIndex].certificate_No : ""}
                                                                                                    errorText={certError[q.name] && certError[q.name]["certificate_No"] ? certError[q.name]["certificate_No"].message : undefined}
                                                                                                />
                                                                                            </LAGridItem>

                                                                                        </LAGrid>
                                                                                    </LAGridItem>
                                                                                );
                                                                            })}

                                                                            <LAGridItem xs={12}>
                                                                                <LALinkButton
                                                                                    label="Certificate History"
                                                                                    onClick={certificateHistoryClick}
                                                                                />
                                                                            </LAGridItem>

                                                                        </LAGrid>
                                                                    </LAPaperWithLessPadding>
                                                                </LAGridItem>
                                                            )
                                                        }
                                                    } else {
                                                        return <></>
                                                    }
                                                    return <></>
                                                })}
                                            </LAGrid>

                                        </LARedPaper>
                                    </LAGridItem>}

                                    <LAGridItem xs={12} className="text-center">
                                        <LABluePaper>

                                            <LAGrid>
                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>ATTACHMENTS</h3>
                                                    <hr />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    <LADropZone onFileDrop={this.handleAttachment} />
                                                </LAGridItem>

                                                {attachments.length > 0 && attachments.map((x, index) => {
                                                    const onDownload = (): void => this.handleDownload(x);
                                                    const onRemove = (): void => this.handleFileRemove(index);
                                                    return <LAGridItem key={index} xs={4}>
                                                        {x.name && x.name.length > 0
                                                            && <LALinkButton className="pl-5" label={x.name}
                                                                onClick={onDownload} />}
                                                        {x.name && <LAIconButton
                                                            label="Remove"
                                                            onClick={onRemove}
                                                            icon={<DeleteIcon />}
                                                        />}
                                                    </LAGridItem>
                                                })}
                                            </LAGrid>
                                        </LABluePaper>
                                    </LAGridItem>

                                    {((Object.values(errors).length > 0) || (Object.values(certError).length > 0)) && driverChanged && <LAGridItem xs={12}>
                                        <LAErrorBox text="Fix the error's first to save the form successfully..." />
                                    </LAGridItem>}

                                    <LAGridItem xs={12}>
                                        <LASaveAndCancelButton
                                            onSave={this.onSave}
                                            onCancel={this.handleCancel}
                                            disableSave={((Object.values(errors).length > 0) || (Object.values(certError).length > 0)) ? true : CVIPReadOnly}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAPaperWithPadding>

                            <RequestStatus requestStatus={addShopEquipment.kind} successMessage="Equipment successfully saved" />
                            <RequestStatus requestStatus={updateShopEquipment.kind} successMessage="Equipment successfully updated" />

                        </ShopEquipmentStyles>

                        <LADeleteConfirm
                            cancelClick={this.cancelBack}
                            confirmClick={this.confirmBack}
                            open={(back !== undefined) ? true : false}
                            text="All unsaved changes will be lost? Are you sure to leave?"
                        />
                    </>}
                </RoleBasedAccessForShop>

                <HistoryPopup
                    column={column}
                    open={historyOpen}
                    data={historyData}
                    onCancel={this.historyPopupClose}
                    status={getEquipmentHistoryColumns.kind}
                />

                {certificateHistory && <CertificateHistoryPopup
                    token={getToken}
                    id={equipment.id}
                    open={certificateHistory}
                    onCancel={this.historyPopupClose}
                />}
            </PageSpacing>
        );
    }

    private handleCertCheck = (name: string, check: boolean, type: string): void => {
        const iS = { ...this.state.equipment };
        const errors = this.state.certError;
        const index = iS.certificates.findIndex(x => x.type === type);



        if (index >= 0) {
            iS.certificates.splice(index, 1);
            delete errors[type];
        } else {
            iS.certificates.push({
                type: type,
                certificate_No: "",
                due_Date: "",
                equipment_List_ID: iS.id.toString()
            });

            errors[type] = {};
            errors[type]["certificate_No"] = { key: "certificate_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            errors[type]["due_Date"] = { key: "due_Date", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        };

        this.setState({ equipment: iS, certError: errors });
    };

    private handleCertificate = (name: string, value: string, type: string): void => {
        const iS = { ...this.state.equipment };
        const errors = this.state.certError;
        const index = iS.certificates.findIndex(x => x.type === type);
        if (name === "due_Date")
            console.log(new Date(value).toLocaleDateString('us'));
        if (index >= 0) {
            const arrays = [...iS.certificates];
            let record = { ...arrays[index] };

            record = { ...record, [name]: name === "due_Date" ? new Date(value).toLocaleDateString('us') : value };
            arrays[index] = record;
            iS.certificates = arrays;

            if (errors[type]) {
                if (errors[type][name])
                    delete errors[type][name];
            };

            if (errors[type] && (Object.values(errors[type]).length === 0))
                delete errors[type];

        } else {
            iS.certificates.push({
                type: type,
                certificate_No: name === "certificate_No" ? value : "",
                due_Date: name === "due_Date" ? new Date(value).toLocaleDateString('us') : "",
                equipment_List_ID: iS.id.toString()
            });

            errors[type] = {};
            if (name === "certificate_No") {
                errors[type]["due_Date"] = { key: "due_Date", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            } else if (name === "due_Date") {
                errors[type]["certificate_No"] = { key: "certificate_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            }
        };

        this.setState({ equipment: iS, certError: errors });
    };

    private handleServiceHours = (value: string): void => {
        let iS = { ...this.state.equipment };
        let serviceHours = iS.service_Hours ? iS.service_Hours.split(",") : [];
        const index = serviceHours.findIndex(x => x === value);

        if (index >= 0) {
            serviceHours.splice(index, 1);
        } else {
            serviceHours.push(value);
        };

        iS.service_Hours = serviceHours.toString();

        this.setState({ equipment: iS });
    };
    private handleSOCTCheck = (value: boolean): void => {
        let iS = { ...this.state.equipment };
        let soctEnabled = iS.add_To_SOCT === "Yes" ? "Yes" : "No";
        if (value === true) {
            soctEnabled = "Yes";
        }
        else {
            soctEnabled = "No";
        }
        iS.add_To_SOCT = soctEnabled;

        this.setState({ equipment: iS });
    };

    private handleFileRemove = (index: number): void => {
        const iS = [...this.state.attachments];
        iS.splice(index, 1);
        this.setState({
            ...this.state,
            attachments: iS
        });
    };

    private handleDownload = (file: IShopEquipmentFile): void => {
        if (file.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = file.base64String;
            a.download = file.name;
            a.click();
        } else {
            this.downloadFromEndpoint(file.name);
        }
    };

    private downloadFromEndpoint = (fileName: string): void => {
        if (fileName !== null) {
            if (hasPayload(this.props.getToken)) {

                fetch(END_POINTS.SHOP.GET_EQUIPMENT_FILE, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            "File": fileName,
                            "Equipment_ID": this.state.equipment.id
                        },
                        token: this.props.getToken.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };

    private handleAttachment = (files: Array<File | DataTransferItem>): void => {
        const newFiles: IShopEquipmentFile[] = [...this.state.attachments];

        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            const check = newFiles.findIndex(z => z.name === x.name);
                            if (check === -1) {
                                newFiles.push({
                                    name: x.name,
                                    base64String: reader.result.toString()
                                });
                            } else {
                                newFiles[check].base64String = reader.result.toString()
                            };

                            if (files.length === index + 1) {
                                resolve(true);
                            };
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        }).then(() => {
            this.setState({
                ...this.state,
                attachments: newFiles
            });
        })
    };

    private sortColumns = (a: any, b: any): any => {
        return a.id - b.id;
    };

    private cancelBack = (): void => {
        this.setState({ back: undefined });
    };

    private confirmBack = (): void => {
        this.props.history.push(ROUTE.SHOP.EQUIPMENT.INDEX);
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        if (this.state.backStatus === undefined) {
            this.props.history.push(ROUTE.SHOP.EQUIPMENT.INDEX);
        } else {
            this.setState({ back: true });
        }
    };

    private onSave = (): void => {
        const request = { ...this.state.equipment };
        if (hasPayload(this.props.getToken))
            if (this.state.equipment.id === 0) {
                const date = new Date();
                request.created = date.toISOString();
                request.modified = date.toISOString();
                request.files = this.state.attachments;
                this.props.addEquipmentRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } else {

                if (hasPayload(this.props.getEquipments)) {
                    const equipment = this.props.getEquipments.payload.response[Number(request.id)];

                    if ((equipment.driver !== request.driver) && (this.state.driverChanged === false)) {
                        let errors: ById<IFieldErrorKeyValue> = {};

                        request.driver_Comments = "";
                        request.driver_Start_Date = "";
                        request.driver_End_Date = "";
                        request.driver_Owned_By = "";

                        errors["driver_Start_Date"] = { key: "driver_Start_Date", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        errors["driver_Owned_By"] = { key: "driver_Owned_By", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

                        this.setState({ driverChanged: true, errors, equipment: request });
                    } else {
                        request.files = this.state.attachments;

                        if (Object.values(this.state.errors).length === 0) {
                            if (request.created_By === undefined || request.created_By === "" || request.created_By === null)
                                request.created_By = userName;

                            request.modified_By = userName;

                            this.props.updateEquipmentRequest({
                                token: this.props.getToken.payload.response.token,
                                request
                            });
                        }
                    }
                }
            };

        this.setState({ backStatus: undefined });
    };

    private handleDropDownValue = (event: unknown, value: any, name?: string): void => {
        if (name)
            this.setState({
                ...this.state,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: (value !== "" && value !== null) ? value.name : ""
                }
            });
    };

    private handleDropDownChange = (event: unknown, value: any, name?: string): void => {
        let errors = this.state.errors;

        // if (name && name === "model_Name") {
        //     errors = this.errorChecker(name, (value !== "" && value !== null) ? value.name : "", errors, true);

        //     this.setState({
        //         ...this.state,
        //         errors,
        //         backStatus: true,
        //         equipment: {
        //             ...this.state.equipment,
        //             model_ID: (value !== "" && value !== null) ? value.id : 0,
        //             model_Name: (value !== "" && value !== null) ? value.model : ""
        //         }
        //     });
        // }

        if (name && name === "make") {
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    make: (value !== "" && value !== null) ? value.manufacturer_Name : ""
                }
            });
        }

        if (name && (name === "equipment_Type")) {
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: (value !== "" && value !== null) ? value.name : ""
                }
            });
        }

        if (name && (name === "owner")) {
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: (value !== "" && value !== null) ? value : ""
                }
            });
        }
    };

    private onDriverChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (this.state.driverChanged === true) {

            if (hasPayload(this.props.getEquipments)) {
                const request = { ...this.state.equipment };

                const equipment = this.props.getEquipments.payload.response[Number(request.id)];
                if (request.driver === value) {
                    request.driver_Comments = equipment.driver_Comments;
                    request.driver_End_Date = equipment.driver_End_Date;
                    request.driver_Owned_By = equipment.driver_Owned_By;
                    request.driver_Start_Date = equipment.driver_Start_Date;

                    if (errors["driver_Start_Date"])
                        delete errors["driver_Start_Date"];

                    if (errors["driver_Owned_By"])
                        delete errors["driver_Owned_By"];
                } else {
                    if (name === "driver")
                        request.driver = value;
                }

                this.setState({
                    ...this.state,
                    errors,
                    equipment: request
                });
            }
        } else {
            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: value
                }
            });
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        if (errors["driver_Start_Date"] && name === "driver_Start_Date" && value.length > 0)
            delete errors["driver_Start_Date"];

        if (errors["driver_Owned_By"] && name === "driver_Owned_By" && value.length > 0)
            delete errors["driver_Owned_By"];

        if (errors["model"] && name === "model" && value.length > 0)
            delete errors["model"];

        this.setState({
            ...this.state,
            errors,
            backStatus: true,
            equipment: {
                ...this.state.equipment,
                [name]: value
            }
        });
    };

    private onNumberChange = (name: string, value: string): void => {
        if (!isNaN(+value)) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: +value
                }
            });
        }
    };

    private onYearChange = (name: string, value: string): void => {
        if (!isNaN(+value)) {
            let errors = this.state.errors;

            if (((+value > new Date().getFullYear()) || (+value < 1900)) && value !== "") {
                if (errors["year"] === undefined) {
                    errors["year"] = { key: "year", message: `Year must be between 1900 and ${new Date().getFullYear()}` };
                };
            } else {
                if (errors["year"])
                    delete errors["year"];
            }

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: value !== "" ? +value : undefined
                }
            });
        }
    };

    private historyPopupClose = (): void => {
        this.setState({ historyOpen: false, column: "", certificateHistory: false });
    };

    private callColumnHistory = (columnName: string): void => {
        if (hasPayload(this.props.getToken) && this.state.equipment.id !== 0) {

            if (columnName !== "certificate") {
                this.props.getEquipmentHistoryColumnsRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        Column_Name: columnName,
                        Equipment_ID: this.state.equipment.id
                    }
                });

                this.setState({ historyOpen: true, column: columnName });
            } else {
                this.setState({ certificateHistory: true });
            }
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getShopEquipmentColumnsStatus))
            this.props.getShopEquipmentColumnsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getEquipments) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getShopEquipmentsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token
            });

        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getEquipments)) && (this.state.equipment.id === 0)) {
            const equipment = this.props.getEquipments.payload.response[Number(id)];
            if (equipment) {
                const existingAtt = equipment.attachments ? equipment.attachments.split(";") : [];
                const attachments: IShopEquipmentFile[] = [];

                existingAtt.forEach(x => {
                    attachments.push({ name: x, base64String: "" });
                });

                if (equipment)
                    this.setState({ equipment, errors: {}, attachments });
            }
        };

    };

}

const mapStateToProps = (state: IStore): IShopEquipmentStoreProps => ({
    getToken: getToken(state),
    getEquipments: getShopEquipments(state),
    addShopEquipment: addShopEquipment(state),
    updateShopEquipment: updateShopEquipment(state),
    getDropDownData: getLookups(state),
    getShopEquipmentColumnsStatus: getShopEquipmentColumns(state),
    validateShopGroup: validateShopGroup(state),
    getEquipmentHistoryColumns: getEquipmentHistoryColumns(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IShopEquipmentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getShopEquipmentsRequest: (data: IShopEquipmentRequest) => dispatch(getShopEquipmentsLoadAction(data)),
    addEquipmentRequest: (data: IAddShopEquipmentRequest) => dispatch(addShopEquipmentLoadAction(data)),
    updateEquipmentRequest: (data: IUpdateShopEquipmentRequest) => dispatch(updateShopEquipmentLoadAction(data)),
    getDropDownDataRequest: (data: IShopLookupsRequest) => dispatch(getLookupsLoadAction(data)),
    getShopEquipmentColumnsRequest: (data: IGetShopEquipmentColumnRequest) => dispatch(getShopEquipmentColumnsLoadAction(data)),
    getEquipmentHistoryColumnsRequest: (data: IGetEquipmentHistoryColumnRequest) => dispatch(getEquipmentHistoryColumnsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopEquipment);


export interface IConvertList {
    id: string;
    name: string;
};

export const convertList = (list: any[]): IConvertList[] => {
    let response = [];
    for (let i = 0; i < list.length; i++) {
        if (list[i] !== null)
            response.push({ id: list[i], name: list[i] });
    };
    return response;
};