import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { PickupTruckIcon } from "../shared/icons";

interface IPartDismantledPickupTrucksDropDownProps {
    onDrawer?(): void;
};

type IPartDismantledPickupTruckDropDown =
    RouteComponentProps &
    IPartDismantledPickupTrucksDropDownProps;

class DismantledPickupTruckDropDown extends PureComponent<IPartDismantledPickupTruckDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoPartDismantledPage}>
                <PickupTruckIcon />
                <span className="ml-2">
                   Dismantled Pickup Trucks
                </span>
            </LAMenuItem>
        );
    };

    private gotoPartDismantledPage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();
            
        this.props.history.push(ROUTE.PARTS.PICKUP_TRUCK.INDEX);
    };

}

export default DismantledPickupTruckDropDown;
