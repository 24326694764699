import { combineEpics, Epic } from "redux-observable";
import { IAction, IStore } from "./reducers";
import { EpicDependencies } from "./store";
import { addOrderEpic } from "./toolRentals/add/order/addOrderEpic";
import { addProjectEpic } from "./toolRentals/add/project/addProjectEpic";
import { addRateEpic } from "./toolRentals/add/rate/addRateEpic";
import { addUnitEpic } from "./toolRentals/add/unit/addUnitEpic";
import { getOrdersEpic } from "./toolRentals/getOrders/getOrdersEpic";
import { getProjectsEpic } from "./toolRentals/getProjects/getProjectsEpic";
import { getRatesEpic } from "./toolRentals/getRates/getRatesEpic";
import { getTokenEpic } from "./getToken/getTokenEpic";
import { getUnitsEpic } from "./toolRentals/getUnits/getUnitsEpic";
import { getClassAndTypesEpic } from "./toolRentals/powerDropdown/getClassAndType/getClassAndTypeEpic";
import { getClassTypeAndUnitEpic } from "./toolRentals/powerDropdown/getClassTypeAndUnit/getClassTypeAndUnitEpic";
import { getProjectListDDEpic } from "./toolRentals/powerDropdown/getProjectListDD/getProjectListDDEpic";
import { updateOrderEpic } from "./toolRentals/update/order/updateOrderEpic";
import { updateProjectEpic } from "./toolRentals/update/project/updateProjectEpic";
import { updateRateEpic } from "./toolRentals/update/rate/updateRateEpic";
import { updateUnitEpic } from "./toolRentals/update/unit/updateUnitEpic";
import { validateShopGroupEpic } from "./toolRentals/validateShopGroup/validateShopGroupEpic";
import { addManufacturerEpic } from "./parts/add/manufacturer/addManufacturerEpic";
import { getManufacturersEpic } from "./parts/getManufacturers/getManufacturerEpic";
import { updateManufacturerEpic } from "./parts/update/manufacturer/updateManufacturerEpic";
import { getModelsEpic } from "./parts/getModels/getModelEpic";
import { addModelEpic } from "./parts/add/model/addModelEpic";
import { updateModelEpic } from "./parts/update/model/updateModelEpic";
import { getManufacturerListDDEpic } from "./parts/powerDropdown/getManufacturerListDD/getManufacturerListDDEpic";
import { addPartEpic } from "./parts/add/part/addPartEpic";
import { getPartsEpic } from "./parts/getParts/getPartEpic";
import { updatePartEpic } from "./parts/update/part/updatePartEpic";
import { getModelListDDEpic } from "./parts/powerDropdown/getModelListDD/getModelListDDEpic";
import { getReportEpic } from "./toolRentals/getReport/getReportEpic";
import { getInvoicesEpic } from "./toolRentals/getInvoices/getInvoicesEpic";
import { getShopManufacturesEpic } from "./shop/getManufactures/getShopManufacturesEpic";
import { addShopManufacturesEpic } from "./shop/addManufactures/addShopManufacturesEpic";
import { updateShopManufacturesEpic } from "./shop/updateManufactures/updateShopManufacturesEpic";
import { getShopLookupsEpic } from "./shop/getLookups/getLookupsEpic";
import { getShopModelsEpic } from "./shop/getModels/getShopModelEpic";
import { addShopModelEpic } from "./shop/addModels/addShopModelsEpic";
import { updateShopModelEpic } from "./shop/updateModels/updateShopModelsEpic";
import { getShopEquipmentsEpic } from "./shop/getEquipments/getEquipmentEpic";
import { addShopEquipmentEpic } from "./shop/addEquipments/addShopEquipmentsEpic";
import { updateShopEquipmentEpic } from "./shop/updateEquipments/updateShopEquipmentsEpic";
import { getEquipmentTypesEpic } from "./shop/getEquipmentTypes/getEquipmentTypesEpic";
import { addEquipmentTypeEpic } from "./shop/addEquipmentType/addEquipmentTypeEpic";
import { updateEquipmentTypeEpic } from "./shop/updateEquipmentType/updateEquipmentTypeEpic";
import { getShopEquipmentColumnsEpic } from "./shop/getShopEquipmentColumns/getShopEquipmentColumnsEpic";
import { assignEquipmentTypeColumnEpic } from "./shop/assignEquipmentTypeColumn/assignEquipmentTypeColumnEpic";
import { getPartsDismantledEquipmentsEpic } from "./parts/getPartsDismantledEquipments/getPartsDismantledEquipmentEpic";
import { updatePartDismantledEquipmentEpic } from "./parts/update/dismantledEquipment/updateDismantledEquipmentEpic";
import { addPartDismantledEquipmentEpic } from "./parts/add/dismantledEquipment/addDismantledEquipmentEpic";
import { getEquipmentHistoryColumnsEpic } from "./shop/getEquipmentHistoryColumn/getEquipmentHistoryColumnEpic";
import { getDismantledLookupsEpic } from "./parts/getDismantledLookups/getDismantledLookupsEpic";
import { deletePartEpic } from "./parts/delete/part/deletePartEpic";
import { deleteDismantledEpic } from "./parts/delete/dismantled/deleteDismantledEpic";
import { getAllPartsEpic } from "./parts/getAllParts/getAllPartsEpic";
import { getEquipmentVersionHistoryEpic } from "./shop/getEquipmentVersionHistory/getEquipmentVersionHistoryEpic";
import { getDismantledEquipmentFacetsEpic } from "./parts/getDismantledEquipmentFacets/getDismantledEquipmentFacetsEpic";
import { addPartPickupTruckEpic } from "./parts/add/pickupTruck/addPickupTruckEpic";
import { deletePickupTruckEpic } from "./parts/delete/pickupTruck/deletePickupTruckEpic";
import { getPartsDismantledPickupTrucksEpic } from "./parts/getPartsDismantledPickupTrucks/getPartsDismantledPickupTruckEpic";
import { updatePartPickupTruckEpic } from "./parts/update/pickupTruck/updatePickupTruckEpic";
import { updatePartHeavyTruckEpic } from "./parts/update/heavyTruck/updateHeavyTruckEpic";
import { getPartsDismantledHeavyTrucksEpic } from "./parts/getPartsDismantledHeavyTrucks/getPartsDismantledHeavyTruckEpic";
import { deleteHeavyTruckEpic } from "./parts/delete/heavyTruck/deleteHeavyTruckEpic";
import { addPartHeavyTruckEpic } from "./parts/add/heavyTruck/addHeavyTruckEpic";


export type IEpic = Epic<IAction, IAction, IStore, EpicDependencies>;

export default combineEpics(
    getProjectsEpic,
    getOrdersEpic,
    getUnitsEpic,
    getRatesEpic,
    getManufacturersEpic,    
    getPartsEpic,    
    addProjectEpic,
    addRateEpic,
    addUnitEpic,
    addOrderEpic,
    addManufacturerEpic,
    addModelEpic,
    addPartEpic,
    updateRateEpic,
    updateUnitEpic,
    updateOrderEpic,
    updateProjectEpic,
    updateManufacturerEpic,
    updateModelEpic,
    updatePartEpic,
    getClassAndTypesEpic,
    getProjectListDDEpic,
    getClassTypeAndUnitEpic,
    getManufacturerListDDEpic,
    getModelListDDEpic,
    getTokenEpic,
    validateShopGroupEpic,
    getModelsEpic,
    getReportEpic,
    getInvoicesEpic,
    getShopManufacturesEpic,
    addShopManufacturesEpic,
    updateShopManufacturesEpic,
    getShopLookupsEpic,
    getShopModelsEpic,
    addShopModelEpic,
    updateShopModelEpic,
    getShopEquipmentsEpic,
    addShopEquipmentEpic,
    updateShopEquipmentEpic,
    getEquipmentTypesEpic,
    addEquipmentTypeEpic,
    updateEquipmentTypeEpic,
    getShopEquipmentColumnsEpic,
    assignEquipmentTypeColumnEpic,
    getPartsDismantledEquipmentsEpic,
    updatePartDismantledEquipmentEpic,
    addPartDismantledEquipmentEpic,
    getEquipmentHistoryColumnsEpic,
    getDismantledLookupsEpic,
    deletePartEpic,
    deleteDismantledEpic,
    getAllPartsEpic,
    getEquipmentVersionHistoryEpic,
    getDismantledEquipmentFacetsEpic,
    addPartPickupTruckEpic,
    deletePickupTruckEpic,
    getPartsDismantledPickupTrucksEpic,
    updatePartPickupTruckEpic,
    addPartHeavyTruckEpic,
    deleteHeavyTruckEpic,
    getPartsDismantledHeavyTrucksEpic,
    updatePartHeavyTruckEpic
);
