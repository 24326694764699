
export enum IUPDATE_SHOP_MANUFACTURERS_REQUEST {
    REQUEST = "updateShopManufactures/UPDATE_SHOP_MANUFACTURERS_REQUEST",
    SUCCESS = "updateShopManufactures/UPDATE_SHOP_MANUFACTURERS_SUCCESS",
    FAILED = "updateShopManufactures/UPDATE_SHOP_MANUFACTURERS_FAILED"
};

export interface IUpdateShopManufactureRequest {
    token: string;
    request: {
        ID: number;
        Created_By: string;
        Modified_By: string;
        Manufacturer_Name: string;
    };
};