import { IPaginationRequest } from "../../../../react/shared/publicInterfaces";
import { IProject } from "../../getProjects/getProjectsConstants";

export enum IADD_PROJECT_REQUEST {
    REQUEST = "addProject/ADD_PROJECT_REQUEST",
    SUCCESS = "addProject/ADD_PROJECT_SUCCESS",
    FAILED = "addProject/ADD_PROJECT_FAILED",
    DUPLICATE = "addProject/ADD_PROJECT_DUPLICATE"
};

export interface IAddProjectRequest extends IPaginationRequest {
    request: IProject;
    token: string;
};