import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import styled from "styled-components";
import { LAIconButton } from "./buttons";
import React, { useCallback } from "react";
import "devextreme/dist/css/dx.light.css";
import { AddIcon, DeleteIcon } from "./icons";
import { undefinedFunction } from "./constExports";
import { RowClickEvent, RowPreparedEvent } from "devextreme/ui/data_grid";
import Not_Available_Image from "../../No-Image.jpg";
import { exportDataGrid } from "devextreme/excel_exporter";
import { DARK_GREY_COLOR, LIGHT_GREEN_COLOR, LIGHT_GREY_COLOR, RED_COLOR, ROW_SELECT_COLOR, GREEN_COLOR, LIGHT_ORANGE_COLOR, LIGHT_RED_COLOR } from "./theme";
import DataGrid, { Button, Column, Editing, Export, FilterPanel, FilterRow, Grouping, HeaderFilter, Lookup, Paging, Scrolling, SearchPanel, StateStoring, StringLengthRule, Summary, TotalItem } from "devextreme-react/data-grid";
import { equipmentStatusDropdown } from "../../redux/shop/getEquipments/getEquipmentConstants";


interface IDevExtremeColumn {
    name: string;
    max?: number;
    type?: string;
    width?: number;
    format?: string;
    dropDownId?: any;
    caption?: string;
    sortDesc?: boolean;
    sortAsc?: true;
    dropDownName?: any;
    requiredField?: true;
    dropDownSource?: any;
    allowEditing?: boolean;
    calulated?: (rowData: any) => any;
};

interface ISumColumn {
    caption: string;
    columnName: string;
};

interface ILADevExtremeGridProps {
    id?: string;
    data: any[];
    add?: boolean;
    export?: true;
    height?: number;
    onAdd?: () => void;
    actionWidth?: number;
    columnWidth?: number;
    searchPanel: boolean;
    removeStyleBtn?: true;
    filterHeader: boolean;
    exportFileName?: string;
    customBtnLabel?: string;
    sumColumn?: ISumColumn[];
    onEdit?: (e: any) => void;
    onDelete?: (e: any) => void;
    columns: IDevExtremeColumn[];
    onEditIcon?: (e: any) => void;
    customBtn?: (event?: any) => void;
    onSaving?: (event?: any) => void;
    customRowColor?: true;
    pictureField?: string;
    storeLocally?: true;
    pageSize?: number;
    onCustomClick?: (name: string, e: any) => void;
    onClick: (e: RowClickEvent<any, unknown>) => void;
};

const LADevExtremeGridStyles = styled.div`
.dx-datagrid-headers .dx-header-row {  
    font-weight: bold;
    background-color: ${LIGHT_GREY_COLOR};
};
.dx-header-filter-indicator, .dx-cell-focus-disabled, .dx-datagrid-content {
    font-size: 12px !important;
};
.dx-state-hover
{
    background-color: ${ROW_SELECT_COLOR} !important;
}
    .control-button{
        font-weight: 400 !important;
        font-size: 14px !important;
        font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif !important;
    
        padding: 10px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-bottom: 20px !important;
        display:inline-block;
        padding:0.3em 1.2em;
        margin:0 0.1em 0.1em 0;
        border:0.16em solid rgba(255,255,255,0);
        border-radius: 0.5em;
        box-sizing: border-box;
        text-decoration:none;
        font-weight: bold;
        color:#FFFFFF;
        text-align:center;
        transition: all 0.2s;
    };

    a.button4:hover{
        border-color: rgba(255,255,255,1);
    };
    
    @media all and (max-width:30em){
        a.button4 {
            display:block;
            margin:0.2em auto;
        };
    };

    .btn {
        color: #337ab7;
        text-align:center;
        transition: all 0.2s;
    };

    .editBg{
        background-color: #4CAF50;
    };

    .cloneBg{
        background-color: orange;
    };

    .deleteBg{
        background-color: #CD5C5C;
    };

    .editMode {
        .dx-edit-row {
            background-color: ${DARK_GREY_COLOR};
        };
    
        .dx-edit-row a {
            background-color: ${DARK_GREY_COLOR};
            color: ${RED_COLOR};
        };
    
        .dx-texteditor-input {
            color: ${GREEN_COLOR};
            border: 2px outset;
        };
    };
`;

const storageKey = "shop-toolrental-datagrid-state";

export const LADevExtremeGrid: React.FC<ILADevExtremeGridProps> = React.memo((props: ILADevExtremeGridProps) => {
    const onExportClick = (e: any): void => onExporting(e, props.exportFileName ?? "File");

    const loadState = useCallback(() => {
        let data = localStorage.getItem(storageKey);

        if (data)
            return JSON.parse(data);

    }, []);

    const saveState = useCallback((state) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }
        localStorage.setItem(storageKey, JSON.stringify(state));
    }, []);

    return (
        <LADevExtremeGridStyles>
            <DataGrid
                id={props.id}
                showBorders={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                dataSource={props.data}
                hoverStateEnabled={true}
                onRowClick={props.onClick}
                rowAlternationEnabled={props.customRowColor ? false : true}
                height={props.height}
                columnWidth={props.columnWidth}
                // onToolbarPreparing={moveAddBtn}
                onRowPrepared={(e: RowPreparedEvent<any, unknown>) => {
                    if (props.customRowColor) {
                        if (e.data && e.data.status) {
                            if (e.data.status === equipmentStatusDropdown[0].name) {
                                e.rowElement.style.backgroundColor = LIGHT_GREEN_COLOR;
                            } else if (e.data.status === equipmentStatusDropdown[1].name) {
                                e.rowElement.style.backgroundColor = LIGHT_ORANGE_COLOR;
                            } else if (e.data.status === equipmentStatusDropdown[2].name) {
                                e.rowElement.style.backgroundColor = LIGHT_RED_COLOR;
                            }
                        }
                    }
                }}
                onSaved={props.onSaving}
                onInitNewRow={props.onAdd}
                onExporting={onExportClick}
                className={props.onEditIcon ? "editMode" : ""}
                onRowRemoved={props.onDelete ? props.onDelete : undefinedFunction}
            >
                {(props.onEdit || props.onDelete || props.onEditIcon) && <Editing
                    mode="row"
                    allowAdding={props.add ? true : false}
                    allowUpdating={props.onEditIcon ? true : props.onEdit ? true : false}
                    allowDeleting={props.onDelete ? true : false}
                />}

                {(props.onEdit || props.onDelete || props.customBtn || props.customBtnLabel || props.onEditIcon) && <Column type="buttons" width={props.actionWidth ?? 180}>
                    {(props.onEdit) && <Button cssClass={props.removeStyleBtn ? "btn" : "control-button editBg"} text="View/Update" onClick={props.onEdit} />}
                    {(props.onEditIcon) && <LAIconButton label="Edit" icon={<AddIcon />} onClick={props.onEditIcon} />}
                    {props.onDelete && <LAIconButton label="Edit" icon={<DeleteIcon />} onClick={props.onDelete} />}
                    {props.customBtn && props.customBtnLabel && <Button cssClass={props.removeStyleBtn ? "btn" : "control-button editBg"} text={props.customBtnLabel} onClick={props.customBtn} />}
                </Column>}

                {props.columns.map((x, index): JSX.Element => {
                    if (x.dropDownSource !== undefined) {
                        return <Column dataField={x.name} caption={x.caption ? x.caption : x.name}>
                            <Lookup dataSource={x.dropDownSource} displayExpr={x.dropDownName} valueExpr={x.dropDownId} />
                            {x.requiredField && <StringLengthRule
                                min={1}
                                message={(x.caption ? x.caption : x.name) + " Required"}
                            />}
                        </Column>
                    } else {
                        if ((x.dropDownSource === undefined) && (x.type === "date")) {
                            return <Column
                                key={index}
                                width={x.width}
                                dataType={x.type}
                                dataField={x.name}
                                format="MM/dd/yyyy"
                                caption={x.caption ? x.caption : x.name}
                                sortOrder={x.sortDesc ? "desc" : undefined}
                            />
                        } else if ((x.dropDownSource === undefined) && (x.type === "button")) {
                            const onCustomClick = (e: any): void => props.onCustomClick ? props.onCustomClick(x.name, e) : undefined;
                            return <Column caption={x.caption} width={x.width} type="buttons" sortOrder={x.sortAsc ? "asc" : undefined}>
                                <Button text={x.caption} onClick={onCustomClick} />
                            </Column>
                        } else if (props.pictureField && (x.type === "picture")) {
                            return <Column
                                caption={"Photos"}
                                width={100}
                                allowSorting={false}
                                cellRender={cellRender}
                                dataField={props.pictureField}
                            />
                        } 
                        else {
                            return <Column
                                key={index}
                                width={x.width}
                                dataType={x.type}
                                dataField={x.name}
                                allowSearch={true}
                                format={x.format}
                                alignment={"left"}
                                sortOrder={x.sortDesc ? "desc" : undefined}
                                calculateCellValue={x.calulated}
                                allowEditing={x.allowEditing ?? true}
                                caption={x.caption ? x.caption : x.name}
                            >
                                {(x.requiredField && x.type === "string") ? <StringLengthRule
                                    min={1}
                                    max={x.max}
                                    message={(x.caption ? x.caption : x.name) + " Required"}
                                /> : undefined}
                            </Column>
                        }
                    }
                })}

                {props.export && <Export enabled={true} />}
                <Scrolling columnRenderingMode="virtual" />
                <FilterRow visible={props.filterHeader ? true : false} />
                <HeaderFilter visible={props.filterHeader ? true : false} />
                <FilterPanel visible={props.filterHeader ? true : false} />
                <Grouping autoExpandAll={false} />
                <Paging pageSize={props.pageSize} />

                <StateStoring
                    type="custom"
                    storageKey={storageKey}
                    customLoad={loadState}
                    customSave={saveState}
                    enabled={props.storeLocally}
                />
                {props.sumColumn && <Summary>
                    {props.sumColumn.map((x) => (
                        <TotalItem
                            alignment="left"
                            summaryType="sum"
                            column={x.columnName}
                            showInColumn={x.columnName}
                            displayFormat={`${x.caption}: {0}`}
                        />
                    ))}
                </Summary>}

                {props.searchPanel && <SearchPanel
                    visible={true}
                    width={200}
                    placeholder="Search..."
                />}

            </DataGrid>
        </LADevExtremeGridStyles>
    );
});

// const moveAddBtn = (e: EventInfo<dxDataGrid<any, unknown>> & ToolbarPreparingInfo): any => {
//     return e.toolbarOptions.items?.forEach((x: any) => {
//         x.location = "before";
//     });
// };

const onExporting = (e: any, name: string): any => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(name);

    exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), name + ".xlsx");
        });
    });
    e.cancel = true;
};


const cellRender = (data: any): JSX.Element => {
    return <img alt="part" src={data.value ? data.value : Not_Available_Image} width="100" height="auto" />
};