import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_INVOICES_REQUEST, IInvoices, IInvoicesRequest } from "./getInvoicesConstants";


export interface IGetInvoicesLoadAction {
    type: IGET_INVOICES_REQUEST.REQUEST;
    data: IInvoicesRequest
}
export const getInvoicesLoadAction = (data: IInvoicesRequest): IGetInvoicesLoadAction => ({
    type: IGET_INVOICES_REQUEST.REQUEST,
    data
});
export interface IGetInvoicesSuccessAction {
    type: IGET_INVOICES_REQUEST.SUCCESS;
    list: ToolRentalsResponse<ByIdNumber<IInvoices>>;
}
export const getInvoicesLoadSuccessAction = (list: ToolRentalsResponse<ByIdNumber<IInvoices>>): IGetInvoicesSuccessAction => ({
    type: IGET_INVOICES_REQUEST.SUCCESS,
    list
});
export interface IGetInvoicesLoadFailedAction {
    type: IGET_INVOICES_REQUEST.FAILED;
    message: string;
}
export const getInvoicesLoadFailedAction = (message: string): IGetInvoicesLoadFailedAction => ({
    type: IGET_INVOICES_REQUEST.FAILED,
    message
});
