import { IUPDATE_PART_DISMANTLED_EQUIPMENT_REQUEST, IUpdatePartDismantledEquipmentRequest, IAddUpdatePartDismantledEquipment } from "./updateDismantledEquipmentConstants";


export interface IUpdatePartDismantledEquipmentLoadAction {
    type: IUPDATE_PART_DISMANTLED_EQUIPMENT_REQUEST.REQUEST;
    data: IUpdatePartDismantledEquipmentRequest
}
export const updatePartDismantledEquipmentLoadAction = (data: IUpdatePartDismantledEquipmentRequest): IUpdatePartDismantledEquipmentLoadAction => ({
    type: IUPDATE_PART_DISMANTLED_EQUIPMENT_REQUEST.REQUEST,
    data
});
export interface IUpdatePartDismantledEquipmentSuccessAction {
    type: IUPDATE_PART_DISMANTLED_EQUIPMENT_REQUEST.SUCCESS;
    data: IAddUpdatePartDismantledEquipment;
}
export const updatePartDismantledEquipmentLoadSuccessAction = (data: IAddUpdatePartDismantledEquipment): IUpdatePartDismantledEquipmentSuccessAction => ({
    type: IUPDATE_PART_DISMANTLED_EQUIPMENT_REQUEST.SUCCESS,
    data
});
export interface IUpdatePartDismantledEquipmentLoadFailedAction {
    type: IUPDATE_PART_DISMANTLED_EQUIPMENT_REQUEST.FAILED;
    message: string;
}
export const updatePartDismantledEquipmentLoadFailedAction = (message: string): IUpdatePartDismantledEquipmentLoadFailedAction => ({
    type: IUPDATE_PART_DISMANTLED_EQUIPMENT_REQUEST.FAILED,
    message
});
