import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_EQUIPMENT_FACETS_REQUEST, IDismantledEquipmentFacets, IDismantledEquipmentFacetsRequest } from "./getDismantledEquipmentFacetsConstants";


export interface IGetDismantledEquipmentFacetsLoadAction {
    type: IGET_EQUIPMENT_FACETS_REQUEST.REQUEST;
    data: IDismantledEquipmentFacetsRequest
}
export const getDismantledEquipmentFacetsLoadAction = (data: IDismantledEquipmentFacetsRequest): IGetDismantledEquipmentFacetsLoadAction => ({
    type: IGET_EQUIPMENT_FACETS_REQUEST.REQUEST,
    data
});
export interface IGetDismantledEquipmentFacetsSuccessAction {
    type: IGET_EQUIPMENT_FACETS_REQUEST.SUCCESS;
    list: PartsResponse<IDismantledEquipmentFacets[]>;
}
export const getDismantledEquipmentFacetsLoadSuccessAction = (list: PartsResponse<IDismantledEquipmentFacets[]>): IGetDismantledEquipmentFacetsSuccessAction => ({
    type: IGET_EQUIPMENT_FACETS_REQUEST.SUCCESS,
    list
});
export interface IGetDismantledEquipmentFacetsLoadFailedAction {
    type: IGET_EQUIPMENT_FACETS_REQUEST.FAILED;
    message: string;
}
export const getDismantledEquipmentFacetsLoadFailedAction = (message: string): IGetDismantledEquipmentFacetsLoadFailedAction => ({
    type: IGET_EQUIPMENT_FACETS_REQUEST.FAILED,
    message
});
