import { IPaginationRequest } from "../../../../react/shared/publicInterfaces";
import { IRate } from "../../getRates/getRatesConstants";

export enum IADD_RATE_REQUEST {
    REQUEST = "addRate/ADD_RATE_REQUEST",
    SUCCESS = "addRate/ADD_RATE_SUCCESS",
    FAILED = "addRate/ADD_RATE_FAILED"
};

export interface IAddRateRequest extends IPaginationRequest {
    request: IRate;
    token: string;
};