import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_PART_REQUEST } from "./updatePartConstants";
import { IUpdatePartLoadAction, IUpdatePartLoadFailedAction, IUpdatePartSuccessAction } from "./updatePartActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IUpdatePartLoadAction
    | IUpdatePartSuccessAction
    | IUpdatePartLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdatePartReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_PART_REQUEST.REQUEST:
            return loading;

        case IUPDATE_PART_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_PART_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};