
export enum IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST {
    REQUEST = "getShopEquipmentColumns/GET_SHOP_EQUIPMENT_COLUMNS_REQUEST",
    SUCCESS = "getShopEquipmentColumns/GET_SHOP_EQUIPMENT_COLUMNS_SUCCESS",
    FAILED = "getShopEquipmentColumns/GET_SHOP_EQUIPMENT_COLUMNS_FAILED"
};

export interface IGetShopEquipmentColumnRequest {
    token: string;
};

export interface IGetShopEquipmentColumn {
    master_Columns: IMasterColumn[];
    mapped_Columns: IMappedColumn[];
};

export interface IMasterColumn {
    id: number;
    name: string;
};

export interface IMappedColumn {
    column_IDs: string; // Comma Separated
    equipment_Type: string;
};