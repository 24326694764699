
export enum IGET_EQUIPMENT_TYPES_REQUEST {
    REQUEST = "getEquipmentTypes/GET_EQUIPMENT_TYPES_REQUEST",
    SUCCESS = "getEquipmentTypes/GET_EQUIPMENT_TYPES_SUCCESS",
    FAILED = "getEquipmentTypes/GET_EQUIPMENT_TYPES_FAILED"
};

export interface IGetEquipmentTypeRequest {
    token: string;
};

export interface IGetEquipmentType {
    id: number;
    name: string;
    active: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};