import { IUnit } from "../../getUnits/getUnitsConstants";
import { IUPDATE_UNIT_REQUEST, IUpdateUnitRequest } from "./updateUnitConstants";


export interface IUpdateUnitLoadAction {
    type: IUPDATE_UNIT_REQUEST.REQUEST;
    data: IUpdateUnitRequest
}
export const updateUnitLoadAction = (data: IUpdateUnitRequest): IUpdateUnitLoadAction => ({
    type: IUPDATE_UNIT_REQUEST.REQUEST,
    data
});
export interface IUpdateUnitSuccessAction {
    type: IUPDATE_UNIT_REQUEST.SUCCESS;
    data: IUnit;
}
export const updateUnitLoadSuccessAction = (data: IUnit): IUpdateUnitSuccessAction => ({
    type: IUPDATE_UNIT_REQUEST.SUCCESS,
    data
});
export interface IUpdateUnitLoadFailedAction {
    type: IUPDATE_UNIT_REQUEST.FAILED;
    message: string;
}
export const updateUnitLoadFailedAction = (message: string): IUpdateUnitLoadFailedAction => ({
    type: IUPDATE_UNIT_REQUEST.FAILED,
    message
});
