import React, { useEffect, useState } from "react";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LAErrorBox from "../../../shared/errorBox";
import { LAPopover } from "../../../shared/popOver";
import { LACenteredLoading } from "../../../shared/loading";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import styled from "styled-components";
import { DARK_GREY_COLOR, LIGHT_GREY_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { getDate } from "../../../shared/constExports";
import { END_POINTS } from "../../../../redux/endpoints";
import { hasPayload, Server } from "../../../../redux/server";
import { PartsResponse } from "../../../shared/publicInterfaces";
import { IToken } from "../../../../redux/getToken/getTokenConstants";

interface ICertificateHistoryPopupProps {
    id: number;
    open: boolean;
    onCancel: () => void;
    token: Server<PartsResponse<IToken>>;
};

interface ICertificateHistory {
    id: number;
    equipment_List_ID: number;
    type: string;
    due_Date: string;
    certificate_No: string;
    created_By: string;
    created: string;
}

const CertificateHistoryPopupStyles = styled(LAPaperWithPadding)`
        .title {
            font-weight: bold;
            color: ${WHITE_COLOR};
            background-color: ${DARK_GREY_COLOR};
        };

    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
`;

export const CertificateHistoryPopup: React.FC<ICertificateHistoryPopupProps> = React.memo((props: ICertificateHistoryPopupProps) => {

    const onCancel = () => props.onCancel();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ICertificateHistory[]>([]);

    const callEndpoint = (): void => {
        if (hasPayload(props.token))
            fetch(END_POINTS.SHOP.CERTIFICATE_VERSION_HISTORY, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: props.id,
                    token: props.token.payload.response.token
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    setData(res.response);
                    setLoading(false);
                })
                .catch((res) => console.log(res));
    };

    useEffect(() => {
        callEndpoint();
    }, []);

    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <CertificateHistoryPopupStyles>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>CERTIFICATE HISTORY</h3>
                    <hr />
                </LAGridItem>

                {(loading === true) && <LAGridItem xs={12}>
                    <LACenteredLoading message="Loading History..." />
                </LAGridItem>}

                {(loading === false) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        <Table aria-label="history table">
                            <TableHead>
                                <TableRow className="header-row">
                                    <TableCell className="title" align="center">Certificate #</TableCell>
                                    <TableCell className="title" align="center">Due Date</TableCell>
                                    <TableCell className="title" align="center">Type</TableCell>
                                    <TableCell className="title" align="center">Modified Date</TableCell>
                                    <TableCell className="title" align="center">Modified By</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row: ICertificateHistory, index: number) => (
                                    <TableRow key={index} className={index % 2 ? "odd-row" : ""}>
                                        <TableCell component="th" scope="row" align="center">
                                            {row.certificate_No}
                                        </TableCell>
                                        <TableCell align="center">{getDate(false, row.due_Date)}</TableCell>
                                        <TableCell align="center">{row.type}</TableCell>
                                        <TableCell align="center">{getDate(false, row.created)}</TableCell>
                                        <TableCell align="center">{row.created_By}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </CertificateHistoryPopupStyles>
    </LAPopover>
});