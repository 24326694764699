import { PARTS_END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IDELETE_HEAVY_TRUCK_REQUEST } from "./deleteHeavyTruckConstants";
import { IDeleteHeavyTruckLoadAction, IDeleteHeavyTruckLoadFailedAction, IDeleteHeavyTruckSuccessAction, 
    deleteHeavyTruckLoadFailedAction, deleteHeavyTruckLoadSuccessAction } from "./deleteHeavyTruckActions";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const deleteHeavyTruckEpic: Epic = (
    action$: ActionsObservable<IDeleteHeavyTruckLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IDeleteHeavyTruckSuccessAction | IDeleteHeavyTruckLoadFailedAction> =>
    action$.ofType(IDELETE_HEAVY_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS_DISMANTLED_HEAVY_TRUCKS.DELETE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<string>): IDeleteHeavyTruckSuccessAction | IDeleteHeavyTruckLoadFailedAction => {
                            return deleteHeavyTruckLoadSuccessAction(response.message);
                        }),
                        catchError((response: PartsResponse<any>) => ActionsObservable.of(deleteHeavyTruckLoadFailedAction(response.message)))
                    )
            )
        );