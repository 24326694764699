import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_PART_HEAVY_TRUCK_REQUEST } from "./addHeavyTruckConstants";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { addPartHeavyTruckLoadFailedAction, addPartHeavyTruckLoadSuccessAction, IAddPartHeavyTruckLoadAction, IAddPartHeavyTruckLoadFailedAction, IAddPartHeavyTruckSuccessAction } from "./addHeavyTruckActions";
import { IPartHeavyTruck } from "../../getPartsDismantledHeavyTrucks/getPartsDismantledHeavyTruckConstants";

export const addPartHeavyTruckEpic: Epic = (
    action$: ActionsObservable<IAddPartHeavyTruckLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddPartHeavyTruckSuccessAction | IAddPartHeavyTruckLoadFailedAction> =>
    action$.ofType(IADD_PART_HEAVY_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IPartHeavyTruck[]>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS_DISMANTLED_HEAVY_TRUCKS.ADD,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IPartHeavyTruck[]>): IAddPartHeavyTruckSuccessAction | IAddPartHeavyTruckLoadFailedAction => {
                            if(response.message === "Success"){
                                return addPartHeavyTruckLoadSuccessAction(response);
                            }
                            return addPartHeavyTruckLoadFailedAction(response.message);
                        }),
                        catchError((response: PartsResponse<IPartHeavyTruck[]>) => ActionsObservable.of(addPartHeavyTruckLoadFailedAction(response.message)))
                    )
            )
        );