import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
  FilterBuilderPopup,
  Export,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography, FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import {RouteComponentProps} from 'react-router-dom';
  import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import SOCTHome from './socthome';

interface IState {
  soctMaster:any;
  open:boolean;
}
interface IProps extends RouteComponentProps{
  history:any
}

var editData:any;
var unitNumbers:any = [];
var copy_list:any[] = [];
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
var globalEditID:Number = 0;
var renderPageType:string = '';
export default class SOTC extends React.Component<IProps,IState>{
  constructor(props:any){
    super(props);
    this.state ={soctMaster:[], open:false};
    this.setOpen = this.setOpen.bind(this);
    this.editItem = this.editItem.bind(this);
    this.filterView = this.filterView.bind(this);
  }
  filterView(eventKey:any){
    console.log(copy_list);
    switch(eventKey.target.value){
      case 'active':
        var filtered_list = copy_list.filter((elem:any) => { return (elem.status === "Active" || elem.status===null)});
        this.setState({soctMaster:filtered_list});
        break;
      case 'inactive':
        var filtered_list = copy_list.filter((elem:any) => { return (elem.status === "Inactive")});
        this.setState({soctMaster:filtered_list});
        break;
      case 'out_of_service':
        var filtered_list = copy_list.filter((elem:any) => { return (elem.status === "Out Of Service")});
        this.setState({soctMaster:filtered_list});
        break;
    }
  }
  setOpen(){
    this.setState({open:!this.state.open});
  }
  editItem(id:number, data:any){
    renderPageType='';
    editData = data;
    if(editData.attachments != null){
      if(editData.attachments.length > 1){
        editData.attachments = editData.attachments.split(";");
      }
  }
    globalEditID = id;
    this.props.history.push('/soct/lists/edit/id='+id);
  }
  componentDidMount(){
    window.location.href = "https://apps.sureway.ca/field/soct/lists";
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
  // api.sureway.ca
    fetch('https://api.sureway.ca/api/SOCT/GetSOCTMasterList', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        unitNumbers=[];
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
          unitNumbers.push(history.unit_Number);
        });
        copy_list = master_temp_list;
        this.setState({soctMaster:master_temp_list});
    })
  }
    render(){
        return (
          <> <SOCTHome history={this.props.history}></SOCTHome>
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
            <MUIButton onClick={()=> {this.props.history.push('/soct/lists/create')}} style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" startIcon={<AddCircleIcon />}>Add New</MUIButton>
            <Typography className='dataGrid-title'>SOCT Main</Typography>
            <Grid item xs={3}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={this.filterView}
                        defaultValue={"active"}
                    >
                        <FormControlLabel value="active" control={<Radio size="small"/>} label="Active" />
                        <FormControlLabel value="inactive" control={<Radio size="small"/>} label="Inactive" />
                        <FormControlLabel value="out_of_service" control={<Radio size="small"/>} label="Out Of Service" />
                        </RadioGroup>
                </FormControl>
            </Grid>
            <DataGrid focusedRowEnabled={true}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
                dataSource={this.state.soctMaster}
                keyExpr="id"
                showBorders={true}
                wordWrapEnabled={true}
                onCellPrepared={(e:CellPreparedEvent<any, unknown>) => {
                  if(e.data){
                  switch(e.column.dataField){
                    case 'next_250_Due':
                      if(e.data.next_250_Due > 50){
                        e.cellElement.style.backgroundColor='red';
                      }
                      if(e.data.next_250_Due > -51 && e.data.next_250_Due < 51){
                        e.cellElement.style.backgroundColor='yellow';
                      }
                      break;
                    case 'next_500_Due':
                      if(e.data.next_500_Due > 50 && e.data.last_500!=null){
                        e.cellElement.style.backgroundColor='red';
                      }
                      if((e.data.next_500_Due > -51 && e.data.next_500_Due < 51) &&  e.data.last_500!=null){
                        e.cellElement.style.backgroundColor='yellow';
                      }
                      if(e.data.last_500===null){
                        e.cellElement.textContent = '';
                      }
                      break;
                    case 'next_1500_Due':
                      if(e.data.next_1500_Due > 150 && e.data.last_1500!=null){
                        e.cellElement.style.backgroundColor='red';
                      }
                      if((e.data.next_1500_Due > -151 && e.data.next_1500_Due < 151) && e.data.last_1500!=null){
                        e.cellElement.style.backgroundColor='yellow';
                      }
                      if(e.data.last_1500===null){
                        e.cellElement.textContent = '';
                      }
                      break;
                    case 'next_3000_Due':
                      if(e.data.next_3000_Due > 300 && e.data.last_3000!=null){
                        e.cellElement.style.backgroundColor='red';
                      }
                      if((e.data.next_3000_Due > -301 && e.data.next_3000_Due < 301) && e.data.last_3000!=null){
                        e.cellElement.style.backgroundColor='yellow';
                      }
                      if(e.data.last_3000===null){
                        e.cellElement.textContent = '';
                      }
                      break;
                  }
                }
                }}
                > 
            <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." />    
              <Paging enabled={true} defaultPageSize={20}/>
              <Pager
                visible={true}
                allowedPageSizes={[20,40,60]}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
                <FilterRow visible={true}></FilterRow>
                <FilterPanel visible={true} />
   <FilterBuilderPopup position={filterBuilderPopupPosition} />
     <HeaderFilter visible={true} allowSearch={true} />
     <FilterRow visible={true}></FilterRow>
      <FilterPanel visible={true} />
      <Export enabled={true} allowExportSelectedData={true} />
     <Column type="buttons" caption="Edit" width={100} headerCellRender={renderTitleHeader}>
         <Button text="View/Update" cssClass="dataGrid-edit-btn" onClick={(e:any) => {this.editItem(e.row.data.id, e.row.data)}}></Button>
       </Column>
       {/* <Column dataField="id" caption="Id" alignment="left" width={80} headerCellRender={renderTitleHeader}/> */}
       <Column dataField="unit_Number" caption="Unit #" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       {/* <Column dataField="equipment_Type" caption="Equipment Type" alignment="left" width={110} headerCellRender={renderTitleHeader}/> */}
       <Column dataField="make" caption="Make" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="model" caption="Model" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="location" caption="Location" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="meter_Date" dataType="date" caption="Meter Date" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="service_Date" dataType="date" caption="Service Date" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="current_Hours" caption="Current Hours" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="ecm" caption="ECM" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="last_EO" caption="Last EO#" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       {/* <Column dataField="filter_Cuttings" caption="Filter Cuttings" alignment="left" width={80} headerCellRender={renderTitleHeader}/> */}
       <Column type="buttons" caption="Service History" width={150} headerCellRender={renderTitleHeader}>
         <Button text="View" cssClass="dataGrid-edit-btn" onClick={(e:any) => {renderPageType='Services'; editData=e.row.data; this.props.history.push('/soct/lists/edit/id='+e.row.data.id)}}></Button>
       </Column>
       <Column type="buttons" caption="Meter History" width={150} headerCellRender={renderTitleHeader}>
         <Button text="View" cssClass="dataGrid-edit-btn" onClick={(e:any) => {renderPageType='Meters'; editData=e.row.data; this.props.history.push('/soct/lists/edit/id='+e.row.data.id)}}></Button>
       </Column>
       {/* <Column type="buttons" caption="Equipment Move History" width={150} headerCellRender={renderTitleHeader}>
         <Button text="View" cssClass="dataGrid-edit-btn" onClick={(e:any) => {renderPageType='Moves'; editData=e.row.data; this.props.history.push('/soct/lists/edit/id='+e.row.data.id)}}></Button>
       </Column> */}
       <Column dataField="next_250_Due" caption="Next 250 Due" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="next_500_Due" caption="Next 500 Due" alignment="left" width={80}  headerCellRender={renderTitleHeader}/>
       <Column dataField="next_1500_Due" caption="Next 1500 Due" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="next_3000_Due" caption="Next 3000 Due" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="comments" caption="Comments" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="last_500" caption="Last 500" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="last_1500" caption="Last 1500" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="last_3000" caption="Last 3000" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       {/* <Column dataField="machine_Hours" caption="Machine Hours" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column dataField="truck" caption="Truck" alignment="left" width={80} headerCellRender={renderTitleHeader}/> */}
     
       <Column defaultSortOrder={"desc"} dataField="modified" dataType="date"caption="Modified" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="modified_By" caption="Modified_By" alignment="left" width={150} headerCellRender={renderTitleHeader}/>
            </DataGrid>
            </Card>
            </Grid>
            </>
        );
    }
}
export {editData, globalEditID, unitNumbers, renderPageType};