import { IShopModel } from "./shop/getModels/getShopModelConstants";
import { Reducer, combineReducers, Dispatch } from "redux";
import { ById, ByIdNumber, PartsResponse, ToolRentalsResponse } from "../react/shared/publicInterfaces";
import { Server } from "./server";
import { AddOrderReducer } from "./toolRentals/add/order/addOrderReducer";
import { AddProjectReducer } from "./toolRentals/add/project/addProjectReducer";
import { AddRateReducer } from "./toolRentals/add/rate/addRateReducer";
import { AddUnitReducer } from "./toolRentals/add/unit/addUnitReducer";
import { IOrder } from "./toolRentals/getOrders/getOrdersConstants";
import { GetOrdersReducer } from "./toolRentals/getOrders/getOrdersReducer";
import { IProject } from "./toolRentals/getProjects/getProjectsConstants";
import { GetProjectsReducer } from "./toolRentals/getProjects/getProjectsReducer";
import { IRate } from "./toolRentals/getRates/getRatesConstants";
import { GetRatesReducer } from "./toolRentals/getRates/getRatesReducer";
import { IToken } from "./getToken/getTokenConstants";
import { GetTokenReducer } from "./getToken/getTokenReducer";
import { IUnit } from "./toolRentals/getUnits/getUnitsConstants";
import { GetUnitsReducer } from "./toolRentals/getUnits/getUnitsReducer";
import { GetClassAndTypesReducer } from "./toolRentals/powerDropdown/getClassAndType/getClassAndTypeReducer";
import { GetClassTypeAndUnitReducer } from "./toolRentals/powerDropdown/getClassTypeAndUnit/getClassTypeAndUnitReducer";
import { IProjectListDD } from "./toolRentals/powerDropdown/getProjectListDD/getProjectListDDConstants";
import { GetProjectListDDReducer } from "./toolRentals/powerDropdown/getProjectListDD/getProjectListDDReducer";
import { UpdateOrderReducer } from "./toolRentals/update/order/updateOrderReducer";
import { UpdateProjectReducer } from "./toolRentals/update/project/updateProjectReducer";
import { UpdateRateReducer } from "./toolRentals/update/rate/updateRateReducer";
import { UpdateUnitReducer } from "./toolRentals/update/unit/updateUnitReducer";
import { IValidateShopGroup } from "./toolRentals/validateShopGroup/validateShopGroupConstants";
import { ValidateShopGroupReducer } from "./toolRentals/validateShopGroup/validateShopGroupReducer";
import { IManufacturer } from "./parts/getManufacturers/getManufacturerConstants";
import { GetManufacturersReducer } from "./parts/getManufacturers/getManufacturerReducer";
import { UpdateManufacturerReducer } from "./parts/update/manufacturer/updateManufacturerReducer";
import { AddManufacturerReducer } from "./parts/add/manufacturer/addManufacturerReducer";
import { UpdateModelReducer } from "./parts/update/model/updateModelReducer";
import { AddModelReducer } from "./parts/add/model/addModelReducer";
import { IPart } from "./parts/getParts/getPartConstants";
import { GetPartsReducer } from "./parts/getParts/getPartReducer";
import { UpdatePartReducer } from "./parts/update/part/updatePartReducer";
import { AddPartReducer } from "./parts/add/part/addPartReducer";
import { IManufacturerListDD } from "./parts/powerDropdown/getManufacturerListDD/getManufacturerListDDConstants";
import { GetManufacturerListDDReducer } from "./parts/powerDropdown/getManufacturerListDD/getManufacturerListDDReducer";
import { IModelListDD } from "./parts/powerDropdown/getModelListDD/getModelListDDConstants";
import { GetModelListDDReducer } from "./parts/powerDropdown/getModelListDD/getModelListDDReducer";
import { IReport } from "./toolRentals/getReport/getReportConstants";
import { GetReportReducer } from "./toolRentals/getReport/getReportReducer";
import { IInvoices } from "./toolRentals/getInvoices/getInvoicesConstants";
import { GetInvoicesReducer } from "./toolRentals/getInvoices/getInvoicesReducer";
import { IModel } from "./parts/getModels/getModelConstants";
import { GetModelsReducer } from "./parts/getModels/getModelReducer";
import { IShopManufacture } from "./shop/getManufactures/getShopManufacturesConstants";
import { GetShopManufacturersReducer } from "./shop/getManufactures/getShopManufacturesReducer";
import { AddShopManufacturersReducer } from "./shop/addManufactures/addShopManufacturesReducer";
import { UpdateShopManufacturersReducer } from "./shop/updateManufactures/updateShopManufacturesReducer";
import { IShopLookups } from "./shop/getLookups/getLookupsConstants";
import { GetShopLookupsReducer } from "./shop/getLookups/getLookupsReducer";
import { GetShopModelsReducer } from './shop/getModels/getShopModelReducer';
import { AddShopModelReducer } from './shop/addModels/addShopModelsReducer';
import { UpdateShopModelReducer } from './shop/updateModels/updateShopModelsReducer';
import { IShopEquipment } from './shop/getEquipments/getEquipmentConstants';
import { AddShopEquipmentReducer } from './shop/addEquipments/addShopEquipmentsReducer';
import { UpdateShopEquipmentReducer } from './shop/updateEquipments/updateShopEquipmentsReducer';
import { GetShopEquipmentsReducer } from './shop/getEquipments/getEquipmentReducer';
import { IGetEquipmentType } from './shop/getEquipmentTypes/getEquipmentTypesConstants';
import { GetEquipmentTypesReducer } from './shop/getEquipmentTypes/getEquipmentTypesReducer';
import { AddEquipmentTypeReducer } from './shop/addEquipmentType/addEquipmentTypeReducer';
import { UpdateEquipmentTypeReducer } from './shop/updateEquipmentType/updateEquipmentTypeReducer';
import { IGetShopEquipmentColumn } from './shop/getShopEquipmentColumns/getShopEquipmentColumnsConstants';
import { GetShopEquipmentColumnsReducer } from './shop/getShopEquipmentColumns/getShopEquipmentColumnsReducer';
import { AssignEquipmentTypeColumnReducer } from "./shop/assignEquipmentTypeColumn/assignEquipmentTypeColumnReducer";
import { IPartDismantledEquipment } from "./parts/getPartsDismantledEquipments/getPartsDismantledEquipmentConstants";
import { GetPartsDismantledEquipmentsReducer } from "./parts/getPartsDismantledEquipments/getPartsDismantledEquipmentReducer";
import { GetDismantledLookupsReducer } from "./parts/getDismantledLookups/getDismantledLookupsReducer";
import { AddPartDismantledEquipmentReducer } from "./parts/add/dismantledEquipment/addDismantledEquipmentReducer";
import { UpdatePartDismantledEquipmentReducer } from "./parts/update/dismantledEquipment/updateDismantledEquipmentReducer";
import { GetEquipmentHistoryColumnsReducer } from "./shop/getEquipmentHistoryColumn/getEquipmentHistoryColumnReducer";
import { IGetEquipmentHistoryColumn } from "./shop/getEquipmentHistoryColumn/getEquipmentHistoryColumnConstants";
import { IDismantledLookups } from "./parts/getDismantledLookups/getDismantledLookupsConstants";
import { DeletePartReducer } from "./parts/delete/part/deletePartReducer";
import { DeleteDismantledReducer } from "./parts/delete/dismantled/deleteDismantledReducer";
import { GetAllPartsReducer } from "./parts/getAllParts/getAllPartsReducer";
import { IGetEquipmentVersionHistory } from "./shop/getEquipmentVersionHistory/getEquipmentVersionHistoryConstants";
import { GetEquipmentVersionHistoryReducer } from "./shop/getEquipmentVersionHistory/getEquipmentVersionHistoryReducer";
import { GetDismantledEquipmentFacetsReducer } from "./parts/getDismantledEquipmentFacets/getDismantledEquipmentFacetsReducer";
import { IDismantledEquipmentFacets } from "./parts/getDismantledEquipmentFacets/getDismantledEquipmentFacetsConstants";
import { IPartPickupTruck } from "./parts/getPartsDismantledPickupTrucks/getPartsDismantledPickupTruckConstants";
import { GetPartsDismantledPickupTrucksReducer } from "./parts/getPartsDismantledPickupTrucks/getPartsDismantledPickupTruckReducer";
import { DeletePickupTruckReducer } from "./parts/delete/pickupTruck/deletePickupTruckReducer";
import { UpdatePartPickupTruckReducer } from "./parts/update/pickupTruck/updatePickupTruckReducer";
import { AddPartPickupTruckReducer } from "./parts/add/pickupTruck/addPickupTruckReducer";
import { IPartHeavyTruck } from "./parts/getPartsDismantledHeavyTrucks/getPartsDismantledHeavyTruckConstants";
import { GetPartsDismantledHeavyTrucksReducer } from "./parts/getPartsDismantledHeavyTrucks/getPartsDismantledHeavyTruckReducer";
import { UpdatePartHeavyTruckReducer } from "./parts/update/heavyTruck/updateHeavyTruckReducer";
import { DeleteHeavyTruckReducer } from "./parts/delete/heavyTruck/deleteHeavyTruckReducer";
import { AddPartHeavyTruckReducer } from "./parts/add/heavyTruck/addHeavyTruckReducer";

export interface IAction {
  type: any;
  [key: string]: any;
}

export type ReducerMap<T> = { [K in keyof T]: Reducer<T[K], IAction>; };

export type IDispatch = Dispatch<IAction>;

export interface IStore {
  GetToken: Server<ToolRentalsResponse<IToken>>;
  ValidateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
  ToolRentals: {    
    GetProjects: Server<ToolRentalsResponse<ByIdNumber<IProject>>>;
    GetOrders: Server<ToolRentalsResponse<ByIdNumber<IOrder>>>;
    GetUnits: Server<ToolRentalsResponse<ByIdNumber<IUnit>>>;
    GetRates: Server<ToolRentalsResponse<ByIdNumber<IRate>>>;
    GetReport: Server<ToolRentalsResponse<ByIdNumber<IReport>>>;
    GetInvoices: Server<ToolRentalsResponse<ByIdNumber<IInvoices>>>;
    Update: {
      Project: Server<string>;
      Rate: Server<string>;
      Unit: Server<string>;
      Order: Server<string>;
    };
    Add: {
      Project: Server<string>;
      Rate: Server<string>;
      Unit: Server<string>;
      Order: Server<string>;
    },
    PowerDropDown: {
      ClassAndType: Server<ToolRentalsResponse<IRate[]>>;
      ProjectListDD: Server<ToolRentalsResponse<IProjectListDD[]>>;
      ClassTypeAndUnit: Server<ToolRentalsResponse<IUnit[]>>;
    }
  },

  Parts:{   
    GetManufacturers: Server<PartsResponse<IManufacturer[]>>;
    GetModels: Server<PartsResponse<IModel[]>>;
    GetParts: Server<PartsResponse<IPart[]>>;
    GetAllParts:Server<PartsResponse<ById<IPart>>>;
    GetPartsDismantledEquipments: Server<PartsResponse<IPartDismantledEquipment[]>>;
    GetPartsPickupTrucks: Server<PartsResponse<IPartPickupTruck[]>>;
    GetPartsHeavyTrucks: Server<PartsResponse<IPartHeavyTruck[]>>;
    GetDismantledEquipmentLookups: Server<PartsResponse<IDismantledLookups>>;
    GetDismantledEquipmentFacets: Server<PartsResponse<IDismantledEquipmentFacets[]>>;
    DELETE_DISMANTLED: Server<string>;
    Update: {
      Manufacturer: Server<string>;
      Model: Server<string>;
      Part: Server<string>;
      PartDismantledEquipment: Server<string>;
      PartPickupTruck: Server<string>;
      PartHeavyTruck: Server<string>;
    };
    Delete: {
      Part: Server<string>;
      PickupTruck: Server<string>;
      HeavyTruck: Server<string>;
    },
    Add: {
      Manufacturer: Server<string>;   
      Model: Server<string>;      
      Part: Server<string>;     
      PartDismantledEquipment: Server<string>;
      PartPickupTruck: Server<string>;
      PartHeavyTruck: Server<string>;
    },
    PowerDropDown: {      
      ManufacturerListDD: Server<PartsResponse<IManufacturerListDD[]>>;      
      ModelListDD: Server<PartsResponse<IModelListDD[]>>;      
    }    
  }
  Shop: {
    Manufacturer: Server<PartsResponse<ById<IShopManufacture>>>;
    AddManufacturer: Server<PartsResponse<string>>;
    UpdateManufacturer: Server<PartsResponse<string>>;
    ShopLookups: Server<PartsResponse<IShopLookups>>;
    Models: Server<PartsResponse<ById<IShopModel>>>;
    AddModel: Server<PartsResponse<string>>;
    UpdateModel: Server<PartsResponse<string>>;
    Equipments: Server<PartsResponse<ById<IShopEquipment>>>;
    AddEquipment: Server<PartsResponse<string>>;
    UpdateEquipment: Server<PartsResponse<string>>;
    GetEquipmentTypes: Server<PartsResponse<ById<IGetEquipmentType>>>;
    AddEquipmentType: Server<PartsResponse<string>>;
    UpdateEquipmentType: Server<PartsResponse<string>>;
    GetShopEquipmentColumns: Server<PartsResponse<IGetShopEquipmentColumn>>;
    AssignEquipmentTypeColumn: Server<PartsResponse<string>>;
    GetEquipmentHistoryColumns: Server<PartsResponse<IGetEquipmentHistoryColumn[]>>;
    GetEquipmentVersionHistory: Server<PartsResponse<IGetEquipmentVersionHistory[]>>;
  }
};

const reducerMap: ReducerMap<IStore> = {
  GetToken: GetTokenReducer,
  ValidateShopGroup: ValidateShopGroupReducer,
  ToolRentals: combineReducers({    
    GetProjects: GetProjectsReducer,
    GetOrders: GetOrdersReducer,
    GetUnits: GetUnitsReducer,
    GetRates: GetRatesReducer,
    GetReport: GetReportReducer,
    GetInvoices: GetInvoicesReducer,
    Update: combineReducers({
      Project: UpdateProjectReducer,
      Rate: UpdateRateReducer,
      Unit: UpdateUnitReducer,
      Order: UpdateOrderReducer
    }),
    Add: combineReducers({
      Project: AddProjectReducer,
      Rate: AddRateReducer,
      Unit: AddUnitReducer,
      Order: AddOrderReducer
    }),
    PowerDropDown: combineReducers({
      ClassAndType: GetClassAndTypesReducer,
      ProjectListDD: GetProjectListDDReducer,
      ClassTypeAndUnit: GetClassTypeAndUnitReducer
    })
  }),
  Parts: combineReducers({      
    GetManufacturers: GetManufacturersReducer,
    GetModels: GetModelsReducer,
    GetParts: GetPartsReducer,
    GetAllParts: GetAllPartsReducer,
    GetPartsDismantledEquipments: GetPartsDismantledEquipmentsReducer,
    GetPartsPickupTrucks: GetPartsDismantledPickupTrucksReducer,
    GetPartsHeavyTrucks: GetPartsDismantledHeavyTrucksReducer,
    GetDismantledEquipmentLookups: GetDismantledLookupsReducer,
    GetDismantledEquipmentFacets: GetDismantledEquipmentFacetsReducer,
    DELETE_DISMANTLED: DeleteDismantledReducer,
    Update: combineReducers({
      Manufacturer: UpdateManufacturerReducer,
      Model: UpdateModelReducer,
      Part: UpdatePartReducer,
      PartDismantledEquipment: UpdatePartDismantledEquipmentReducer,
      PartPickupTruck: UpdatePartPickupTruckReducer,
      PartHeavyTruck: UpdatePartHeavyTruckReducer
    }),
    Delete: combineReducers({
      Part: DeletePartReducer,
      PickupTruck: DeletePickupTruckReducer,
      HeavyTruck: DeleteHeavyTruckReducer
    }),
    Add: combineReducers({
      Manufacturer: AddManufacturerReducer,
      Model: AddModelReducer,
      Part: AddPartReducer,
      PartDismantledEquipment: AddPartDismantledEquipmentReducer,
      PartPickupTruck: AddPartPickupTruckReducer,
      PartHeavyTruck: AddPartHeavyTruckReducer
    }),
    PowerDropDown: combineReducers({     
      ManufacturerListDD: GetManufacturerListDDReducer,
      ModelListDD: GetModelListDDReducer      
    })
  }),
  Shop: combineReducers({
    Manufacturer: GetShopManufacturersReducer,
    AddManufacturer: AddShopManufacturersReducer,
    UpdateManufacturer: UpdateShopManufacturersReducer,
    ShopLookups: GetShopLookupsReducer,
    Models: GetShopModelsReducer,
    AddModel: AddShopModelReducer,
    UpdateModel: UpdateShopModelReducer,
    Equipments: GetShopEquipmentsReducer,
    AddEquipment: AddShopEquipmentReducer,
    UpdateEquipment: UpdateShopEquipmentReducer,
    GetEquipmentTypes: GetEquipmentTypesReducer,
    AddEquipmentType: AddEquipmentTypeReducer,
    UpdateEquipmentType: UpdateEquipmentTypeReducer,
    GetShopEquipmentColumns: GetShopEquipmentColumnsReducer,
    AssignEquipmentTypeColumn: AssignEquipmentTypeColumnReducer,
    GetEquipmentHistoryColumns: GetEquipmentHistoryColumnsReducer,
    GetEquipmentVersionHistory: GetEquipmentVersionHistoryReducer
  })
};

export default combineReducers<IStore>(reducerMap);