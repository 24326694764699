import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IUPDATE_SHOP_EQUIPMENT_REQUEST } from "./updateShopEquipmentsConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";
import { IUpdateShopEquipmentLoadAction, IUpdateShopEquipmentLoadFailedAction, IUpdateShopEquipmentSuccessAction } from "./updateShopEquipmentsActions";

type Actions =
    | IUpdateShopEquipmentLoadAction
    | IUpdateShopEquipmentSuccessAction
    | IUpdateShopEquipmentLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateShopEquipmentReducer = (state: Server<PartsResponse<string>> = notLoaded, action: Actions): Server<PartsResponse<string>> => {
    switch (action.type) {
        case IUPDATE_SHOP_EQUIPMENT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_SHOP_EQUIPMENT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_SHOP_EQUIPMENT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};