import { ReactNode, PureComponent } from "react";
import styled from "styled-components";
import { IDismantledEquipmentFacets } from "../../../../redux/parts/getDismantledEquipmentFacets/getDismantledEquipmentFacetsConstants";
import { LAButton } from "../../../shared/buttons";
import LAChip from "../../../shared/chip";
import { ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { HasClass } from "../../../shared/publicInterfaces";
import { MEDIA_QUERY_PHONE } from "../../../shared/theme";
import LAFacetFiltersList from "./facetFiltersList";


interface ILAFacetProps extends HasClass {
    numberOfItems: number;
    facets: IDismantledEquipmentFacets[];
    preSelected: IDismantledEquipmentFacets[];
    onFacetUpdateClick: (values: IDismantledEquipmentFacets[]) => void;
};

interface ILAFacetState {
    fixFilter: boolean;
    viewMore: any;
    selectedValues: IDismantledEquipmentFacets[];
    facetValues: IDismantledEquipmentFacets[] | undefined;
};

const FacetStyles = styled.div`
 
    padding: 1.75rem 20px !important;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        padding-top: 0.75rem !important;
        
        .filterWrapper {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 5;
        };
    };
`;

class LAFacet extends PureComponent<ILAFacetProps, ILAFacetState> {

    public constructor(props: ILAFacetProps) {
        super(props);

        this.state = {
            facetValues: undefined,
            selectedValues: [],
            fixFilter: false,
            viewMore: {
                id: "",
                count: 3
            }
        };
    }

    public componentDidMount(): void {
        this.setState({ selectedValues: this.props.preSelected });
    };

    public componentDidUpdate(prevProps: ILAFacetProps): void {
        if(prevProps.preSelected !== this.props.preSelected)
            this.setState({ selectedValues: this.props.preSelected });
    };


    public render(): ReactNode {

        const { selectedValues, fixFilter, viewMore } = this.state;
        const { numberOfItems, facets } = this.props;

        return (
            <FacetStyles>

                <LAGrid direction="column">
                    {(Object.values(selectedValues).length > ZEROTH || (numberOfItems === ZEROTH)) &&
                        <LAGridItem xs={12} className="mb-2">
                            <LAGrid direction="column">
                                <LAButton
                                    label="CLEAR ALL"
                                    className="mb-2"
                                    onClick={this.handleClearAllFacets}
                                />

                                    {selectedValues.length > 0 && selectedValues.length > 0 &&
                                    <>
                                        <strong>Selected Equipment</strong>
                                        {selectedValues
                                            .map((x, idx): JSX.Element => (
                                                <LAGridItem xs={12} md={12} key={idx}>
                                                    <LAChip
                                                        label={x.equipment}
                                                        className="mt-2 mb-2"
                                                        onClick={(): void => this.handleFacetRemove(x.equipment, "equipment", idx)}
                                                        onDelete={(): void => this.handleFacetRemove(x.equipment, "equipment", idx)}
                                                    />
                                                </LAGridItem>
                                            ))}
                                    </>}
                            </LAGrid>
                        </LAGridItem>
                    }

                    <LAGridItem xs={12}>
                        <div id="filterBox" className={`${fixFilter ? "filterWrapper" : ""}`}>
                            <LAGrid>
                                <LAGridItem xs={12}>
                                    <LAFacetFiltersList
                                        viewMore={viewMore}
                                        facets={facets ? facets : []}
                                        onViewMoreClick={this.onViewMoreClick}
                                        selectedValues={selectedValues}
                                        onIndividualFacetClick={this.handleFacetClick}
                                    />
                                </LAGridItem>
                            </LAGrid>
                        </div>
                    </LAGridItem>


                </LAGrid>
            </FacetStyles>
        );
    };


    private onViewMoreClick = (type: string): void => {
        this.setState({
            viewMore: {
                id: type,
                count: this.state.viewMore.count + 10
            }
        });
    };

    private handleClearAllFacets = (): void => {
        this.setState({ selectedValues: [] });
        this.callSearch([]);
    };

    private callSearch = (values: IDismantledEquipmentFacets[]): void => {
        this.props.onFacetUpdateClick(values);
    };

    private handleFacetRemove = (id: number | string, type: string, index: number): void => {
        const currentSelected: IDismantledEquipmentFacets[] = [...this.state.selectedValues];

        currentSelected.splice(index, 1);

        this.setState({ selectedValues: currentSelected });
        this.callSearch(currentSelected);
    };

    private handleFacetClick = (id: number | string, type: string): void => {
        const currentSelected: IDismantledEquipmentFacets[] = [...this.state.selectedValues];

            const find = this.props.facets.find(x => x.equipment === id);

            let check = false;
            currentSelected.forEach(x => {
                if (x.equipment === id)
                    check = true;
            });
    
            if (check === false && find)
                currentSelected.push(find);

        this.setState({ selectedValues: currentSelected });
        this.callSearch(currentSelected);
    };

};


export default LAFacet;
