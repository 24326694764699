import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_MODELS_REQUEST, IModel } from "./getModelConstants";
import { IGetModelsLoadAction, IGetModelsLoadFailedAction, IGetModelsSuccessAction, getModelsLoadFailedAction, getModelsLoadSuccessAction } from "./getModelActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { PARTS_END_POINTS } from "../../endpoints";
import { withJsonContentType } from "../../epicUtils";

export const getModelsEpic: Epic = (
    action$: ActionsObservable<IGetModelsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetModelsSuccessAction | IGetModelsLoadFailedAction> =>
    action$.ofType(IGET_MODELS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IModel[]>>(
                    PARTS_END_POINTS.GET_PARTS.MODELS.GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IModel[]>): IGetModelsSuccessAction => {
                            return getModelsLoadSuccessAction(response);
                        }),
                        catchError((response: PartsResponse<IModel[]>) => ActionsObservable.of(getModelsLoadFailedAction(response.message)))
                    )
            )
        );