import React from "react";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LAErrorBox from "../../../shared/errorBox";
import { LAPopover } from "../../../shared/popOver";
import { STATUS_ENUM } from "../../../../redux/server";
import { LACenteredLoading } from "../../../shared/loading";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IGetEquipmentHistoryColumn } from "../../../../redux/shop/getEquipmentHistoryColumn/getEquipmentHistoryColumnConstants";
import styled from "styled-components";
import { DARK_GREY_COLOR, LIGHT_GREY_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { getDate, undefinedFunction } from "../../../shared/constExports";
import { IGetEquipmentVersionHistory } from "../../../../redux/shop/getEquipmentVersionHistory/getEquipmentVersionHistoryConstants";
import { LADevExtremeGrid } from "../../../shared/devExtreme";

interface IHistoryPopupProps {
    open: boolean;
    column: string;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: IGetEquipmentHistoryColumn[];
};

const HistoryPopupStyles = styled(LAPaperWithPadding)`
        .title {
            font-weight: bold;
            color: ${WHITE_COLOR};
            background-color: ${DARK_GREY_COLOR};
        };

    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
`;

export const HistoryPopup: React.FC<IHistoryPopupProps> = React.memo((props: IHistoryPopupProps) => {

    const onCancel = () => props.onCancel();

    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <HistoryPopupStyles>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>{props.column.toLocaleUpperCase()} HISTORY</h3>
                    <hr />
                </LAGridItem>

                {props.status === STATUS_ENUM.LOADING && <LAGridItem xs={12}>
                    <LACenteredLoading message="Loading History..." />
                </LAGridItem>}

                {props.status === STATUS_ENUM.FAILED && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}

                {props.status === STATUS_ENUM.SUCCEEDED && <LAGridItem xs={12}>
                    {(props.data && props.data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        <Table aria-label="history table">
                            <TableHead>
                                <TableRow className="header-row">
                                    <TableCell className="title" align="center">History</TableCell>
                                    {props.data.some(q => q.owned_By !== undefined) ? <TableCell className="title" align="center">Owned By</TableCell> : <></>}
                                    {props.data.some(q => q.start_Date !== undefined) ? <TableCell className="title" align="center">Start Date</TableCell> : <></>}
                                    {props.data.some(q => q.end_Date !== undefined) ? <TableCell className="title" align="center">End Date</TableCell> : <></>}
                                    {props.data.some(q => q.comments !== undefined) ? <TableCell className="title" align="center">Comments</TableCell> : <></>}
                                    <TableCell className="title" align="center">Created</TableCell>
                                    <TableCell className="title" align="center">Created By</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.map((row: IGetEquipmentHistoryColumn, index: number) => (
                                    <TableRow key={index} className={index % 2 ? "odd-row" : ""}>
                                        <TableCell component="th" scope="row" align="center">
                                            {row.history}
                                        </TableCell>
                                        {props.data.some(q => q.owned_By !== undefined) ? <TableCell align="center">{row.owned_By}</TableCell> : <></>}
                                        {props.data.some(q => q.start_Date !== undefined) ? <TableCell align="center">{getDate(false, row.start_Date)}</TableCell> : <></>}
                                        {props.data.some(q => q.end_Date !== undefined) ? <TableCell align="center">{getDate(false, row.end_Date)}</TableCell> : <></>}
                                        {props.data.some(q => q.comments !== undefined) ? <TableCell align="center">{row.comments}</TableCell> : <></>}
                                        <TableCell align="center">{getDate(false, row.created)}</TableCell>
                                        <TableCell align="center">{row.created_By}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </HistoryPopupStyles>
    </LAPopover>
});

interface IVersionHistoryPopupProps {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: IGetEquipmentVersionHistory[];
};

export const VersionHistoryPopup: React.FC<IVersionHistoryPopupProps> = React.memo((props: IVersionHistoryPopupProps) => {

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null}>
        <LAPaperWithPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>VERSION HISTORY</h3>
                    <hr />
                </LAGridItem>

                {props.status === STATUS_ENUM.LOADING && <LAGridItem xs={12}>
                    <LACenteredLoading message="Loading Version History..." />
                </LAGridItem>}

                {props.status === STATUS_ENUM.FAILED && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}

                {props.status === STATUS_ENUM.SUCCEEDED && (props.data && props.data.length === 0)
                    && <LAGridItem xs={12}>
                        <LAErrorBox text="No records Found" />
                    </LAGridItem>}

                <LAGridItem xs={12}>
                    <LADevExtremeGrid
                        height={600}
                        id="versionHistory"
                        searchPanel={false}
                        filterHeader={false}
                        data={props.data}
                        onClick={undefinedFunction}
                        columns={[
                            { name: "unit_Number", caption: "Unit #", type: "string", sortAsc: true },
                            { name: "equipment", caption: "Equipment", type: "string" },
                            { name: "equipment_Type", caption: "Equipment Type", type: "string" },
                            { name: "owner", caption: "Owner", type: "string" },
                            { name: "serial_Number", caption: "Serial Number", type: "string" },
                            { name: "make", caption: "Make", type: "string" },
                            { name: "model", caption: "Model", type: "string" },
                            { name: "details", caption: "Details", type: "string" },
                            { name: "tire_Size", caption: "Tire Size", type: "string" },
                            { name: "status", caption: "Status", type: "string" },
                            { name: "year", caption: "Year", type: "number" },
                            { name: "driver", caption: "Driver", type: "string" },
                            { name: "plate", caption: "Plate", type: "string" },
                            { name: "current_Hours", caption: "Current Hours", type: "string" },
                            { name: "gpS_Level", caption: "GPS Level", type: "string" },
                            { name: "cert_Number", caption: "Cert Number", type: "string" },
                            { name: "equipment_Details", caption: "Equipment Details", type: "string" },
                            { name: "modified", caption: "Modified", type: "date" },
                            { name: "modified_By", caption: "Modified By", type: "string" }

                        ]}
                    />
                </LAGridItem>

            </LAGrid>
        </LAPaperWithPadding>
    </LAPopover>
});