import { IPaginationRequest } from "../../../../react/shared/publicInterfaces";
import { IManufacturer } from "../../getManufacturers/getManufacturerConstants";

export enum IADD_MANUFACTURER_REQUEST {
    REQUEST = "addManufacturer/ADD_MANUFACTURER_REQUEST",
    SUCCESS = "addManufacturer/ADD_MANUFACTURER_SUCCESS",
    FAILED = "addManufacturer/ADD_MANUFACTURER_FAILED"
};

export interface IAddManufacturerRequest extends IPaginationRequest {
    request: IManufacturer;
    token: string;
};