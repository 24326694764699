import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../clearRedux/clearReduxConstants";
import { IGET_INVOICES_REQUEST, IInvoices } from "./getInvoicesConstants";
import { IGetInvoicesLoadAction, IGetInvoicesLoadFailedAction, IGetInvoicesSuccessAction } from "./getInvoicesActions";
import { IAddRateSuccessAction } from "../add/rate/addRateActions";
import { IADD_RATE_REQUEST } from "../add/rate/addRateConstants";

type Actions =
    | IGetInvoicesLoadAction
    | IGetInvoicesSuccessAction
    | IGetInvoicesLoadFailedAction
    | IAddRateSuccessAction
    | IFlushDataSuccessAction;

export const GetInvoicesReducer = (state: Server<ToolRentalsResponse<ByIdNumber<IInvoices>>> = notLoaded, action: Actions): Server<ToolRentalsResponse<ByIdNumber<IInvoices>>> => {
    switch (action.type) {
        case IGET_INVOICES_REQUEST.REQUEST:
            return loading;

        case IGET_INVOICES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_INVOICES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_RATE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};