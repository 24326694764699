import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_MODEL_LIST_DD_REQUEST, IModelListDD } from "./getModelListDDConstants";
import { IAddModelSuccessAction } from "../../add/model/addModelActions";
import { IADD_MODEL_REQUEST } from "../../add/model/addModelConstants";
import { IGetModelListDDLoadAction, IGetModelListDDLoadFailedAction, IGetModelListDDSuccessAction } from "./getModelListDDActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IGetModelListDDLoadAction
    | IGetModelListDDSuccessAction
    | IGetModelListDDLoadFailedAction
    | IAddModelSuccessAction
    | IFlushDataSuccessAction;

export const GetModelListDDReducer = (state: Server<ToolRentalsResponse<IModelListDD[]>> = notLoaded, action: Actions): Server<ToolRentalsResponse<IModelListDD[]>> => {
    switch (action.type) {
        case IGET_MODEL_LIST_DD_REQUEST.REQUEST:
            return loading;

        case IGET_MODEL_LIST_DD_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MODEL_LIST_DD_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_MODEL_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};