import { IPaginationRequest } from "../../../react/shared/publicInterfaces";

export enum IGET_MODELS_REQUEST {
    REQUEST = "getModels/GET_MODELS_REQUEST",
    SUCCESS = "getModels/GET_MODELS_SUCCESS",
    FAILED = "getModels/GET_MODELS_FAILED"
};

export interface IModelRequest extends IPaginationRequest {
    request: {};
    token: string;    
};

export interface IModel {
    created: string;
    created_By: string;
    id: number;
    manufacturer: string;
    model: string;
    modified: string;
    modified_By: string;
    type: string;
};

export const AvailableValues: {id: number, name: string}[] = [{id: 1, name: "Yes"},{id: 2, name: "No"}];