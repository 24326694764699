import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isFailed, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, PartsResponse, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { undefinedFunction, userName } from "../../../shared/constExports";
import { LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import PageSpacing from "../../../shared/pageSpacing";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IValidateShopGroup, ReadAndWrite, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../../shared/roleBaseAccessForShop";
import LAErrorBox from "../../../shared/errorBox";
import { IShopEquipmentRequest } from "../../../../redux/shop/getEquipments/getEquipmentConstants";
import { IGetEquipmentType, IGetEquipmentTypeRequest } from "../../../../redux/shop/getEquipmentTypes/getEquipmentTypesConstants";
import { getEquipmentTypesLoadAction } from "../../../../redux/shop/getEquipmentTypes/getEquipmentTypesActions";
import { getEquipmentTypes } from "../../../../redux/shop/getEquipmentTypes/getEquipmentTypesAccessor";
import LAAutoComplete from "../../../shared/autoComplete";
import { IAssignEquipmentTypeColumnRequest } from "../../../../redux/shop/assignEquipmentTypeColumn/assignEquipmentTypeColumnConstants";
import { assignEquipmentTypeColumnLoadAction } from "../../../../redux/shop/assignEquipmentTypeColumn/assignEquipmentTypeColumnActions";
import { assignEquipmentTypeColumnStatus } from "../../../../redux/shop/assignEquipmentTypeColumn/assignEquipmentTypeColumnAccessor";
import { getShopEquipmentColumns } from "../../../../redux/shop/getShopEquipmentColumns/getShopEquipmentColumnsAccessor";
import { IGetShopEquipmentColumn, IGetShopEquipmentColumnRequest, IMasterColumn } from "../../../../redux/shop/getShopEquipmentColumns/getShopEquipmentColumnsConstants";
import { getShopEquipmentColumnsLoadAction } from "../../../../redux/shop/getShopEquipmentColumns/getShopEquipmentColumnsActions";
import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Paper } from "@material-ui/core";
import React from "react";
import { ArrowFastForwardIcon, ArrowFastRewindIcon, ArrowLeftIcon, ArrowRightIcon } from "../../../shared/icons";
import { RED_COLOR, BLUE_COLOR } from "../../../shared/theme";
import { LACheckBox } from "../../../shared/checkBox";

interface IConfigEquipmentTypeStoreProps {
    getToken: Server<PartsResponse<IToken>>;
    assignEquipmentTypeColumn: Server<PartsResponse<string>>;
    getEquipmentTypes: Server<PartsResponse<ById<IGetEquipmentType>>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
    getShopEquipmentColumnsStatus: Server<PartsResponse<IGetShopEquipmentColumn>>;
};

interface IConfigEquipmentTypeDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getConfigEquipmentTypesRequest: (data: IShopEquipmentRequest) => unknown;
    getShopEquipmentColumnsRequest: (data: IGetShopEquipmentColumnRequest) => unknown;
    assignEquipmentTypeColumnRequest: (data: IAssignEquipmentTypeColumnRequest) => unknown;
};

interface IConfigEquipmentTypeOwnProps {

};

interface IConfigEquipmentTypeState {
    sError: string;
    selected: number;
    addedToList: IMasterColumn[];
    masterColumns: IMasterColumn[];
    listOneSelected: IMasterColumn[];
    listTwoSelected: IMasterColumn[];
};

const ConfigEquipmentTypeStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IConfigEquipmentTypeProps = RouteComponentProps
    & IConfigEquipmentTypeStoreProps
    & IConfigEquipmentTypeDispatchProps
    & IConfigEquipmentTypeOwnProps;

class ConfigEquipmentType extends PureComponent<IConfigEquipmentTypeProps, IConfigEquipmentTypeState> {

    public constructor(props: IConfigEquipmentTypeProps) {
        super(props);
        this.state = {
            sError: "",
            selected: 0,
            addedToList: [],
            masterColumns: [],
            listOneSelected: [],
            listTwoSelected: []
        };
    }

    public componentDidMount(): void {
        //this.callServer();
        window.location.href = "https://apps.sureway.ca/field/config";
    };

    public componentDidUpdate(prevProps: IConfigEquipmentTypeProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.assignEquipmentTypeColumn !== prevProps.assignEquipmentTypeColumn) {
                if (isSucceeded(this.props.assignEquipmentTypeColumn)) {
                    this.handleCancel();
                };
                if (isFailed(this.props.assignEquipmentTypeColumn)) {
                    this.setState({ sError: this.props.assignEquipmentTypeColumn.message as string });
                };
            };
        }
    };


    public render(): ReactNode {

        const { selected, sError, listOneSelected, listTwoSelected, addedToList, masterColumns } = this.state;
        const { getEquipmentTypes, assignEquipmentTypeColumn, validateShopGroup } = this.props;
        const getRole = hasPayload(validateShopGroup) ? validateShopGroup.payload.response.adminAccess : "";
        const readOnly = getRole === ReadOnly ? true : undefined;
        const types = hasPayload(getEquipmentTypes) ? getEquipmentTypes.payload.response : {};

        return (
            <PageSpacing title="Equipment List - Field Config" description="Equipment List Field Config" fixedSpaceOnSmallerScreens={true}>
                <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>
                    <ConfigEquipmentTypeStyles>
                        <h2 className="text-center">FIELDS CONFIG</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid justify="center" spacing={2}>

                                {sError.length > 0 && <LAGridItem xs={12}>
                                    <LAErrorBox text={sError} />
                                </LAGridItem>}

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        multiple={false}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={this.onEquipSelect}
                                        filterSelectedOptions={true}
                                        option={Object.values(types)}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Select Equipment Type"
                                        value={selected !== 0 ? types[selected] : ""}
                                        defaultValue={selected !== 0 ? types[selected] : ""}
                                    />
                                </LAGridItem>

                                {selected !== 0 && <LAGridItem xs={12}>
                                    <LAGrid className="text-center" spacing={2}>

                                        <LAGridItem xs={5}>
                                            <ColumnList
                                                {...this.state}
                                                items={masterColumns}
                                                title="Available Columns"
                                                selected={listOneSelected}
                                                onCheck={this.listOneCheck}
                                                onAllSelect={this.onAvailableAll}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <LAGrid>

                                                <LAGridItem xs={12}>
                                                    <LAIconButton
                                                        label="Move All to Right"
                                                        onClick={this.onMoveAllRight}
                                                        icon={<ArrowFastForwardIcon color={RED_COLOR} />}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    <LAIconButton
                                                        label="Move Selected to Right"
                                                        onClick={this.onMoveRight}
                                                        icon={<ArrowRightIcon color={RED_COLOR} />}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    <LAIconButton
                                                        label="Move Selected To Left"
                                                        onClick={this.onMoveLeft}
                                                        icon={<ArrowLeftIcon color={BLUE_COLOR} />}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    <LAIconButton
                                                        label="Move All to Left"
                                                        onClick={this.onMoveAllLeft}
                                                        icon={<ArrowFastRewindIcon color={BLUE_COLOR} />}
                                                    />
                                                </LAGridItem>

                                            </LAGrid>
                                        </LAGridItem>

                                        <LAGridItem xs={5}>
                                            <ColumnList
                                                {...this.state}
                                                items={addedToList}
                                                title="Assigned Columns"
                                                selected={listTwoSelected}
                                                onCheck={this.listTwoCheck}
                                                onAllSelect={this.onAllSelected}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                </LAGridItem>}

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={selected === 0 ? true : readOnly}
                                        disableCancel={selected === 0 ? true : undefined}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={assignEquipmentTypeColumn.kind} successMessage="Changes successfully saved" />
                    </ConfigEquipmentTypeStyles>
                </RoleBasedAccessForShop>
            </PageSpacing>
        );
    }

    private listOneCheck = (obj: IMasterColumn): void => {
        const listOneSelected = [...this.state.listOneSelected];
        const index = listOneSelected.indexOf(obj);
        if (index !== -1) {
            listOneSelected.splice(index, 1);
        } else {
            listOneSelected.push(obj);
        };
        this.setState({ listOneSelected });
    };

    private listTwoCheck = (obj: IMasterColumn): void => {
        const listTwoSelected = [...this.state.listTwoSelected];
        const index = listTwoSelected.indexOf(obj);
        if (index !== -1) {
            listTwoSelected.splice(index, 1);
        } else {
            listTwoSelected.push(obj);
        };
        this.setState({ listTwoSelected });
    };

    private onMoveRight = (): void => {
        const masterColumns = [...this.state.masterColumns];
        const addedToList = [...this.state.addedToList];
        const listOneSelected = this.state.listOneSelected;

        listOneSelected.forEach(x => {
            masterColumns.splice(masterColumns.indexOf(x), 1);
            addedToList.push(x);
        });

        this.setState({ addedToList, listOneSelected: [], listTwoSelected: [], masterColumns });
    };

    private onMoveLeft = (): void => {
        const masterColumns = [...this.state.masterColumns];
        const addedToList = [...this.state.addedToList];
        const listTwoSelected = this.state.listTwoSelected;

        listTwoSelected.forEach(x => {
            addedToList.splice(addedToList.indexOf(x), 1);
            masterColumns.push(x);
        });

        this.setState({ addedToList, listOneSelected: [], listTwoSelected: [], masterColumns });
    };

    private onMoveAllRight = (): void => {
        const masterColumns = this.state.masterColumns;
        const addedToList = [...this.state.addedToList];

        masterColumns.forEach(x => {
            addedToList.push(x);
        });

        this.setState({ addedToList, listOneSelected: [], listTwoSelected: [], masterColumns: [] });
    };

    private onAvailableAll = (): void => {
        const { listOneSelected, masterColumns } = this.state;
        if(masterColumns.length === listOneSelected.length){
            this.setState({ listOneSelected: [] });
        } else {
            this.setState({ listOneSelected: this.state.masterColumns });
        };
    };

    private onAllSelected = (): void => {
        const { listTwoSelected, addedToList } = this.state;
        if(addedToList.length === listTwoSelected.length){
            this.setState({ listTwoSelected: [] });
        } else {
            this.setState({ listTwoSelected: this.state.addedToList });
        };
    };

    private onMoveAllLeft = (): void => {
        const addedToList = this.state.addedToList;
        const masterColumns = [...this.state.masterColumns];

        addedToList.forEach(x => {
            masterColumns.push(x);
        });

        this.setState({ addedToList: [], listOneSelected: [], listTwoSelected: [], masterColumns });
    };

    private onEquipSelect = (event: unknown, value: IGetEquipmentType): void => {
        if (value !== null && hasPayload(this.props.getShopEquipmentColumnsStatus)) {

            const masterColumns: IMasterColumn[] = [];
            const addedToList: IMasterColumn[] = [];
            const mColumns = this.props.getShopEquipmentColumnsStatus.payload.response;
            const findMapped = mColumns.mapped_Columns.length > 0 ? mColumns.mapped_Columns.findIndex(x => x.equipment_Type === value.name) : -1;

            let pre: string[] = [];
            if (findMapped !== -1) {
                pre = mColumns.mapped_Columns[findMapped].column_IDs.split(",");
            };

            mColumns.master_Columns.forEach(x => {
                const index = pre.findIndex(q => (+q === x.id));
                if (index !== -1) {
                    addedToList.push(x);
                } else {
                    masterColumns.push(x);
                }
            });

            this.setState({ selected: value.id, addedToList, masterColumns, listOneSelected: [], listTwoSelected: [] });
        } else {
            this.handleCancel();
        };
    };

    private handleCancel = (): void => {
        this.setState({ selected: 0, addedToList: [], masterColumns: [], listOneSelected: [], listTwoSelected: [], sError: "" });
    };

    private onSave = (): void => {

        const { selected, addedToList } = this.state;
        const { getEquipmentTypes, getToken } = this.props;

        if (hasPayload(getToken) && hasPayload(getEquipmentTypes)) {

            let idsOnly: string = "";
            const total = addedToList.length - 1;
            for (let i = 0; i < addedToList.length; i++) {
                if (i === total) {
                    idsOnly = idsOnly + addedToList[i].id.toString();
                } else {
                    idsOnly = idsOnly + `${addedToList[i].id.toString()},`;
                };
            };

            this.props.assignEquipmentTypeColumnRequest({
                token: getToken.payload.response.token,
                request: {
                    Column_IDs: idsOnly,
                    Created_By: userName,
                    Modified_By: userName,
                    Equipment_Type: getEquipmentTypes.payload.response[selected.toString()].name,
                }
            });
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && shopRoleCheck([ReadOnly, ReadAndWrite])) {

            if (isNotLoaded(this.props.getEquipmentTypes))
                this.props.getConfigEquipmentTypesRequest({
                    token: this.props.getToken.payload.response.token
                });

            if (isNotLoaded(this.props.getShopEquipmentColumnsStatus))
                this.props.getShopEquipmentColumnsRequest({
                    token: this.props.getToken.payload.response.token
                });
        }

    };

}

const mapStateToProps = (state: IStore): IConfigEquipmentTypeStoreProps => ({
    getToken: getToken(state),
    getEquipmentTypes: getEquipmentTypes(state),
    validateShopGroup: validateShopGroup(state),
    getShopEquipmentColumnsStatus: getShopEquipmentColumns(state),
    assignEquipmentTypeColumn: assignEquipmentTypeColumnStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IConfigEquipmentTypeDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getConfigEquipmentTypesRequest: (data: IGetEquipmentTypeRequest) => dispatch(getEquipmentTypesLoadAction(data)),
    getShopEquipmentColumnsRequest: (data: IGetShopEquipmentColumnRequest) => dispatch(getShopEquipmentColumnsLoadAction(data)),
    assignEquipmentTypeColumnRequest: (data: IAssignEquipmentTypeColumnRequest) => dispatch(assignEquipmentTypeColumnLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigEquipmentType);



interface IListProps<T> {
    items: T[];
    selected: T[];
    title: string;
    onAllSelect: () => void;
    onCheck: (obj: IMasterColumn) => void;
};

const ColumnList: React.FC<IListProps<IMasterColumn>> = React.memo((props: IListProps<IMasterColumn>): JSX.Element =>
    <Paper style={{ width: "auto", height: 300, overflow: "auto" }}>
        <LACheckBox
            label=""
            onChange={props.onAllSelect}
            value={props.selected.length === props.items.length}
        />
        <strong>{props.title}</strong>
        <hr />
        <List dense component="div" role="list">
            {props.items.map((x: IMasterColumn, index: number) => {
                const onSelect = (): void => props.onCheck(x);
                return (
                    <ListItem
                        role="listitem"
                        button
                        key={index}
                        onClick={onSelect}
                    >
                        <ListItemIcon>
                            <Checkbox
                                tabIndex={-1}
                                disableRipple
                                checked={props.selected.indexOf(x) !== -1}
                            />
                        </ListItemIcon>
                        <ListItemText id={x.name} primary={x.name} />
                    </ListItem>
                );
            })}
        </List>
    </Paper>
);