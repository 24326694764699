import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IOrder } from "../../getOrders/getOrdersConstants";
import { IADD_ORDER_REQUEST, IAddOrderRequest } from "./addOrderConstants";


export interface IAddOrderLoadAction {
    type: IADD_ORDER_REQUEST.REQUEST;
    data: IAddOrderRequest
}
export const addOrderLoadAction = (data: IAddOrderRequest): IAddOrderLoadAction => ({
    type: IADD_ORDER_REQUEST.REQUEST,
    data
});
export interface IAddOrderSuccessAction {
    type: IADD_ORDER_REQUEST.SUCCESS;
    data: ToolRentalsResponse<IOrder[]>;
}
export const addOrderLoadSuccessAction = (data: ToolRentalsResponse<IOrder[]>): IAddOrderSuccessAction => ({
    type: IADD_ORDER_REQUEST.SUCCESS,
    data
});
export interface IAddOrderLoadFailedAction {
    type: IADD_ORDER_REQUEST.FAILED;
    message: ToolRentalsResponse<string[]>;
}
export const addOrderLoadFailedAction = (message: ToolRentalsResponse<string[]>): IAddOrderLoadFailedAction => ({
    type: IADD_ORDER_REQUEST.FAILED,
    message
});
