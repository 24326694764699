import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_PART_PICKUP_TRUCK_REQUEST } from "./addPickupTruckConstants";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { addPartPickupTruckLoadFailedAction, addPartPickupTruckLoadSuccessAction, IAddPartPickupTruckLoadAction, IAddPartPickupTruckLoadFailedAction, IAddPartPickupTruckSuccessAction } from "./addPickupTruckActions";
import { IPartPickupTruck } from "../../getPartsDismantledPickupTrucks/getPartsDismantledPickupTruckConstants";

export const addPartPickupTruckEpic: Epic = (
    action$: ActionsObservable<IAddPartPickupTruckLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddPartPickupTruckSuccessAction | IAddPartPickupTruckLoadFailedAction> =>
    action$.ofType(IADD_PART_PICKUP_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IPartPickupTruck[]>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS_DISMANTLED_PICKUP_TRUCKS.ADD,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<IPartPickupTruck[]>): IAddPartPickupTruckSuccessAction | IAddPartPickupTruckLoadFailedAction => {
                            if(response.message === "Success"){
                                return addPartPickupTruckLoadSuccessAction(response);
                            }
                            return addPartPickupTruckLoadFailedAction(response.message);
                        }),
                        catchError((response: PartsResponse<IPartPickupTruck[]>) => ActionsObservable.of(addPartPickupTruckLoadFailedAction(response.message)))
                    )
            )
        );