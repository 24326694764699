import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IDispatch, IStore } from "../../../redux/reducers";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAPaperWithPadding } from "../../shared/paper";
import { LAButton } from "../../shared/buttons";
import { ROUTE } from "../../routes";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { userName } from "../../shared/constExports";
import { images } from "./desktopHome";



interface IMobileHomeStoreProps {

};

interface IMobileHomeDispatchProps {

};

interface IMobileHomeOwnProps {

};

interface IMobileHomeState {

};

const MobileHomeStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;
`;

type IMobileHomeProps = RouteComponentProps
    & IMobileHomeStoreProps
    & IMobileHomeDispatchProps
    & IMobileHomeOwnProps;

class MobileHome extends PureComponent<IMobileHomeProps, IMobileHomeState> {

    public constructor(props: IMobileHomeProps) {
        super(props);
        this.state = {

        };

    }

    public componentDidMount(): void {

    };

    public async componentDidUpdate(prevProps: IMobileHomeProps, prevState: IMobileHomeState): Promise<void> {
    };

    public render(): ReactNode {

        return (
            <MobileHomeStyles>
                <LAGrid spacing={3} className="text-center">

                    <LAGridItem xs={12}>
                        <h2>Welcome {userName}</h2>
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LAButton fullWidth={true} label="Parts" onClick={this.onPartsClick} />
                    </LAGridItem>

                    <LAGridItem xs={12} className="slider">
                        <ImageGallery
                            showNav={false}
                            showPlayButton={false}
                            showFullscreenButton={false}
                            showThumbnails={true}
                            autoPlay={true}
                            items={images}
                        />
                    </LAGridItem>

                </LAGrid>
            </MobileHomeStyles>
        );
    }

    private onPartsClick = (): void => {
        this.props.history.push(ROUTE.PARTS.PART.INDEX);
    };

}

const mapStateToProps = (state: IStore): IMobileHomeStoreProps => ({

});

const mapDispatchToProps = (dispatch: IDispatch): IMobileHomeDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHome);