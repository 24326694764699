import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IDELETE_PART_REQUEST } from "./deletePartConstants";
import { IDeletePartLoadAction, IDeletePartLoadFailedAction, IDeletePartSuccessAction, deletePartLoadFailedAction, deletePartLoadSuccessAction } from "./deletePartActions";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const deletePartEpic: Epic = (
    action$: ActionsObservable<IDeletePartLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IDeletePartSuccessAction | IDeletePartLoadFailedAction> =>
    action$.ofType(IDELETE_PART_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    PARTS_END_POINTS.GET_PARTS.PARTS.DELETE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<string>): IDeletePartSuccessAction | IDeletePartLoadFailedAction => {
                            return deletePartLoadSuccessAction(response.message);
                        }),
                        catchError((response: PartsResponse<any>) => ActionsObservable.of(deletePartLoadFailedAction(response.message)))
                    )
            )
        );