import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_PROJECT_REQUEST } from "./updateProjectConstants";
import { IUpdateProjectLoadAction, IUpdateProjectLoadFailedAction, IUpdateProjectSuccessAction, updateProjectLoadFailedAction, updateProjectLoadSuccessAction } from "./updateProjectActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const updateProjectEpic: Epic = (
    action$: ActionsObservable<IUpdateProjectLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateProjectSuccessAction | IUpdateProjectLoadFailedAction> =>
    action$.ofType(IUPDATE_PROJECT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<null>>(
                    END_POINTS.GET_TOOL_RENTALS.PROJECTS.UPDATE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<null>): IUpdateProjectSuccessAction | IUpdateProjectLoadFailedAction => {
                            if(response.message === "Success"){
                            return updateProjectLoadSuccessAction(action.data.request);
                            }
                            return updateProjectLoadFailedAction(response.message);
                        }),
                        catchError((response: ToolRentalsResponse<any>) => ActionsObservable.of(updateProjectLoadFailedAction(response.message)))
                    )
            )
        );