/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import ReactDOM from 'react-dom';
import App from './app';
import './index.css';
import registerServiceWorker from "./serviceWorkerDev";
import reportWebVitals from './reportWebVitals';
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';

const DO_NOT_LOGIN = false;

// In order to run app locally comment this section (Production with Login) and uncomment section (Development without Login)

// #region Production with Login




runWithAdal(authContext, () => {
  ReactDOM.render(
    <App />,
  document.getElementById('root')
);
reportWebVitals();
},DO_NOT_LOGIN);



// #endregion


//#region Development without Login


// ReactDOM.render(
//   <App />,
// document.getElementById('root')
// );
// reportWebVitals();



registerServiceWorker();
//#endregion


