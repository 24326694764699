import { IOrder } from "../../getOrders/getOrdersConstants";

export enum IADD_ORDER_REQUEST {
    REQUEST = "addOrder/ADD_ORDER_REQUEST",
    SUCCESS = "addOrder/ADD_ORDER_SUCCESS",
    FAILED = "addOrder/ADD_ORDER_FAILED"
};

export interface IAddOrderRequest {
    request: IOrder;
    token: string;
};