import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_MODEL_REQUEST } from "./updateModelConstants";
import { IUpdateModelLoadAction, IUpdateModelLoadFailedAction, IUpdateModelSuccessAction, updateModelLoadFailedAction, updateModelLoadSuccessAction } from "./updateModelActions";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const updateModelEpic: Epic = (
    action$: ActionsObservable<IUpdateModelLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateModelSuccessAction | IUpdateModelLoadFailedAction> =>
    action$.ofType(IUPDATE_MODEL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<null>>(
                    PARTS_END_POINTS.GET_PARTS.MODELS.UPDATE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<null>): IUpdateModelSuccessAction | IUpdateModelLoadFailedAction => {
                            if(response.message === "Success"){
                            return updateModelLoadSuccessAction(action.data.request);
                            }
                            return updateModelLoadFailedAction(response.message);
                        }),
                        catchError((response: PartsResponse<any>) => ActionsObservable.of(updateModelLoadFailedAction(response.message)))
                    )
            )
        );