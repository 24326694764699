import React from 'react';
import {TextField, Select, Paper, Grid, Button} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {RouteComponentProps} from 'react-router-dom';
import CreateSOCTMain, {soct_data} from './createSOCTMain';
import ServiceHistoryTable from './serviceHistoryTable';
import MeterHistoryTable from './meterHistoryTable';
import MoveHistoryTable from './moveHistorytable';
import CustomHistoryTable from './customHistoryTable';
import {unitNumbers} from '../../../sotc';

interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
    showMain:boolean;showMeterHist:boolean;showMoveHist:boolean;showCustomHist:boolean;showServiceHist:boolean;
}
export default class CreateSOTCPage extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false};
        // this.renderCustomHistory = this.renderCustomHistory.bind(this);
        // this.renderMeterHistory = this.renderMeterHistory.bind(this);
        // this.renderMoveHistory = this.renderMoveHistory.bind(this);
        // this.renderServiceHist = this.renderServiceHist.bind(this);
        this.renderSOCTMain = this.renderSOCTMain.bind(this);
        // this.onFormCancel = this.onFormCancel.bind(this);
        // this.onFormSave = this.onFormSave.bind(this);
    }
    renderSOCTMain(){
        this.setState({showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false});
    }
    // renderMeterHistory(){
    //     this.setState({showMeterHist:true,showMain:false,showMoveHist:false,showCustomHist:false,showServiceHist:false});
    // }
    // renderMoveHistory(){
    //     this.setState({showMoveHist:true,showCustomHist:false,showServiceHist:false,showMain:false,showMeterHist:false});
    // }
    // renderCustomHistory(){
    //     this.setState({showCustomHist:true,showMain:false,showMeterHist:false,showServiceHist:false,showMoveHist:false});
    // }
    // renderServiceHist(){
    //     this.setState({showServiceHist:true,showMoveHist:false,showMain:false,showMeterHist:false,showCustomHist:false});
    // }
    // onFormSave(){
    //     console.log('Saving data with: ', soct_data)
    // }
    // onFormCancel(){
    //     this.props.history.goBack();
    // }
    render(){
        return(
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Creating new SOCT</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Button
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                   onClick={this.renderSOCTMain}
                                >
                                    Sureway Oil Change Tracker
                                </Button>
                              
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    {/* <Button
                                         sx={{ my: 2, color: 'white', display: 'block'}}
                                         onClick={this.renderServiceHist}
                                     >
                                         Service History
                                     </Button>
                                   
                                        <Button
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                            onClick={this.renderMeterHistory}
                                        >
                                            Meter History
                                        </Button>

                                        <Button
                                         sx={{ my: 2, color: 'white', display: 'block' }}
                                         onClick={this.renderMoveHistory}
                                     >
                                         Equipment Move History
                                     </Button>
                                     
                                     <Button
                                         sx={{ my: 2, color: 'white', display: 'block' }}
                                         onClick={this.renderCustomHistory}
                                     >
                                         Custom History
                                     </Button> */}
                                    </Box>
                                    </Toolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                {this.state.showMain ?
                   <CreateSOCTMain history={this.props.history} unitNumbers={unitNumbers}></CreateSOCTMain>
                :null}
                {/* {this.state.showMeterHist ?  
                    <MeterHistoryTable history={this.props.history}></MeterHistoryTable>
                :null}
                {this.state.showServiceHist ?  
                    <ServiceHistoryTable history={this.props.history}></ServiceHistoryTable>
                :null}
                {this.state.showCustomHist ?  
                   <CustomHistoryTable history={this.props.history}></CustomHistoryTable>
                :null}
                {this.state.showMoveHist ?  
                    <MoveHistoryTable history={this.props.history}></MoveHistoryTable>
                :null}  */}
                    {/* <Grid item xs={12}><br></br>
                            <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                            <Button style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onFormSave} variant="contained">Save</Button>
                        </Grid> */}
                  
                    </Paper>
                    </Paper>
                </Paper>
            </div>
        )
    }
}