import { IUPDATE_PART_HEAVY_TRUCK_REQUEST, IUpdatePartHeavyTruckRequest, IAddUpdatePartHeavyTruck } from "./updateHeavyTruckConstants";


export interface IUpdatePartHeavyTruckLoadAction {
    type: IUPDATE_PART_HEAVY_TRUCK_REQUEST.REQUEST;
    data: IUpdatePartHeavyTruckRequest
}
export const updatePartHeavyTruckLoadAction = (data: IUpdatePartHeavyTruckRequest): IUpdatePartHeavyTruckLoadAction => ({
    type: IUPDATE_PART_HEAVY_TRUCK_REQUEST.REQUEST,
    data
});
export interface IUpdatePartHeavyTruckSuccessAction {
    type: IUPDATE_PART_HEAVY_TRUCK_REQUEST.SUCCESS;
    data: IAddUpdatePartHeavyTruck;
}
export const updatePartHeavyTruckLoadSuccessAction = (data: IAddUpdatePartHeavyTruck): IUpdatePartHeavyTruckSuccessAction => ({
    type: IUPDATE_PART_HEAVY_TRUCK_REQUEST.SUCCESS,
    data
});
export interface IUpdatePartHeavyTruckLoadFailedAction {
    type: IUPDATE_PART_HEAVY_TRUCK_REQUEST.FAILED;
    message: string;
}
export const updatePartHeavyTruckLoadFailedAction = (message: string): IUpdatePartHeavyTruckLoadFailedAction => ({
    type: IUPDATE_PART_HEAVY_TRUCK_REQUEST.FAILED,
    message
});
