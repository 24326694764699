import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IGetPartsDismantledHeavyTrucksLoadAction, IGetPartsDismantledHeavyTrucksLoadFailedAction, IGetPartsDismantledHeavyTrucksSuccessAction } from "./getPartsDismantledHeavyTruckActions";
import { IGET_PARTS_HEAVY_TRUCK_REQUEST, IPartHeavyTruck } from "./getPartsDismantledHeavyTruckConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";
import { IDeleteDismantledSuccessAction } from "../delete/dismantled/deleteDismantledActions";
import { IDELETE_DISMANTLED_REQUEST } from "../delete/dismantled/deleteDismantledConstants";
import { IAddPartHeavyTruckSuccessAction } from "../add/heavyTruck/addHeavyTruckActions";
import { IADD_PART_HEAVY_TRUCK_REQUEST } from "../add/heavyTruck/addHeavyTruckConstants";

type Actions =
    | IGetPartsDismantledHeavyTrucksLoadAction
    | IDeleteDismantledSuccessAction
    | IGetPartsDismantledHeavyTrucksSuccessAction
    | IGetPartsDismantledHeavyTrucksLoadFailedAction
    | IAddPartHeavyTruckSuccessAction
    | IFlushDataSuccessAction;

export const GetPartsDismantledHeavyTrucksReducer = (state: Server<PartsResponse<IPartHeavyTruck[]>> = notLoaded, action: Actions): Server<PartsResponse<IPartHeavyTruck[]>> => {
    switch (action.type) {
        case IGET_PARTS_HEAVY_TRUCK_REQUEST.REQUEST:
            return loading;

        case IGET_PARTS_HEAVY_TRUCK_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PARTS_HEAVY_TRUCK_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_PART_HEAVY_TRUCK_REQUEST.SUCCESS:
            return notLoaded;

        case IDELETE_DISMANTLED_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};