export enum IGET_INVOICES_REQUEST {
    REQUEST = "getInvoices/GET_INVOICES_REQUEST",
    SUCCESS = "getInvoices/GET_INVOICES_SUCCESS",
    FAILED = "getInvoices/GET_INVOICES_FAILED"
};

export interface IInvoicesRequest {
    token: string;
};

export interface IInvoices {
    id: number;
    project_Number: string;
    project_Name: string;
    coordinator: string;
    po: string;
    unit_No: string;    
    class: string;
    type: string;        
    invoice: string;       
    rate_Type: string;  
    rate_Period: number;
    rate_CostPerPeriod: number;
    rate_Cost: number;
    status: string;
    invoice_Start: string;
    invoice_End: string;   
    days: number;
    company: string;
    address_L1: string;
    address_L2: string;
    quantity: number;
    picked_Up_By:string;
};