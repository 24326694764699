import { ReactNode, PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ById, PartsResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IManufacturer, IManufacturerRequest } from "../../../../redux/parts/getManufacturers/getManufacturerConstants";
import { getManufacturers } from "../../../../redux/parts/getManufacturers/getManufacturersAccessor";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getManufacturersLoadAction } from "../../../../redux/parts/getManufacturers/getManufacturerActions";
import { MEDIA_QUERY_PHONE } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithPadding } from "../../../shared/paper";
import { ManufacturerGrid } from "./manufacturerGrid";
import SearchBox from "../../../shared/searchBox";
import { LAIconButton } from "../../../shared/buttons";
import { AddIcon, DownloadIcon } from "../../../shared/icons";
import LAPagination from "../../../shared/pagination";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { addManufacturerLoadAction } from "../../../../redux/parts/add/manufacturer/addManufacturerActions";
import { updateManufacturerLoadAction } from "../../../../redux/parts/update/manufacturer/updateManufacturerActions";
import { IUpdateManufacturerRequest } from "../../../../redux/parts/update/manufacturer/updateManufacturerConstants";
import { IAddManufacturerRequest } from "../../../../redux/parts/add/manufacturer/addManufacturerConstants";
import { updateManufacturer } from "../../../../redux/parts/update/manufacturer/updateManufacturerAccessor";
import { addManufacturer } from "../../../../redux/parts/add/manufacturer/addManufacturerAccessor";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import { userName } from "../../../shared/constExports";
import downloadCsv from "download-csv";
import { PARTS_END_POINTS } from "../../../../redux/endpoints";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ROUTE } from "../../../routes";

interface IManufacturerStoreProps {
    token: Server<PartsResponse<IToken>>;
    ManufacturerList: Server<PartsResponse<IManufacturer[]>>;
    UpdateManufacturer: Server<string>;
    AddManufacturer: Server<string>;
    validateShopGroup: Server<PartsResponse<IValidateShopGroup>>;
};

interface IManufacturerDispatchProps {
    RequestManufacturerList: (request: IManufacturerRequest) => unknown;
    RequestAddManufacturer: (request: IAddManufacturerRequest) => unknown;
    RequestUpdateManufacturer: (request: IUpdateManufacturerRequest) => unknown;
};

interface IManufacturerOwnProps {

};

interface IManufacturerState {
    newAdd: boolean;
    editId: number | undefined;
    projects: IManufacturer[];
    searchText: string;
    currentPage: number;
    total: number;
    error: ById<IFieldErrorKeyValue>;
};

const ManufacturerStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IManufacturerProps = RouteComponentProps
    & IManufacturerStoreProps
    & IManufacturerDispatchProps
    & IManufacturerOwnProps;

class Manufacturer extends PureComponent<IManufacturerProps, IManufacturerState> {

    public constructor(props: IManufacturerProps) {
        super(props);
        this.state = {
            newAdd: false,
            editId: undefined,
            projects: [],
            searchText: "",
            currentPage: 1,
            error: {},
            total: 0
        };
        this.keyWordSearch = this.debounce(this.keyWordSearch, 500);
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IManufacturerProps, prevState: IManufacturerState): Promise<void> {
        if (this.props.ManufacturerList !== prevProps.ManufacturerList) {
            this.callReduxForData();
        }
    };

    public render(): ReactNode {
        const { ManufacturerList } = this.props;
        const { projects, editId, searchText, currentPage, error, total, newAdd } = this.state;

        return (
            <ManufacturerStyles>
                <LAGrid spacing={2}>
                    {(ManufacturerList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                        <LACenteredLoading />
                    </LAGridItem>}

                    {(ManufacturerList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                        <LAErrorBox text="Failed to Load Makes" />
                    </LAGridItem>}

                    {(ManufacturerList.kind === STATUS_ENUM.SUCCEEDED) && <>
                        <LAGridItem xs={12} sm={2} className="text-center">
                            <h2>MAKES</h2>
                        </LAGridItem>


                        <LAGridItem xs={12} sm={9}>
                            <SearchBox
                                fullWidth={true}
                                text={searchText}
                                onSubmit={this.sendRequest}
                                onChange={this.handleSearchChange}
                                placeHolder="Search"
                                searchStatus={this.props.ManufacturerList.kind}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={1}>
                            <LAIconButton
                                label="Add New"
                                icon={<AddIcon />}
                                onClick={this.handleAdd}
                            />

                            <LAIconButton
                                label="Download CSV"
                                icon={<DownloadIcon />}
                                onClick={this.getDataForExport}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAPagination
                                rowsPerPage={20}
                                className="mt-5"
                                currentPage={currentPage}
                                numberOfItems={total}
                                onPageChange={this.handlePageChange}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            {projects && projects.length > 0 ?
                                <ManufacturerGrid
                                    {...this.state}
                                    data={projects}
                                    editId={editId}
                                    error={error}
                                    onEdit={this.handleEdit}
                                    onChange={this.onChange}
                                    onSave={this.handleSave}
                                /> : <LAErrorBox text="No Record Found" />}
                        </LAGridItem>

                        <Prompt
                            when={newAdd || (editId !== undefined)}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                    </>}
                </LAGrid>

                <RequestStatus requestStatus={this.props.AddManufacturer.kind} failedMessage="Failed to Add Make" successMessage="Make Successfully Added" />
                <RequestStatus requestStatus={this.props.UpdateManufacturer.kind} failedMessage="Failed to Update Make" successMessage="Make Successfully Updated" />
            </ManufacturerStyles>
        );
    }


    private getDataForExport = (): void => {
        if (hasPayload(this.props.token))
            fetch(PARTS_END_POINTS.GET_PARTS.MANUFACTURERS.GET, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    request: {},
                    Keywords: "",
                    PageSize: this.state.total,
                    PageNumber: 1,
                    token: this.props.token.payload.response.token
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(async res => {
                    const val: IManufacturer[] = res.response;
                    const dataToExport: { name: string, active: string }[] = [];

                    await val.forEach((x: IManufacturer) => {
                        dataToExport.push({
                            name: x.name,
                            active: x.active
                        });
                    });

                    downloadCsv(dataToExport,
                        {
                            name: "Name", active: "Active"
                        },
                        "manufacturers-list.csv");
                })
                .catch((res) => console.log(res));
    };

    private debounce = (fn: any, delay: any): any => {
        let timer: any = null;
        return (...args: any): any => {
            const context = this;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    };

    private keyWordSearch = async (): Promise<void> => {
        this.sendRequest();
    };

    private onChange = (name: string, value: string) => {

        const iS = [...this.state.projects];
        const iSError = this.state.error;
        const ind = this.state.projects.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };

        switch (name) {
            case "name":
                val.name = value;
                break;

            case "active":
                val.active = value;
                break;
        };

        if (value !== undefined) {
            if (value.length > 0) {
                if (iSError[name])
                    delete iSError[name];
            } else {
                iSError[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };
        };

        iS[ind] = val;
        this.setState({ projects: iS, error: iSError });
    };

    private handlePageChange = (currentPage?: number, rowsPerPage?: number): void => {
        this.setState({ currentPage: currentPage ?? 1 });
        this.sendRequest(currentPage);
    };

    private handleAdd = (): void => {
        const iS = [...this.state.projects];
        const time = new Date().toISOString();

        let max = iS[0].id;
        for (let i = 1; i < iS.length; i++) {
            let value = iS[i].id;
            max = (value > max) ? value : max;
        };

        iS.unshift({
            id: max + 1, name: "", active: "", modified: time, modified_By: userName, created: time, created_By: userName
        });

        let error: ById<IFieldErrorKeyValue> = {};
        error["name"] = { key: "name", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["active"] = { key: "active", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

        this.setState({ projects: iS, editId: max + 1, newAdd: true, error });
    };

    private handleSearchChange = (searchText: string): void => {
        this.setState({ searchText, currentPage: 1, editId: undefined, newAdd: false });
        this.keyWordSearch();
    };

    private handleEdit = (editId: number | undefined): void => {
        if (editId === undefined && this.state.newAdd === true) {
            const iS = [...this.state.projects];
            const ind = iS.findIndex(x => x.id === this.state.editId);
            iS.splice(ind, 1);
            this.setState({ editId, projects: iS, error: {} });
        } else {
            if (editId === undefined) {
                if (hasPayload(this.props.ManufacturerList)) {
                    this.setState({ editId: editId, error: {}, projects: this.props.ManufacturerList.payload.response });
                }
            } else if (editId !== undefined) {
                this.setState({ editId, error: {} });
            }
        };
    };

    private callReduxForData = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.partsaccess === true && this.props.validateShopGroup.payload.response.adminAccess !== "R") {
                if (isNotLoaded(this.props.ManufacturerList)) {
                    this.sendRequest();
                };

                if (hasPayload(this.props.ManufacturerList)) {
                    let data = this.props.ManufacturerList.payload;
                    this.setState({ projects: data.response, total: data.totalRecords ?? 0 });
                };
            } else {
                this.props.history.push(ROUTE.PARTS.UNAUTHORIZED);
            }
        };
    };

    private sendRequest = (currentPage?: number): void => {
        if (hasPayload(this.props.token))
            this.props.RequestManufacturerList({
                request: {},
                Keywords: this.state.searchText,
                PageSize: 20,
                PageNumber: currentPage ?? this.state.currentPage,
                token: this.props.token.payload.response.token
            });
    };

    private handleSave = (): void => {
        if (this.state.newAdd === false) {
            this.updateRequest();
        } else {
            this.addRequest();
        }
    };

    private updateRequest = (): void => {
        if (hasPayload(this.props.token)) {
            const iS = [...this.state.projects];
            const ind = iS.findIndex(x => x.id === this.state.editId);

            this.props.RequestUpdateManufacturer({
                request: iS[ind],
                token: this.props.token.payload.response.token
            });

            this.setState({ editId: undefined });
        }
    };

    private addRequest = (): void => {
        if (hasPayload(this.props.token)) {
            const iS = [...this.state.projects];
            const ind = iS.findIndex(x => x.id === this.state.editId);
            iS[ind].id = 0;
            this.props.RequestAddManufacturer({
                request: iS[ind],
                Keywords: "",
                PageSize: 20,
                PageNumber: 1,
                token: this.props.token.payload.response.token
            });
            iS.splice(ind, 1);
            this.setState({ editId: undefined, newAdd: false, currentPage: 1, searchText: "", projects: iS });
        }
    };

}

const mapStateToProps = (state: IStore): IManufacturerStoreProps => ({
    token: getToken(state),
    ManufacturerList: getManufacturers(state),
    AddManufacturer: addManufacturer(state),
    validateShopGroup: validateShopGroup(state),
    UpdateManufacturer: updateManufacturer(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IManufacturerDispatchProps => ({
    RequestManufacturerList: (request: IManufacturerRequest): unknown => dispatch(getManufacturersLoadAction(request)),
    RequestAddManufacturer: (request: IAddManufacturerRequest): unknown => dispatch(addManufacturerLoadAction(request)),
    RequestUpdateManufacturer: (request: IUpdateManufacturerRequest): unknown => dispatch(updateManufacturerLoadAction(request))
});


export default connect(mapStateToProps, mapDispatchToProps)(Manufacturer);