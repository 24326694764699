import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IADD_SHOP_MANUFACTURERS_REQUEST, IAddShopManufactureRequest } from "./addShopManufacturesConstants";


export interface IAddShopManufacturesLoadAction {
    type: IADD_SHOP_MANUFACTURERS_REQUEST.REQUEST;
    data: IAddShopManufactureRequest
}
export const addShopManufacturesLoadAction = (data: IAddShopManufactureRequest): IAddShopManufacturesLoadAction => ({
    type: IADD_SHOP_MANUFACTURERS_REQUEST.REQUEST,
    data
});
export interface IAddShopManufacturesSuccessAction {
    type: IADD_SHOP_MANUFACTURERS_REQUEST.SUCCESS;
    message: PartsResponse<string>;
}
export const addShopManufacturesLoadSuccessAction = (message: PartsResponse<string>): IAddShopManufacturesSuccessAction => ({
    type: IADD_SHOP_MANUFACTURERS_REQUEST.SUCCESS,
    message
});
export interface IAddShopManufacturesLoadFailedAction {
    type: IADD_SHOP_MANUFACTURERS_REQUEST.FAILED;
    message: string;
}
export const addShopManufacturesLoadFailedAction = (message: string): IAddShopManufacturesLoadFailedAction => ({
    type: IADD_SHOP_MANUFACTURERS_REQUEST.FAILED,
    message
});
