import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_UNITS_REQUEST, IUnit } from "./getUnitsConstants";
import { IGetUnitsLoadAction, IGetUnitsLoadFailedAction, IGetUnitsSuccessAction, getUnitsLoadFailedAction, getUnitsLoadSuccessAction } from "./getUnitsActions";
import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { END_POINTS } from "../../endpoints";
import { withJsonContentType } from "../../epicUtils";

export const getUnitsEpic: Epic = (
    action$: ActionsObservable<IGetUnitsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetUnitsSuccessAction | IGetUnitsLoadFailedAction> =>
    action$.ofType(IGET_UNITS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<ByIdNumber<IUnit>>>(
                    END_POINTS.GET_TOOL_RENTALS.UNITS.GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<ByIdNumber<IUnit>>): IGetUnitsSuccessAction => getUnitsLoadSuccessAction(response)),
                        catchError((response: ToolRentalsResponse<string>) => ActionsObservable.of(getUnitsLoadFailedAction(response.message)))
                    )
            )
        );