import React, { useState } from "react";
import styled from "styled-components";
import LAGrid from "../../../shared/grid";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Gallery from "devextreme-react/gallery";
import LAGridItem from "../../../shared/gridList";
import { LAPopover } from "../../../shared/popOver";
import LALinkButton from "../../../shared/linkButton";
import LAInputButton from "../../../shared/inputButton";
import LAAutoComplete from "../../../shared/autoComplete";
import Not_Available_Image from "../../../../No-Image.jpg";
import { LAPaperWithPadding } from "../../../shared/paper";
import { ById, HasClass } from "../../../shared/publicInterfaces";
import { IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import LATextField, { LANumberField } from "../../../shared/textField";
import { IPart,DiscontinuedValues, ContinuedValues } from "../../../../redux/parts/getParts/getPartConstants";
import { undefinedFunction, ZEROTH } from "../../../shared/constExports";
import { LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { AddPhotoIcon, CancelIcon, EditIcon, SaveIcon, RotateRight, RotateLeft, DeleteIcon } from '../../../shared/icons';
import { DARK_GREY_COLOR, LIGHT_GREY_COLOR, SECONDARY_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { IManufacturerListDD } from "../../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDConstants";
import splitDate from "../../../shared/splitDate";


interface IPartGridProps extends HasClass {
    editId: number | undefined;
    data: IPart[];
    open: boolean;
    disabled: true | undefined;
    newAdd: boolean;
    onSave: () => void;
    error: ById<IFieldErrorKeyValue>;
    removeImage: (index: number) => void;
    onPermanentDelete: (id: number) => void;
    onEdit: (id: number | undefined) => void;
    manufacturersList: IManufacturerListDD[];
    onChange: (name: string, value: string) => void;
    onRotate: (imgIndex: number, reverse: boolean) => void;
    onImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PartGridStyles = styled.div`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };

    .edit-btn {
        position: absolute;
        right: 0;
        top: 0;
    };

    .delete-btn {
        position: absolute;
        right: 0;
        bottom: 0;
    };
    
    .upload-img {
        width: 140px;
        height: 240px;
        background-repeat: no-repeat;
        background-size: cover;
    };

    .itemImage {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};

        max-width: 172px;
        width: 120px;
        min-height: 120px;
        max-height: auto;
        float: left;

    };

    .disableArrows {
        .lb-button.prev {
            display: none;
        };

        .lb-button.next {
            display: none;
        };
    };
`;

export const PartGrid: React.FC<IPartGridProps> = React.memo((props: IPartGridProps) =>
    <PartGridStyles>
        <LAGrid>
            {props.data.map((row: IPart, index: number) => {
                const onRotate = (imgIndex: number, reverse: boolean): void => props.onRotate(imgIndex, reverse);
                return (
                    <LAGridItem xs={12}>
                        <Row {...props} index={index} value={row} onEdit={props.onEdit} onPermanentDelete={props.onPermanentDelete} disabled={props.disabled} />
                        {(props.editId && (row.id === props.editId) && props.open) &&
                            <EditRow {...props} open={props.open} onRotate={onRotate} index={index} error={props.error} value={row} onImageUpload={props.onImageUpload}
                                onCancel={props.onEdit} onSave={props.onSave} onChange={props.onChange} removeImage={props.removeImage} newAdd={props.newAdd} disabled={props.disabled}/>}
                    </LAGridItem>
                );
            })}
        </LAGrid>
    </PartGridStyles>
);


interface IRowProps {
    disabled: true | undefined;
    value: IPart;
    index: number;
    onPermanentDelete: (id: number) => void;
    onEdit: (id: number | undefined) => void;
};

const Row: React.FC<IRowProps> = React.memo((props: IRowProps) => {
    const [openImageId, setImageId] = useState<number | undefined>(undefined);

    const images = getImage(props.value);
    const onEdit = () => props.onEdit(props.value.id);
    const onPermanentDelete = () => props.onPermanentDelete(props.value.id);
    const openImages = () => setImageId(props.value.id);
    const onCloseImage = () => setImageId(undefined);

    return <LAPaperWithPadding>
        <LAGrid justify="center" alignItems="center">

            <LAGridItem xs={12} sm={12} md={12} lg={4} xl={4} className="mt-2 imageDiv">
            <div className={`${images.length > 1 ? "" : "disableArrows"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {
                }}>
                    <Gallery
                        id="gallery"
                        height={150}
                        width={200}
                        loop={true}
                        slideshowDelay={0}
                        dataSource={images}
                        showNavButtons={images.length > 1 ? true: false}
                        showIndicator={false}
                        onItemClick={openImages}
                    />

                    {((openImageId !== undefined) && (openImageId === props.value.id)) &&
                        <Lightbox
                            images={images}
                            onClose={onCloseImage}
                        />
                    }
                </div>
            </LAGridItem>

            <LAGridItem xs={12} sm={12} md={12} lg={8} xl={8}>
                <LAPaperWithPadding>
                    <LAGrid>

                    <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Item #:
                            </strong>
                            <span> {props.value.id}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Name:
                            </strong>
                            <span> {props.value.name}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Part Number:
                            </strong>
                            <span> {props.value.part_No}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Alternate Part Number:
                            </strong>
                            <span> {props.value.alternate_Part_No}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Make:
                            </strong>
                            <span> {props.value.make}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Model:
                            </strong>
                            <span> {props.value.model}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Unit #:
                            </strong>
                            <span> {props.value.unit_No}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Condition:
                            </strong>
                            <span> {props.value.condition}</span>
                        </LAGridItem>

                        {/* <LAGridItem xs={4}>
                            <strong>
                                Type:
                            </strong>
                            <span> {props.value.type}</span>
                        </LAGridItem> */}


                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Core Charge:
                            </strong>
                            <span> {props.value.core_Charge}</span>
                        </LAGridItem>
                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Core Charge Date:
                            </strong>
                            <span> {props.value.core_Charge_Date && splitDate(props.value.core_Charge_Date)}</span>
                        </LAGridItem>
                        
                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Discontinued:
                            </strong>
                            <span> {props.value.discontinued}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Location:
                            </strong>
                            <span> {props.value.location}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Notes:
                            </strong>
                            <span> {props.value.notes}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Weight:
                            </strong>
                            <span> {props.value.weight}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Available Quantity:
                            </strong>
                            <span> {props.value.available_Quantity}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Price:
                            </strong>
                            <span> ${props.value.price}</span>
                        </LAGridItem>
                        <LAGridItem xs={12} sm={4} md={3}>
                            <strong>
                                Price Date:
                            </strong>
                            <span> {props.value.price_Date && splitDate(props.value.price_Date)}</span>
                        </LAGridItem>

                        <LAIconButton className="edit-btn" icon={<EditIcon />} label="Edit" onClick={onEdit} />
                        <LAIconButton className="delete-btn" icon={<DeleteIcon />} label="Delete" onClick={onPermanentDelete} disabled={props.disabled}/>

                    </LAGrid>
                </LAPaperWithPadding>
            </LAGridItem>

        </LAGrid>
    </LAPaperWithPadding>

});


const EditRowStyles = styled(LAPaperWithPadding)`
    .upload-img {
        width: 140px;
        height: 140px;
        background-size: cover;
        background-repeat: no-repeat;
    };
`;

interface IEditRowProps {
    index: number;
    value: IPart;
    open: boolean;
    disabled?: true | undefined
    onSave: () => void;
    manufacturersList: IManufacturerListDD[];
    newAdd: boolean;
    error: ById<IFieldErrorKeyValue>;
    onCancel: (id: undefined) => void;
    removeImage: (index: number) => void;
    onChange: (name: string, value: string) => void;
    onRotate: (imgIndex: number, reverse: boolean) => void;
    onImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const EditRow: React.FC<IEditRowProps> = React.memo((props: IEditRowProps) => {

    const onCancel = () => props.onCancel(undefined);
    const err = Object.values(props.error).length > 0 ? true : undefined;
    const onMake = (e: unknown, value: IManufacturerListDD) => props.onChange("make", (value !== null) ? value.name : "");
    const onDiscontinued = (e: unknown, value: any) => props.onChange("discontinued", (value !== null) ? value.name : "");
    const onCondition = (e: unknown, value: any) => props.onChange("condition", (value !== null) ? value.name : "");


    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <EditRowStyles>
            <LAGrid spacing={3}>

            <LAGridItem xs={6}>
                    <LATextField errorText={props.error["id"] ? props.error["id"].message : undefined}
                        name="id" label="Item #" fullWidth={true} value={props.value.id} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LATextField errorText={props.error["name"] ? props.error["name"].message : undefined}
                        name="name" label="Part Name" fullWidth={true} value={props.value.name} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LATextField errorText={props.error["part_No"] ? props.error["part_No"].message : undefined}
                        name="part_No" label="Part #" fullWidth={true} value={props.value.part_No} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>


                <LAGridItem xs={6}>
                    <LATextField
                        name="alternate_Part_No" label="Alternate Part #" fullWidth={true} value={props.value.alternate_Part_No} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LATextField
                        name="model" label="Model" fullWidth={true} value={props.value.model} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LAAutoComplete
                        dropDownPlaceHolder="Make"
                        multiple={false}
                        getOptionLabel="name"
                        filterSelectedOptions={true}
                        autoHighlight={true}
                        onChange={onMake}
                        selectionRemove={undefinedFunction}
                        option={props.manufacturersList} //.map(s=>s.model).filter((v, i, a) => a.indexOf(v) === i)
                        errorText={props.error["make"] ? props.error["make"].message : undefined}
                        value={props.manufacturersList.find(x => x.name === props.value.make)}
                        defaultValue={props.manufacturersList.find(x => x.name === props.value.make)}
                        disabled={props.disabled}
                    />
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LATextField errorText={props.error["type"] ? props.error["type"].message : undefined}
                        name="type" label="Part Type" fullWidth={true} value={props.value.type} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>
                <LAGridItem xs={6}>
                    <LATextField errorText={props.error["unit_No"] ? props.error["unit_No"].message : undefined}
                        name="unit_No" label="Unit #" fullWidth={true} value={props.value.unit_No} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>
                <LAGridItem xs={6}>                
                    <LAAutoComplete
                        filterSelectedOptions={true}
                        autoHighlight={true}
                        selectionRemove={undefinedFunction}
                        dropDownPlaceHolder="Condition"
                        getOptionLabel="name"
                        name="condition"
                        option={ContinuedValues}
                        multiple={false}
                        onChange={onCondition}
                        value={ContinuedValues.find(x => x.name === props.value.condition)}
                        defaultValue={ContinuedValues.find(x => x.name === props.value.condition)}
                        disabled={props.disabled}
                    />
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LAAutoComplete
                        filterSelectedOptions={true}
                        autoHighlight={true}
                        selectionRemove={undefinedFunction}
                        dropDownPlaceHolder="Discontinued"
                        getOptionLabel="name"
                        name="discontinued"
                        option={DiscontinuedValues}
                        multiple={false}
                        onChange={onDiscontinued}
                        value={DiscontinuedValues.find(x => x.name === props.value.discontinued)}
                        defaultValue={DiscontinuedValues.find(x => x.name === props.value.discontinued)}
                        disabled={props.disabled}
                    />
                </LAGridItem>
                <LAGridItem xs={6}>
                    <LATextField errorText={props.error["core_Charge"] ? props.error["core_Charge"].message : undefined}
                        name="core_Charge" label="Core Charge" fullWidth={true} value={props.value.core_Charge} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>                
                <LAGridItem xs={6}>
                    <strong>Core Charge Date</strong>
                    <LATextField errorText={props.error["core_Charge_Date"] ? props.error["core_Charge_Date"].message : undefined}
                        name="core_Charge_Date" type="date" label="" fullWidth={true} value={props.value.core_Charge_Date ? splitDate(props.value.core_Charge_Date) : ""} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>                

                <LAGridItem xs={6}>
                    <LATextField errorText={props.error["location"] ? props.error["location"].message : undefined}
                        name="location" label="Location" fullWidth={true} value={props.value.location} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LATextField errorText={props.error["notes"] ? props.error["notes"].message : undefined}
                        name="notes" label="Notes" fullWidth={true} value={props.value.notes} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LATextField errorText={props.error["weight"] ? props.error["weight"].message : undefined}
                        name="weight" label="Weight" fullWidth={true} value={props.value.weight} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LANumberField errorText={props.error["available_quantity"] ? props.error["available_quantity"].message : undefined} disabled={props.disabled}
                        name="available_Quantity" label="Available Quantity" value={props.value.available_Quantity} onChange={props.onChange} fullWidth={true}
                    />
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LANumberField errorText={props.error["price"] ? props.error["price"].message : undefined} disabled={props.disabled}
                        name="price" label="Price" value={props.value.price} onChange={props.onChange} fullWidth={true}
                    />
                </LAGridItem>
                <LAGridItem xs={6}>
                    <strong>Price Date</strong>
                    <LATextField errorText={props.error["price_Date"] ? props.error["price_Date"].message : undefined}
                        name="price_Date" type="date" label="" fullWidth={true} value={props.value.price_Date ? splitDate(props.value.price_Date) : ""} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem> 

                <LAGridItem xs={12}>
                    <LAGrid>
                        <LAGridItem xs={4}>
                            {props.value.photos_1 && <div className="text-center">

                                <img className="upload-img" src={props.value.photos_1} alt="part_photo_1" />
                                {props.newAdd && <>
                                    <br />
                                    <LAIconButton label="Rotate Left" icon={<RotateLeft />} onClick={() => props.onRotate(1, true)} />
                                    <LAIconButton label="Rotate Right" icon={<RotateRight />} onClick={() => props.onRotate(1, false)} />
                                </>}
                                <br />
                                {props.disabled !== true &&<LALinkButton label="Remove" onClick={() => props.removeImage(1)} />}
                            </div>}
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            {props.value.photos_2 && <div className="text-center">

                                <img className="upload-img" src={props.value.photos_2} alt="part_photo_2" />
                                {props.newAdd && <>
                                    <br />
                                    <LAIconButton label="Rotate Left" icon={<RotateLeft />} onClick={() => props.onRotate(2, true)} />
                                    <LAIconButton label="Rotate Right" icon={<RotateRight />} onClick={() => props.onRotate(2, false)} />
                                </>}
                                <br />
                                {props.disabled !== true &&<LALinkButton label="Remove" onClick={() => props.removeImage(2)} />}
                            </div>}
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            {props.value.photos_3 && <div className="text-center">

                                <img className="upload-img" src={props.value.photos_3} alt="part_photo_3" />
                                {props.newAdd && <>
                                    <br />
                                    <LAIconButton label="Rotate Left" icon={<RotateLeft />} onClick={() => props.onRotate(3, true)} />
                                    <LAIconButton label="Rotate Right" icon={<RotateRight />} onClick={() => props.onRotate(3, false)} />
                                </>}
                                <br />
                                {props.disabled !== true && <LALinkButton label="Remove" onClick={() => props.removeImage(3)} />}
                            </div>}
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAInputButton
                                label="Add Photo"
                                onChange={props.onImageUpload}
                                disabled={props.disabled}
                                startIcon={<AddPhotoIcon />}
                            />
                        </LAGridItem>

                    </LAGrid>
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LASaveAndCancelButton
                        disableSave={err || props.disabled}
                        onSave={props.onSave}
                        onCancel={onCancel}
                        saveIcon={<SaveIcon color={WHITE_COLOR} />}
                        cancelIcon={<CancelIcon color={WHITE_COLOR} />}
                    />
                </LAGridItem>

            </LAGrid>
        </EditRowStyles>
    </LAPopover >
});


export const getImage = (part: IPart): string[] => {
    const images: string[] = [];

    if (part.photos_1 != null) {
        images.push(part.photos_1);
    }
    if (part.photos_2 != null)
        images.push(part.photos_2);

    if (part.photos_3 != null)
        images.push(part.photos_3);

    if (images.length === ZEROTH)
        images.push(Not_Available_Image);

    return images;
};