import { ReactNode, PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ById, ByIdNumber, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IRate, IRateRequest } from "../../../../redux/toolRentals/getRates/getRatesConstants";
import { getRates } from "../../../../redux/toolRentals/getRates/getRatesAccessor";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getRatesLoadAction } from "../../../../redux/toolRentals/getRates/getRatesActions";
import { MEDIA_QUERY_PHONE } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithPadding } from "../../../shared/paper";
import { RateGrid } from "./rateGrid";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { IAddRateRequest } from "../../../../redux/toolRentals/add/rate/addRateConstants";
import { IUpdateRateRequest } from "../../../../redux/toolRentals/update/rate/updateRateConstants";
import { addRateLoadAction } from "../../../../redux/toolRentals/add/rate/addRateActions";
import { updateRateLoadAction } from "../../../../redux/toolRentals/update/rate/updateRateActions";
import { addRate } from "../../../../redux/toolRentals/add/rate/addRateAccessor";
import { updateRate } from "../../../../redux/toolRentals/update/rate/updateRateAccessor";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import LARoleBasedAccess from "../../../shared/roleBaseAccess";
import { IRoles, IValidateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { userName } from "../../../shared/constExports";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { ROUTE } from "../../../routes";


interface IRateStoreProps {
    token: Server<ToolRentalsResponse<IToken>>;
    RateList: Server<ToolRentalsResponse<ByIdNumber<IRate>>>;
    UpdateRate: Server<string>;
    AddRate: Server<string>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IRateDispatchProps {
    RequestRateList: (request: IRateRequest) => unknown;
    RequestAddRate: (request: IAddRateRequest) => unknown;
    RequestUpdateRate: (request: IUpdateRateRequest) => unknown;
};

interface IRateOwnProps {

};

interface IRateState {
    rates: ByIdNumber<IRate>;
    addEdit: IRate | undefined;
    error: ById<IFieldErrorKeyValue>;
};

const RateStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .moveRight {
        position: absolute;
        top: 0px;
        right: 10px;
    };
`;

type IRateProps = RouteComponentProps
    & IRateStoreProps
    & IRateDispatchProps
    & IRateOwnProps;

class Rate extends PureComponent<IRateProps, IRateState> {

    public constructor(props: IRateProps) {
        super(props);
        this.state = {
            rates: {},
            error: {},
            addEdit: undefined
        };
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IRateProps, prevState: IRateState): Promise<void> {
        if (this.props.RateList !== prevProps.RateList) {
            this.callReduxForData();
        }
    };

    public render(): ReactNode {

        const { RateList } = this.props;
        const { rates, error, addEdit } = this.state;

        return (
            <LARoleBasedAccess error={true} roleFor={IRoles[1]}>
                <RateStyles>
                    <LAGrid spacing={2}>
                        {(RateList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                            <LACenteredLoading />
                        </LAGridItem>}

                        {(RateList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                            <LAErrorBox text="Failed to Load Rates" />
                        </LAGridItem>}

                        {(RateList.kind === STATUS_ENUM.SUCCEEDED) && <>
                            <LAGridItem xs={12}>
                                <h2 className="text-center">{!addEdit ? "RATES" : "ADD/UPDATE RATE"}</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                {addEdit ? <RateGrid
                                    {...this.state}
                                    data={addEdit}
                                    error={error}
                                    onEdit={this.handleEdit}
                                    onChange={this.onChange}
                                    onSave={this.handleSave}
                                /> :
                                    <LADevExtremeGrid
                                        add={true}
                                        export={true}
                                        onAdd={this.handleAdd}
                                        actionWidth={160}
                                        exportFileName="Rates"
                                        removeStyleBtn={true}
                                        searchPanel={true}
                                        filterHeader={true}
                                        onClick={this.handleEdit}
                                        onEdit={this.handleEdit}
                                        data={Object.values(rates)}
                                        columns={[
                                            { name: "class", caption: "Class", type: "string" },
                                            { name: "type", caption: "Type", type: "string" },
                                            { name: "daily", caption: "Daily", type: "number" },
                                            { name: "weekly", caption: "Weekly", type: "number" },
                                            { name: "monthly", caption: "Monthly", type: "number" }
                                        ]}
                                    />}
                            </LAGridItem>

                            <Prompt
                                when={(addEdit !== undefined)}
                                message='You have unsaved changes, are you sure you want to leave?'
                            />
                        </>}
                    </LAGrid>

                    <RequestStatus requestStatus={this.props.AddRate.kind} failedMessage="Failed to Add Rate" successMessage="Rate Successfully Added" />
                    <RequestStatus requestStatus={this.props.UpdateRate.kind} failedMessage="Failed to Update Rate" successMessage="Rate Successfully Updated" />
                </RateStyles>
            </LARoleBasedAccess>
        );
    }


    private onChange = (name: string, value: string) => {

        let val: string | number = value;

        if (this.state.addEdit) {
            const iSError = this.state.error;

            if (val.length > 0) {
                if (name === "daily" || name === "weekly" || name === "monthly") {
                    if (!isNaN(+val) && iSError[name])
                        delete iSError[name]
                } else {
                    if (iSError[name])
                        delete iSError[name]
                }
            } else {
                iSError[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };

            if (name === "daily" || name === "weekly" || name === "monthly") {
                if (!isNaN(+val)) {
                    this.setState({
                        ...this.state,
                        addEdit: {
                            ...this.state.addEdit,
                            [name]: value
                        },
                        error: iSError
                    });
                }
            } else {
                this.setState({
                    ...this.state,
                    addEdit: {
                        ...this.state.addEdit,
                        [name]: value
                    },
                    error: iSError
                });
            }
        }
    };

    private handleAdd = (): void => {

        let error: ById<IFieldErrorKeyValue> = {};
        error["class"] = { key: "class", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["type"] = { key: "type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["daily"] = { key: "daily", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["weekly"] = { key: "weekly", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["monthly"] = { key: "monthly", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

        const time = new Date().toISOString();
        
        const addEdit: IRate = {
            weekly: "",
            daily: "",
            monthly: "",
            id: 0,
            class: "",
            type: "",
            modified: time,
            modified_By: userName,
            created: time,
            created_By: userName
        };

        this.setState({ addEdit: addEdit, error });
    };


    private handleEdit = (event: any): void => {
        const { addEdit } = this.state;
        if (event === undefined && addEdit && addEdit.id === 0) {
            this.setState({ addEdit: undefined, error: {} });
        } else {
            if (event === undefined && addEdit && addEdit.id !== 0) {
                if (hasPayload(this.props.RateList)) {
                    this.setState({ addEdit: undefined, error: {} });
                }
            } else if (event !== undefined) {
                this.setState({ addEdit: this.state.rates[event.row.data.id], error: {} });
            }
        };
    };

    private callReduxForData = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.toolrentalsaccess === true) {
                if (isNotLoaded(this.props.RateList)) {
                    this.sendRequest();
                };
        
                if (hasPayload(this.props.RateList)) {
                    this.setState({ rates: this.props.RateList.payload.response });
                };
            } else {
                this.props.history.push(ROUTE.TOOL_RENTALS.UNAUTHORIZED);
            }
        };
    };

    private sendRequest = (currentPage?: number): void => {
        if (hasPayload(this.props.token))
            this.props.RequestRateList({
                token: this.props.token.payload.response.token
            });
    };

    private handleSave = (): void => {
        if (this.state.addEdit)
            if (this.state.addEdit.id !== 0) {
                this.updateRequest();
            } else {
                this.addRequest();
            }
    };

    private updateRequest = (): void => {
        if (hasPayload(this.props.token) && this.state.addEdit) {
            this.props.RequestUpdateRate({
                request: this.state.addEdit,
                token: this.props.token.payload.response.token
            });

            this.setState({ addEdit: undefined });
        }
    };

    private addRequest = (): void => {
        if (hasPayload(this.props.token) && this.state.addEdit) {
            this.props.RequestAddRate({
                request: this.state.addEdit,
                Keywords: "",
                PageSize: 20,
                PageNumber: 1,
                token: this.props.token.payload.response.token
            });
            this.setState({ addEdit: undefined });
        }
    };

}

const mapStateToProps = (state: IStore): IRateStoreProps => ({
    token: getToken(state),
    RateList: getRates(state),
    AddRate: addRate(state),
    UpdateRate: updateRate(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IRateDispatchProps => ({
    RequestRateList: (request: IRateRequest): unknown => dispatch(getRatesLoadAction(request)),
    RequestAddRate: (request: IAddRateRequest): unknown => dispatch(addRateLoadAction(request)),
    RequestUpdateRate: (request: IUpdateRateRequest): unknown => dispatch(updateRateLoadAction(request))
});


export default connect(mapStateToProps, mapDispatchToProps)(Rate);