import React from "react";
import { LASaveAndCancelButton } from "./buttons";
import { LAPaperWithPadding } from "./paper";
import { LAPopover } from "./popOver";
import { HasClass } from "./publicInterfaces";

interface ILADeleteConfirmProps extends HasClass {
    open: boolean;
    text?: string;
    cancelClick: () => void;
    confirmClick: () => void;
}

const LADeleteConfirm: React.FC<ILADeleteConfirmProps> = (props: ILADeleteConfirmProps) =>
    <LAPopover open={props.open} anchorRef={null} onClose={props.cancelClick}>
        <LAPaperWithPadding>
            <strong>{props.text ? props.text : "Are you sure you want to delete this..."}</strong>
            <br /><br />
            <LASaveAndCancelButton
                saveButtonText="Confirm"
                cancelButtonText="Cancel"
                onSave={props.confirmClick}
                onCancel={props.cancelClick}
            />
        </LAPaperWithPadding>
    </LAPopover>
    ;

export default LADeleteConfirm;

