import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_MODEL_LIST_DD_REQUEST, IModelListDD } from "./getModelListDDConstants";
import { IGetModelListDDLoadAction, IGetModelListDDLoadFailedAction, IGetModelListDDSuccessAction, getModelListDDLoadFailedAction, getModelListDDLoadSuccessAction } from "./getModelListDDActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const getModelListDDEpic: Epic = (
    action$: ActionsObservable<IGetModelListDDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetModelListDDSuccessAction | IGetModelListDDLoadFailedAction> =>
    action$.ofType(IGET_MODEL_LIST_DD_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<IModelListDD[]>>(
                    PARTS_END_POINTS.GET_PARTS.MODELS.GET_LIST_FOR_DROPDOWN,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<IModelListDD[]>): IGetModelListDDSuccessAction => {
                            return getModelListDDLoadSuccessAction(response);
                        }),
                        catchError((response: ToolRentalsResponse<IModelListDD[]>) => ActionsObservable.of(getModelListDDLoadFailedAction(response.message)))
                    )
            )
        );