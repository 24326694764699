import { ROUTE } from "../routes";
import { Server } from "../../redux/server";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { HasClass, ToolRentalsResponse } from "../shared/publicInterfaces";
import { IValidateShopGroup } from "../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";

interface IShopDropDownProps extends HasClass {
    hideEqp?: true;
    onDrawer?(): void;
    validateGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IShopDropDownState {
    config: any;
};


type IOrderDropDown =
    RouteComponentProps &
    IShopDropDownProps;


class ShopDropDown extends PureComponent<IOrderDropDown, IShopDropDownState> {

    public constructor(props: IOrderDropDown) {
        super(props);
        this.state = {
            config: null
        };
    }

    public render(): ReactNode {

        const { validateGroup, hideEqp } = this.props;
        const path = this.props.location.pathname;

        return (
            <>
                {/* {!hideEqp && <LAMenuItem onClick={this.onEquipment} className={(path.includes(ROUTE.SHOP.EQUIPMENT.INDEX)) ? "current" : ""}>
                    <EquipmentIcon />
                    <span className="ml-2">
                        Equipment List
                    </span>
                </LAMenuItem>}

                {(hasPayload(validateGroup) && (validateGroup.payload.response.adminAccess !== ReadOnly)) && <>
                    {!hideEqp && <LAMenuItem onClick={this.onEquipmentType} className={(path.includes(ROUTE.SHOP.EQUIPMENT_TYPE.INDEX)) ? "current" : ""}>
                    <EquipmentTypeIcon />
                    <span className="ml-2">
                        Equipment Types
                    </span>
                </LAMenuItem>}

                {/* <LAMenuItem onClick={this.onModel} className={(path.includes(ROUTE.SHOP.MODEL.INDEX)) ? "current" : ""}>
                    <ModelIcon />
                    <span className="ml-2">
                        Models
                    </span>
                </LAMenuItem> 

                <LAMenuItem onClick={this.onManufacturer} className={(path.includes(ROUTE.SHOP.MANUFACTURER.INDEX)) ? "current" : ""}>
                    <ManufacturerIcon />
                    <span className="ml-2">
                        Makes
                    </span>
                </LAMenuItem>

                <LAMenuItem onClick={this.onConfig} className={(path.includes(ROUTE.SHOP.CONFIG.INDEX)) ? "current" : ""}>
                    <ToolIcon />
                    <span className="ml-2">
                        Admin
                    </span>
                </LAMenuItem>

                <Menu
                    anchorEl={this.state.config}
                    open={this.state.config}
                    onClose={this.onConfig}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <LAMenuItem onClick={this.onEquipConfig}>
                        <span className="">
                            Fields Configuration
                        </span>
                    </LAMenuItem>
                </Menu>
                </>} */}
            </>
        );
    };

    private onConfig = (event?: any): void => {
        if (this.state.config !== null) {
            this.setState({ config: null });
        } else {
            this.setState({ config: event.currentTarget });
        }
    };

    private onEquipConfig = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.SHOP.CONFIG.INDEX);
    };

    private onEquipment = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.SHOP.EQUIPMENT.INDEX);
    };

    private onEquipmentType = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.SHOP.EQUIPMENT_TYPE.INDEX);
    };

    private onModel = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.SHOP.MODEL.INDEX);
    };

    private onManufacturer = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.SHOP.MANUFACTURER.INDEX);
    };

}

export default ShopDropDown;