import { IShopEquipmentFields } from "../getEquipments/getEquipmentConstants";

export enum IADD_SHOP_EQUIPMENT_REQUEST {
    REQUEST = "addShopEquipment/ADD_SHOP_EQUIPMENT_REQUEST",
    SUCCESS = "addShopEquipment/ADD_SHOP_EQUIPMENT_SUCCESS",
    FAILED = "addShopEquipment/ADD_SHOP_EQUIPMENT_FAILED"
};

export interface IAddShopEquipmentRequest {
    token: string;
    request: IShopEquipmentFields;
};