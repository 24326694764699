import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IOrder } from "../../getOrders/getOrdersConstants";
import { IUPDATE_ORDER_REQUEST, IUpdateOrderRequest } from "./updateOrderConstants";


export interface IUpdateOrderLoadAction {
    type: IUPDATE_ORDER_REQUEST.REQUEST;
    data: IUpdateOrderRequest
}
export const updateOrderLoadAction = (data: IUpdateOrderRequest): IUpdateOrderLoadAction => ({
    type: IUPDATE_ORDER_REQUEST.REQUEST,
    data
});
export interface IUpdateOrderSuccessAction {
    type: IUPDATE_ORDER_REQUEST.SUCCESS;
    data: IOrder;
}
export const updateOrderLoadSuccessAction = (data: IOrder): IUpdateOrderSuccessAction => ({
    type: IUPDATE_ORDER_REQUEST.SUCCESS,
    data
});
export interface IUpdateOrderLoadFailedAction {
    type: IUPDATE_ORDER_REQUEST.FAILED;
    message: ToolRentalsResponse<string[]>;
}
export const updateOrderLoadFailedAction = (message: ToolRentalsResponse<string[]>): IUpdateOrderLoadFailedAction => ({
    type: IUPDATE_ORDER_REQUEST.FAILED,
    message
});
