import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IDELETE_PICKUP_TRUCK_REQUEST } from "./deletePickupTruckConstants";
import { IDeletePickupTruckLoadAction, IDeletePickupTruckLoadFailedAction, IDeletePickupTruckSuccessAction } from "./deletePickupTruckActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IDeletePickupTruckLoadAction
    | IDeletePickupTruckSuccessAction
    | IDeletePickupTruckLoadFailedAction
    | IFlushDataSuccessAction;

export const DeletePickupTruckReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IDELETE_PICKUP_TRUCK_REQUEST.REQUEST:
            return loading;

        case IDELETE_PICKUP_TRUCK_REQUEST.SUCCESS:
            return succeeded("Success");

        case IDELETE_PICKUP_TRUCK_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};