import { END_POINTS } from './../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SHOP_MANUFACTURERS_REQUEST, IShopManufacture } from "./getShopManufacturesConstants";
import { IGetShopManufacturesLoadAction, IGetShopManufacturesLoadFailedAction, IGetShopManufacturesSuccessAction, getShopManufacturesLoadFailedAction, getShopManufacturesLoadSuccessAction } from "./getShopManufacturesActions";
import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getShopManufacturesEpic: Epic = (
    action$: ActionsObservable<IGetShopManufacturesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetShopManufacturesSuccessAction | IGetShopManufacturesLoadFailedAction> =>
    action$.ofType(IGET_SHOP_MANUFACTURERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<ById<IShopManufacture>>>(
                    END_POINTS.SHOP.GET_MANUFACTURE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<ById<IShopManufacture>>): IGetShopManufacturesSuccessAction => {
                            return getShopManufacturesLoadSuccessAction(response);
                        }),
                        catchError((response: PartsResponse<string>) => ActionsObservable.of(getShopManufacturesLoadFailedAction(response.message)))
                    )
            )
        );