import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IUPDATE_SHOP_MODEL_REQUEST } from "./updateShopModelsConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";
import { IUpdateShopModelLoadAction, IUpdateShopModelLoadFailedAction, IUpdateShopModelSuccessAction } from "./updateShopModelsActions";

type Actions =
    | IUpdateShopModelLoadAction
    | IUpdateShopModelSuccessAction
    | IUpdateShopModelLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateShopModelReducer = (state: Server<PartsResponse<string>> = notLoaded, action: Actions): Server<PartsResponse<string>> => {
    switch (action.type) {
        case IUPDATE_SHOP_MODEL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_SHOP_MODEL_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_SHOP_MODEL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};