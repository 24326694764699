import React from "react";
import Popover, { PopoverOrigin } from "@material-ui/core/Popover";
import { HasChildren } from "./publicInterfaces";
import { LACrossButtonWithInWindow } from "./crossButton";

interface ILAPopoverProps extends HasChildren {
    open: boolean;
    anchorRef: HTMLButtonElement | null;
    anchorOrigin?: PopoverOrigin;
    onClose: () => void;
    transformOrigin?: PopoverOrigin;
}

const anchorOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "center",
};

const transformOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "center",
};

export const LAPopover: React.FC<ILAPopoverProps> = React.memo((props: ILAPopoverProps) => {
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorRef}
            onClose={props.onClose}
            anchorOrigin={props.anchorOrigin ?? anchorOrigin}
            transformOrigin={props.transformOrigin ?? transformOrigin}
        >
            {props.children}
            <LACrossButtonWithInWindow onClick={props.onClose} />
        </Popover>
    );
});