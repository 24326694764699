import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_UNIT_REQUEST } from "./addUnitConstants";
import { IAddUnitLoadAction, IAddUnitLoadFailedAction, IAddUnitSuccessAction, addUnitLoadFailedAction, addUnitLoadSuccessAction } from "./addUnitActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { IUnit } from "../../getUnits/getUnitsConstants";

export const addUnitEpic: Epic = (
    action$: ActionsObservable<IAddUnitLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddUnitSuccessAction | IAddUnitLoadFailedAction> =>
    action$.ofType(IADD_UNIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<IUnit[]>>(
                    END_POINTS.GET_TOOL_RENTALS.UNITS.ADD,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<IUnit[]>): IAddUnitSuccessAction | IAddUnitLoadFailedAction => {
                            if(response.message === "Success"){
                                return addUnitLoadSuccessAction(response);
                            }
                            return addUnitLoadFailedAction(response.message);
                        }),
                        catchError((response: ToolRentalsResponse<any>) => ActionsObservable.of(addUnitLoadFailedAction(response.message)))
                    )
            )
        );