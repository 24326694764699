
async function UpdateScheduledService(requestObject:any, currentHoursCheck:boolean, currentUser:string, cancelServiceCheck:boolean){
    console.log('updating ', requestObject);
    var cancelled:string;
    if(cancelServiceCheck){
      cancelled = "Yes";
    }
    else{
      cancelled = "No";
    }
    let responseStatus = null;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
        request:{
            ID:requestObject.id,
            Unit_Number:requestObject.unit_Number,
            Service_Type:requestObject.service_Type,
            Service_Date:requestObject.service_Date,
            Meter_Reading:Number(requestObject.meter_Reading),
            ECM:Number(requestObject.ecm),
            Previous_Date:requestObject.previous_Date,
            Previous_Reading:requestObject.previous_Reading,
            Completed_Date:requestObject.completed_Date,
            Comments:requestObject.comments,
            Attachments:requestObject.attachments,
            Current_Hours_Check:currentHoursCheck===true ?"Yes":"No",
            Cancelled:cancelled,
            Created_By:currentUser,
            Modified_By:currentUser,
            }
    })
  }
    await fetch('https://api.sureway.ca/api/SOCT/UpdateSOCTScheduledService', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')
          // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
    });
    return responseStatus;
}
export default UpdateScheduledService;