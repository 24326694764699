export enum IDELETE_PICKUP_TRUCK_REQUEST {
    REQUEST = "deletePickupTruck/DELETE_PICKUP_TRUCK_REQUEST",
    SUCCESS = "deletePickupTruck/DELETE_PICKUP_TRUCK_SUCCESS",
    FAILED = "deletePickupTruck/DELETE_PICKUP_TRUCK_FAILED"
};

export interface IDeletePickupTruckRequest {
    request: {
        ItemID: number;
    };
    token: string;
};