import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { IPart } from "../getParts/getPartConstants";
import { IGET_ALL_PARTS_REQUEST, IGetAllPartRequest } from "./getAllPartsConstants";


export interface IGetAllPartsLoadAction {
    type: IGET_ALL_PARTS_REQUEST.REQUEST;
    data: IGetAllPartRequest
}
export const getAllPartsLoadAction = (data: IGetAllPartRequest): IGetAllPartsLoadAction => ({
    type: IGET_ALL_PARTS_REQUEST.REQUEST,
    data
});
export interface IGetAllPartsSuccessAction {
    type: IGET_ALL_PARTS_REQUEST.SUCCESS;
    list: PartsResponse<ById<IPart>>;
}
export const getAllPartsLoadSuccessAction = (list: PartsResponse<ById<IPart>>): IGetAllPartsSuccessAction => ({
    type: IGET_ALL_PARTS_REQUEST.SUCCESS,
    list
});
export interface IGetAllPartsLoadFailedAction {
    type: IGET_ALL_PARTS_REQUEST.FAILED;
    message: string;
}
export const getAllPartsLoadFailedAction = (message: string): IGetAllPartsLoadFailedAction => ({
    type: IGET_ALL_PARTS_REQUEST.FAILED,
    message
});
