import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_RATE_REQUEST } from "./addRateConstants";
import { IAddRateLoadAction, IAddRateLoadFailedAction, IAddRateSuccessAction, addRateLoadFailedAction, addRateLoadSuccessAction } from "./addRateActions";
import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { IRate } from "../../getRates/getRatesConstants";

export const addRateEpic: Epic = (
    action$: ActionsObservable<IAddRateLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddRateSuccessAction | IAddRateLoadFailedAction> =>
    action$.ofType(IADD_RATE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ToolRentalsResponse<IRate[]>>(
                    END_POINTS.GET_TOOL_RENTALS.RATES.ADD,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ToolRentalsResponse<IRate[]>): IAddRateSuccessAction | IAddRateLoadFailedAction => {
                            if(response.message === "Success"){
                                return addRateLoadSuccessAction(response);
                            }
                            return addRateLoadFailedAction(response.message);
                        }),
                        catchError((response: ToolRentalsResponse<any>) => ActionsObservable.of(addRateLoadFailedAction(response.message)))
                    )
            )
        );