import React from "react";
import styled from "styled-components";
import { RED_COLOR } from "./shared/theme";

interface IUnAuthorizedPageProps {

};

const UnAuthorizedPageStyles = styled.div`
    text-align: center;

    h1 {
        color: ${RED_COLOR};
        font-size: 50px;
    };

    p {
        font-size: 30px;
    };
`;

export const UnAuthorizedPage: React.FC<IUnAuthorizedPageProps> = React.memo((props: IUnAuthorizedPageProps) => {
    return <UnAuthorizedPageStyles>
            <h1>401</h1>
            <p id="heading">Unauthorized</p>
            <p id="text">Sorry you do not have permission to access this application</p>
    </UnAuthorizedPageStyles>;
});