import { ByIdNumber } from './../../../react/shared/publicInterfaces';
import { ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_ORDERS_REQUEST, IOrder, IOrderRequest } from "./getOrdersConstants";


export interface IGetOrdersLoadAction {
    type: IGET_ORDERS_REQUEST.REQUEST;
    data: IOrderRequest
}
export const getOrdersLoadAction = (data: IOrderRequest): IGetOrdersLoadAction => ({
    type: IGET_ORDERS_REQUEST.REQUEST,
    data
});
export interface IGetOrdersSuccessAction {
    type: IGET_ORDERS_REQUEST.SUCCESS;
    list: ToolRentalsResponse<ByIdNumber<IOrder>>;
}
export const getOrdersLoadSuccessAction = (list: ToolRentalsResponse<ByIdNumber<IOrder>>): IGetOrdersSuccessAction => ({
    type: IGET_ORDERS_REQUEST.SUCCESS,
    list
});
export interface IGetOrdersLoadFailedAction {
    type: IGET_ORDERS_REQUEST.FAILED;
    message: string;
}
export const getOrdersLoadFailedAction = (message: string): IGetOrdersLoadFailedAction => ({
    type: IGET_ORDERS_REQUEST.FAILED,
    message
});
