export const ROUTE = {
    INDEX: "/",
    UNAUTHORIZED: "/unauthorized",
    TOOL_RENTALS: {
        INDEX: "/toolrentals",
        UNAUTHORIZED: "/toolrentals/unauthorized",
        PROJECT: {
            INDEX: "/toolrentals/projects"
        },
        ORDER: {
            INDEX: "/toolrentals/orders"
        },
        UNIT: {
            INDEX: "/toolrentals/units"
        },
        RATE: {
            INDEX: "/toolrentals/rates"
        },
        REPORT: {
            INDEX: "/toolrentals/report"
        },
        INVOICE: {
            INDEX: "/toolrentals/invoice"
        }
    },
    PARTS: {
        INDEX: "/parts",
        UNAUTHORIZED: "/parts/unauthorized",
        MANUFACTURER: {
            INDEX: "/parts/manufacturers"
        },
        MODEL: {
            INDEX: "/parts/models"
        },
        PART: {
            INDEX: "/parts/parts",
            REPORT: "/parts/report"
        },
        DISMANTLED: {
            INDEX: "/parts/dismantled"
        },
        PICKUP_TRUCK: {
            INDEX: "/parts/dismantled-pickup-truck"
        },
        HEAVY_TRUCK: {
            INDEX: "/parts/dismantled-heavy-truck"
        }
    },
    SHOP: {
        INDEX: "/shop",
        MANUFACTURER: {
            INDEX: "/shop/manufacturers",
            MANUFACTURER: "/shop/manufacturer",
        },
        MODEL: {
            INDEX: "/shop/models",
            MODEL: "/shop/model",
        },
        EQUIPMENT: {
            INDEX: "/shop/equipments",
            EQUIPMENT: "/shop/equipment"
        },
        EQUIPMENT_TYPE: {
            INDEX: "/shop/equipment-types",
            TYPE: "/shop/equipment-type",
        },
        CONFIG: {
            INDEX: "/shop/config"
        }
    },
    SOCT: {
        INDEX:"/soct/lists",
        SOCT_MAIN: {
            LISTCREATE:"/soct/lists/create",
            LISTEDIT:"/soct/lists/edit/id=:id",
        },
        METERS: {
            INDEX:"/soct/meters",
            METERSCREATE:"/soct/meters/create",
            METERSEDIT:"/soct/meters/edit/id=:id",
        },
        SERVICES: {
            INDEX:"/soct/scheduledservice",
            SCHEDULEDSERVICECREATE:"/soct/scheduledservice/create",
            SCHEDULEDSERVICEEDIT:"/soct/scheduledservice/edit/id=:id",
            CUSTOMSERVICE:"/soct/customservice",
            CUSTOMSERVICECREATE:"/soct/customservice/create",
            CUSTOMSERVICEEDIT:"/soct/customservice/edit/id=:id",
        },
        EQUIPMENT_MOVES: {
            INDEX:"/soct/moves",
            MOVESCREATE:"/soct/moves/create",
            MOVESEDIT:"/soct/moves/edit/id=:id",
        },
        WORKING_SITES: {
            INDEX:"/soct/sites",
            SITESCREATE:"/soct/sites/create",
            SITESEDIT:"/soct/sites/edit/id=:id",
        },
        TRUCKING: {
            INDEX:"/soct/trucking"
        },
        BULKSOCT: {
            INDEX:"/soct/bulksoct"
        },
        METERAPP: {
            INDEX:"/soct/meterapp"
        },
    }
};
