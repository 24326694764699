import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST, IPartDismantledEquipment, IPartDismantledEquipmentRequest } from "./getPartsDismantledEquipmentConstants";


export interface IGetPartsDismantledEquipmentsLoadAction {
    type: IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST.REQUEST;
    data: IPartDismantledEquipmentRequest
}
export const getPartsDismantledEquipmentsLoadAction = (data: IPartDismantledEquipmentRequest): IGetPartsDismantledEquipmentsLoadAction => ({
    type: IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST.REQUEST,
    data
});
export interface IGetPartsDismantledEquipmentsSuccessAction {
    type: IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST.SUCCESS;
    list: PartsResponse<IPartDismantledEquipment[]>;
}
export const getPartsDismantledEquipmentsLoadSuccessAction = (list: PartsResponse<IPartDismantledEquipment[]>): IGetPartsDismantledEquipmentsSuccessAction => ({
    type: IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST.SUCCESS,
    list
});
export interface IGetPartsDismantledEquipmentsLoadFailedAction {
    type: IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST.FAILED;
    message: string;
}
export const getPartsDismantledEquipmentsLoadFailedAction = (message: string): IGetPartsDismantledEquipmentsLoadFailedAction => ({
    type: IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST.FAILED,
    message
});
