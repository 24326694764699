import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";
import { IGET_SHOP_EQUIPMENTS_REQUEST, IShopEquipment } from "./getEquipmentConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { IGetShopEquipmentsLoadAction, IGetShopEquipmentsLoadFailedAction, IGetShopEquipmentsSuccessAction } from "./getEquipmentActions";
import { IAddShopEquipmentSuccessAction } from "../addEquipments/addShopEquipmentsActions";
import { IUpdateShopEquipmentSuccessAction } from "../updateEquipments/updateShopEquipmentsActions";
import { IADD_SHOP_EQUIPMENT_REQUEST } from "../addEquipments/addShopEquipmentsConstants";
import { IUPDATE_SHOP_EQUIPMENT_REQUEST } from "../updateEquipments/updateShopEquipmentsConstants";

type Actions =
    | IGetShopEquipmentsLoadAction
    | IGetShopEquipmentsSuccessAction
    | IUpdateShopEquipmentSuccessAction
    | IAddShopEquipmentSuccessAction
    | IGetShopEquipmentsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetShopEquipmentsReducer = (state: Server<PartsResponse<ById<IShopEquipment>>> = notLoaded, action: Actions): Server<PartsResponse<ById<IShopEquipment>>> => {
    switch (action.type) {
        case IGET_SHOP_EQUIPMENTS_REQUEST.REQUEST:
            return loading;

        case IGET_SHOP_EQUIPMENTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SHOP_EQUIPMENTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SHOP_EQUIPMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SHOP_EQUIPMENT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};