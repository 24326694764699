import React from "react";
import { HasClass } from "./publicInterfaces";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

interface ILADatePickerProps extends HasClass {
    value: any;
    onChange: (date: string) => void;
    disable?: true | undefined;
    minDate?: string;
    maxDate?: string;
    dateFormat?: string;
}

const LADatePicker: React.FC<ILADatePickerProps> = (props: ILADatePickerProps) => {
    let newD = (props.value || (props.value !== null)) ? new Date(props.value) : undefined;
    let minDate = props.minDate ? new Date(props.minDate) : undefined;
    let maxDate = props.maxDate ? new Date(props.maxDate) : undefined;

    return <Flatpickr
        value={newD}
        options={{
            dateFormat: props.dateFormat ?? "m/d/Y",
            minDate: minDate,
            maxDate: maxDate
        }}
        disabled={props.disable}
        onChange={(date: any) => props.onChange(date[0].toString())} />;
};

export default LADatePicker;
