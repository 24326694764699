import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_PROJECT_LIST_DD_REQUEST, IProjectListDD } from "./getProjectListDDConstants";
import { IAddProjectSuccessAction } from "../../add/project/addProjectActions";
import { IADD_PROJECT_REQUEST } from "../../add/project/addProjectConstants";
import { IGetProjectListDDLoadAction, IGetProjectListDDLoadFailedAction, IGetProjectListDDSuccessAction } from "./getProjectListDDActions";

type Actions =
    | IGetProjectListDDLoadAction
    | IGetProjectListDDSuccessAction
    | IGetProjectListDDLoadFailedAction
    | IAddProjectSuccessAction
    | IFlushDataSuccessAction;

export const GetProjectListDDReducer = (state: Server<ToolRentalsResponse<IProjectListDD[]>> = notLoaded, action: Actions): Server<ToolRentalsResponse<IProjectListDD[]>> => {
    switch (action.type) {
        case IGET_PROJECT_LIST_DD_REQUEST.REQUEST:
            return loading;

        case IGET_PROJECT_LIST_DD_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PROJECT_LIST_DD_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_PROJECT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};