
async function AddScheduledService(requestObject:any, customServiceList:any, currentUser:string){
    let responseStatus = null;
    var update_location_check:string;
    if(requestObject.updateLocationCheck){
      update_location_check = "Yes";
    }
    else{
      update_location_check = "No";
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
        request:{
            Unit_Number:requestObject.unit_Number,
            Service_Type:requestObject.serviceType,
            Service_Date:requestObject.serviceDate,
            Meter_Reading:Number(requestObject.meterReading),
            Custom_Service:requestObject.customService,
            Custom_Service_Notes:requestObject.customServiceNotes,
            ECM:Number(requestObject.ecm),
            Previous_Date:requestObject.previous_Date,
            Previous_Reading:requestObject.previous_Reading,
            Location:requestObject.location,
            Current_Sub_Location:requestObject.current_Sub_Location,
            Current_Sub_Location_ID:requestObject.current_Sub_Location_ID,
            New_Service_Location:requestObject.new_Location,
            New_Service_Sub_Location:requestObject.sub_Location,
            New_Service_Sub_Location_ID:requestObject.sub_Location_ID,
            Update_Location_Check:update_location_check,
            Comments:requestObject.comments,
            Attachments:requestObject.attachments,
            Current_Hours_Check:requestObject.currentHoursCheck===true ?"Yes":"No",
            Custom_Service_List:customServiceList,
            Created_By:currentUser,
            Modified_By:currentUser,
            }
    })
  }
    await fetch('https://api.sureway.ca/api/SOCT/AddSOCTScheduledService', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')
          // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
    });
    return responseStatus;
}
async function GetScheduledService(unit_Number:string){
let scheduled_services:any = [];
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type':'application/json' }, 
          body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request:{Unit_Number:unit_Number}})
      }
        await fetch('https://api.sureway.ca/api/SOCT/GetSOCTScheduledService', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((history:any) => {
                scheduled_services.push(history);
            });
          });
        return scheduled_services;
  }

  async function getServiceTypesByUnitNumber(unit_Number:string){
    let serviceTypes:any = [];
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type':'application/json' }, 
          body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request:{Unit_Number:unit_Number}})
      }
        await fetch('https://api.sureway.ca/api/SOCT/GetSOCTServiceTypesByUnitNumer', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((serivce_types:any) => {
              serviceTypes.push(serivce_types);
            });
          });
        return serviceTypes;
  }
  
function getSOCTServiceFile(id:any, filename:string, service_id:any){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{Equipment_ID:id, File:filename, serviceID:service_id}, token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  fetch('https://api.sureway.ca/api/SOCT/GetSOCTServiceFile', requestOptions)
  .then((response:any) => {
      return response.blob();
  })
  .then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
  })
  .catch((res) => console.log(res));
}

export default AddScheduledService;
export {GetScheduledService, getServiceTypesByUnitNumber, getSOCTServiceFile};