
export enum IADD_SHOP_MODEL_REQUEST {
    REQUEST = "addShopModel/ADD_SHOP_MODEL_REQUEST",
    SUCCESS = "addShopModel/ADD_SHOP_MODEL_SUCCESS",
    FAILED = "addShopModel/ADD_SHOP_MODEL_FAILED"
};

export interface IAddShopModelRequest {
    token: string;
    request: {
        Make: string;
        Model: string;
        Manufacturer_ID: string;
        Unit_Number: string;
        Created_By: string;
        Modified_By: string;
    };
};