
import React, { ReactNode } from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { HasClass } from "./publicInterfaces";

interface ILASecondaryButtonProps extends HasClass {
    label: string;
    startIcon?: ReactNode;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: true | undefined;
}

const LAInputButtonStyles = styled.div`
    .input {
        display: none;
      };
`;

const LAInputButton: React.FC<ILASecondaryButtonProps> = React.memo((props: ILASecondaryButtonProps) => 
    <LAInputButtonStyles>
        <input
            className="input"
            accept="image/*"
            id="contained-button-file"
            multiple={true}
            type="file"
            onChange={props.onChange}
            disabled={props.disabled}
        />
        <label htmlFor="contained-button-file">
            <Button component="span" color="secondary" startIcon={props.startIcon}>
                {props.label}
            </Button>
        </label>
    </LAInputButtonStyles>
);

export default LAInputButton;
