import React, { PureComponent, ReactNode } from "react";
import { connect } from "react-redux";
import { IDispatch, IStore } from "../../redux/reducers";
import { hasPayload, Server } from "../../redux/server";
import { validateShopGroup } from "../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup } from "../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ZEROTH } from "./constExports";
import LAErrorBox from "./errorBox";
import LALoading from "./loading";
import { HasChildren, ToolRentalsResponse } from "./publicInterfaces";

interface ILARoleBasedAccess extends HasChildren {
    roleFor: string;
    error: boolean;
};


interface ILARoleBasedAccessDispatchProps {
}

interface ILARoleBasedAccessStoreProps {
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
}

interface ILARoleBasedAccessState {
    myRoles?: string[];
    isLoading?: boolean;
}

type ILARoleBasedAccessProps =
    ILARoleBasedAccess &
    ILARoleBasedAccessDispatchProps &
    ILARoleBasedAccessStoreProps;

class LARoleBasedAccess extends PureComponent<ILARoleBasedAccessProps, ILARoleBasedAccessState> {


    public constructor(props: ILARoleBasedAccessProps) {
        super(props);
        this.state = {
            myRoles: [],
            isLoading: true
        };
    };

    public componentDidMount(): void {
        if (hasPayload(this.props.validateShopGroup) && this.state.myRoles?.length === ZEROTH) {
            this.setState({ myRoles: this.props.validateShopGroup.payload.response.toolrentalsRoles, isLoading: false });
        }
    }

    public componentDidUpdate(prevProps: ILARoleBasedAccessProps): void {
        if (hasPayload(this.props.validateShopGroup) && this.state.myRoles?.length === ZEROTH) {
            this.setState({ myRoles: this.props.validateShopGroup.payload.response.toolrentalsRoles, isLoading: false });
        };
    }

    public render(): ReactNode {
        const { myRoles, isLoading } = this.state;
        const { roleFor } = this.props;
        return (
            <>
                {isLoading === false ? myRoles !== undefined && myRoles?.length > ZEROTH
                    && (myRoles.includes(roleFor))
                    ?
                    <>
                        {this.props.children}
                    </>
                    :
                    this.props.error === true ? <LAErrorBox text="You do not have permission to view this page. Contact your system admin." /> : null
                    : <LALoading />
                }
            </>
        )
    }

};

const mapStateToProps = (state: IStore): ILARoleBasedAccessStoreProps => ({
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ILARoleBasedAccessDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LARoleBasedAccess);