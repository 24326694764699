import { IProject } from "../../getProjects/getProjectsConstants";

export enum IUPDATE_PROJECT_REQUEST {
    REQUEST = "updateProject/UPDATE_PROJECT_REQUEST",
    SUCCESS = "updateProject/UPDATE_PROJECT_SUCCESS",
    FAILED = "updateProject/UPDATE_PROJECT_FAILED"
};

export interface IUpdateProjectRequest {
    request: IProject;
    token: string;
};