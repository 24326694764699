
export enum IUPDATE_SHOP_MODEL_REQUEST {
    REQUEST = "updateShopModel/UPDATE_SHOP_MODEL_REQUEST",
    SUCCESS = "updateShopModel/UPDATE_SHOP_MODEL_SUCCESS",
    FAILED = "updateShopModel/UPDATE_SHOP_MODEL_FAILED"
};

export interface IUpdateShopModelRequest {
    token: string;
    request: {
        ID: number;
        Make: string;
        Model: string;
        Manufacturer_ID: string;
        Unit_Number: string;
        Created_By: string;
        Modified_By: string;
    };
};