import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IDispatch, IStore } from "../../../redux/reducers";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAPaperWithPadding } from "../../shared/paper";
import { hasPayload, Server } from "../../../redux/server";
import { PartsResponse } from "../../shared/publicInterfaces";
import { IValidateShopGroup } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroup } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { ROUTE } from "../../routes";

interface IPartsHomeStoreProps {
    validateShopGroup: Server<PartsResponse<IValidateShopGroup>>;
};

interface IPartsHomeDispatchProps {

};

interface IPartsHomeOwnProps {

};

interface IPartsHomeState {

};

const PartsHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IPartsHomeProps = RouteComponentProps
    & IPartsHomeStoreProps
    & IPartsHomeDispatchProps
    & IPartsHomeOwnProps;

class PartsHome extends PureComponent<IPartsHomeProps, IPartsHomeState> {

    public constructor(props: IPartsHomeProps) {
        super(props);
        this.state = {

        };

    }

    public componentDidMount(): void {
        this.roleCheck();
    };

    public async componentDidUpdate(prevProps: IPartsHomeProps, prevState: IPartsHomeState): Promise<void> {
        if (this.props !== prevProps)
            this.roleCheck();
    };

    public render(): ReactNode {

        return (
            <PartsHomeStyles>
                <LAGrid spacing={3} className="text-center">

                    <LAGridItem xs={12}>
                        <h1>Welcome to Parts</h1>
                    </LAGridItem>

                </LAGrid>
            </PartsHomeStyles>
        );
    }

    private roleCheck = (): void => {
        if (hasPayload(this.props.validateShopGroup))
            if (this.props.validateShopGroup.payload.response.partsaccess === false)
                this.props.history.push(ROUTE.PARTS.UNAUTHORIZED);
    };

}

const mapStateToProps = (state: IStore): IPartsHomeStoreProps => ({
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IPartsHomeDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PartsHome);