import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IModel } from "../../getModels/getModelConstants";
import { IADD_MODEL_REQUEST, IAddModelRequest } from "./addModelConstants";


export interface IAddModelLoadAction {
    type: IADD_MODEL_REQUEST.REQUEST;
    data: IAddModelRequest
}
export const addModelLoadAction = (data: IAddModelRequest): IAddModelLoadAction => ({
    type: IADD_MODEL_REQUEST.REQUEST,
    data
});
export interface IAddModelSuccessAction {
    type: IADD_MODEL_REQUEST.SUCCESS;
    data: PartsResponse<IModel[]>;
}
export const addModelLoadSuccessAction = (data: PartsResponse<IModel[]>): IAddModelSuccessAction => ({
    type: IADD_MODEL_REQUEST.SUCCESS,
    data
});
export interface IAddModelLoadFailedAction {
    type: IADD_MODEL_REQUEST.FAILED;
    message: string;
}
export const addModelLoadFailedAction = (message: string): IAddModelLoadFailedAction => ({
    type: IADD_MODEL_REQUEST.FAILED,
    message
});
