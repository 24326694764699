import { ReactNode, PureComponent } from "react";
import { IDismantledEquipmentFacets } from "../../../../redux/parts/getDismantledEquipmentFacets/getDismantledEquipmentFacetsConstants";
import { ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALinkButton from "../../../shared/linkButton";
import { HasClass } from "../../../shared/publicInterfaces";


interface ILAFacetFilterListProps extends HasClass {
    viewMore: any;
    facets: IDismantledEquipmentFacets[];
    selectedValues: IDismantledEquipmentFacets[];
    onViewMoreClick: (facetGroupKey: string) => void;
    onIndividualFacetClick: (id: number | string, type: string) => void;
};

class LAFacetFiltersList extends PureComponent<ILAFacetFilterListProps> {

    public render(): ReactNode {

        const { viewMore, onIndividualFacetClick, onViewMoreClick, facets } = this.props;

        return (
            <LAGrid direction="column">

                <LAGridItem xs={12} className="mt-4">
                    <div>
                        <strong>Equipments</strong>
                    </div>
                </LAGridItem>

                {(facets !== null) && <LAGridItem xs={12}>
                    <LAGrid spacing={0}>
                        {this.modelFacets()
                            .slice(ZEROTH, ("equipment" === viewMore.id) ? viewMore.count : 10)
                            .filter(x => !this.props.selectedValues.includes(x))
                            .map((x: IDismantledEquipmentFacets, index: number): JSX.Element =>
                                <LAGridItem xs={12} key={x.equipment}>
                                    <LALinkButton
                                        color="inherit"
                                        label={x.equipment}
                                        badgeCount={x.count > 0 ? x.count : undefined}
                                        onClick={(): void => onIndividualFacetClick(x.equipment, "equipment")}
                                    >
                                    </LALinkButton>
                                </LAGridItem>
                            )}
                    </LAGrid>

                    {((viewMore.id === "equipment") ? (facets.length > viewMore.count) : (facets.length > 10)) && <LALinkButton
                        onClick={(): void => {
                            onViewMoreClick("equipment");
                        }}
                        className="mt-2"
                        label="View More"
                        fontSize={14}
                    />}

                </LAGridItem>}
            </LAGrid>
        );
    }

    private modelFacets = (): IDismantledEquipmentFacets[] => {
        const response: IDismantledEquipmentFacets[] = [];
        const selected = this.props.selectedValues;
        if(selected.length > 0){
            this.props.facets.forEach(x => {
                if(!selected.some(q => q.equipment === x.equipment)){
                    response.push(x);
                }
            });
            return response;
        } else {
            return this.props.facets;
        }
    };

}

export default LAFacetFiltersList;
