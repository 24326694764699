import React from 'react';
import '../../../App.css';
import NavigationBar from './navbar';
import {RouteComponentProps} from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { getSOCTUserRole } from './api/soct/soctAPI';

const getToken = localStorage.getItem("adal.idtoken");
const token: any = (getToken !== null && getToken.length > 0) ? jwt_decode(getToken) : { unique_name: "SUREWAYGROUP\\dylan.ollikka" };
const currentUser = token.unique_name.substr(token.unique_name.lastIndexOf("\\") + 1);

interface IProps{
  history:any
}
export default class SOCTHome extends React.Component<IProps,{}>{

  handleRoute(id:string){
    switch(id){
      case 'home':
        this.props.history.push('/');
        break;
      case 'sotc':
        this.props.history.push('/soct/lists');
        break;
      case 'meters':
        this.props.history.push('/soct/meters');
        break;
      case 's_service':
        this.props.history.push('/soct/scheduledservice');
        break;
      case 'c_service':
        this.props.history.push('/soct/customservice');
        break;
      case 'sites':
        this.props.history.push('/soct/sites');
        break;
      case 'moves':
        this.props.history.push('/soct/moves');
        break;
      case 'foremans':
        this.props.history.push('/soct/foremans');
        break;
      case 'trucks':
        this.props.history.push('/soct/trucking');
        break;
      case 'bulkedit':
        this.props.history.push('/soct/bulksoct');
        break;
      case 'meterapp':
        this.props.history.push('/soct/meterapp');
        break;
    }
  }

  async componentDidMount(){
    // var res:any = await getSOCTUserRole(currentUser);
    // if(res.isAuthenticated){
    //   if(res.role !='Admin'){
    //     this.props.history.push('/');
    //   }
    // }
    window.location.href = "https://apps.sureway.ca/field/soct/lists";
    
  }

  render(){
  return (
    <div className="SOCT-App">
      <div className="SOCT-App-header">
        <p>
          Site Oil Change Tracker <link rel="manifest" href="/manifest.json"></link>
        </p>
        <NavigationBar handleRoute={this.handleRoute.bind(this)}></NavigationBar>
      </div>
      
    </div>
  );
  }
}
export {currentUser};