import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ByIdNumber, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { MEDIA_QUERY_PHONE } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithPadding } from "../../../shared/paper";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { IReport, IReportRequest } from "../../../../redux/toolRentals/getReport/getReportConstants";
import { getReport } from "../../../../redux/toolRentals/getReport/getReportAccessor";
import { getReportLoadAction } from "../../../../redux/toolRentals/getReport/getReportActions";
import { undefinedFunction } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { IValidateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { ROUTE } from "../../../routes";


interface IReportStoreProps {
    token: Server<ToolRentalsResponse<IToken>>;
    ReportList: Server<ToolRentalsResponse<ByIdNumber<IReport>>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IReportDispatchProps {
    RequestReportList: (request: IReportRequest) => unknown;
};

interface IReportOwnProps {

};

interface IReportState {
    reports: ByIdNumber<IReport>;
};

const ReportStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .moveRight {
        position: absolute;
        top: 0px;
        right: 10px;
    };
`;

type IReportProps = RouteComponentProps
    & IReportStoreProps
    & IReportDispatchProps
    & IReportOwnProps;

class Report extends PureComponent<IReportProps, IReportState> {

    public constructor(props: IReportProps) {
        super(props);
        this.state = {
            reports: {}
        };
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IReportProps, prevState: IReportState): Promise<void> {
        if (this.props.ReportList !== prevProps.ReportList) {
            this.callReduxForData();
        }
    };

    public render(): ReactNode {

        const { reports } = this.state;
        const { ReportList } = this.props;

        return (
            <ReportStyles>
                <LAGrid spacing={2}>
                    {(ReportList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                        <LACenteredLoading />
                    </LAGridItem>}

                    {(ReportList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                        <LAErrorBox text="Failed to Load Reports" />
                    </LAGridItem>}

                    {(ReportList.kind === STATUS_ENUM.SUCCEEDED) && <>
                        <LAGridItem xs={12}>
                            <h2 className="text-center">REPORT</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LADevExtremeGrid
                                export={true}
                                actionWidth={100}
                                removeStyleBtn={true}
                                searchPanel={true}
                                filterHeader={true}
                                exportFileName="Report"
                                onClick={undefinedFunction}
                                data={Object.values(reports)}
                                columns={[
                                    { name: "project_Number", caption: "Project Number", type: "string" },
                                    { name: "project_Name", caption: "Project Name", type: "string" },
                                    { name: "picked_Up_By", caption: "Picked Up By", type: "string" },
                                    { name: "unit_No", caption: "Unit No", type: "string" },
                                    { name: "class", caption: "Class", type: "string" },
                                    { name: "type", caption: "Type", type: "string" },
                                    { name: "quantity", caption: "Quantity", type: "number", width: 90 },
                                    { name: "date_Out", caption: "Date Out", type: "date" },
                                    { name: "date_Returned", caption: "Date Returned", type: "date" },
                                    { name: "po", caption: "PO", type: "string" },
                                    { name: "status", caption: "Status", type: "string" }
                                ]}
                            />
                        </LAGridItem>
                    </>}
                </LAGrid>
            </ReportStyles>
        );
    }

    private callReduxForData = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.toolrentalsaccess === true) {
                if (isNotLoaded(this.props.ReportList)) {
                    this.sendRequest();
                };
        
                if (hasPayload(this.props.ReportList)) {
                    this.setState({ reports: this.props.ReportList.payload.response });
                };
            } else {
                this.props.history.push(ROUTE.TOOL_RENTALS.UNAUTHORIZED);
            }
        };
    };

    private sendRequest = (): void => {
        if (hasPayload(this.props.token))
            this.props.RequestReportList({
                token: this.props.token.payload.response.token
            });
    };

}

const mapStateToProps = (state: IStore): IReportStoreProps => ({
    token: getToken(state),
    ReportList: getReport(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IReportDispatchProps => ({
    RequestReportList: (request: IReportRequest): unknown => dispatch(getReportLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);