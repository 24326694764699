import React from "react";
import { ById, HasClass } from "../../../shared/publicInterfaces";
import { LAIconButton } from "../../../shared/buttons";
import { CancelIcon, SaveIcon } from "../../../shared/icons";
import { LAPaperWithLessPadding } from "../../../shared/paper";
import LATextField from "../../../shared/textField";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IRate } from "../../../../redux/toolRentals/getRates/getRatesConstants";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { GREEN_COLOR, LIGHT_GREY_COLOR, RED_COLOR, SECONDARY_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import styled from "styled-components";
import LANumberField from "../../../shared/textField";

interface IRateGridProps extends HasClass {
    data: IRate;
    error: ById<IFieldErrorKeyValue>;
    onSave: () => void;
    onEdit: (id: number | undefined) => void;
    onChange: (name: string, value: string) => void;
};

const RateGridStyles = styled.div`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };
`;


export const RateGrid: React.FC<IRateGridProps> = React.memo((props: IRateGridProps) => {
    const onCancel = (): void => props.onEdit(undefined);
    return <RateGridStyles>
        <TableContainer component={LAPaperWithLessPadding}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow className="header-row">
                        <TableCell className="title" align="center">CLASS</TableCell>
                        <TableCell className="title" align="center">TYPE</TableCell>
                        <TableCell className="title" align="center">DAILY</TableCell>
                        <TableCell className="title" align="center">WEEKLY</TableCell>
                        <TableCell className="title" align="center">MONTHLY</TableCell>
                        <TableCell className="title" align="center">EDIT</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <EditRow {...props} error={props.error} value={props.data} onCancel={onCancel} onSave={props.onSave} onChange={props.onChange} />
                </TableBody>
            </Table>
        </TableContainer>
    </RateGridStyles>;
}
);


interface IEditRowProps {
    value: IRate;
    onSave: () => void;
    error: ById<IFieldErrorKeyValue>;
    onCancel: (id: undefined) => void;
    onChange: (name: string, value: string) => void;
};

const EditRow: React.FC<IEditRowProps> = React.memo((props: IEditRowProps) => {

    const onCancel = () => props.onCancel(undefined);
    const err = Object.values(props.error).length > 0 ? true : undefined;

    return <TableRow key={props.value.id}>
        <TableCell component="th" scope="row" align="center">
            <LATextField errorText={props.error["class"] ? props.error["class"].message : undefined}
                name="class" label="Class" value={props.value.class} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LATextField errorText={props.error["type"] ? props.error["type"].message : undefined}
                name="type" label="Type" fullWidth={true} value={props.value.type} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LANumberField errorText={props.error["daily"] ? props.error["daily"].message : undefined}
                name="daily" label="Daily" value={props.value.daily} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LANumberField errorText={props.error["weekly"] ? props.error["weekly"].message : undefined}
                name="weekly" label="Weekly" value={props.value.weekly} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LANumberField errorText={props.error["monthly"] ? props.error["monthly"].message : undefined}
                name="monthly" label="Monthly" value={props.value.monthly} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="right">{<LAGrid spacing={1}>
            <LAGridItem xs={6}>
                <LAIconButton disabled={err} icon={<SaveIcon color={err ? LIGHT_GREY_COLOR : GREEN_COLOR} />} label="Save" onClick={props.onSave} />
            </LAGridItem>

            <LAGridItem xs={6}>
                <LAIconButton icon={<CancelIcon color={RED_COLOR} />} label="Cancel" onClick={onCancel} />
            </LAGridItem>
        </LAGrid>}</TableCell>
    </TableRow>
});