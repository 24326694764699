export enum IGET_TOKENS_REQUEST {
    REQUEST = "getToken/GET_TOKENS_REQUEST",
    SUCCESS = "getToken/GET_TOKENS_SUCCESS",
    FAILED = "getToken/GET_TOKENS_FAILED"
};

export interface ITokenRequest {
    request: {
        username: string;
    };
    token?: string;
};

export interface IToken {
  token: string;
};