import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { OrderIcon } from "../shared/icons";

interface IOrderDropDownProps {
    onDrawer?(): void;
};

type IOrderDropDown =
    RouteComponentProps &
    IOrderDropDownProps;

class OrderDropDown extends PureComponent<IOrderDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoOrderPage}>
                <OrderIcon />
                
                <span className="ml-2">
                    Orders
                </span>
            </LAMenuItem>
        );
    };

    private gotoOrderPage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.TOOL_RENTALS.ORDER.INDEX);
    };

}

export default OrderDropDown;
