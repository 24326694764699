import styled from "styled-components";
import { LAIconButton } from "./buttons";
import { CrossIcon } from "./icons";
import { DARK_GREY_COLOR, MAIN_COLOR } from "./theme";

export interface LACrossButtonProps {
    onClick: () => void;
}

export const CrossButtonOnTopRightStyles = styled.div`
    position: fixed;
    z-index: 1100;
    top: 8px;
    right: 16px;
    background-color: ${DARK_GREY_COLOR};
    border-radius: 24px; 
`;

export const LACrossButton = (props: LACrossButtonProps): JSX.Element => {
    return (
        <CrossButtonOnTopRightStyles>
            <LAIconButton label="" icon={<CrossIcon color={MAIN_COLOR} />} onClick={props.onClick} />
        </CrossButtonOnTopRightStyles>
    );
};

export const CrossButtonWithInWindowStyles = styled.div`
    z-index: 1100;
    position: absolute;
    right: 0.5px;
    top: 0.5px;
    background-color: ${DARK_GREY_COLOR};
    border-radius: 24px; 
`;

export const LACrossButtonWithInWindow = (props: LACrossButtonProps): JSX.Element => {
    return (
        <CrossButtonWithInWindowStyles>
            <LAIconButton label="" icon={<CrossIcon color={MAIN_COLOR} />} onClick={props.onClick} />
        </CrossButtonWithInWindowStyles>
    );
};
