import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_MANUFACTURER_REQUEST } from "./updateManufacturerConstants";
import { IUpdateManufacturerLoadAction, IUpdateManufacturerLoadFailedAction, IUpdateManufacturerSuccessAction, updateManufacturerLoadFailedAction, updateManufacturerLoadSuccessAction } from "./updateManufacturerActions";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { PARTS_END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const updateManufacturerEpic: Epic = (
    action$: ActionsObservable<IUpdateManufacturerLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateManufacturerSuccessAction | IUpdateManufacturerLoadFailedAction> =>
    action$.ofType(IUPDATE_MANUFACTURER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<null>>(
                    PARTS_END_POINTS.GET_PARTS.MANUFACTURERS.UPDATE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<null>): IUpdateManufacturerSuccessAction | IUpdateManufacturerLoadFailedAction => {
                            if(response.message === "Success"){
                            return updateManufacturerLoadSuccessAction(action.data.request);
                            }
                            return updateManufacturerLoadFailedAction(response.message);
                        }),
                        catchError((response: PartsResponse<any>) => ActionsObservable.of(updateManufacturerLoadFailedAction(response.message)))
                    )
            )
        );