
export enum IGET_PROJECTS_REQUEST {
    REQUEST = "getProjects/GET_PROJECTS_REQUEST",
    SUCCESS = "getProjects/GET_PROJECTS_SUCCESS",
    FAILED = "getProjects/GET_PROJECTS_FAILED"
};

export interface IProjectRequest {
    token: string;
};

export interface IProject {
    id: number;
    project_Number: string;
    company: string;
    project_Name: string;
    coordinator: string;
    status: string;
    rental_Status: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
};

export const StatusValues: {id: number, name: string}[] = [{id: 1, name: "Ongoing"},{id: 2, name: "Complete"}];