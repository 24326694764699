import { ReactNode, PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ById, ByIdNumber, PartsResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { MEDIA_QUERY_PHONE, RED_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithPadding } from "../../../shared/paper";
import { DismantledGrid } from "./pickupTruckGrid";
import SearchBox from "../../../shared/searchBox";
import { LAButton, LAIconButton } from "../../../shared/buttons";
import { AddIcon, DownloadIcon } from "../../../shared/icons";
import LAPagination from "../../../shared/pagination";
import LALoading from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import { ONE, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import downloadCsv from "download-csv";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IManufacturerListDD } from "../../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDConstants";
import { IManufacturerRequest } from "../../../../redux/parts/getManufacturers/getManufacturerConstants";
import { getManufacturerListDD } from "../../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDAccessor";
import { getManufacturerListDDLoadAction } from "../../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDActions";
import { IDismantledLookups, IDismantledLookupsRequest } from "../../../../redux/parts/getDismantledLookups/getDismantledLookupsConstants";
import { getDismantledLookupsLoadAction } from "../../../../redux/parts/getDismantledLookups/getDismantledLookupsActions";
import { getDismantledLookups } from "../../../../redux/parts/getDismantledLookups/getDismantledLookupsAccessor";
import { convertList } from "../../shop/equipment/equipment";
import LADeleteConfirm from "../../../shared/deleteConfirmationBox";
import { deleteDismantledLoadAction } from "../../../../redux/parts/delete/dismantled/deleteDismantledActions";
import { IDeleteDismantledRequest } from "../../../../redux/parts/delete/dismantled/deleteDismantledConstants";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ROUTE } from "../../../routes";
// import LAFacet from "../facet/facet";
import { IPartPickupTruck, IPartPickupTruckRequest } from "../../../../redux/parts/getPartsDismantledPickupTrucks/getPartsDismantledPickupTruckConstants";
import { IAddPartPickupTruckRequest } from "../../../../redux/parts/add/pickupTruck/addPickupTruckConstants";
import { IUpdatePartPickupTruckRequest } from "../../../../redux/parts/update/pickupTruck/updatePickupTruckConstants";
import { getPartsDismantledPickupTrucks } from "../../../../redux/parts/getPartsDismantledPickupTrucks/getPartsDismantledPickupTruckAccessor";
import { addPartPickupTruck } from "../../../../redux/parts/add/pickupTruck/addPickupTruckAccessor";
import { updatePartPickupTruck } from "../../../../redux/parts/update/pickupTruck/updatePickupTruckAccessor";
import { getPartsDismantledPickupTrucksLoadAction } from "../../../../redux/parts/getPartsDismantledPickupTrucks/getPartsDismantledPickupTruckActions";
import { addPartPickupTruckLoadAction } from "../../../../redux/parts/add/pickupTruck/addPickupTruckActions";
import { updatePartPickupTruckLoadAction } from "../../../../redux/parts/update/pickupTruck/updatePickupTruckActions";

interface IPartPickupTruckStoreProps {
    token: Server<PartsResponse<IToken>>;
    PartPickupTruckList: Server<PartsResponse<IPartPickupTruck[]>>;
    UpdatePartPickupTruck: Server<string>;
    AddPartPickupTruck: Server<string>;
    ManufacturerListDD: Server<PartsResponse<IManufacturerListDD[]>>;
    getDropDownData: Server<PartsResponse<IDismantledLookups>>;
    validateShopGroup: Server<PartsResponse<IValidateShopGroup>>;
    // equipmentFacets: Server<PartsResponse<IPickupTruckFacets[]>>;
};

interface IPartPickupTruckDispatchProps {
    RequestDeletePart: (request: IDeleteDismantledRequest) => unknown;
    RequestPickupTruckList: (request: IPartPickupTruckRequest) => unknown;
    RequestAddPickupTruck: (request: IAddPartPickupTruckRequest) => unknown;
    RequestUpdatePickupTruck: (request: IUpdatePartPickupTruckRequest) => unknown;
    RequestManufacturerListDD: (request: IManufacturerRequest) => unknown;
    getDropDownDataRequest: (request: IDismantledLookupsRequest) => unknown;
    // getEquipmentFacetsRequest: (request: IPickupTruckFacetsRequest) => unknown;
};

interface IPartPickupTruckOwnProps {

};

interface IPartPickupTruckState {
    total: number;
    open: boolean;
    newAdd: boolean;
    deleteId: number;
    searchText: string;
    currentPage: number;
    deleteConfirm: boolean;
    editId: number | undefined;
    error: ById<IFieldErrorKeyValue>;
    trucks: IPartPickupTruck[];
    // selectedFacet: IPickupTruckFacets[];
    avaCompError: ByIdNumber<IFieldErrorKeyValue>;
};

const PartStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .total-record {
        font-weight: bold;
        color: ${RED_COLOR};
    };
    
    .action-btn {
        position: absolute;
        right: 0;
        top: 0;
    };
`;

type IPartPickupTruckProps = RouteComponentProps
    & IPartPickupTruckStoreProps
    & IPartPickupTruckDispatchProps
    & IPartPickupTruckOwnProps;

class PickupTruck extends PureComponent<IPartPickupTruckProps, IPartPickupTruckState> {

    public constructor(props: IPartPickupTruckProps) {
        super(props);
        this.state = {
            newAdd: false,
            open: false,
            editId: undefined,
            trucks: [],
            searchText: "",
            currentPage: 1,
            error: {},
            avaCompError: {},
            total: 0,
            deleteConfirm: false,
            deleteId: 0,
            // selectedFacet: []
        };
        this.keyWordSearch = this.debounce(this.keyWordSearch, 500);
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IPartPickupTruckProps, prevState: IPartPickupTruckState): Promise<void> {
        if (this.props.PartPickupTruckList !== prevProps.PartPickupTruckList) {
            this.callReduxForData();
        }

    };


    public render(): ReactNode {

        const { ManufacturerListDD, PartPickupTruckList, getDropDownData, /*equipmentFacets*/ } = this.props;
        const { trucks, editId, searchText, currentPage, error, total, open, newAdd, avaCompError, deleteConfirm, /*selectedFacet*/ } = this.state;
        const dropDown = hasPayload(getDropDownData) ? getDropDownData.payload.response : { makes: [], equipmentTypes: [] };
        const equipTypes = hasPayload(getDropDownData) ? convertList(dropDown.equipmentTypes ?? []) : [];
        const disabled = (hasPayload(this.props.validateShopGroup) && this.props.validateShopGroup.payload.response.partsAdminAccess === "R") ? true : undefined;

        return (
            <PartStyles>
                <LAGrid spacing={2}>
                    {(PartPickupTruckList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                        <LALoading message="Loading trucks..." />
                    </LAGridItem>}

                    {(PartPickupTruckList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                        <LAErrorBox text="Failed to Load Trucks" />
                    </LAGridItem>}

                    {(PartPickupTruckList.kind === STATUS_ENUM.SUCCEEDED) && <>
                        <LAGridItem xs={12} sm={12} md={12} lg={2} className="text-center">
                            <LAPaperWithPadding>
                                <h2>DISMANTLED PICKUP TRUCKS</h2>

                                <span className="total-record">Total Count: {PartPickupTruckList.payload.totalRecords ?? 0}</span>
                                <SearchBox
                                    fullWidth={true}
                                    text={searchText}
                                    placeHolder="Search"
                                    onSubmit={() => this.sendRequest()}
                                    onChange={this.handleSearchChange}
                                    searchStatus={this.props.PartPickupTruckList.kind}
                                />
                                <br></br>
                                <LAGrid>
                                    <LAGridItem xs={12} md={6} lg={6} className="text-center">
                                        <LAButton 
                                            label="Search"
                                            onClick={this.keyWordSearch}
                                        />
                                    </LAGridItem>
                                    <LAGridItem xs={12} md={6} lg={6} className="text-center">
                                        <LAButton 
                                            label="Reset"
                                            onClick={this.handleResetClick}
                                        />
                                    </LAGridItem>
                                </LAGrid>

                                {/* <LAFacet
                                    {...this.state}
                                    preSelected={selectedFacet}
                                    onFacetUpdateClick={this.onFacetUpdateClick}
                                    numberOfItems={PartPickupTruckList.payload.totalRecords ?? 0}
                                    facets={hasPayload(equipmentFacets) ? equipmentFacets.payload.response : []}
                                /> */}

                            </LAPaperWithPadding>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={12} lg={10}>
                            <LAPaperWithPadding>
                                <LAGrid>

                                    <div className="action-btn">
                                        <LAIconButton
                                            label="Add New"
                                            icon={<AddIcon />}
                                            onClick={this.handleAdd}
                                            disabled={disabled}
                                        />

                                        <LAIconButton
                                            label="Download CSV"
                                            icon={<DownloadIcon />}
                                            onClick={this.getDataForExport}
                                        />
                                    </div>

                                    <LAGridItem xs={12}>
                                        <LAPagination
                                            rowsPerPage={20}
                                            className="mt-5"
                                            currentPage={currentPage}
                                            numberOfItems={total}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        {trucks && trucks.length > 0 ?
                                            <DismantledGrid
                                                {...this.state}
                                                data={trucks}
                                                editId={editId}
                                                disabled={disabled}
                                                error={error}
                                                open={open}
                                                newAdd={newAdd}
                                                onDelete={this.onDelete}
                                                onRotate={this.onRotate}
                                                onEdit={this.handleEdit}
                                                onChange={this.onChange}
                                                onSave={this.handleSave}
                                                equipmentList={equipTypes}
                                                avaCompError={avaCompError}
                                                removeImage={this.removeImage}
                                                onImageUpload={this.onImageUpload}
                                                onPermanentDelete={this.onPermanentDelete}
                                                manufacturersList={hasPayload(ManufacturerListDD) ? ManufacturerListDD.payload.response : []}
                                            /> : <LAErrorBox text="No Record Found" />}
                                    </LAGridItem>
                                    <LAGridItem xs={12}>
                                        <LAPagination
                                            rowsPerPage={20}
                                            className="mt-5"
                                            currentPage={currentPage}
                                            numberOfItems={total}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAPaperWithPadding>
                        </LAGridItem>

                        <Prompt
                            when={newAdd || (editId !== undefined)}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                    </>}
                </LAGrid>

                <LADeleteConfirm
                    cancelClick={this.onDeleteCancel}
                    confirmClick={this.onDeleteConfirm}
                    open={deleteConfirm}
                    text="Are you sure you want to delete this ?"
                />

                <RequestStatus requestStatus={this.props.AddPartPickupTruck.kind} failedMessage="Failed to Add Pickup Truck" successMessage="Pickup Truck Successfully Added" />
                <RequestStatus requestStatus={this.props.UpdatePartPickupTruck.kind} failedMessage="Failed to Update Pickup Truck" successMessage="Pickup Truck Successfully Updated" />
            </PartStyles>
        );
    }

    // private onFacetUpdateClick = async (values: IPickupTruckFacets[]): Promise<void> => {
    //     if (hasPayload(this.props.token)) {
    //         await this.setState({ selectedFacet: values });

    //         this.props.RequestPickupTruckList({
    //             PageSize: 20,
    //             Keywords: this.state.searchText,
    //             PageNumber: this.state.currentPage,
    //             token: this.props.token.payload.response.token,
    //             equipments: values.map(({ equipment }) => equipment)
    //         });
    //     }
    // };

    private onPermanentDelete = (id: number): void => {
        this.setState({ deleteId: id, deleteConfirm: true });
    };

    private onDeleteCancel = (): void => {
        this.setState({ deleteId: 0, deleteConfirm: false });
    };

    private onDeleteConfirm = (): void => {
        if (hasPayload(this.props.token)) {
            this.props.RequestDeletePart({
                token: this.props.token.payload.response.token,
                request: {
                    ItemID: this.state.deleteId
                }
            });

            this.onDeleteCancel();
        };
    };

    private onDelete = (index: number) => {
        const iS = [...this.state.trucks];
        const ind = this.state.trucks.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };
        iS[ind] = val;

        const avaCompError = { ...this.state.avaCompError };

        if (avaCompError[index])
            delete avaCompError[index];

        this.setState({ trucks: iS, avaCompError });
    };

    private onRotate = async (imgIndex: number, reverse: boolean): Promise<void> => {
        if (reverse)
            this.rotateImage(270, imgIndex);

        if (!reverse)
            this.rotateImage(90, imgIndex);
    };

    private rotateImage = (degree: number, imgIndex: number): void => {
        let img = new Image();

        const iS = [...this.state.trucks];
        const ind = this.state.trucks.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };

        img.onload = async (): Promise<void> => {
            let canvas: any = document.createElement("canvas");
            let cContext = canvas.getContext("2d");
            let cw = img.width, ch = img.height, cx = 0, cy = 0;

            switch (degree) {
                case 90:
                    cw = img.height;
                    ch = img.width;
                    cy = img.height * (-1);
                    break;
                case 180:
                    cx = img.width * (-1);
                    cy = img.height * (-1);
                    break;
                case 270:
                    cw = img.height;
                    ch = img.width;
                    cx = img.width * (-1);
                    break;
            };

            canvas.setAttribute("width", cw);
            canvas.setAttribute("height", ch);

            cContext.rotate(degree * Math.PI / 180);
            cContext.drawImage(img, cx, cy);

            if (imgIndex === 1) {
                val.photos_1 = canvas.toDataURL();
            } else if (imgIndex === 2) {
                val.photos_2 = canvas.toDataURL();
            } else if (imgIndex === 3) {
                val.photos_3 = canvas.toDataURL();
            };

            iS[ind] = val;
            this.setState({ trucks: iS });
        };

        if (imgIndex === 1) {
            img.src = val.photos_1;
        } else if (imgIndex === 2) {
            img.src = val.photos_2;
        } else if (imgIndex === 3) {
            img.src = val.photos_3;
        };
    };

    private removeImage = (index: number): void => {
        const iS = [...this.state.trucks];
        const ind = this.state.trucks.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };

        if (index === 1)
            val.photos_1 = "";

        if (index === 2)
            val.photos_2 = "";

        if (index === 3)
            val.photos_3 = "";

        iS[ind] = val;
        this.setState({ trucks: iS });
    };

    private onImageUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {

        const iS = [...this.state.trucks];
        const ind = this.state.trucks.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };
        new Promise<void>((resolve, reject): void => {
            if (event.target.files !== null) {
                Object.values(event.target.files).forEach(async (x, index, array) => {

                    // const converted = await webPImageConverter(x, false);
                    // eslint-disable-next-line no-undef
                    const reader = new FileReader();

                    reader.onloadend = (e): void => {

                        if (reader.result) {
                            if ((val.photos_1 === null) || (val.photos_1.length === ZEROTH)) {
                                val.photos_1 = reader.result.toString();
                            } else if ((val.photos_2 === null) || (val.photos_2.length === ZEROTH)) {
                                val.photos_2 = reader.result.toString();
                            } else if ((val.photos_3 === null) || (val.photos_3.length === ZEROTH)) {
                                val.photos_3 = reader.result.toString();
                            }

                            iS[ind] = val;
                            this.setState({ trucks: iS });
                        }

                        if (index === array.length - ONE)
                            resolve();
                    };
                    reader.readAsDataURL(x);
                });
            }
        });
    };

    private getDataForExport = async (): Promise<void> => {

        const val: IPartPickupTruck[] = this.state.trucks;
        const dataToExport: {
            make: string, model: string, serial_No: string,
            unit_No: string, hours: string, location: string, notes: string
        }[] = [];

        await val.forEach((x: IPartPickupTruck) => {
            dataToExport.push({
                make: x.make,
                model: x.model,
                hours: x.hours,
                notes: x.notes,
                unit_No: x.unit_No,
                location: x.location,
                serial_No: x.serial_No,
            });
        });

        downloadCsv(dataToExport,
            {
                make: "Make", model: "Model", hours: "Hours",
                serial_No: "Serial #", unit_No: "Unit #", location: "Location",
                notes: "Notes"
            }, "pickup_trucks.csv");

    };

    private debounce = (fn: any, delay: any): any => {
        let timer: any = null;
        return (...args: any): any => {
            const context = this;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    };

    private keyWordSearch = async (): Promise<void> => {
        this.sendRequest();
    };

    private onChange = (name: string, value: string) => {

        const iS = [...this.state.trucks];
        const iSError = this.state.error;
        const ind = this.state.trucks.findIndex(x => x.id === this.state.editId);
        const val = { ...iS[ind] };

        switch (name) {
            case "serial_No":
                val.serial_No = value;
                break;
            case "unit_No":
                val.unit_No = value;
                break;
            case "make":
                val.make = value;
                break;
            case "model":
                val.model = value;
                break;
            case "hours":
                val.hours = value;
                break;
            case "location":
                val.location = value;
                break;
            case "notes":
                val.notes = value;
                break;
            case "status":
                val.status = value;
                break;
        };

        if (value !== undefined) {
            if (value.length > 0) {
                if (iSError[name])
                    delete iSError[name];
            } else {
                if (name === "make")
                    iSError[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };
        };

        iS[ind] = val;
        this.setState({ trucks: iS, error: iSError });
    };

    private handlePageChange = (currentPage?: number, rowsPerPage?: number): void => {
        this.setState({ currentPage: currentPage ?? 1 });
        this.sendRequest(currentPage);
    };

    private handleAdd = (): void => {
        const iS = [...this.state.trucks];
        const time = new Date().toISOString();

        let max = 1;

        if (iS.length > 0) {
            max = iS[0].id;
            for (let i = 1; i < iS.length; i++) {
                let value = iS[i].id;
                max = (value > max) ? value : max;
            };
        }

        iS.unshift({
            id: max + 1, make: "", serial_No: "", unit_No: "", model: "", hours: "", location: "", photos_1: "", notes: "",
            photos_2: "", photos_3: "", modified: time, modified_By: userName, created: time, created_By: userName, status: ""
        });

        let error: ById<IFieldErrorKeyValue> = {};
        error["make"] = { key: "make", message: FIELD_VALIDATOR_ERRORS.REQUIRED };


        this.setState({ trucks: iS, editId: max + 1, newAdd: true, error, open: true });
    };

    private handleSearchChange = (searchText: string): void => {
        this.setState({ searchText, currentPage: 1, editId: undefined, newAdd: false });
        // this.keyWordSearch();
    };

    private handleResetClick = (): void => {
        this.setState({ searchText: "", currentPage: 1, editId: undefined, newAdd: false });
        this.keyWordSearch();
    };

    private handleEdit = (editId: number | undefined): void => {
        if (editId === undefined && this.state.newAdd === true) {
            const iS = [...this.state.trucks];
            const ind = iS.findIndex(x => x.id === this.state.editId);
            iS.splice(ind, 1);

            this.setState({ editId, trucks: iS, error: {}, open: false });
        } else {
            if (editId === undefined) {
                if (hasPayload(this.props.PartPickupTruckList)) {
                    this.setState({ editId, error: {}, trucks: this.props.PartPickupTruckList.payload.response, open: false });
                }
            } else if (editId !== undefined) {
                const iS = this.state.trucks;
                const ind = iS.findIndex(x => x.id === editId);
                let error: ById<IFieldErrorKeyValue> = {};
                if (iS[ind].make === undefined || iS[ind].make.length === ZEROTH)
                    error["make"] = { key: "make", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

                this.setState({ editId, error, open: true });
            }
        };
    };

    private callReduxForData = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.partsaccess === true) {

                if (isNotLoaded(this.props.PartPickupTruckList)) {
                    this.sendRequest();

                };

                if (isNotLoaded(this.props.ManufacturerListDD)) {
                    if (hasPayload(this.props.token))
                        this.props.RequestManufacturerListDD({
                            request: {},
                            Keywords: "",
                            PageSize: 0,
                            PageNumber: 0,
                            token: this.props.token.payload.response.token
                        });
                };

                // if (isNotLoaded(this.props.equipmentFacets)) {
                //     if (hasPayload(this.props.token))
                //         this.props.getEquipmentFacetsRequest({
                //             token: this.props.token.payload.response.token
                //         });
                // };

                if (hasPayload(this.props.PartPickupTruckList)) {
                    let data = this.props.PartPickupTruckList.payload;
                    this.setState({ trucks: data.response, total: data.totalRecords ?? 0 });
                };

                this.props.getDropDownDataRequest({});
            } else {
                this.props.history.push(ROUTE.PARTS.UNAUTHORIZED);
            }
        };
    };

    private sendRequest = (currentPage?: number): void => {
        if (hasPayload(this.props.token)) {
            this.props.RequestPickupTruckList({
                // equipments: this.state.selectedFacet.map(({ equipment }) => equipment),
                Keywords: this.state.searchText,
                PageSize: 20,
                PageNumber: currentPage ?? this.state.currentPage,
                token: this.props.token.payload.response.token
            });
        }

    };

    private handleSave = (): void => {
        if (this.state.newAdd === false) {
            this.updateRequest();
        } else {
            this.addRequest();
        };
    };

    private updateRequest = (): void => {
        if (hasPayload(this.props.token)) {
            const iS = [...this.state.trucks];
            const ind = iS.findIndex(x => x.id === this.state.editId);

            this.props.RequestUpdatePickupTruck({
                request: {
                    ...iS[ind],
                    modified_By: userName
                },
                token: this.props.token.payload.response.token
            });

            this.setState({ editId: undefined });
        }
    };

    private addRequest = (): void => {
        if (hasPayload(this.props.token)) {
            const iS = [...this.state.trucks];
            const ind = iS.findIndex(x => x.id === this.state.editId);
            iS[ind].id = 0;
            this.props.RequestAddPickupTruck({
                request: {
                    ...iS[ind],
                    modified_By: userName
                },
                Keywords: "",
                PageSize: 20,
                PageNumber: 1,
                token: this.props.token.payload.response.token
            });
            iS.splice(ind, 1);
            this.setState({ editId: undefined, newAdd: false, currentPage: 1, searchText: "", trucks: iS });
        }
    };
}

const mapStateToProps = (state: IStore): IPartPickupTruckStoreProps => ({
    token: getToken(state),
    PartPickupTruckList: getPartsDismantledPickupTrucks(state),
    AddPartPickupTruck: addPartPickupTruck(state),
    UpdatePartPickupTruck: updatePartPickupTruck(state),
    ManufacturerListDD: getManufacturerListDD(state),
    getDropDownData: getDismantledLookups(state),
    // equipmentFacets: getPickupTruckFacets(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IPartPickupTruckDispatchProps => ({
    RequestDeletePart: (request: IDeleteDismantledRequest): unknown => dispatch(deleteDismantledLoadAction(request)),
    RequestPickupTruckList: (request: IPartPickupTruckRequest): unknown => dispatch(getPartsDismantledPickupTrucksLoadAction(request)),
    RequestAddPickupTruck: (request: IAddPartPickupTruckRequest): unknown => dispatch(addPartPickupTruckLoadAction(request)),
    RequestUpdatePickupTruck: (request: IUpdatePartPickupTruckRequest): unknown => dispatch(updatePartPickupTruckLoadAction(request)),
    RequestManufacturerListDD: (request: IManufacturerRequest): unknown => dispatch(getManufacturerListDDLoadAction(request)),
    getDropDownDataRequest: (request: IDismantledLookupsRequest): unknown => dispatch(getDismantledLookupsLoadAction(request)),
    // getEquipmentFacetsRequest: (request: IPickupTruckFacetsRequest): unknown => dispatch(getPickupTruckFacetsLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupTruck);