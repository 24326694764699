import { IManufacturer } from "../../getManufacturers/getManufacturerConstants";
import { IUPDATE_MANUFACTURER_REQUEST, IUpdateManufacturerRequest } from "./updateManufacturerConstants";


export interface IUpdateManufacturerLoadAction {
    type: IUPDATE_MANUFACTURER_REQUEST.REQUEST;
    data: IUpdateManufacturerRequest
}
export const updateManufacturerLoadAction = (data: IUpdateManufacturerRequest): IUpdateManufacturerLoadAction => ({
    type: IUPDATE_MANUFACTURER_REQUEST.REQUEST,
    data
});
export interface IUpdateManufacturerSuccessAction {
    type: IUPDATE_MANUFACTURER_REQUEST.SUCCESS;
    data: IManufacturer;
}
export const updateManufacturerLoadSuccessAction = (data: IManufacturer): IUpdateManufacturerSuccessAction => ({
    type: IUPDATE_MANUFACTURER_REQUEST.SUCCESS,
    data
});
export interface IUpdateManufacturerLoadFailedAction {
    type: IUPDATE_MANUFACTURER_REQUEST.FAILED;
    message: string;
}
export const updateManufacturerLoadFailedAction = (message: string): IUpdateManufacturerLoadFailedAction => ({
    type: IUPDATE_MANUFACTURER_REQUEST.FAILED,
    message
});
