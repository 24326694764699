import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_SHOP_MODEL_REQUEST, IUpdateShopModelRequest } from "./updateShopModelsConstants";


export interface IUpdateShopModelLoadAction {
    type: IUPDATE_SHOP_MODEL_REQUEST.REQUEST;
    data: IUpdateShopModelRequest
}
export const updateShopModelLoadAction = (data: IUpdateShopModelRequest): IUpdateShopModelLoadAction => ({
    type: IUPDATE_SHOP_MODEL_REQUEST.REQUEST,
    data
});
export interface IUpdateShopModelSuccessAction {
    type: IUPDATE_SHOP_MODEL_REQUEST.SUCCESS;
    message: PartsResponse<string>;
}
export const updateShopModelLoadSuccessAction = (message: PartsResponse<string>): IUpdateShopModelSuccessAction => ({
    type: IUPDATE_SHOP_MODEL_REQUEST.SUCCESS,
    message
});
export interface IUpdateShopModelLoadFailedAction {
    type: IUPDATE_SHOP_MODEL_REQUEST.FAILED;
    message: string;
}
export const updateShopModelLoadFailedAction = (message: string): IUpdateShopModelLoadFailedAction => ({
    type: IUPDATE_SHOP_MODEL_REQUEST.FAILED,
    message
});
