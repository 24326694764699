import React from "react";
import { ById, HasClass } from "../../../shared/publicInterfaces";
import { LAIconButton } from "../../../shared/buttons";
import { CancelIcon, SaveIcon } from "../../../shared/icons";
import { LAPaperWithLessPadding } from "../../../shared/paper";
import LATextField from "../../../shared/textField";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IUnit } from "../../../../redux/toolRentals/getUnits/getUnitsConstants";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { GREEN_COLOR, LIGHT_GREY_COLOR, RED_COLOR, SECONDARY_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAAutoComplete from "../../../shared/autoComplete";
import { IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import { undefinedFunction } from "../../../shared/constExports";
import { IRate } from "../../../../redux/toolRentals/getRates/getRatesConstants";
import styled from "styled-components";

interface IUnitGridProps extends HasClass {
    data: IUnit;
    onSave: () => void;
    ddData: IRate[];
    error: ById<IFieldErrorKeyValue>;
    onEdit: (id: number | undefined) => void;
    onChange: (name: string, value: string) => void;
};

const UnitGridStyles = styled.div`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };
`;


export const UnitGrid: React.FC<IUnitGridProps> = React.memo((props: IUnitGridProps) => {
    const onCancel = (): void => props.onEdit(undefined);
    return <UnitGridStyles>
        <TableContainer component={LAPaperWithLessPadding}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow className="header-row">

                        <TableCell className="title" align="center">UNIT NUMBER</TableCell>
                        <TableCell className="title" align="center">DESCRIPTION</TableCell>
                        <TableCell className="title" align="center">CLASS</TableCell>
                        <TableCell className="title" align="center">TYPE</TableCell>
                        <TableCell className="title" align="center">DAILY</TableCell>
                        <TableCell className="title" align="center">WEEKLY</TableCell>
                        <TableCell className="title" align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <EditRow {...props} ddData={props.ddData} value={props.data} error={props.error}
                        onCancel={onCancel} onSave={props.onSave} onChange={props.onChange} />
                </TableBody>
            </Table>
        </TableContainer>
    </UnitGridStyles>;
}
);


interface IEditRowProps {
    value: IUnit;
    ddData: IRate[];
    onSave: () => void;
    error: ById<IFieldErrorKeyValue>;
    onCancel: (id: undefined) => void;
    onChange: (name: string, value: string) => void;
};

const EditRow: React.FC<IEditRowProps> = React.memo((props: IEditRowProps) => {

    const onCancel = () => props.onCancel(undefined);
    const err = Object.values(props.error).length > 0 ? true : undefined;
    const onClassChange = (e: unknown, value: IRate) => props.onChange("class", (value !== null) ? value.class : "");
    const onTypeChange = (e: unknown, value: IRate) => props.onChange("type", (value !== null) ? value.type : "");

    return <TableRow key={props.value.id}>
        <TableCell component="th" scope="row" align="center">
            <LATextField errorText={props.error["unit_No"] ? props.error["unit_No"].message : undefined}
                name="unit_No" label="Unit Number" value={props.value.unit_No} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LATextField errorText={props.error["description"] ? props.error["description"].message : undefined}
                name="description" label="Description" fullWidth={true} value={props.value.description} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center" width={250}>
            <LAAutoComplete
                dropDownPlaceHolder="Class"
                multiple={false}
                getOptionLabel="class"
                filterSelectedOptions={true}
                autoHighlight={true}
                onChange={onClassChange}
                selectionRemove={undefinedFunction}
                option={filterDropDown(props.ddData, props.value, true)}
                errorText={props.error["class"] ? props.error["class"].message : undefined}
                value={props.ddData.find(x => x.class === props.value.class)}
                defaultValue={props.ddData.find(x => x.class === props.value.class)}
            />
        </TableCell>

        <TableCell align="center" width={400}>
            <LAAutoComplete
                dropDownPlaceHolder="Type"
                multiple={false}
                getOptionLabel="type"
                filterSelectedOptions={true}
                autoHighlight={true}
                onChange={onTypeChange}
                selectionRemove={undefinedFunction}
                option={filterDropDown(props.ddData, props.value, false)}
                errorText={props.error["type"] ? props.error["type"].message : undefined}
                value={props.ddData.find(x => x.type === props.value.type)}
                defaultValue={props.ddData.find(x => x.type === props.value.type)}
            />
        </TableCell>


        <TableCell align="center">
            <LATextField errorText={props.error["size"] ? props.error["size"].message : undefined}
                name="size" label="Size" value={props.value.size} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LATextField errorText={props.error["quantity"] ? props.error["quantity"].message : undefined}
                name="quantity" label="Quantity" value={props.value.quantity} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="right"><LAGrid spacing={1}>
            <LAGridItem xs={6}>
                <LAIconButton disabled={err} icon={<SaveIcon color={err ? LIGHT_GREY_COLOR : GREEN_COLOR} />} label="Save" onClick={props.onSave} />
            </LAGridItem>

            <LAGridItem xs={6}>
                <LAIconButton icon={<CancelIcon color={RED_COLOR} />} label="Cancel" onClick={onCancel} />
            </LAGridItem>
        </LAGrid></TableCell>
    </TableRow>;
});


const filterDropDown = (data: IRate[], selected: IUnit, forClass: boolean): IRate[] => {
    let response: IRate[] = [];

    if (forClass === true) {
        if (selected.type !== "") {
            data.forEach(x => {
                if (x.type === selected.type)
                    response.push(x);
            });
        } else {
            response = data.filter((v, i, a) => a.findIndex(t => (t.class === v.class)) === i);
        };
    } else {
        if (selected.class !== "") {
            data.forEach(x => {
                if (x.class === selected.class)
                    response.push(x);
            });
        } else {
            response = data;
        };
    };

    return response;
};