import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IAddOrderSuccessAction } from "../add/order/addOrderActions";
import { IADD_ORDER_REQUEST } from "../add/order/addOrderConstants";
import { IFlushDataSuccessAction } from "../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../clearRedux/clearReduxConstants";
import { IUpdateOrderSuccessAction } from "../update/order/updateOrderActions";
import { IUPDATE_ORDER_REQUEST } from "../update/order/updateOrderConstants";
import { IGetOrdersLoadAction, IGetOrdersLoadFailedAction, IGetOrdersSuccessAction } from "./getOrdersActions";
import { IGET_ORDERS_REQUEST, IOrder } from "./getOrdersConstants";

type Actions =
    | IGetOrdersLoadAction
    | IGetOrdersSuccessAction
    | IGetOrdersLoadFailedAction
    | IAddOrderSuccessAction
    | IUpdateOrderSuccessAction
    | IFlushDataSuccessAction;

export const GetOrdersReducer = (state: Server<ToolRentalsResponse<ByIdNumber<IOrder>>> = notLoaded, action: Actions): Server<ToolRentalsResponse<ByIdNumber<IOrder>>> => {
    switch (action.type) {
        case IGET_ORDERS_REQUEST.REQUEST:
            return loading;

        case IGET_ORDERS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ORDERS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_ORDER_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ORDER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};