import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_PART_REQUEST } from "./addPartConstants";
import { IAddPartLoadAction, IAddPartLoadFailedAction, IAddPartSuccessAction } from "./addPartActions";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IAddPartLoadAction
    | IAddPartSuccessAction
    | IAddPartLoadFailedAction
    | IFlushDataSuccessAction;

export const AddPartReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_PART_REQUEST.REQUEST:
            return loading;

        case IADD_PART_REQUEST.SUCCESS:
            return succeeded("Success");

        case IADD_PART_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};