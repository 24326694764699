import jwt_decode from "jwt-decode";
import { RouteComponentProps } from "react-router";

export const calculateAge = (dateString: string): number => {
    var birthday = +new Date(dateString);
    return ~~((Date.now() - birthday) / (31557600000));
  };


export const ZEROTH = 0;
export const ONE = 0;

export const ActiveValues: {id: number, name: string}[] = [{id: 1, name: "Yes"},{id: 2, name: "No"}];
export const ConditionValues: {id: number, name: string}[] = [{id: 1, name: "New"},{id: 2, name: "Used"}];

export const undefinedFunction = (): void => undefined;


// Javascript Month Index starts from 0, so it return wrong month number so that's why we need to declare these below array values
export const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
];

export const monthInNumber = ["01","02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const getDate = (showTime: boolean, value?: string): string => {
   if(value !== "" && value){

    const date = new Date(value);
    const dateFormat = `${monthInNumber[date.getMonth()]}/${date.getDate()}/${date.getFullYear()}`;

    return dateFormat;
   }
   return "";
};


const getToken = localStorage.getItem("adal.idtoken");
export const token: any = (getToken !== null && getToken.length > 0) ? jwt_decode(getToken) : { unique_name: "surewaygroup\\narendran.kumar" };
export const userName = token.unique_name.substr(token.unique_name.lastIndexOf("\\") + 1);

export const webPImageConverter = async (file: any, fromBase64: boolean): Promise<any> => {

  return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const image = new Image();
      image.onload = (): any => {

          const canvas = document.createElement("canvas");
          canvas.width = image.naturalWidth;
          canvas.height = image.naturalHeight;
          // eslint-disable-next-line no-unused-expressions
          canvas.getContext("2d")?.drawImage(image, ZEROTH, ZEROTH);
          canvas.toBlob((blob: Blob | null) => {
              let myImage: Blob | null = null;
              // Now we have a `blob` containing webp data

              // Use the file rename trick to turn it back into a file
              if(blob !== null)
                  // eslint-disable-next-line no-undef
                  myImage = new File([blob], "my-new-name.webp", { type: blob.type });

              resolve(myImage);

          }, "image/webp");

      };

      image.src = fromBase64 === false ? URL.createObjectURL(file) : file;
  });
};

export const getBase64FromImage = async (file: any): Promise<string> => {

  return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const reader = new FileReader();

      reader.onloadend = async (e): Promise<void> => {
          if (reader.result !== null) {
              resolve(reader.result.toString());
          };
      };
      reader.readAsDataURL(file);
  });
};

export interface IQueryString {
    id?: string;
    keyword?: string;
  };
  
  interface ICallRouteWithQueryStringProps {
    route: RouteComponentProps;
    pathName: string;
    search: IQueryString;
  };
  
  export const callRouteWithQueryString = (props: ICallRouteWithQueryStringProps): void => {
    let search = {};
    if (props.search.id)
      search = { ...search, id: props.search.id };
    if (props.search.keyword)
      search = { ...search, keyword: props.search.keyword };
  
    props.route.history.push({ pathname: props.pathName, search: "?" + new URLSearchParams(search).toString() });
  };