import { ROUTE } from "./routes";
import { connect } from "react-redux";
import Error404Page from "./error404Page";
import { Component, ReactNode } from "react";
import Part from "./component/parts/part/part";
import { userName } from "./shared/constExports";
import PartsHome from "./component/home/partsHome";
import PartsReport from "./component/parts/report";
import { IStore,IDispatch } from "../redux/reducers";
import { LACenteredLoading } from "./shared/loading";
import { PartsResponse } from "./shared/publicInterfaces";
import { getToken } from "../redux/getToken/getTokenAccessor";
import Dismantled from "./component/parts/dismantled/dismantled";
import HeavyTruck from "./component/parts/heavyTruck/heavyTruck";
import { RouteComponentProps, Route, Switch } from "react-router";
import PickupTruck from "./component/parts/pickupTruck/pickupTruck";
import Manufacturer from "./component/parts/manufacturer/manufacturer";
import { getTokenLoadAction } from "../redux/getToken/getTokenActions";
import { IToken, ITokenRequest } from "../redux/getToken/getTokenConstants";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../redux/server";
import { PartsUnauthorizedPage } from "./component/parts/partsUnauthorizedPage";
import { validateShopGroup } from "../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup } from "../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroupLoadAction } from "../redux/toolRentals/validateShopGroup/validateShopGroupActions";

interface IPartsSecuredRoutesStoreProps {
    token: Server<PartsResponse<IToken>>;
    validateShopGroup: Server<PartsResponse<IValidateShopGroup>>;
};

interface IPartsSecuredRoutesDispatchProps {
    RequestToken: (request: ITokenRequest) => unknown;
    RequestValidateShopGroup: (request: ITokenRequest) => unknown;
};

interface IPartsSecuredRoutesState {
    showHeader: boolean;
    loading: boolean;
};

type IPartsSecuredRoutesProps =
    RouteComponentProps &
    IPartsSecuredRoutesDispatchProps &
    IPartsSecuredRoutesStoreProps;

class PartsSecuredRoutes extends Component<IPartsSecuredRoutesProps, IPartsSecuredRoutesState> {
    public constructor(props: IPartsSecuredRoutesProps) {
        super(props);
        this.state = {
            showHeader: true,
            loading: true
        };
    }

    public componentDidMount(): void {
        this.authorizationCheck();
    }

    public componentDidUpdate(prevProps: IPartsSecuredRoutesProps): void {
        if (this.props.token !== prevProps.token || this.props.validateShopGroup !== prevProps.validateShopGroup) {
            this.authorizationCheck();
        }
    }

    public render(): ReactNode {
        return (
            this.state.loading ? <LACenteredLoading message="Loading..." /> :
                <>
                    <Switch>
                        <Route exact={true} path={ROUTE.PARTS.INDEX} component={PartsHome} />
                        <Route exact={true} path={ROUTE.PARTS.MANUFACTURER.INDEX} component={Manufacturer} />
                        <Route exact={true} path={ROUTE.PARTS.PART.INDEX} component={Part} />
                        <Route path={ROUTE.PARTS.PART.REPORT} component={PartsReport} />
                        <Route exact={true} path={ROUTE.PARTS.DISMANTLED.INDEX} component={Dismantled} />        
                        <Route exact={true} path={ROUTE.PARTS.PICKUP_TRUCK.INDEX} component={PickupTruck} />
                        <Route exact={true} path={ROUTE.PARTS.HEAVY_TRUCK.INDEX} component={HeavyTruck} />          
                        <Route path={ROUTE.PARTS.UNAUTHORIZED} component={PartsUnauthorizedPage} />
                        <Route render={(): ReactNode => <Error404Page handleShowHeader={this.handleShowHeader} />} />
                    </Switch>
                </>
        );
    }

    private handleShowHeader = (showHeader: boolean): void => {
        this.setState({ showHeader });
    };

    private authorizationCheck = (): void => {
        if (hasPayload(this.props.token)) {
            switch (this.props.validateShopGroup.kind) {
                case STATUS_ENUM.SUCCEEDED:
                    if (this.props.validateShopGroup.payload.message !== "Success" && this.props.validateShopGroup.payload.response === null) {
                        if (this.state.loading === true) {
                            this.setState({ loading: false });
                        }
                        this.props.history.push(ROUTE.PARTS.UNAUTHORIZED);
                    } else {
                        if (this.state.loading) {
                            this.setState({ loading: false });
                            if (this.props.validateShopGroup.payload.response.partsaccess === false) {
                                this.props.history.push(ROUTE.PARTS.UNAUTHORIZED);
                            }
                        };
                    }
                    break;

                case STATUS_ENUM.NOT_LOADED:
                    this.requestValidateShopGroup();
                    break;

                case STATUS_ENUM.FAILED:
                    if (this.state.loading === true) {
                        this.setState({ loading: false });
                    }
                    this.props.history.push(ROUTE.PARTS.UNAUTHORIZED);
                    break;

                case STATUS_ENUM.LOADING:
                    if (this.state.loading === false) {
                        this.setState({ loading: true });
                    }
                    break;

                default:
                    break;
            };
        };

        if (isNotLoaded(this.props.token)) {
            this.props.RequestToken({ request: { username: userName } });
        };
    };

    private requestValidateShopGroup = (): void => {
        if (isNotLoaded(this.props.validateShopGroup) && hasPayload(this.props.token)){
            this.props.RequestValidateShopGroup({ request: { username: userName }, token: this.props.token.payload.response.token });
        }
    };

}

const mapStateToProps = (state: IStore): IPartsSecuredRoutesStoreProps => ({
    token: getToken(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IPartsSecuredRoutesDispatchProps => ({
    RequestToken: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    RequestValidateShopGroup: (request: ITokenRequest): unknown => dispatch(validateShopGroupLoadAction(request))
});


export default connect(mapStateToProps, mapDispatchToProps)(PartsSecuredRoutes);
