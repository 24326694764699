import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_MODEL_REQUEST } from "./addModelConstants";
import { IAddModelLoadAction, IAddModelLoadFailedAction, IAddModelSuccessAction } from "./addModelActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";

type Actions =
    | IAddModelLoadAction
    | IAddModelSuccessAction
    | IAddModelLoadFailedAction
    | IFlushDataSuccessAction;

export const AddModelReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_MODEL_REQUEST.REQUEST:
            return loading;

        case IADD_MODEL_REQUEST.SUCCESS:
            return succeeded("Success");

        case IADD_MODEL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};