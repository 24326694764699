import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../server";
import { IGetAllPartsLoadAction, IGetAllPartsLoadFailedAction, IGetAllPartsSuccessAction } from "./getAllPartsActions";
import { IGET_ALL_PARTS_REQUEST } from "./getAllPartsConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";
import { IDELETE_PART_REQUEST } from "../delete/part/deletePartConstants";
import { IDeletePartSuccessAction } from "../delete/part/deletePartActions";
import { IPart } from "../getParts/getPartConstants";
import { IUPDATE_PART_REQUEST } from "../update/part/updatePartConstants";
import { IUpdatePartSuccessAction } from "../update/part/updatePartActions";

type Actions =
    | IGetAllPartsLoadAction
    | IGetAllPartsSuccessAction
    | IDeletePartSuccessAction
    | IGetAllPartsLoadFailedAction
    | IUpdatePartSuccessAction    
    | IFlushDataSuccessAction;

export const GetAllPartsReducer = (state: Server<PartsResponse<ById<IPart>>> = notLoaded, action: Actions): Server<PartsResponse<ById<IPart>>> => {
    switch (action.type) {
        case IGET_ALL_PARTS_REQUEST.REQUEST:
            return loading;

        case IGET_ALL_PARTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ALL_PARTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IDELETE_PART_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_PART_REQUEST.SUCCESS:
                if (hasPayload(state)) {
                    state.payload.response[action.data.id] = action.data;
                    return state;
                }
                return notLoaded;
    

        default:
            return state;
    }
};