
export enum IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST {
    REQUEST = "assignEquipmentTypeColumn/ASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST",
    SUCCESS = "assignEquipmentTypeColumn/ASSIGN_EQUIPMENT_TYPE_COLUMN_SUCCESS",
    FAILED = "assignEquipmentTypeColumn/ASSIGN_EQUIPMENT_TYPE_COLUMN_FAILED"
};

export interface IAssignEquipmentTypeColumnRequest {
    token: string;
    request: {
        Equipment_Type: string;
        Column_IDs: string; //Comma separated
        Created_By: string;
        Modified_By: string;
    };
};