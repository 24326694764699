import { IRate } from "../../getRates/getRatesConstants";

export enum IUPDATE_RATE_REQUEST {
    REQUEST = "updateRate/UPDATE_RATE_REQUEST",
    SUCCESS = "updateRate/UPDATE_RATE_SUCCESS",
    FAILED = "updateRate/UPDATE_RATE_FAILED"
};

export interface IUpdateRateRequest {
    request: IRate;
    token: string;
};