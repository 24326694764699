import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getAllParts } from "../../../redux/parts/getAllParts/getAllPartsAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IDispatch, IStore } from "../../../redux/reducers";
import { IGetAllPartRequest } from "../../../redux/parts/getAllParts/getAllPartsConstants";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getAllPartsLoadAction } from "../../../redux/parts/getAllParts/getAllPartsActions";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../shared/roleBaseAccessForShop";
import { hasPayload, isNotLoaded, Server } from "../../../redux/server";
import { IValidateShopGroup, ReadAndWrite, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IPart } from "../../../redux/parts/getParts/getPartConstants";
import { ById, PartsResponse } from "../../shared/publicInterfaces";
import { LAPaperWithPadding } from "../../shared/paper";
import PageSpacing from "../../shared/pageSpacing";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { ONE, userName, ZEROTH } from "../../shared/constExports";
import { EditRow } from "./part/partGrid";
import { IUpdatePartRequest } from "../../../redux/parts/update/part/updatePartConstants";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidation";
import { updatePartLoadAction } from "../../../redux/parts/update/part/updatePartActions";
import { getManufacturerListDDLoadAction } from "../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDActions";
import { IManufacturerRequest } from "../../../redux/parts/getManufacturers/getManufacturerConstants";
import { updatePart } from "../../../redux/parts/update/part/updatePartAccessor";
import { getManufacturerListDD } from "../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDAccessor";
import { IManufacturerListDD } from "../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDConstants";
import { validateShopGroup } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { ROUTE } from "../../routes";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";


interface IPartsReportStoreProps {
    UpdatePart: Server<string>;
    getToken: Server<PartsResponse<IToken>>;
    getPartsReport: Server<PartsResponse<ById<IPart>>>;
    validateShopGroup: Server<PartsResponse<IValidateShopGroup>>;
    ManufacturerListDD: Server<PartsResponse<IManufacturerListDD[]>>;
};

interface IPartsReportDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    RequestUpdatePart: (request: IUpdatePartRequest) => unknown;
    getPartsReportRequest: (data: IGetAllPartRequest) => unknown;
    RequestManufacturerListDD: (request: IManufacturerRequest) => unknown;
};


interface IPartsReportOwnProps {

};

type IView = "All" | "Not_Available";

interface IPartsReportState {
    edit: IPart;
    initialEdit: IPart | undefined;
    view: IView;
    open: boolean;
    error: ById<IFieldErrorKeyValue>;
};

const PartsReportStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IPartsReportProps = RouteComponentProps
    & IPartsReportStoreProps
    & IPartsReportDispatchProps
    & IPartsReportOwnProps;

class PartsReport extends PureComponent<IPartsReportProps, IPartsReportState> {

    public constructor(props: IPartsReportProps) {
        super(props);
        this.state = {
            error: {},
            open: false,
            view: "All",
            edit: {
                id: 0,
                name: "", 
                make: "",
                model: "",
                part_No: "",
                type: "",
                condition: "",
                location: "",
                description: "",
                notes: "",
                year: "",
                weight: "",
                available_Quantity: "",
                price: "",
                photos_1: "",
                photos_2: "",
                photos_3: "",
                alternate_Part_No: "",
                core_Charge: "",
                unit_No: "",
                discontinued: "",
                modified: "",
                modified_By: "",
                created: "",
                created_By: ""
            },
            initialEdit: undefined
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IPartsReportProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { error, open, edit, view } = this.state;
        const { getPartsReport, ManufacturerListDD } = this.props;
        const list = hasPayload(getPartsReport) ? Object.values(getPartsReport.payload.response) : [];
        const data = ((view === "Not_Available") && (list.length > 0)) ? Object.values(list).filter((x) => +x.available_Quantity === 0) : list;


        return (
            <PageSpacing title="Parts Report" description="SHOP - PARTS REPORT" fixedSpaceOnSmallerScreens={true}>
                <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>
                    <PartsReportStyles>

                        <h2 className="text-center">PARTS REPORT</h2>
                        <RadioGroup row aria-label="" name="radioGroup" value={view} onChange={this.handleRadioClick}>
                            <FormControlLabel value="All" control={<Radio />} label="All" />
                            <FormControlLabel value="Not_Available" control={<Radio />} label="Not Available" />
                        </RadioGroup>
                        <hr />

                        <LADevExtremeGrid
                            data={Object.values(data)}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            actionWidth={120}
                            removeStyleBtn={true}
                            pageSize={100}
                            pictureField="photos_1"
                            exportFileName="PARTS REPORT"
                            columns={[
                                { name: "", caption: "Photos", type: "picture" },
                                { name: "id", caption: "Item #", type: "number"},
                                { name: "name", caption: "Name", type: "string" },
                                { name: "part_No", caption: "Part No", type: "string" },
                                { name: "alternate_Part_No", caption: "Alternate Part No", type: "string" },
                                { name: "make", caption: "Make", type: "string" },  
                                { name: "model", caption: "Model", type: "string" },                                
                                { name: "location", caption: "Location", type: "string" },  
                                { name: "notes", caption: "Notes", type: "string" },               
                                { name: "condition", caption: "Condition", type: "string" },                                     
                                { name: "discontinued", caption: "Discontinued", type: "string" },   
                                { name: "created", caption: "Created", type: "datetime" },
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true  },
                                { name: "modified_By", caption: "Modified By", type: "string"  }
                            ]}
                        />
                    </PartsReportStyles>

                    {open && <EditRow
                        {...this.state}
                        index={0}
                        open={open}
                        value={edit}
                        error={error}
                        newAdd={false}
                        onSave={this.onSave}
                        onCancel={this.onEdit}
                        onChange={this.onChange}
                        onRotate={this.onRotate}
                        removeImage={this.removeImage}
                        onImageUpload={this.onImageUpload}
                        manufacturersList={hasPayload(ManufacturerListDD) ? ManufacturerListDD.payload.response : []}
                    />}

                </RoleBasedAccessForShop>
            </PageSpacing>
        );
    }

    private handleRadioClick = async (event: React.ChangeEvent<HTMLInputElement>, view: any): Promise<void> => {
        await this.setState({ view });
    };

    private onEdit = (e: any): void => {
        const disabled = (hasPayload(this.props.validateShopGroup) && this.props.validateShopGroup.payload.response.adminAccess === "R") ? true : undefined;
        if(e && e.row && disabled !== true){
  
            this.setState({ edit: e.row.data, initialEdit: e.row.data, open: true });
        } else {
            this.setState({ open: false , initialEdit: undefined});
        };
    };

    private onClick = (): void => {

    };

    private onChange = (name: string, value: string) => {

        const iS = {...this.state.edit};
        const initialVal = {...this.state.initialEdit};
        const iSError = this.state.error;

        if (iS) {
            switch (name) {
                case "name":
                    iS.name = value;
                    break;
                case "part_No":
                    iS.part_No = value;
                    break;
                case "make":
                    iS.make = value;
                    break;
                case "alternate_Part_No":
                    iS.alternate_Part_No = value;
                    break;
                case "discontinued":
                    iS.discontinued = value;
                    break;
                case "core_Charge":
                    if(iS.core_Charge === null || iS.core_Charge === undefined || iS.core_Charge === "") {
                        iS.core_Charge_Date = new Date().toLocaleDateString('en-CA');
                    }
                    iS.core_Charge = value;
                    if((iS.core_Charge !== null || iS.core_Charge !== undefined || iS.core_Charge !== "") && (!iS.core_Charge_Date)) {
                        iS.core_Charge_Date = new Date().toLocaleDateString('en-CA');
                    }
                    if(iS.core_Charge === null || iS.core_Charge === undefined || iS.core_Charge === "" || iS.core_Charge === initialVal?.core_Charge?.toString() ) {
                        iS.core_Charge_Date = initialVal.core_Charge_Date
                    }
                    break;
                case "core_Charge_Date":
                    iS.core_Charge_Date = value;
                    break;
                case "unit_No":
                    iS.unit_No = value;
                    break;
                case "type":
                    iS.type = value;
                    break;
                case "model":
                    iS.model = value;
                    break;
                case "condition":
                    iS.condition = value;
                    break;
                case "location":
                    iS.location = value;
                    break;
                // case "description":
                //     val.description = value;
                //     break;
                case "notes":
                    iS.notes = value;
                    break;
                // case "year":
                //     val.year = value;
                //     break;
                case "weight":
                    iS.weight = value;
                    break;
                case "available_Quantity":
                    if (Number(value) > 0)
                        iS.available_Quantity = value;
                    break;
                case "price":
                    if(iS.price === null || iS.price === undefined || iS.price === "") {
                        iS.price_Date = new Date().toLocaleDateString('en-CA');
                    }
                    iS.price = value;
                    if((iS.price !== null || iS.price !== undefined || iS.price !== "") && (!iS.price_Date)) {
                        iS.price_Date = new Date().toLocaleDateString('en-CA');
                    }
                    if(iS.price === null || iS.price === undefined || iS.price === "" || iS.price === initialVal?.price?.toString() ) {
                        iS.price_Date = initialVal.price_Date
                    }
                    break;
                case "price_Date":
                    iS.price_Date = value;
                    break;
            };

            if (value !== undefined) {
                if (value.length > 0) {
                    if (iSError[name])
                        delete iSError[name];
                } else {
                    if (name === "part_No" || name === "name" || name === "make" || name === "condition")
                        iSError[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                };
            };

            this.setState({ edit: iS, error: iSError });
        }
    };

    private onSave = (): void => {
        const iS = {...this.state.edit};

        if (hasPayload(this.props.getToken) && iS) {

            this.props.RequestUpdatePart({
                request: iS,
                token: this.props.getToken.payload.response.token
            });

            this.setState({ open: false });
        }
    };

    private onRotate = async (imgIndex: number, reverse: boolean): Promise<void> => {
        if (reverse)
            this.rotateImage(270, imgIndex);

        if (!reverse)
            this.rotateImage(90, imgIndex);
    };

    private rotateImage = (degree: number, imgIndex: number): void => {
        let img = new Image();

        const iS = {...this.state.edit};

        if (iS) {
            img.onload = async (): Promise<void> => {
                let canvas: any = document.createElement("canvas");
                let cContext = canvas.getContext("2d");
                let cw = img.width, ch = img.height, cx = 0, cy = 0;

                switch (degree) {
                    case 90:
                        cw = img.height;
                        ch = img.width;
                        cy = img.height * (-1);
                        break;
                    case 180:
                        cx = img.width * (-1);
                        cy = img.height * (-1);
                        break;
                    case 270:
                        cw = img.height;
                        ch = img.width;
                        cx = img.width * (-1);
                        break;
                };

                canvas.setAttribute("width", cw);
                canvas.setAttribute("height", ch);

                cContext.rotate(degree * Math.PI / 180);
                cContext.drawImage(img, cx, cy);

                if (imgIndex === 1) {
                    iS.photos_1 = canvas.toDataURL();
                } else if (imgIndex === 2) {
                    iS.photos_2 = canvas.toDataURL();
                } else if (imgIndex === 3) {
                    iS.photos_3 = canvas.toDataURL();
                };
                this.setState({ edit: iS });
            };

            if (imgIndex === 1) {
                img.src = iS.photos_1;
            } else if (imgIndex === 2) {
                img.src = iS.photos_2;
            } else if (imgIndex === 3) {
                img.src = iS.photos_3;
            };
        }
    };

    private removeImage = (index: number): void => {
         const iS = {...this.state.edit};

        if (iS) {
            if (index === 1)
                iS.photos_1 = "";

            if (index === 2)
                iS.photos_2 = "";

            if (index === 3)
                iS.photos_3 = "";

            this.setState({ edit: iS });
        }
    };

    private onImageUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {

        const iS = {...this.state.edit};

        new Promise<void>((resolve, reject): void => {
            if (event.target.files !== null && iS) {
                Object.values(event.target.files).forEach(async (x, index, array) => {

                    // const converted = await webPImageConverter(x, false);
                    // eslint-disable-next-line no-undef
                    const reader = new FileReader();

                    reader.onloadend = (e): void => {

                        if (reader.result) {
                            if ((iS.photos_1 === null) || (iS.photos_1.length === ZEROTH)) {
                                iS.photos_1 = reader.result.toString();
                            } else if ((iS.photos_2 === null) || (iS.photos_2.length === ZEROTH)) {
                                iS.photos_2 = reader.result.toString();
                            } else if ((iS.photos_3 === null) || (iS.photos_3.length === ZEROTH)) {
                                iS.photos_3 = reader.result.toString();
                            }

                            this.setState({ edit: iS });
                        }

                        if (index === array.length - ONE)
                            resolve();
                    };
                    reader.readAsDataURL(x);
                });
            }
        });
    };


    private callServer = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.partsaccess === true) {
                if (isNotLoaded(this.props.getToken))
                this.props.getTokenRequest({
                    request: {
                        username: userName
                    }
                });
    
                if (isNotLoaded(this.props.ManufacturerListDD)) {
                    if (hasPayload(this.props.getToken))
                        this.props.RequestManufacturerListDD({
                            request: {},
                            Keywords: "",
                            PageSize: 0,
                            PageNumber: 0,
                            token: this.props.getToken.payload.response.token
                        });
                };
    
    
            if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getPartsReport) && shopRoleCheck([ReadOnly, ReadAndWrite]))
                this.props.getPartsReportRequest({
                    token: this.props.getToken.payload.response.token
                });
            } else {
                this.props.history.push(ROUTE.PARTS.UNAUTHORIZED);
            }
        };
        
    };

}

const mapStateToProps = (state: IStore): IPartsReportStoreProps => ({
    getToken: getToken(state),
    UpdatePart: updatePart(state),
    getPartsReport: getAllParts(state),
    validateShopGroup: validateShopGroup(state),
    ManufacturerListDD: getManufacturerListDD(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IPartsReportDispatchProps => ({
    getPartsReportRequest: (data: IGetAllPartRequest) => dispatch(getAllPartsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    RequestUpdatePart: (request: IUpdatePartRequest): unknown => dispatch(updatePartLoadAction(request)),
    RequestManufacturerListDD: (request: IManufacturerRequest): unknown => dispatch(getManufacturerListDDLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartsReport);