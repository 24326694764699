import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST } from "./assignEquipmentTypeColumnConstants";
import { IAssignEquipmentTypeColumnLoadAction, IAssignEquipmentTypeColumnLoadFailedAction, IAssignEquipmentTypeColumnSuccessAction, assignEquipmentTypeColumnLoadFailedAction, assignEquipmentTypeColumnLoadSuccessAction } from "./assignEquipmentTypeColumnActions";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const assignEquipmentTypeColumnEpic: Epic = (
    action$: ActionsObservable<IAssignEquipmentTypeColumnLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAssignEquipmentTypeColumnSuccessAction | IAssignEquipmentTypeColumnLoadFailedAction> =>
    action$.ofType(IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.SHOP.ASSIGN_EQUIPMENT_TYPE_COLUMNS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<string>): IAssignEquipmentTypeColumnSuccessAction | IAssignEquipmentTypeColumnLoadFailedAction => {
                            if(response.message === "Success"){
                                return assignEquipmentTypeColumnLoadSuccessAction(response);
                            } else {
                                return assignEquipmentTypeColumnLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: PartsResponse<string>) => ActionsObservable.of(assignEquipmentTypeColumnLoadFailedAction(response.message)))
                    )
            )
        );