import { ByIdNumber, ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../clearRedux/clearReduxConstants";
import { IGET_RATES_REQUEST, IRate } from "./getRatesConstants";
import { IGetRatesLoadAction, IGetRatesLoadFailedAction, IGetRatesSuccessAction } from "./getRatesActions";
import { IAddRateSuccessAction } from "../add/rate/addRateActions";
import { IADD_RATE_REQUEST } from "../add/rate/addRateConstants";
import { IUPDATE_RATE_REQUEST } from "../update/rate/updateRateConstants";
import { IUpdateRateSuccessAction } from "../update/rate/updateRateActions";

type Actions =
    | IGetRatesLoadAction
    | IGetRatesSuccessAction
    | IGetRatesLoadFailedAction
    | IAddRateSuccessAction
    | IUpdateRateSuccessAction
    | IFlushDataSuccessAction;

export const GetRatesReducer = (state: Server<ToolRentalsResponse<ByIdNumber<IRate>>> = notLoaded, action: Actions): Server<ToolRentalsResponse<ByIdNumber<IRate>>> => {
    switch (action.type) {
        case IGET_RATES_REQUEST.REQUEST:
            return loading;

        case IGET_RATES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_RATES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_RATE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_RATE_REQUEST.SUCCESS:
            if (hasPayload(state)) {
                state.payload.response[action.data.id] = action.data;
                return state;
            }
            return notLoaded;

        default:
            return state;
    }
};