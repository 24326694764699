import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IManufacturerRequest } from "../../getManufacturers/getManufacturerConstants";
import { IGET_MANUFACTURER_LIST_DD_REQUEST, IManufacturerListDD } from "./getManufacturerListDDConstants";


export interface IGetManufacturerListDDLoadAction {
    type: IGET_MANUFACTURER_LIST_DD_REQUEST.REQUEST;
    data: IManufacturerRequest
}
export const getManufacturerListDDLoadAction = (data: IManufacturerRequest): IGetManufacturerListDDLoadAction => ({
    type: IGET_MANUFACTURER_LIST_DD_REQUEST.REQUEST,
    data
});
export interface IGetManufacturerListDDSuccessAction {
    type: IGET_MANUFACTURER_LIST_DD_REQUEST.SUCCESS;
    list: PartsResponse<IManufacturerListDD[]>;
}
export const getManufacturerListDDLoadSuccessAction = (list: PartsResponse<IManufacturerListDD[]>): IGetManufacturerListDDSuccessAction => ({
    type: IGET_MANUFACTURER_LIST_DD_REQUEST.SUCCESS,
    list
});
export interface IGetManufacturerListDDLoadFailedAction {
    type: IGET_MANUFACTURER_LIST_DD_REQUEST.FAILED;
    message: string;
}
export const getManufacturerListDDLoadFailedAction = (message: string): IGetManufacturerListDDLoadFailedAction => ({
    type: IGET_MANUFACTURER_LIST_DD_REQUEST.FAILED,
    message
});
