


const OutlinedRequiredStyle:any = {
    border: '2px solid orange'
};
const OutlinedRequiredStyleSelected:any = {
    border: '1px solid lightgrey'
};

export {OutlinedRequiredStyle, OutlinedRequiredStyleSelected}