import React from 'react';
import DataGrid, {
    Column, Export, FilterRow, FilterPanel, HeaderFilter, Paging, Pager, SearchPanel,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import { Grid, Button as MUIButton} from '@mui/material';
import {GetScheduledService} from '../../../api/scheduledservice/addScheduledService';
import {getSOCTEquipmentMoves} from '../../../api/equipmentmoves/equipmentMovesAPI';
import { getSOCTMeterReading } from '../../../api/meterreading/meterReadingAPI';
import splitDate from '../../../../../shared/splitDate';
import { RowPreparedEvent } from 'devextreme/ui/data_grid';

  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
function StyledComponent(cellData:any):any {
    switch(cellData.data.type){
        case 'Meter History':
            return <div style={{
                backgroundColor: 'rgb(146, 218, 150)',
                color: 'black',
                font: "Cookie, Arial, Helvetica, sans-serif",
                fontSize: '16px',
                padding: '5px'}}
                >{cellData.value}</div>;
        case 'Scheduled Service':
            return <div style={{
                backgroundColor: 'rgb(236, 176, 65)',
                color: 'black',
                font: "Cookie, Arial, Helvetica, sans-serif",
                fontSize: '16px',
                padding: '5px'}}
                >{cellData.value}</div>;
        case 'Custom Service':
            return <div style={{
                backgroundColor: 'rgb(122, 90, 150)',
                color: 'black',
                font: "Cookie, Arial, Helvetica, sans-serif",
                fontSize: '16px',
                padding: '5px'}}
                >{cellData.value}</div>;
        case 'Equipment Moves':
            return <div style={{
                backgroundColor: '#5383d3',
                color: 'black',
                font: "Cookie, Arial, Helvetica, sans-serif",
                fontSize: '16px',
                padding: '5px'}}
                >{cellData.value}</div>;
    }
}
interface IProps {
    history:any;
    data:any[];
    editData:any;
}
interface IState {
    reports:any[];
}
var reports:any[] = [];
export default class EditReportTable extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {reports};
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    onFormSave(){
        console.log(this.props.data);
    }
    async componentDidMount(){
        reports = [];
        var scheduled_services:any = await GetScheduledService(this.props.editData.unit_Number);
        var equipment_moves:any = await getSOCTEquipmentMoves(this.props.editData.unit_Number);
        var meter_history:any = await getSOCTMeterReading(this.props.editData.unit_Number);
        scheduled_services.map((elem:any) => {
            if(elem.custom_Service === "Yes"){
                elem.type = "Custom Service";
                elem.date = elem.completed_Date;
                elem.created = elem.created.replace("T"," ");
                elem.modified = elem.modified.replace("T"," ");
                reports.push(elem);
            }
            else{
                elem.type = "Scheduled Service";
                elem.date = elem.service_Date;
                elem.created = elem.created.replace("T"," ");
                elem.modified = elem.modified.replace("T"," ");
                reports.push(elem);
            }
        });
        equipment_moves.map((elem:any) => {
            elem.type = "Equipment Moves";
            elem.date = elem.date_Of_Move;
            elem.created = elem.created.replace("T"," ");
            elem.modified = elem.modified.replace("T"," ");
            reports.push(elem);
        });
        meter_history.map((elem:any) => {
            if(elem.meter_Replacement === "Yes"){
                elem.meter_Reading = elem.current_Hours
                elem.type = "Meter Replaced";
                elem.date = elem.date;
                elem.created = elem.created.replace("T"," ");
                elem.modified = elem.modified.replace("T"," ");
                reports.push(elem);
            }
            else{
                elem.meter_Reading = elem.current_Hours
                elem.type = "Meter History";
                elem.date = elem.date;
                elem.created = elem.created.replace("T"," ");
                elem.modified = elem.modified.replace("T"," ");
                reports.push(elem);
            }
        });
        this.setState({});
    }
    render(){
        return (
            <>
            <Grid container spacing={6}>
                            <Grid item xs={12}>
                             <h1>Reports</h1>
            <DataGrid focusedRowEnabled={true}
              hoverStateEnabled={true}
            //   rowAlternationEnabled={true}
                dataSource={reports}
                keyExpr="id"
                showBorders={true}
                onRowPrepared={(e: RowPreparedEvent<any, unknown>) => {
                    if(e.data){
                        switch(e.data.type){
                            case 'Meter History':
                                e.rowElement.style.backgroundColor = 'rgb(146, 218, 150)';
                                break;
                            case 'Scheduled Service':
                                e.rowElement.style.backgroundColor = 'rgb(236, 176, 65)';
                                break;
                            case 'Custom Service':
                                e.rowElement.style.backgroundColor = 'rgb(252, 148, 107)';
                                break;
                            case 'Equipment Moves':
                                e.rowElement.style.backgroundColor = 'rgb(170, 220, 243)';
                                break;
                            case 'Meter Replaced':
                                e.rowElement.style.backgroundColor = 'red';
                                break;
                        }
                    }
                }}
                wordWrapEnabled={true}
                >
                     <SearchPanel visible={true}
                        width={340}
                        placeholder="Search for..." />    
                    <FilterRow visible={true}></FilterRow>
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Paging enabled={true} defaultPageSize={20}/>
                    <Pager
                        visible={true}
                        allowedPageSizes={[20,40,60]}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} />
                     <Export enabled={true} allowExportSelectedData={true} />
                    <Column dataType="string" dataField="type" caption="Type" alignment="left"  headerCellRender={renderTitleHeader} />
                    <Column defaultSortOrder="desc" dataType="date" dataField="date" caption="Specified Date" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="meter_Reading" caption="Meter Reading" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="new_Location" caption="Location of Move" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="service_Type" caption="Service Type" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="comments" caption="Comments" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="meter_Replacement" caption="Meter Reset" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="datetime" dataField="created" caption="Created" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="datetime" dataField="modified" caption="Modified" alignment="left" headerCellRender={renderTitleHeader}/>
            </DataGrid>
            </Grid>
            </Grid>
            <Grid item xs={12}>
                            {/* <MUIButton style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</MUIButton>
                            <MUIButton style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onFormSave} variant="contained">Save</MUIButton> */}
                        </Grid>
            </>
        );
    }
}