import { ReactNode, PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ById, ByIdNumber, ToolRentalsResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IUnit, IUnitRequest } from "../../../../redux/toolRentals/getUnits/getUnitsConstants";
import { getUnits } from "../../../../redux/toolRentals/getUnits/getUnitsAccessor";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getUnitsLoadAction } from "../../../../redux/toolRentals/getUnits/getUnitsActions";
import { MEDIA_QUERY_PHONE } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithPadding } from "../../../shared/paper";
import { UnitGrid } from "./unitGrid";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { addUnitLoadAction } from "../../../../redux/toolRentals/add/unit/addUnitActions";
import { updateUnitLoadAction } from "../../../../redux/toolRentals/update/unit/updateUnitActions";
import { IUpdateUnitRequest } from "../../../../redux/toolRentals/update/unit/updateUnitConstants";
import { IAddUnitRequest } from "../../../../redux/toolRentals/add/unit/addUnitConstants";
import { updateUnit } from "../../../../redux/toolRentals/update/unit/updateUnitAccessor";
import { addUnit } from "../../../../redux/toolRentals/add/unit/addUnitAccessor";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import { IRate, IRateRequest } from "../../../../redux/toolRentals/getRates/getRatesConstants";
import { getClassAndTypesLoadAction } from "../../../../redux/toolRentals/powerDropdown/getClassAndType/getClassAndTypeActions";
import { getClassAndTypes } from "../../../../redux/toolRentals/powerDropdown/getClassAndType/getClassAndTypeAccessor";
import LARoleBasedAccess from "../../../shared/roleBaseAccess";
import { IRoles, IValidateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { userName } from "../../../shared/constExports";
import { validateShopGroup } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { ROUTE } from "../../../routes";

interface IUnitStoreProps {
    token: Server<ToolRentalsResponse<IToken>>;
    UnitList: Server<ToolRentalsResponse<ByIdNumber<IUnit>>>;
    UpdateUnit: Server<string>;
    AddUnit: Server<string>;
    ClassAndTypes: Server<ToolRentalsResponse<IRate[]>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IUnitDispatchProps {
    RequestUnitList: (request: IUnitRequest) => unknown;
    RequestAddUnit: (request: IAddUnitRequest) => unknown;
    RequestUpdateUnit: (request: IUpdateUnitRequest) => unknown;
    RequestClassAndTypes: (request: IRateRequest) => unknown;
};

interface IUnitOwnProps {

};

interface IUnitState {
    units: ByIdNumber<IUnit>;
    addEdit: IUnit | undefined;
    error: ById<IFieldErrorKeyValue>;
};

const UnitStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .moveRight {
        position: absolute;
        top: 0px;
        right: 10px;
    };
`;

type IUnitProps = RouteComponentProps
    & IUnitStoreProps
    & IUnitDispatchProps
    & IUnitOwnProps;

class Unit extends PureComponent<IUnitProps, IUnitState> {

    public constructor(props: IUnitProps) {
        super(props);
        this.state = {
            units: {},
            error: {},
            addEdit: undefined
        };
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IUnitProps, prevState: IUnitState): Promise<void> {
        if (this.props.UnitList !== prevProps.UnitList) {
            this.callReduxForData();
        }
    };

    public render(): ReactNode {

        const { ClassAndTypes, UnitList } = this.props;
        const { units, error, addEdit } = this.state;

        return (
            <LARoleBasedAccess error={true} roleFor={IRoles[1]}>
                <UnitStyles>
                    <LAGrid spacing={2}>
                        {(UnitList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                            <LACenteredLoading />
                        </LAGridItem>}

                        {(UnitList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                            <LAErrorBox text="Failed to Load Units" />
                        </LAGridItem>}

                        {(UnitList.kind === STATUS_ENUM.SUCCEEDED) && <>
                            <LAGridItem xs={12}>
                                <h2 className="text-center">{!addEdit ? "UNITS" : "ADD/UPDATE UNIT"}</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                {addEdit ?
                                    <UnitGrid
                                        {...this.state}
                                        data={addEdit}
                                        error={error}
                                        onEdit={this.handleEdit}
                                        onChange={this.onChange}
                                        onSave={this.handleSave}
                                        ddData={hasPayload(ClassAndTypes) ? Object.values(ClassAndTypes.payload.response) : []}
                                    /> :
                                    <LADevExtremeGrid
                                        add={true}
                                        export={true}
                                        onAdd={this.handleAdd}
                                        actionWidth={160}
                                        searchPanel={true}
                                        filterHeader={true}
                                        removeStyleBtn={true}
                                        exportFileName="Units"
                                        onClick={this.handleEdit}
                                        onEdit={this.handleEdit}
                                        data={Object.values(units)}
                                        columns={[
                                            { name: "unit_No", caption: "Unit No", type: "string" },
                                            { name: "description", caption: "Description", type: "string" },
                                            { name: "class", caption: "Class", type: "string" },
                                            { name: "type", caption: "Type", type: "string" },
                                            { name: "daily", caption: "Daily", type: "number" },
                                            { name: "weekly", caption: "Weekly", type: "number" },
                                            { name: "monthly", caption: "Monthly", type: "number" },
                                            { name: "size", caption: "Size", type: "string" },
                                            { name: "quantity", caption: "Quantity", type: "number" }
                                        ]}
                                    />}

                            </LAGridItem>

                            <Prompt
                                when={(addEdit !== undefined)}
                                message='You have unsaved changes, are you sure you want to leave?'
                            />
                        </>}
                    </LAGrid>

                    <RequestStatus requestStatus={this.props.AddUnit.kind} failedMessage="Failed to Add Unit" successMessage="Unit Successfully Added" />
                    <RequestStatus requestStatus={this.props.UpdateUnit.kind} failedMessage="Failed to Update Unit" successMessage="Unit Successfully Updated" />
                </UnitStyles>
            </LARoleBasedAccess>
        );
    }

    private onChange = (name: string, value: string) => {

        if (this.state.addEdit) {
            const iSError = this.state.error;
            if (value !== undefined) {
                if (value.length > 0) {
                    if (iSError[name])
                        delete iSError[name];
                } else {
                    iSError[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                };
            };

            this.setState({
                ...this.state,
                addEdit: {
                    ...this.state.addEdit,
                    [name]: value
                },
                error: iSError
            });
        }
    };

    private handleAdd = (): void => {
        let error: ById<IFieldErrorKeyValue> = {};
        error["unit_No"] = { key: "unit_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["description"] = { key: "description", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["class"] = { key: "class", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["type"] = { key: "type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["size"] = { key: "size", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        error["quantity"] = { key: "quantity", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

        const time = new Date().toISOString();

        const addEdit: IUnit = {
            type: "",
            class: "",
            weekly: "",
            monthly: "",
            description: "",
            daily: "",
            size: "",
            quantity: "",
            id: 0,
            unit_No: "",
            modified: time,
            modified_By: userName,
            created: time,
            created_By: userName
        };

        this.setState({ addEdit: addEdit, error });
    };


    private handleEdit = (event: any): void => {
        const { addEdit } = this.state;
        if (event === undefined && addEdit && addEdit.id === 0) {
            this.setState({ addEdit: undefined, error: {} });
        } else {
            if (event === undefined && addEdit && addEdit.id !== 0) {
                if (hasPayload(this.props.UnitList)) {
                    this.setState({ addEdit: undefined, error: {} });
                }
            } else if (event !== undefined) {
                this.setState({ addEdit: this.state.units[event.row.data.id], error: {} });
            }
        };
    };

    private callReduxForData = (): void => {
        if (hasPayload(this.props.validateShopGroup)) {
            if (this.props.validateShopGroup.payload.response.toolrentalsaccess === true) {
                if (isNotLoaded(this.props.UnitList))
                    this.sendRequest();

                if (isNotLoaded(this.props.ClassAndTypes)) {
                    if (hasPayload(this.props.token))
                        this.props.RequestClassAndTypes({
                            token: this.props.token.payload.response.token
                        });
                };

                if (hasPayload(this.props.UnitList))
                    this.setState({ units: this.props.UnitList.payload.response });
            } else {
                this.props.history.push(ROUTE.TOOL_RENTALS.UNAUTHORIZED);
            }
        };
    };

    private sendRequest = (): void => {
        if (hasPayload(this.props.token))
            this.props.RequestUnitList({
                token: this.props.token.payload.response.token
            });
    };

    private handleSave = (): void => {
        if (this.state.addEdit)
            if (this.state.addEdit.id !== 0) {
                this.updateRequest();
            } else {
                this.addRequest();
            }
    };

    private updateRequest = (): void => {
        if (hasPayload(this.props.token) && this.state.addEdit) {
            this.props.RequestUpdateUnit({
                request: this.state.addEdit,
                token: this.props.token.payload.response.token
            });

            this.setState({ addEdit: undefined });
        }
    };

    private addRequest = (): void => {
        if (hasPayload(this.props.token) && this.state.addEdit) {
            const iS = this.state.addEdit;
            if (iS) {
                this.props.RequestAddUnit({
                    request: iS,
                    Keywords: "",
                    PageSize: 20,
                    PageNumber: 1,
                    token: this.props.token.payload.response.token
                });
                this.setState({ addEdit: undefined });
            }
        }
    };

}

const mapStateToProps = (state: IStore): IUnitStoreProps => ({
    token: getToken(state),
    UnitList: getUnits(state),
    AddUnit: addUnit(state),
    UpdateUnit: updateUnit(state),
    ClassAndTypes: getClassAndTypes(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IUnitDispatchProps => ({
    RequestUnitList: (request: IUnitRequest): unknown => dispatch(getUnitsLoadAction(request)),
    RequestAddUnit: (request: IAddUnitRequest): unknown => dispatch(addUnitLoadAction(request)),
    RequestUpdateUnit: (request: IUpdateUnitRequest): unknown => dispatch(updateUnitLoadAction(request)),
    RequestClassAndTypes: (request: IRateRequest): unknown => dispatch(getClassAndTypesLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Unit);