export enum IGET_ORDERS_REQUEST {
    REQUEST = "getOrders/GET_ORDERS_REQUEST",
    SUCCESS = "getOrders/GET_ORDERS_SUCCESS",
    FAILED = "getOrders/GET_ORDERS_FAILED"
};

export interface IOrderRequest {
    // request: {};
    token: string;
    // allStatus: boolean;
};

export interface IOrder {
    id: number;
    project_Number: string;
    project_Name: string;
    po: string;
    rental_Status: string;
    units: IOrderUnit[];
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
};

export interface IOrderUnit {
    id: number;
    unit_No: string;
    class: string;
    type: string;
    damages: string;
    invoices: string;
    picked_Up_By: string;
    date_Out: string;
    date_Returned?: string | null;
    status_Set_Date?: string | null;
    quantity: string;
};

export const DamagesValues: {id: number, name: string}[] = [{id: 1, name: "None"},{id: 2, name: "Damaged"}];