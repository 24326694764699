import { ToolRentalsResponse } from "../../../../react/shared/publicInterfaces";
import { IRate } from "../../getRates/getRatesConstants";
import { IADD_RATE_REQUEST, IAddRateRequest } from "./addRateConstants";


export interface IAddRateLoadAction {
    type: IADD_RATE_REQUEST.REQUEST;
    data: IAddRateRequest
}
export const addRateLoadAction = (data: IAddRateRequest): IAddRateLoadAction => ({
    type: IADD_RATE_REQUEST.REQUEST,
    data
});
export interface IAddRateSuccessAction {
    type: IADD_RATE_REQUEST.SUCCESS;
    data: ToolRentalsResponse<IRate[]>;
}
export const addRateLoadSuccessAction = (data: ToolRentalsResponse<IRate[]>): IAddRateSuccessAction => ({
    type: IADD_RATE_REQUEST.SUCCESS,
    data
});
export interface IAddRateLoadFailedAction {
    type: IADD_RATE_REQUEST.FAILED;
    message: string;
}
export const addRateLoadFailedAction = (message: string): IAddRateLoadFailedAction => ({
    type: IADD_RATE_REQUEST.FAILED,
    message
});
