import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
  FilterBuilderPopup,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography} from '@mui/material';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import {RouteComponentProps} from 'react-router-dom';

  interface IProps extends RouteComponentProps{
    history:any
  }
var testdata = [{id:1,L_Unit_Number:'12341',current_Location:'Kelowna', new_Location:'1492',scheduled_Move:'No',comment:'test',date_Of_Move:'01/12/2022',created:'01/12/2022',modified:'01/12/2022',created_By:'admin',modified_By:'admin'},
{id:2,L_Unit_Number:'8494',current_Location:'Ritchie Bros', new_Location:'1492',scheduled_Move:'No',comment:'test',date_Of_Move:'01/12/2022',created:'01/12/2022',modified:'01/12/2022',created_By:'admin',modified_By:'admin'},
{id:3,L_Unit_Number:'703',current_Location:'Gibson Energy', new_Location:'Gibson Energy',scheduled_Move:'No',comment:'test',date_Of_Move:'01/12/2022',created:'01/12/2022',modified:'01/12/2022',created_By:'admin',modified_By:'admin'},
{id:4,L_Unit_Number:'608',current_Location:'Snow Dump - Ardrossan', new_Location:'1492',scheduled_Move:'No',comment:'test',date_Of_Move:'01/12/2022',created:'01/12/2022',modified:'01/12/2022',created_By:'admin',modified_By:'admin'}];
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
  const renderTitleHeader = (data:any) => {
    return <p style={{ color:'#5383d3',font: 'Cookie'}}>{data.column.caption}</p>
}
export default class Moves extends React.Component<IProps,{}>{
    render(){
        return (
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
              <MUIButton onClick={()=> {this.props.history.push('/soct/moves/create')}} style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" startIcon={<AddCircleIcon />}>Add New</MUIButton>
              <Typography className='dataGrid-title'>Equipment Moves</Typography>
            <DataGrid focusedRowEnabled={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={true}
               dataSource={testdata}
               keyExpr="id"
               showBorders={true}
              > 
           <SearchPanel visible={true}
       width={340}
       placeholder="Search for..." />    
     <Paging enabled={true} defaultPageSize={5}/>
     <Pager
       visible={true}
       allowedPageSizes={[5,10,20]}
       displayMode={'full'}
       showPageSizeSelector={true}
       showInfo={true}
       showNavigationButtons={true} />
       <FilterRow visible={true}></FilterRow>
       <FilterPanel visible={true} />
   <FilterBuilderPopup position={filterBuilderPopupPosition} />
     <HeaderFilter visible={true} allowSearch={true} />
     
     <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
     <Button text="View/Update" cssClass="dataGrid-edit-btn" onClick={(e:any) => this.props.history.push('/soct/moves/edit/id='+e.row.data.id)}></Button>
       </Column>
       <Column dataField="id" caption="id" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="L_Unit_Number" caption="L_Unit_Number" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="new_Location" caption="New Location" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="current_Location" caption="Current Location" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="scheduled_Move" caption="Scheduled Move" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="comment" caption="Comment" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="date_Of_Move" dataType="date" caption="Date Of Move" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="created" dataType="date" caption="Created" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="modified" dataType="date" caption="Modified" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="created_By" caption="Created By" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="modified_By" caption="Modified By" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
            </DataGrid>
            </Card>
            </Grid>
        );
    }
}