import { PARTS_END_POINTS } from './../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IDELETE_DISMANTLED_REQUEST } from "./deleteDismantledConstants";
import { IDeleteDismantledLoadAction, IDeleteDismantledLoadFailedAction, IDeleteDismantledSuccessAction, deleteDismantledLoadFailedAction, deleteDismantledLoadSuccessAction } from "./deleteDismantledActions";
import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const deleteDismantledEpic: Epic = (
    action$: ActionsObservable<IDeleteDismantledLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IDeleteDismantledSuccessAction | IDeleteDismantledLoadFailedAction> =>
    action$.ofType(IDELETE_DISMANTLED_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    PARTS_END_POINTS.GET_PARTS.PARTSDISMANTLEDEQUIPMENTS.DELETE_DISMANTLED,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<string>): IDeleteDismantledSuccessAction | IDeleteDismantledLoadFailedAction => {
                            return deleteDismantledLoadSuccessAction(response.message);
                        }),
                        catchError((response: PartsResponse<any>) => ActionsObservable.of(deleteDismantledLoadFailedAction(response.message)))
                    )
            )
        );