import { IPaginationRequest } from "../../../../react/shared/publicInterfaces";
import { IAddUpdatePartHeavyTruck } from "../../update/heavyTruck/updateHeavyTruckConstants";

export enum IADD_PART_HEAVY_TRUCK_REQUEST {
    REQUEST = "addPartHeavyTruck/ADD_PART_HEAVY_TRUCK_REQUEST",
    SUCCESS = "addPartHeavyTruck/ADD_PART_HEAVY_TRUCK_SUCCESS",
    FAILED = "addPartHeavyTruck/ADD_PART_HEAVY_TRUCK_FAILED"
};

export interface IAddPartHeavyTruckRequest extends IPaginationRequest {
    request: IAddUpdatePartHeavyTruck;
    token: string;
};