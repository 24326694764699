import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { ReportIcon } from "../shared/icons";

interface IReportDropDownProps {
    onDrawer?(): void;
};

type IOrderDropDown =
    RouteComponentProps &
    IReportDropDownProps;

class ReportDropDown extends PureComponent<IOrderDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoReportPage}>
                <ReportIcon />
                
                <span className="ml-2">
                    Report
                </span>
            </LAMenuItem>
        );
    };

    private gotoReportPage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.TOOL_RENTALS.REPORT.INDEX);
    };

}

export default ReportDropDown;
