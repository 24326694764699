export enum IUPDATE_PART_HEAVY_TRUCK_REQUEST {
    REQUEST = "updatePartHeavyTruck/UPDATE_PART_HEAVY_TRUCK_REQUEST",
    SUCCESS = "updatePartHeavyTruck/UPDATE_PART_HEAVY_TRUCK_SUCCESS",
    FAILED = "updatePartHeavyTruck/UPDATE_PART_HEAVY_TRUCK_FAILED"
};

export interface IUpdatePartHeavyTruckRequest {
    request: IAddUpdatePartHeavyTruck;
    token: string;
};

export interface IAddUpdatePartHeavyTruck {
    id: number;
    make: string; 
    model: string;
    hours: string;
    location: string;
    notes: string;
    photos_1: string;
    photos_2: string;
    photos_3: string;
    modified: string;
    modified_By: string;
    created?: string; //Only for add
    created_By?: string; //Only for add
    serial_No: string;
    unit_No: string;
};