import { PartsResponse } from "../../../../react/shared/publicInterfaces";
import { IPartPickupTruck } from "../../getPartsDismantledPickupTrucks/getPartsDismantledPickupTruckConstants";
import { IADD_PART_PICKUP_TRUCK_REQUEST, IAddPartPickupTruckRequest } from "./addPickupTruckConstants";


export interface IAddPartPickupTruckLoadAction {
    type: IADD_PART_PICKUP_TRUCK_REQUEST.REQUEST;
    data: IAddPartPickupTruckRequest
}
export const addPartPickupTruckLoadAction = (data: IAddPartPickupTruckRequest): IAddPartPickupTruckLoadAction => ({
    type: IADD_PART_PICKUP_TRUCK_REQUEST.REQUEST,
    data
});
export interface IAddPartPickupTruckSuccessAction {
    type: IADD_PART_PICKUP_TRUCK_REQUEST.SUCCESS;
    data: PartsResponse<IPartPickupTruck[]>;
}
export const addPartPickupTruckLoadSuccessAction = (data: PartsResponse<IPartPickupTruck[]>): IAddPartPickupTruckSuccessAction => ({
    type: IADD_PART_PICKUP_TRUCK_REQUEST.SUCCESS,
    data
});
export interface IAddPartPickupTruckLoadFailedAction {
    type: IADD_PART_PICKUP_TRUCK_REQUEST.FAILED;
    message: string;
}
export const addPartPickupTruckLoadFailedAction = (message: string): IAddPartPickupTruckLoadFailedAction => ({
    type: IADD_PART_PICKUP_TRUCK_REQUEST.FAILED,
    message
});
