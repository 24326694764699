import React, { useState } from "react";
import styled from "styled-components";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPopover } from "../../../shared/popOver";
import LALinkButton from "../../../shared/linkButton";
import LAInputButton from "../../../shared/inputButton";
import LAAutoComplete from "../../../shared/autoComplete";
import Not_Available_Image from "../../../../No-Image.jpg";
import { LAPaperWithPadding } from "../../../shared/paper";
import { ById, ByIdNumber, HasClass } from "../../../shared/publicInterfaces";
import { IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import LATextField from "../../../shared/textField";
import { undefinedFunction, ZEROTH } from "../../../shared/constExports";
import { LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { AddPhotoIcon, CancelIcon, EditIcon, SaveIcon, RotateRight, RotateLeft } from '../../../shared/icons';
import { DARK_GREY_COLOR, LIGHT_GREY_COLOR, SECONDARY_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { IManufacturerListDD } from "../../../../redux/parts/powerDropdown/getManufacturerListDD/getManufacturerListDDConstants";
import LATextArea from "../../../shared/textArea";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Gallery from "devextreme-react/gallery";
import { IConvertList } from "../../shop/equipment/equipment";
import { IPartHeavyTruck } from "../../../../redux/parts/getPartsDismantledHeavyTrucks/getPartsDismantledHeavyTruckConstants";
import { StatusValues } from "../../../../redux/parts/getPartsDismantledEquipments/getPartsDismantledEquipmentConstants";
import { PARTS_END_POINTS } from "../../../../redux/endpoints";


interface IPartHeavyTruckGridProps extends HasClass {
    editId: number | undefined;
    data: IPartHeavyTruck[];
    open: boolean;
    disabled?: true | undefined;
    newAdd: boolean;
    avaCompError: ByIdNumber<IFieldErrorKeyValue>;
    manufacturersList: IManufacturerListDD[];
    equipmentList: IConvertList[];
    error: ById<IFieldErrorKeyValue>;
    onSave: () => void;
    onPermanentDelete: (id: number) => void;
    onDelete: (index: number) => void;
    removeImage: (index: number) => void;
    onEdit: (id: number | undefined) => void;
    onChange: (name: string, value: string) => void;
    onRotate: (imgIndex: number, reverse: boolean) => void;
    onImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const DismantledGridStyles = styled.div`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };

    .edit-btn {
        position: absolute;
        right: 0;
        top: 0;
    };

    .delete-btn {
        position: absolute;
        right: 0;
        bottom: 0;
    };
    
    .upload-img {
        width: 140px;
        height: 240px;
        background-repeat: no-repeat;
        background-size: cover;
    };

    .itemImage {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};

        max-width: 172px;
        width: 120px;
        min-height: 120px;
        max-height: auto;
        float: left;
    };

    .disable-arrows {
        .lb-button.prev {
            display: none;
        };

        .lb-button.next {
            display: none;
        };
    };
`;

export const DismantledGrid: React.FC<IPartHeavyTruckGridProps> = React.memo((props: IPartHeavyTruckGridProps) =>
    <DismantledGridStyles>
        <LAGrid>
            {props.data.map((row: IPartHeavyTruck, index: number) => {
                const onRotate = (imgIndex: number, reverse: boolean): void => props.onRotate(imgIndex, reverse);
                return (
                    <LAGridItem xs={12}>
                        <Row {...props} index={index} value={row} onEdit={props.onEdit} onPermanentDelete={props.onPermanentDelete} disabled={props.disabled}/>
                        {(props.editId && (row.id === props.editId) && props.open) &&
                            <EditRow {...props} open={props.open} onRotate={onRotate} index={index} error={props.error} value={row} onImageUpload={props.onImageUpload}
                                onCancel={props.onEdit} onSave={props.onSave} onChange={props.onChange} removeImage={props.removeImage} newAdd={props.newAdd} disabled={props.disabled}/>}
                    </LAGridItem>
                );
            })}
        </LAGrid>
    </DismantledGridStyles>
);


interface IRowProps {
    disabled?: true | undefined;
    index: number;
    value: IPartHeavyTruck;
    onPermanentDelete: (id: number) => void;
    onEdit: (id: number | undefined) => void;
};

const Row: React.FC<IRowProps> = React.memo((props: IRowProps) => {
    const [openImageId, setImageId] = useState<number | undefined>(undefined);

    const images = getImage(props.value);
    const openImages = () => setImageId(props.value.id);
    const onCloseImage = () => setImageId(undefined);
    const onEdit = () => props.onEdit(props.value.id);

    return <LAPaperWithPadding>
        <LAGrid justify="center" alignItems="center">

            <LAGridItem xs={12} sm={12} md={4} lg={4} xl={4} className="mt-2 imageDiv">
                <div className={`${images.length > 1 ? "" : "disable-arrows"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {
                }}>
                    <Gallery
                        id="gallery"
                        height={150}
                        width={200}
                        loop={true}
                        slideshowDelay={0}
                        dataSource={images}
                        showNavButtons={images.length > 1 ? true : false}
                        showIndicator={false}
                        onItemClick={openImages}
                    />

                    {((openImageId !== undefined) && (openImageId === props.value.id)) &&
                        <Lightbox
                            images={images}
                            onClose={onCloseImage}
                        />
                    }
                </div>
            </LAGridItem>

            <LAGridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                <LAPaperWithPadding>
                    <LAGrid>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>
                                Serial Number:
                            </strong>
                            <span> {props.value.serial_No}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>
                                Unit Number:
                            </strong>
                            <span> {props.value.unit_No}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>
                                Make:
                            </strong>
                            <span> {props.value.make}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>
                                Model:
                            </strong>
                            <span> {props.value.model}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>
                                Hours:
                            </strong>
                            <span> {props.value.hours}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>
                                Location:
                            </strong>
                            <span> {props.value.location}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>
                                Status:
                            </strong>
                            <span> {props.value.status}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>
                                Notes:
                            </strong>
                            <span> {props.value.notes}</span>
                        </LAGridItem>

                        <LAIconButton className="edit-btn" icon={<EditIcon />} label="Edit" onClick={onEdit} />

                    </LAGrid>
                </LAPaperWithPadding>
            </LAGridItem>

        </LAGrid>
    </LAPaperWithPadding>

});


const EditRowStyles = styled(LAPaperWithPadding)`
    .upload-img {
        width: 140px;
        height: 140px;
        background-size: cover;
        background-repeat: no-repeat;
    };
`;

interface IEditRowProps {
    index: number;
    open: boolean;
    disabled?: true | undefined;
    newAdd: boolean;
    onSave: () => void;
    value: IPartHeavyTruck;
    onDelete: (index: number) => void;
    error: ById<IFieldErrorKeyValue>;
    onCancel: (id: undefined) => void;
    removeImage: (index: number) => void;
    manufacturersList: IManufacturerListDD[];
    equipmentList: IConvertList[];
    avaCompError: ByIdNumber<IFieldErrorKeyValue>;
    onChange: (name: string, value: string) => void;
    onRotate: (imgIndex: number, reverse: boolean) => void;
    onImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const EditRow: React.FC<IEditRowProps> = React.memo((props: IEditRowProps) => {

    const onCancel = () => props.onCancel(undefined);
    const err = Object.values(props.error).length > 0 ? true : undefined;
    const onMake = (e: unknown, value: IManufacturerListDD) => props.onChange("make", (value !== null) ? value.name : "");
    const onStatusChange = (e: unknown, value: any) => props.onChange("status", (value !== null) ? value.name : "");

    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <EditRowStyles>
            <LAGrid spacing={3}>

                <LAGridItem xs={12} sm={6} md={6}>
                    <LATextField name="serial_No" label="Serial Number" fullWidth={true} value={props.value.serial_No} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={12} sm={6} md={6}>
                    <LATextField name="unit_No" label="Unit Number" fullWidth={true} value={props.value.unit_No} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={12} sm={6} md={6}>
                    <LAAutoComplete
                        dropDownPlaceHolder="Make"
                        multiple={false}
                        getOptionLabel="name"
                        filterSelectedOptions={true}
                        autoHighlight={true}
                        onChange={onMake}
                        selectionRemove={undefinedFunction}
                        option={props.manufacturersList}
                        errorText={props.error["make"] ? props.error["make"].message : undefined}
                        value={props.manufacturersList.find(x => x.name === props.value.make)}
                        defaultValue={props.manufacturersList.find(x => x.name === props.value.make)}
                        disabled={props.disabled}
                    />
                </LAGridItem>

                <LAGridItem xs={12} sm={6} md={6}>
                    <LATextField errorText={props.error["model"] ? props.error["model"].message : undefined}
                        name="model" label="Model" fullWidth={true} value={props.value.model} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={12} sm={6} md={6}>
                    <LATextField errorText={props.error["hours"] ? props.error["hours"].message : undefined}
                        name="hours" label="Hours" fullWidth={true} value={props.value.hours} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={12} sm={6} md={6}>
                    <LATextField errorText={props.error["location"] ? props.error["location"].message : undefined}
                        name="location" label="Location" fullWidth={true} value={props.value.location} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LAAutoComplete
                        filterSelectedOptions={true}
                        autoHighlight={true}
                        selectionRemove={undefinedFunction}
                        dropDownPlaceHolder="Status"
                        getOptionLabel="name"
                        name="status"
                        option={StatusValues}
                        multiple={false}
                        onChange={onStatusChange}
                        value={StatusValues.find(x => x.name === props.value.status)}
                        defaultValue={StatusValues.find(x => x.name === props.value.status)}
                        disabled={props.disabled}
                    />
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LATextArea errorText={props.error["notes"] ? props.error["notes"].message : undefined} rowsMax={8} rowsMin={3}
                        name="notes" label="Notes" fullWidth={true} value={props.value.notes} onChange={props.onChange} disabled={props.disabled}/>
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LAGrid>
                        <LAGridItem xs={4}>
                            {props.value.photos_1 && <div className="text-center">

                                <img className="upload-img" src={getImageUrlOrBase64(props.value.photos_1)} alt="part_photo_1" />
                                {props.newAdd && <>
                                    <br />
                                    <LAIconButton label="Rotate Left" icon={<RotateLeft />} onClick={() => props.onRotate(1, true)} />
                                    <LAIconButton label="Rotate Right" icon={<RotateRight />} onClick={() => props.onRotate(1, false)} />
                                </>}
                                <br />
                                {props.disabled !== true && <LALinkButton label="Remove" onClick={() => props.removeImage(1)} />}
                            </div>}
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            {props.value.photos_2 && <div className="text-center">

                                <img className="upload-img" src={getImageUrlOrBase64(props.value.photos_2)} alt="part_photo_2" />
                                {props.newAdd && <>
                                    <br />
                                    <LAIconButton label="Rotate Left" icon={<RotateLeft />} onClick={() => props.onRotate(2, true)} />
                                    <LAIconButton label="Rotate Right" icon={<RotateRight />} onClick={() => props.onRotate(2, false)} />
                                </>}
                                <br />
                                {props.disabled !== true && <LALinkButton label="Remove" onClick={() => props.removeImage(2)} />}
                            </div>}
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            {props.value.photos_3 && <div className="text-center">

                                <img className="upload-img" src={getImageUrlOrBase64(props.value.photos_3)} alt="part_photo_3" />
                                {props.newAdd && <>
                                    <br />
                                    <LAIconButton label="Rotate Left" icon={<RotateLeft />} onClick={() => props.onRotate(3, true)} />
                                    <LAIconButton label="Rotate Right" icon={<RotateRight />} onClick={() => props.onRotate(3, false)} />
                                </>}
                                <br />
                                {props.disabled !== true && <LALinkButton label="Remove" onClick={() => props.removeImage(3)} />}
                            </div>}
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAInputButton
                                label="Add Photo"
                                onChange={props.onImageUpload}
                                startIcon={<AddPhotoIcon />}
                                disabled={props.disabled}
                            />
                        </LAGridItem>

                    </LAGrid>
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LASaveAndCancelButton
                        onSave={props.onSave}
                        onCancel={onCancel}
                        saveIcon={<SaveIcon color={WHITE_COLOR} />}
                        cancelIcon={<CancelIcon color={WHITE_COLOR} />}
                        disableSave={(Object.values(props.avaCompError).length > 0 ? true : err) || props.disabled}
                    />
                </LAGridItem>

            </LAGrid>
        </EditRowStyles>
    </LAPopover >
});

const getImage = (part: IPartHeavyTruck): string[] => {
    const images: string[] = [];

    if (part.photos_1 != null) {
        images.push(getImageUrlOrBase64(part.photos_1));
    }
    if (part.photos_2 != null)
        images.push(getImageUrlOrBase64(part.photos_2));

    if (part.photos_3 != null)
        images.push(getImageUrlOrBase64(part.photos_3));

    if (images.length === ZEROTH)
        images.push(Not_Available_Image);

    return images;
};

const getImageUrlOrBase64 = (value: string): string => {

    if(value.includes("data:image/")){
        return value;
    } else {
        return PARTS_END_POINTS.GET_PARTS.PARTS_DISMANTLED_HEAVY_TRUCKS.PHOTO + value;
    }
};