export enum IDELETE_PART_REQUEST {
    REQUEST = "deletePart/DELETE_PART_REQUEST",
    SUCCESS = "deletePart/DELETE_PART_SUCCESS",
    FAILED = "deletePart/DELETE_PART_FAILED"
};

export interface IDeletePartRequest {
    request: {
        ItemID: number;
    };
    token: string;
};