import React from "react";
import { ById, HasClass } from "../../../shared/publicInterfaces";
import { LAIconButton } from "../../../shared/buttons";
import { CancelIcon, SaveIcon } from "../../../shared/icons";
import { LAPaperWithLessPadding } from "../../../shared/paper";
import LATextField from "../../../shared/textField";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IProject, StatusValues } from "../../../../redux/toolRentals/getProjects/getProjectsConstants";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { GREEN_COLOR, LIGHT_GREY_COLOR, RED_COLOR, SECONDARY_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAAutoComplete from "../../../shared/autoComplete";
import { undefinedFunction } from "../../../shared/constExports";
import { IFieldErrorKeyValue } from "../../../shared/fieldValidation";
import styled from "styled-components";

interface IProjectGridProps extends HasClass {
    data: IProject;
    error: ById<IFieldErrorKeyValue>;
    onSave: () => void;
    onEdit: (id: number | undefined) => void;
    onChange: (name: string, value: string) => void;
};

const ProjectGridStyles = styled.div`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };
`;


export const ProjectGrid: React.FC<IProjectGridProps> = React.memo((props: IProjectGridProps) =>
    {
        const onCancel = (): void => props.onEdit(undefined);
        return <ProjectGridStyles>
            <TableContainer component={LAPaperWithLessPadding}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow className="header-row">

                            <TableCell className="title" align="center">PROJECT NUMBER</TableCell>
                            <TableCell className="title" align="center">PROJECT NAME</TableCell>
                            <TableCell className="title" align="center">COMPANY</TableCell>
                            <TableCell className="title" align="center">COORDINATOR</TableCell>
                            <TableCell className="title" align="center">STATUS</TableCell>
                            <TableCell className="title" align="center">EDIT</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <EditRow {...props} error={props.error} value={props.data} onCancel={onCancel} onChange={props.onChange} onSave={props.onSave} />
                    </TableBody>
                </Table>
            </TableContainer>
        </ProjectGridStyles>;
    }
);


interface IEditRowProps {
    value: IProject;
    onSave: () => void;
    error: ById<IFieldErrorKeyValue>;
    onCancel: (id: undefined) => void;
    onChange: (name: string, value: string) => void;
};

const EditRow: React.FC<IEditRowProps> = React.memo((props: IEditRowProps) => {
    const onCancel = () => props.onCancel(undefined);
    const err = Object.values(props.error).length > 0 ? true : undefined;
    const onStatusChange = (e: unknown, value: { id: number, name: string }) => props.onChange("status", (value !== null) ? value.name : "");

    return <TableRow key={props.value.id}>
        <TableCell component="th" scope="row" align="center">
            <LATextField errorText={props.error["project_Number"] ? props.error["project_Number"].message : undefined}
                name="project_Number" label="Project Number" value={props.value.project_Number}
                onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LATextField errorText={props.error["project_Name"] ? props.error["project_Name"].message : undefined}
                name="project_Name" label="Project Name" fullWidth={true}
                value={props.value.project_Name} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LATextField errorText={props.error["company"] ? props.error["company"].message : undefined}
                name="company" label="Company" value={props.value.company} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LATextField errorText={props.error["coordinator"] ? props.error["coordinator"].message : undefined}
                name="coordinator" label="Coordinator" value={props.value.coordinator} onChange={props.onChange}
            />
        </TableCell>

        <TableCell align="center">
            <LAAutoComplete
                dropDownPlaceHolder="Status"
                multiple={false}
                getOptionLabel="name"
                filterSelectedOptions={true}
                autoHighlight={true}
                onChange={onStatusChange}
                selectionRemove={undefinedFunction}
                option={StatusValues}
                errorText={props.error["status"] ? props.error["status"].message : undefined}
                value={StatusValues.find(x => x.name === props.value.status)}
                defaultValue={StatusValues.find(x => x.name === props.value.status)}
            />
        </TableCell>

        <TableCell align="right">
            <LAGrid spacing={1}>
                <LAGridItem xs={6}>
                    <LAIconButton disabled={err} icon={<SaveIcon color={err ? LIGHT_GREY_COLOR : GREEN_COLOR} />} label="Save" onClick={props.onSave} />
                </LAGridItem>

                <LAGridItem xs={6}>
                    <LAIconButton icon={<CancelIcon color={RED_COLOR} />} label="Cancel" onClick={onCancel} />
                </LAGridItem>
            </LAGrid>
        </TableCell>
    </TableRow>
});