import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { ProjectIcon } from "../shared/icons";

interface IProjectDropDownProps {
    onDrawer?(): void;
};

type IProjectDropDown =
    RouteComponentProps &
    IProjectDropDownProps;

class ProjectDropDown extends PureComponent<IProjectDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoProjectPage}>
                <ProjectIcon />
                
                <span className="ml-2">
                    Projects
                </span>
            </LAMenuItem>
        );
    };

    private gotoProjectPage = (): void => {
        if(this.props.onDrawer)
            this.props.onDrawer();

        this.props.history.push(ROUTE.TOOL_RENTALS.PROJECT.INDEX);
    };

}

export default ProjectDropDown;
