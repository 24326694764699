import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IUPDATE_PROJECT_REQUEST } from "./updateProjectConstants";
import { IUpdateProjectLoadAction, IUpdateProjectLoadFailedAction, IUpdateProjectSuccessAction } from "./updateProjectActions";

type Actions =
    | IUpdateProjectLoadAction
    | IUpdateProjectSuccessAction
    | IUpdateProjectLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateProjectReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_PROJECT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_PROJECT_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_PROJECT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};