import React from "react";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import { DARK_GREY_COLOR, DARK_WHITE, MAIN_COLOR, MEDIA_QUERY_PHONE, RED_COLOR, WHITE_COLOR } from "../../react/shared/theme";
import logo from "../../logo.png";
import { LAButton, LASecondaryButton } from "../../react/shared/buttons";
import { authContext } from "../../adalConfig";
import ProjectDropDown from "./projectDropDown";
import OrderDropDown from "./orderDropDown";
import UnitDropDown from "./unitDropDown";
import RateDropDown from "./rateDropDown";
import { HomeIcon, LogOutIcon } from "../../react/shared/icons";
import { ROUTE } from "../../react/routes";
import ManufacturerDropDown from "./manufacturerDropDown";
import PartDropDown from "./partDropDown";
import ReportDropDown from "./reportDropDown";
import InvoiceDropDown from "./invoiceDropDown";
import { store } from "../../app";
import { flushDataRequestAction } from "../../redux/toolRentals/clearRedux/clearReduxActions";
import LARoleBasedAccess from "../shared/roleBaseAccess";
import { IRoles, IValidateShopGroup } from "../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import ShopDropDown from "./shopDropDown";
import { hasPayload, Server } from "../../redux/server";
import { ToolRentalsResponse } from "../shared/publicInterfaces";
import DismantledEquipmentDropDown from "./dismantledEquipmentDropDown";
import DismantledPickupTruckDropDown from "./dismantledPickupTruckDropDown";
import DismantledHeavyTruckDropDown from "./dismantledHeavyTruckDropDown";


const HeaderStyles = styled.div`
    .main-header {
        background-color: #cccccc;
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;

        .logo-container {
            display: flex;
            align-items: center;
            color: ${MAIN_COLOR};
            padding: 1rem 2rem;
            margin-right: 1.5rem; 
        }

        .header-btn {
            margin-left: auto;
            margin-right: 1rem;
        };
    };

    .sub-header {
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 100%;
        height: 45px;
        box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);
    };

    .logo-img {
        padding-left: 40px;
        width: 200px;
        height: 35px;
    };

    .webserver {
        color: ${DARK_WHITE};
    };

    @media (max-width: ${MEDIA_QUERY_PHONE}) {
        .hide-on-phone, .hide-on-phone * {
            display: none;
        }
        .main-header .logo-container {
            margin-right: 0.5rem;
            padding: 0, 1rem, 1rem, 1rem;
        }
        .main-header .logo-container img {
            margin-right: 0;
        }
    };

    .current {
        background-color: ${DARK_GREY_COLOR};
    };
`;

interface IHeaderProps extends RouteComponentProps {
    validateGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

const Header: React.FC<IHeaderProps> = React.memo((props: IHeaderProps) =>
    {

        const onHomeClick = (): void => {
           // props.history.push(ROUTE.INDEX);
           window.open('https://apps.sureway.ca', '_self');
        };

        return <HeaderStyles>
            <div className="main-header">
                <img src={logo} className="logo-img" alt="logo" title="logo" onClick={() => props.history.push(ROUTE.INDEX)} />

                <span className="webserver">
                    {hasPayload(props.validateGroup) ? props.validateGroup.payload.webserver : ""}
                </span>

                <div className="header-btn">
                    <LASecondaryButton label="Home" className="mr-2" startIcon={<HomeIcon color={RED_COLOR} />} onClick={onHomeClick} />
                    <LAButton label="Log Out" startIcon={<LogOutIcon color={WHITE_COLOR} />} onClick={logOutClick} />
                </div>
            </div>

            {(hasPayload(props.validateGroup) && (props.validateGroup.payload.response.adminAccess !== null)) &&
                ((props.location.pathname === ROUTE.INDEX) || props.location.pathname.includes(ROUTE.SHOP.INDEX)) &&
                <div className="sub-header">
                    <ShopDropDown {...props} />
                </div>}

            {check(props) && props.location.pathname.includes(ROUTE.TOOL_RENTALS.INDEX) &&
                <div className="sub-header">
                    <OrderDropDown {...props} />

                    <LARoleBasedAccess error={false} roleFor={IRoles[1]}>
                        <ProjectDropDown {...props} />
                    </LARoleBasedAccess>

                    <LARoleBasedAccess error={false} roleFor={IRoles[3]}>
                        <RateDropDown {...props} />
                    </LARoleBasedAccess>

                    <LARoleBasedAccess error={false} roleFor={IRoles[2]}>
                        <UnitDropDown {...props} />
                    </LARoleBasedAccess>

                    <ReportDropDown {...props} />
                    <LARoleBasedAccess error={false} roleFor={IRoles[4]}>
                        <InvoiceDropDown {...props} />
                    </LARoleBasedAccess>

                </div>}

            {check(props) && props.location.pathname.includes(ROUTE.PARTS.INDEX) &&
                <div className="sub-header">
                    <ManufacturerDropDown {...props} />
                    {/* <ModelDropDown {...props} /> */}
                    <PartDropDown {...props} />
                    <DismantledEquipmentDropDown {...props} />
                    <DismantledPickupTruckDropDown {...props} />
                    <DismantledHeavyTruckDropDown {...props} />
                </div>}

        </HeaderStyles>;
    }
);

export default Header;


export const logOutClick = (): void => {
    //Clear Redux
    store.dispatch(flushDataRequestAction());
    //Clear LocalStorage
    localStorage.clear();
    //Log Out Now
    authContext.logOut();
};

export const check = (props: RouteComponentProps): boolean => {
    switch (props.location.pathname) {
        case ROUTE.UNAUTHORIZED:
            return false;

        case ROUTE.TOOL_RENTALS.UNAUTHORIZED:
            return false;

        case ROUTE.PARTS.UNAUTHORIZED:
            return false;

        case ROUTE.INDEX:
            return false;

        default:
            return true;
    }
};

