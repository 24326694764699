import { IRate } from "../../getRates/getRatesConstants";
import { IUPDATE_RATE_REQUEST, IUpdateRateRequest } from "./updateRateConstants";


export interface IUpdateRateLoadAction {
    type: IUPDATE_RATE_REQUEST.REQUEST;
    data: IUpdateRateRequest
}
export const updateRateLoadAction = (data: IUpdateRateRequest): IUpdateRateLoadAction => ({
    type: IUPDATE_RATE_REQUEST.REQUEST,
    data
});
export interface IUpdateRateSuccessAction {
    type: IUPDATE_RATE_REQUEST.SUCCESS;
    data: IRate;
}
export const updateRateLoadSuccessAction = (data: IRate): IUpdateRateSuccessAction => ({
    type: IUPDATE_RATE_REQUEST.SUCCESS,
    data
});
export interface IUpdateRateLoadFailedAction {
    type: IUPDATE_RATE_REQUEST.FAILED;
    message: string;
}
export const updateRateLoadFailedAction = (message: string): IUpdateRateLoadFailedAction => ({
    type: IUPDATE_RATE_REQUEST.FAILED,
    message
});
