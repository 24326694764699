import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_PART_HEAVY_TRUCK_REQUEST } from "./updateHeavyTruckConstants";
import { IFlushDataSuccessAction } from "../../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../toolRentals/clearRedux/clearReduxConstants";
import { IUpdatePartHeavyTruckLoadAction, IUpdatePartHeavyTruckLoadFailedAction, IUpdatePartHeavyTruckSuccessAction } from "./updateHeavyTruckActions";

type Actions =
    | IUpdatePartHeavyTruckLoadAction
    | IUpdatePartHeavyTruckSuccessAction
    | IUpdatePartHeavyTruckLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdatePartHeavyTruckReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_PART_HEAVY_TRUCK_REQUEST.REQUEST:
            return loading;

        case IUPDATE_PART_HEAVY_TRUCK_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_PART_HEAVY_TRUCK_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};