import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

interface IProps{
    handleRoute:Function;
  }


export default class NavigationBar extends React.Component<IProps,{}>{
    constructor(props:any){
        super(props);
      
    }
    handleClick(elem:any){
        console.log(elem);
    }
    render(){
        return (
            <Box sx={{ display: 'flex', alignItems:"flex-start"}}>
                <MenuItem id='test1' onClick={() => this.props.handleRoute('home')}  className="SOCT-App-link">
                   Home
                </MenuItem>
                <MenuItem id='test2' onClick={() => this.props.handleRoute('sotc')} className="SOCT-App-link">
                   SOCT
                </MenuItem>
                <MenuItem id='test5' onClick={() => this.props.handleRoute('sites')} className="SOCT-App-link">
                  Working Sites
                </MenuItem>
                 <MenuItem id='test6' onClick={() => this.props.handleRoute('bulkedit')} className="SOCT-App-link">
                  Bulk Meter Adjustment
                </MenuItem>
                <MenuItem id='test7' onClick={() => this.props.handleRoute('meterapp')} className="SOCT-App-link">
                  Meter App Update
                </MenuItem>
            </Box>
        );
    }
}