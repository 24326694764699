import { ToolRentalsResponse } from ".././../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_CLASS_AND_TYPES_REQUEST } from "./getClassAndTypeConstants";
import { IGetClassAndTypesLoadAction, IGetClassAndTypesLoadFailedAction, IGetClassAndTypesSuccessAction } from "./getClassAndTypeActions";
import { IRate } from "../../getRates/getRatesConstants";
import { IADD_RATE_REQUEST } from "../../add/rate/addRateConstants";
import { IAddRateSuccessAction } from "../../add/rate/addRateActions";
import { IUPDATE_RATE_REQUEST } from "../../update/rate/updateRateConstants";
import { IUpdateRateSuccessAction } from "../../update/rate/updateRateActions";

type Actions =
    | IGetClassAndTypesLoadAction
    | IGetClassAndTypesSuccessAction
    | IGetClassAndTypesLoadFailedAction
    | IAddRateSuccessAction
    | IUpdateRateSuccessAction
    | IFlushDataSuccessAction;

export const GetClassAndTypesReducer = (state: Server<ToolRentalsResponse<IRate[]>> = notLoaded, action: Actions): Server<ToolRentalsResponse<IRate[]>> => {
    switch (action.type) {
        case IGET_CLASS_AND_TYPES_REQUEST.REQUEST:
            return loading;

        case IGET_CLASS_AND_TYPES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_CLASS_AND_TYPES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_RATE_REQUEST.SUCCESS:
            return notLoaded;
        
        case IUPDATE_RATE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};